import { Fade, Slide } from "@mui/material";
import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteMyApp, getMyAppDetail, myAppsList } from "../../../../api/apps";
import allAssetHeadingIcon from "../../../../images/apps_icon.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import exportIcon from "../../../../images/export.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import plus from "../../../../images/plus.svg";
import ThreeDotIcon from "../../../../images/three_dot.svg";
import uploadIcon from "../../../../images/upload.svg";
import { changeTitle } from "../../../../state/slices/header";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { delayTime, fadedelayTime, slideDirection } from "../../../../utils/transitionEffectParams";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import AddApp from "../AddApp/AddApp";
import EditMyApp from "../EditMyApp/EditMyApp";
import "./MyApps.scss";
import GetCapabilities from "../../../../utils/getCapabilities";


const ActionComponent = ({ setOpenAddApp }) => {
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const navigate = useNavigate();
  const UploadButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "8px 12px",
    color: "#717171",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const AddButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
    variant: "secondary",
  });

  return (
    <Stack direction="row" spacing={2} className="py-3">
      <div className="d-none">
        <UploadButton
          startIcon={<img src={exportIcon} alt="icon" id="center-blueShadow" />}
        >
          Export
        </UploadButton>
        <UploadButton
          startIcon={<img src={uploadIcon} alt="icon" id="center-blueShadow" />}
        >
          Bulk Upload
        </UploadButton>
      </div>
      {isCapable && isCapable.apps.add &&(<AddButton
        onClick={() => {
          setOpenAddApp((o) => !o);
        }}
        variant="outlined"
        startIcon={
          <img src={plus} alt="icon" id="center-blueShadow" />
        }
      >
        Add App
      </AddButton>)}

     { isCapable && isCapable.system_user.view && <AddButton
        onClick={() => {
          navigate(`/dashboard/mysystemusers`);
        }}
        variant="contained"

      >
        System Users
      </AddButton>}
    </Stack>
  );
};

function MyApps() {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const dispatch = useDispatch();
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const navigate = useNavigate();
  const [apps, setApps] = useState([]);
  const [systemUsers, setSystemUsers] = useState(null);
  const [app, setApp] = useState(null);
  const [totalApps, setTotalApps] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_MyApps") !== null
      ? localStorage.getItem("rowsPerPage_MyApps")
      : 5
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [openAddApp, setOpenAddApp] = useState(false);
  const [openEditApp, setOpenEditApp] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [appDetail, setAppDetail] = useState(null);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [deleteAppNames, setDeleteAppNames] = useState([]);
  const [openDeleteApp, setOpenDeleteApp] = useState(false);
  const [slideChecked, setSlideChecked] = useState(false);
  const pages_url = "dashboard/myApps";
  const customizeColumnDict = {
    "App Id": true,
    "IP Address": true,
    "App Type": true,
    "Port": true,
    "Default Database": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const title_heading = useSelector((state) => state.header.title); const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const appColumns = [
    { label: "App Id", value: "id" },
    { label: "App Name", value: "app_name" },
    { label: "IP Address", value: "ip_host" },
    { label: "App Type", value: "app_type" },
    { label: "Port", value: "port" },
    { label: "Default Database", value: "default_database" },
  ];

  const actionOptions = [{ label: "Delete", value: "delete" }];

  const getMyAppList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data } = await myAppsList({
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setApps(data.apps);
      setFilteredData(data.apps);
      setTotalApps(data.totalApps);
      setPending(false);
    }
  };

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getMyAppList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, app]);

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = apps.filter((object) => {
      return (
        object.app_name.toLowerCase().match(s.toLowerCase()) ||
        object.ip_host.toLowerCase().match(s.toLowerCase()) ||
        object.app_type.toLowerCase().match(s.toLowerCase()) ||
        object.id.toString().toLowerCase().match(s.toLowerCase()) ||
        object.port.toString().toLowerCase().match(s.toLowerCase()) ||
        object.default_database.toString().toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  useEffect(() => {
    dispatch(changeTitle("Apps"));
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_MyApps", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleAction = async () => {
    if (action === "delete") {
      handleDelete(null);
    }
  };

  const handleDelete = async (row) => {
    let temp = [];
    if (row) {
      temp = row;
    } else {
      temp = selectedRows;
    }
    setRowsToDelete(temp);
    let t = temp.map((object) => object.app_name);
    setDeleteAppNames(t);
    setOpenDeleteApp((o) => !o);
  };

  const handleDeleteAction = async (row) => {
    let ids = rowsToDelete.map((item) => item.id);
    const { data, error } = await deleteMyApp({ ids: ids });
    if (data !== null) {
      showSuccess(data.message);
      setApp(Object.create(null));
      setOpenDeleteApp((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const handleAppEdit = async (app_id) => {
    const { data, error } = await getMyAppDetail({ app_id: app_id });
    if (data !== null) {
      setAppDetail(data.app);
      setOpenEditApp((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    getMyAppList({ page, rowsPerPage, search, filterBy });
  };

  const columns = [
    {
      name: "App Id",
      selector: (row) => row.id,
      grow: 1,
      omit: !checkedStateDict["App Id"],
      sortable: true,
    },
    {
      name: "App Name",
      selector: (row) => row.app_name,
      grow: 1,
      sortable: true,
    },
    {
      name: "IP Address/Domain",
      selector: (row) => row.ip_host,
      grow: 1,
      omit: !checkedStateDict["IP Address"],
      width: "200px",
      sortable: true,
    },
    {
      name: "App Type",
      selector: (row) => row.app_type,
      grow: 1,
      omit: !checkedStateDict["App Type"],
      sortable: true,
    },
    {
      name: "Port",
      selector: (row) => row.port,
      grow: 1,
      omit: !checkedStateDict["Port"],
      sortable: true,
    },
    {
      name: "Default Database",
      selector: (row) => row.default_database,
      grow: 1,
      omit: !checkedStateDict["Default Database"],
      sortable: true,
    },
    isCapable && ( isCapable.apps.edit || isCapable.apps.delete|| isCapable.apps.allocate_apps||  isCapable.apps.deallocate_apps ) &&{
      name: "Action",
      cell: (row) => (
        <>
          <Nav>
            <NavDropdown
              id="nav-dropdown-systemuser"
              title={<img src={ThreeDotIcon} alt="action" />}
              menuVariant="primary"
            >
              {isCapable && isCapable.apps.edit && (<NavDropdown.Item onClick={() => handleAppEdit(row.id)}>
                Edit
              </NavDropdown.Item>)}
             {isCapable && isCapable.apps.delete && ( <NavDropdown.Item
                onClick={async () => {
                  handleDelete([row]);
                }}
              >
                Delete
              </NavDropdown.Item>)}
              {isCapable && isCapable.apps.allocate_apps && (<NavDropdown.Item
                onClick={() => {
                  navigate(`/dashboard/apps/main-allocate/${row.id}`);
                }}
              >
                Allocate
              </NavDropdown.Item>)}
              {isCapable && isCapable.apps.deallocate_apps && (<NavDropdown.Item
                onClick={() => {
                  navigate(`/dashboard/apps/main-deallocate/${row.id}`);
                }}
              >
                View Allocated
              </NavDropdown.Item>)}
            </NavDropdown>
          </Nav>
        </>
      ),
      middle: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Apps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/apps_icon.svg").default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <CustomModal open={openAddApp} handleClose={()=>{setOpenAddApp(o=>!o);}}>
                  <AddApp setOpenAddApp={setOpenAddApp} setApp={setApp} />
                </CustomModal>
                {appDetail && (
                  <CustomModal open={openEditApp} handleClose={()=>{setOpenEditApp(o=>!o);}}>
                    <EditMyApp
                      app={appDetail}
                      setOpenEditApp={setOpenEditApp}
                      setApp={setApp}
                    />
                  </CustomModal>
                )}
                {deleteAppNames.length > 0 && (
                  <CustomModal open={openDeleteApp} handleClose={()=>{setOpenDeleteApp(o=>!o);}}>
                    <ShowAlert
                      setOpenAlert={setOpenDeleteApp}
                      handleAlertAction={handleDeleteAction}
                      colName={deleteAppNames}
                      alertMsg="Below apps will be deleted :"
                      headingMsg="Delete Apps"
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="All Apps"
                        dataTableHeadingIcon={allAssetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      colCount !== null && (
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={6}
                        />
                      )
                    }
                    fixedHeader
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalApps}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    actions={<ActionComponent setOpenAddApp={setOpenAddApp} />}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={appColumns}
                        actionOptions={(isCapable && isCapable.apps.delete)?actionOptions:null}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        placeholder="all apps"
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
                    paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
                  />
                </div>

              </div>
            </div>
          </Fade>

        </div>
      </Slide>



    </>
  );
}

export default MyApps;

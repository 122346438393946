import { useDispatch, useSelector } from "react-redux";
import { showError } from "../../../../utils/showMessage";
import { MdWebAsset } from "react-icons/md";
import React, {useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import systemUserHeadingIcon from "../../../../images/apps_icon.svg";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import {getToken, myAppsListUser} from "../../../../api/apps";
import {changeTitle} from "../../../../state/slices/header";
import {GetColmn} from "../../../common/CustomColumn/customize_column";
import {challengeMFA, challengeMFAForResourceAccess, getEndUserMFAConfigurations} from "../../../../api/two_fa";
import CustomModal from "../../../common/CustomModal/CustomModal";
import MFAOnResourceAccess from "../../TwoFa/MFAOnResourceAccess/MFAOnResourceAccess";
import {
  getEndUserProductSettings,
  getProductSettings,
} from "../../../../api/customer";
import Loader from "../../../common/Loader/Loader";
import GetCapabilities from "../../../../utils/getCapabilities";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import Slide from "@mui/material/Slide";
import { delayTime,fadedelayTime, slideDirection } from "../../../../utils/transitionEffectParams";
import { Fade } from "@mui/material";
const UserApps = () => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height-280 + "px";
    const dispatch = useDispatch();
    const my_capabilities=GetCapabilities();
    const isCapable =my_capabilities[0]
    const isCapableEnduser=  my_capabilities[1] 
    const {serverUrl} = useSelector((state) => state.customer);
    const userid = useSelector((state) => state.user.user.id);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage_UserApps') !== null ? localStorage.getItem('rowsPerPage_UserApps') : 5);
    const [totalApps, setTotalApps] = useState(0);
    const [apps, setApps] = useState([]);
    const [filterBy, setFilterBy] = React.useState("");
    const [search, setSearch] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [open, setOpen] = useState(false);
    const [configuredMFAInfo, setConfiguredMFAInfo] = useState("");
    const [rowID, setRowID] = useState("");
    const [accessButtonLoading, setAccessButtonLoading] = useState(false);
    const [colCount, setColCount] = useState(null);
    const [pending, setPending] = useState(true);
    const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const title_heading = useSelector((state) => state.header.title);
  const pages_url = "dashboard/userApps";
  const customizeColumnDict = {
    "App Id": true,
    "IP Address": true,
    "App Type": true,
    "Port": true,
    "System Username": true,
    "Default Database": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);

  const appColumns = [
    { label: "App Id", value: "id" },
    { label: "App Name", value: "app_name" },
    { label: "IP Address", value: "ip_host" },
    { label: "App Type", value: "app_type" },
    { label: "Port", value: "port" },
    { label: "Default Database", value: "default_database" },
  ];

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getAppsList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage]);

  const getAppsList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await myAppsListUser({
      userid: userid,
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setApps(data.apps);
      setFilteredData(data.apps);
      setTotalApps(data.totalApps);
    }
    if (error !== null) {
      showError(error);
    }
    setPending(false)
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = apps.filter((object) => {
      return (
        object.app_name.toLowerCase().match(s.toLowerCase()) ||
        object.ip_host.toLowerCase().match(s.toLowerCase()) ||
        object.app_type.toLowerCase().match(s.toLowerCase()) ||
        object.id.toString().toLowerCase().match(s.toLowerCase()) ||
        object.port.toString().toLowerCase().match(s.toLowerCase()) ||
        object.default_database
          .toString()
          .toLowerCase()
          .match(s.toLowerCase()) ||
        object.system_user.system_display_username
          .toString()
          .toLowerCase()
          .match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  useEffect(() => {
    dispatch(changeTitle("Apps"));
  }, []);

  const handleSearch = (event) => {
    event.preventDefault();
    getAppsList({ page, rowsPerPage, search, filterBy });
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_UserApps", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const getConfiguredMFAMethod = async () => {
    const { data, error } = await challengeMFAForResourceAccess(userid);
    if (data != null) {
      setConfiguredMFAInfo(data);
      setOpen((o) => !o);
    }
    if (error != null) {
      showError(error);
    }
    setAccessButtonLoading(false);
  };

  const openAppTab = async (openModal = true, row_id = null) => {
    const { tokenData, tokenError } = await getToken();
    if (row_id == null) {
      row_id = rowID;
    }
    if (tokenData !== null) {
      const url = serverUrl + "/?resource_id=" + row_id + '&resource_type=' + 'apps' + '&token_key=' + tokenData.token_key  + '&token_value=' + tokenData.token_value + '&session_key=' + tokenData.session_key;
      window.open(url, "_blank");
      if (openModal) {
        setOpen((o) => !o);
      }
    }
    if (tokenError !== null) {
      showError(tokenError);
    }
  };

  const checkIfMFARequired = async (row_id) => {
    setAccessButtonLoading(true);
    const { data, error } = await getEndUserProductSettings();
    if (data != null) {
      if (data.MFAOverResourceAccess === 'false') {
        setAccessButtonLoading(false);
        openAppTab(false, row_id);
      }
      else {
        getConfiguredMFAMethod();
      }
    }
    if (error != null) {
      showError(error);
      setAccessButtonLoading(false);
    }
  };

  const columns = [
    {
      name: "App Id",
      selector: (row) => row.id,
      width: "120px",
      omit: !checkedStateDict["App Id"],
      grow: 1,
      sortable: true,
    },
    {
      name: "App Name",
      selector: (row) => row.app_name,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "IP Address",
      selector: (row) => row.ip_host,
      minWidth: "150px",
      omit: !checkedStateDict["IP Address"],
      sortable: true,
    },
    {
      name: "App Type",
      selector: (row) => row.app_type,
      minWidth: "150px",
      omit: !checkedStateDict["App Type"],
      sortable: true,
    },
    {
      name: "Port",
      selector: (row) => row.port,
      minWidth: "150px",
      omit: !checkedStateDict["Port"],
      sortable: true,
    },
    {
      name: "System Username",
      selector: (row) => row.system_user.system_display_username,
      minWidth: "150px",
      omit: !checkedStateDict["System Username"],
      sortable: true,
    },
    {
      name: "Default Database/SID",
      selector: (row) => row.default_database,
      minWidth: "150px",
      omit: !checkedStateDict["Default Database"],
      sortable: true,
    },

        ((isCapable && isCapable.apps.access_app)||(isCapableEnduser && isCapableEnduser.access_apps)) &&{
            name: "Action",
            cell: (row) => (
                ((isCapable && isCapable.apps.access_app)||(isCapableEnduser && isCapableEnduser.access_apps)) && <div role="button" className="text-decoration-none text-primary d-flex align-items-center"
                     onClick={() => {
                         setRowID(row.id);
                         checkIfMFARequired(row.id);
                     }}
                >
                    <div className="d-flex align-items-center"><MdWebAsset/></div>
                    <div className="ps-1">Access</div>
                </div>

            ),
            middle: true,
            minWidth: "50px"
        },

    ];
    return (

        <>  <Loader loading={accessButtonLoading} bar_loader={true} />
        <CustomModal open={open} width="48% !important" bgcolor="white" handleClose={()=>{setOpen(o=>!o);}}>
          <MFAOnResourceAccess
            userid={userid}
            setOpen={setOpen}
            configuredMFAInfo={configuredMFAInfo}
            serverUrl={serverUrl}
            rowID={rowID}
            openAppTab={openAppTab}
          />
        </CustomModal>
        {(() => {
          switch (title_heading) {
            case "Apps":
              return (
                <div className="heading_datable bg-white">
                  <span
                    style={{ color: "#50514F" }}
                    className="ff-poppins fs-24px fw-600"
                  >
                    <div className="d-flex align-items-center">
                      <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                        <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                          <img
                            src={
                              require("../../../../images/apps_icon.svg").default
                            }
                            alt="users round icon"
                          />{" "}
                          &nbsp; Apps
                        </span>
                        <br />
                      </div>
                    </div>
                  </span>
                </div>
              );
          }
        })()}
        <Slide timeout={delayTime}  direction={slideDirection} in={slideChecked}>
          <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
    <div>
    <div className="main_content_container p-3 mx-auto w-100">
              <div
                className="position-relative bg-white"
                style={{ height: `${datatable_height}` }}
              >
                <DataTable
                  title={
                    <ListHeading
                      dataTableHeadingText="My Apps"
                      dataTableHeadingIcon={systemUserHeadingIcon}
                    />
                  }
                  columns={columns}
                  data={filteredData}
                  className="rdt_container"
                  progressPending={pending}
                  progressComponent={
                    colCount !== null && (
                      <CustomDelaySkeleton
                        rowsPerPage={rowsPerPage}
                        colCount={colCount}
                        totalColumns={6}
                      />
                    )
                  }
                  fixedHeader
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                  pagination
                  paginationServer
                  paginationTotalRows={totalApps}
                  highlightOnHover
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  subHeader
                  subHeaderComponent={
                    <SubHeaderComponent
                      filterBy={filterBy}
                      setFilterBy={setFilterBy}
                      filterOptions={appColumns}
                      search={search}
                      setSearch={setSearch}
                      handleSearch={handleSearch}
                      placeholder="apps"
                      pages_url={pages_url}
                      checkedStateDict={checkedStateDict}
                      setCheckedStateDict={setCheckedStateDict}
                    />
                  }
                  subHeaderAlign="center"
                  paginationIconFirstPage={
                    <img src={FirstPageIcon} alt="first page" />
                  }
                  paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
                  paginationIconNext={<img src={NextPageIcon} alt="next" />}
                  paginationIconPrevious={
                    <img src={PreviousPageIcon} alt="previous" />
                  }
                />
              </div>
            </div>     
    </div>
  </Fade>
            
          </div>
        </Slide>
      </>
  );
};

export default UserApps;

import axios from "./axios_interceptor";
import { store } from "../state/store";
import { get_jwt_token, loginRedirect } from "./helper_funtions";

export const GetReportData = async (page, perPage, search, filterBy, path) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    const email = ""
    const statuss = ""
    const from_date = ""
    const to_date = ""
    let url = `/audits/${path}/?page=${page}&limit=${perPage}&search=${search}&filter_by=${filterBy}&email=${email}&status=${statuss}&from_date=${from_date}&to_date=${to_date}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response };
  }
};

export const GetAdvanceData = async (page, perPage, status, from_date, to_date, path) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const search = ""
    const filterBy = "advance"
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    path = 'active-usage'
    let url = `/audits/${path}/?page=${page}&limit=${perPage}&search=${search}&filter_by=${filterBy}&status=${status}&from_date=${from_date}&to_date=${to_date}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const GetAdvanceDataUser = async (email, from_date, to_date, page, perPage, path) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const search = ""
    const filterBy = "advance"
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const statuss = ""
    let url = `/audits/${path}/?page=${page}&limit=${perPage}&search=${search}&filter_by=${filterBy}&email=${email}&status=${statuss}&from_date=${from_date}&to_date=${to_date}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};


export const getAppsAccessReportData = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/apps-access/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getAssetsAccessReportData = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/assets-access/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};
export const getWebAppsAccessReportData = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/web-apps-access/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};
export const getAppsExecutedCommandsData = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/app-command-execute/?id=${data.id}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getAssetsExecutedCommandsData = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/asset-command-execute/?id=${data.id}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};


export const liveuserslist = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/users/live/?data=${JSON.stringify(data)}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
}

export const getMostActiveUserDetails = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/active-user-detail/?username=${data.username}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { updateRole } from "../../../../api/role";
import { showError, showSuccess } from "../../../../utils/showMessage";
import CrossIcon from "../../../../images/cross.svg";
import LightTooltip from "../../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "../icons/icon-info.svg";
import {RoleNameReg, DisplayNameReg} from "../../../common/regex/Regex";
import {Fade} from "@mui/material";
import { fadedelayTime } from "../../../../utils/transitionEffectParams";

const EditRole = ({ setOpenEditRole, setRoles, data }) => {
  const [role_id, setRole_id] = useState("");
  const [roleName, setRoleName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allowEdit, setAllowEdit] = useState(true);

  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  useEffect(() => {
    setRole_id(data.role_id);
    setRoleName(data.role_name);
    setDisplayName(data.display_name);
  }, []);

  useEffect(() => {
    if (roleName === "Customeradmin" || roleName === "Enduser") setAllowEdit(false)
    else setAllowEdit(true)
  }, [roleName])

  const editRole = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setLoading(true);
      const { data, error } = await updateRole({
        "role_id": role_id,
        "role_name": roleName,
        "display_name": displayName,
      });
      if (data !== null) {
        showSuccess(data.message);
        setRoles(new Object());
        setLoading(false);
        setOpenEditRole((o) => !o);
      }
      if (error !== null) {
        showError(error);
        setLoading(false);
      }
    }
  };

  return (
    <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <Container fluid className="edit_user_container mx-auto main_content_container">
      <div className="d-flex justify-content-between align-items-center  mb-4">
        <h2 className="main_content_heading">Edit Role</h2>
        <img
          src={CrossIcon}
          alt="close"
          className="cursor_pointer"
          onClick={() => setOpenEditRole((o) => !o)}
        />
      </div>
      <Form noValidate validated={validated} onSubmit={editRole}>
        <Stack gap={1}>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formPlaintextName"
          >
            <Form.Label column md={2} className="input_lable">
              Role Name<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Form.Control
                required
                type="text"
                pattern = {RoleNameReg}
                className="user_input_field"
                disabled = {!allowEdit}
                value={roleName}
                onChange={(event) => setRoleName(event.target.value)}
                placeholder="Enter Your Role Name"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid Role Name
                <LightTooltip title="Role Name can contain alphabets , underscore(_) , hyphen(-) and numbers but it should not start or end with  underscore(_) , hyphen(-). Minimum length = 4 .">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formNumber"
          >
            <Form.Label column md={3} className="input_lable">
              Display Name
            </Form.Label>
            <Col md={9}>
              <Form.Control
                type="text"
                pattern = {DisplayNameReg}
                className="user_input_field"
                value={displayName}
                onChange={(event) => setDisplayName(event.target.value)}
                placeholder="Enter Your Display Name"
              />
              <Form.Control.Feedback type="invalid">
              Please enter a valid Display Name
                <LightTooltip title="Minimum length = 4 .">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Row className="mb-3 justify-content-end">
            <Col sm="auto">
              <Button
                variant="light"
                as="input"
                className="user_cancel_button"
                type="button"
                value="Cancel"
                onClick={() => setOpenEditRole((o) => !o)}
              />
            </Col>
            <Col sm="auto" className="me-sm-2 p-0">
              <Button
                as="input"
                className="user_submit_button"
                type="submit"
                value="Submit"
                disabled={loading}
              />
            </Col>
          </Row>
        </Stack>
      </Form>
    </Container>         
  </div>
</Fade>
    
  );
};

export default EditRole;

import React, { useEffect, useState } from 'react'
import { changeTitle } from "../../../state/slices/header";
import { useDispatch, useSelector } from 'react-redux';
import sso from "../../../images/sso.svg"
import active from "../../../images/sso-active.svg"
import inactive from "../../../images/sso-inactive.svg"
import minio from "../../../images/sso-minio-logo.svg";
import sso_other from "../../../images/sso-other.svg";
import MiniOrange from './miniOrange';
import ExternalIDP from './externalIdp';
import { getConfiguredIdp } from '../../../api/sso';
import "./sso.scss"
import { showError, showSuccess } from '../../../utils/showMessage';
import EnableSSO from './EnableSSO';
import useWindowDimensions from '../../../utils/getHeightWidth';
import { Slide, Fade } from '@mui/material';
import { delayTime, slideDirection, fadedelayTime } from '../../../utils/transitionEffectParams';
import { Row, Col, Button } from 'react-bootstrap';
import save_button from '../../../images/save_button_icon.svg';
import GetCapabilities from '../../../utils/getCapabilities';
import { getOrganisationName } from '../../../api/branding';

const Sso = () => {
  const { width,height } = useWindowDimensions();
  let datatable_height = (height-185) + 'px'
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const [miniOActive,setMiniOActive]=useState()
  const [externalActive,setExternalActive]=useState()
  const [selectedServer,setSelectedServer]=useState("")
  const [noEntry,setNoEntry]=useState(true)
  const [enabled,setEnabled] = useState(false)
  const [minioconfigured,setMinioconfigured]=useState(false)
  const [domainurl,setDomainurl] = useState("")
  const dispatch = useDispatch();
  const title_heading = useSelector((state) => state.header.title);
  const [slideChecked, setSlideChecked] = useState(false);
  const [miniOrangeApplicationExists,setMiniOrangeApplicationExists] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState("");
  const [currentBrandingUrl, setCurrentBrandingUrl] = useState("");
  const { oauthUrl } = useSelector((state) => state.customer);
  let redirectURL = `${oauthUrl}sso/get-auth-code/`
  let currentBrandingURL = `${oauthUrl}sso/get-auth-code/` //this is url of mopam not of authcode.
  var oauthappname = "miniOrange";
  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const getConfiguredIDP=async()=>{
    try{
      const {data,error}=await getConfiguredIdp(oauthappname)
    if(data !== null){
      if(data['oauth'] === "miniorange"){
        setMiniOActive(true)
        setExternalActive(false)
        setNoEntry(false)
      }
      else if(data["oauth"]==="doesNotExist"){
        setNoEntry(true)
        setMiniOActive(false)
        setExternalActive(false)
        setEnabled(false);
      }
      else{
        let name=data['oauth']
        setExternalActive(true)
        setMiniOActive(false)
        setSelectedServer(name)
        setNoEntry(false)
      }
      if(data["ssochoice"] === "true"){
        if(data["oauth"]==="doesNotExist"){
          setEnabled(false);
        }else{
          setEnabled(true)
        }
        
      }else{
        setEnabled(false)
      }
      if(data["minioconfig"]==="true"){
      setDomainurl(data["domainurl"])
      setMinioconfigured(true)
    }
    else{
      setMinioconfigured(false)
    }
    if(data["miniOrangeAppExists"]==="True"){
      setMiniOrangeApplicationExists(true);
    }else{
      setMiniOrangeApplicationExists(false);
    }
    }
    }catch{
      setMinioconfigured(false)
    }
  }

  const getCustomerBrandingUrl = async()=>{
      const {data, error} = await getOrganisationName();
      if (process.env.NODE_ENV === "production") {
        redirectURL = `${window.location.protocol}//${data}/openid/sso/get-auth-code/`
      }
      if (process.env.NODE_ENV === "development") {
        redirectURL =`${window.location.protocol}//${data}:8002/openid/sso/get-auth-code/`;
      }
      setRedirectUrl(redirectURL);
      if (process.env.NODE_ENV === "production") {
        currentBrandingURL = `${window.location.protocol}//${data}`
      }
      if (process.env.NODE_ENV === "development") {
        currentBrandingURL =`${window.location.protocol}//${data}:8000`;
      }
      setCurrentBrandingUrl(currentBrandingURL);
  }
  useEffect(() => {
    dispatch(changeTitle("SSO"));
    getCustomerBrandingUrl()
},[])

    useEffect(()=>{
      getConfiguredIDP()
    },[])


    const handleMiniOIDP = ()=>{
      setNoEntry(false)
      setExternalActive(false)
      setMiniOActive(true)
    }
    const handleExternalIDP = ()=>{
      setNoEntry(false)
      setExternalActive(true)
      setMiniOActive(false)
    }
  return (
    <>
    {(() => {
            switch (title_heading) {
              case "SSO":
                return (
                 <div className="heading_datable bg-white">
                   <span
                    style={{ color: "#50514F" }}
                    className="ff-poppins fs-24px fw-600"
                  >
                    <div className="d-flex align-items-center">
                      <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                        <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("../../../images/sso.svg").default}
                          alt="sso icon"
                        /> &nbsp; Authentication
                        </span>
                        <br />
                      </div>
                    </div>
                  </span>
                  </div>
                );
            }
          })()}
             <Slide  timeout={delayTime}  direction={slideDirection} in={slideChecked}><div>
             <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
      <div className="position-relative bg-white overflow-auto" style={{height:`${datatable_height}`}}>
      <div className='unscrollable'>

      <div className='lines'/>
      {/* heading div start */}
    <div className='d-flex flex-lg-row justify-content-between align-items-center py-2 mx-5 my-2'>
      <div className='d-flex flex-lg-row justify-content-center align-items-center'>
          <div className='pe-3'><img className='logo-sso' src={sso} alt="SSO"/></div>
          <div className='sso-heading'>Single Sign On</div>
      </div>
      {!noEntry && <button class="sso_save_button fw-600 d-flex align-items-center justify-content-center" form={ miniOActive ? 'sso-form':'sso-form-other'}><img src={save_button} alt="save-button" height="20px" width="20px" /><div className='ps-2'>Save</div> </button>}

    </div>
    <div className='lines'/>

    </div>
      {/* select option div start */}
      <Row className='py-4 px-5 w-100'>
      <Col lg={8} md={12} className='ps-3 pe-5 pb-4'>
          {noEntry && <p className='ff-poppins'>Choose OAuthServer and Enable SSO.</p>}
          {noEntry &&
          <div className=" d-flex justify-content-start alert_box px-3 pt-3 border rounded">
          <p className="fw-bold ff-poppins fst-italic">Note : &nbsp;</p>
          <p>
          OAuthserver is not configured.
          Please select one from given options.
          </p>
        </div>
          }
          {miniOActive && <p className='ff-poppins'>MiniOrange provides best SSO functionality out there.</p>}
          {externalActive && <p className='ff-poppins'>Configured SSO functionality.</p>}
      </Col>
      <Col lg={4} md={12}  className=' px-3'>
          <Row>
            <Col md={6} sm={6} className='py-2 cursor_pointer' onClick={handleMiniOIDP} >
              <Row className={miniOActive ? ' border-active-card card-m-end selection-card rounded': 'border-inactive-card card-m-end selection-card rounded'}>
                <Col xs={9} className=' p-0 d-flex align-items-center justify-content-start'>
                  <div className='d-flex flex-column ps-4 text-break'>
                <img  alt="miniOrange logo" src={minio} height="40px" width="40px" />
                <div className='ff-poppins fs-12px py-2 fw-600'>miniOrange</div>
                <div className='ff-poppins fs-12px text-secondary'>OAuth</div>
                </div>
                </Col>
                <Col xs={3}  className=' p-0'>
                  <div className='mt-3 me-3 d-flex justify-content-end align-item-top'>
                  <img  alt='active logo'
              src={miniOActive ?active:inactive} />
                  </div>

                </Col>
              </Row>

            </Col>
            <Col md={6} sm={6} className='py-2 cursor_pointer'  onClick={handleExternalIDP}>
            <Row className={externalActive ? ' border-active-card card-m-start selection-card rounded': 'border-inactive-card card-m-start selection-card rounded'}>
            <Col xs={9} className=' p-0 d-flex align-items-center justify-content-start'>
                  <div className='d-flex flex-column ps-4 text-break'>
                <img alt="External IDP logo" src={sso_other} height="40px" width="40px" />
                <div className='ff-poppins fs-12px py-2 fw-600'>External</div>
                <div className='ff-poppins fs-12px text-secondary'>OAuth</div>
                </div>
                </Col>
                <Col xs={3}  className=' p-0'>
                  <div className='mt-3 me-3 d-flex justify-content-end align-item-top'>
                  <img  alt='active logo'
              src={externalActive ?active:inactive} />
                  </div>
                  </Col>

            </Row>
            </Col>
          </Row>

      </Col>
      </Row>

      <div className='lines lines-third'/>
      {/* Enable option div start */}
      <div className='mb-2'>
        <EnableSSO 
        enabled={enabled}
        setEnabled={setEnabled}
        />
      </div>
      <div className='lines' />
      {/* OAuthserver information form start */}
    <div>
      {miniOActive && <MiniOrange miniOrangeApplicationExists={miniOrangeApplicationExists} minioconfigured={minioconfigured} oauthappname={oauthappname} getConfiguredIDP={getConfiguredIDP} domainurl={domainurl} setDomainurl={setDomainurl} setMinioconfigured={setMinioconfigured} redirectUrl={redirectUrl} currentBrandingUrl={currentBrandingUrl}/>}
      {externalActive && <ExternalIDP selectedServer={selectedServer} setSelectedServer={setSelectedServer} redirectUrl={redirectUrl} currentBrandingUrl={currentBrandingUrl} getConfiguredIDP={getConfiguredIDP}/>}
    </div>
    </div>
    </div>
  </div>
</Fade>

    </div>
    </Slide>
    </>
  )
}

export default Sso;
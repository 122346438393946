import React, { useEffect, useState } from "react";
import { changeTitle } from "../../../../state/slices/header";
import { useDispatch, useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { myAssetsListUser } from "../../../../api/asset";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import systemUserHeadingIcon from "../../../../images/all_asset_icon.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import { showError } from "../../../../utils/showMessage";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import "./UsersAssets.scss";
import { MdWebAsset } from "react-icons/md"
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import { challengeMFAForResourceAccess } from "../../../../api/two_fa";
import { getToken } from "../../../../api/apps";
import { getEndUserProductSettings } from "../../../../api/customer";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide, Fade } from "@mui/material";
import { delayTime, fadedelayTime, slideDirection } from "../../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import Loader from "../../../common/Loader/Loader";
import CustomModal from "../../../common/CustomModal/CustomModal";
import MFAOnResourceAccess from "../../TwoFa/MFAOnResourceAccess/MFAOnResourceAccess";
import GetCapabilities from "../../../../utils/getCapabilities";

const UserAssets = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  let datatable_height = (height - 280) + 'px'
  const { serverUrl } = useSelector((state) => state.customer);
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0]
  const isCapableEnduser = my_capabilities[1]
  const userid = useSelector((state) => state.user.user.id);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage_UsersAssets') !== null ? localStorage.getItem('rowsPerPage_UsersAssets') : 5);
  const [totalAssets, setTotalAssets] = useState(0);
  const [assets, setAssets] = useState([]);
  const [filterBy, setFilterBy] = React.useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const pages_url = "dashboard/usersAssets";
  const customizeColumnDict = { "Asset Id": true, "App Type": true, "Port": true, "IP Address": true, "System Username": true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);

  const [open, setOpen] = useState(false);
  const [configuredMFAInfo, setConfiguredMFAInfo] = useState("");
  const [rowID, setRowID] = useState("");
  const [accessButtonLoading, setAccessButtonLoading] = useState(false);

  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const title_heading = useSelector((state) => state.header.title);
  const assetColumns = [
    { label: "Asset Id", value: "id" },
    { label: "Asset Name", value: "asset_name" },
    { label: "IP Address", value: "ip_host" },
    { label: "Asset Type", value: "asset_type" },
    { label: "Port", value: "port" },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitle("Assets"));
  }, [])
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getAssetsList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage]);

  const getAssetsList = async ({ page, rowsPerPage, search, filterBy }) => {
    setPending(true)
    const { data, error } = await myAssetsListUser({
      userid: userid,
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setAssets(data.assets);
      setFilteredData(data.assets);
      setTotalAssets(data.totalAssets);
      setPending(false)

    }
    if (error !== null) {
      showError(error);
      setPending(false)

    }
  };

  useEffect(() => {
    const result = assets.filter((object) => {
      return object.asset_name.toLowerCase().match(search.toLowerCase()) ||
        object.ip_host.toLowerCase().match(search.toLowerCase()) ||
        object.asset_type.toLowerCase().match(search.toLowerCase()) ||
        object.id.toString().toLowerCase().match(search.toLowerCase()) ||
        object.port.toString().toLowerCase().match(search.toLowerCase())

    })
    setFilteredData(result);
  }, [search])

  const handleSearch = (event) => {
    event.preventDefault();
    getAssetsList({ page, rowsPerPage, search, filterBy });
  }

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem('rowsPerPage_UsersAssets', newPerPage);
    setRowsPerPage(newPerPage);
  };

  const getConfiguredMFAMethod = async () => {
    const { data, error } = await challengeMFAForResourceAccess(userid);
    if (data != null) {
      setConfiguredMFAInfo(data);
      setOpen(o => !o);
    }
    if (error != null) {
      showError(error);
    }
    setAccessButtonLoading(false);
  }

  const openAppTab = async (openModal = true, row_id = null) => {
    const { tokenData, tokenError } = await getToken();
    if (row_id == null) {
      row_id = rowID;
    }
    if (tokenData !== null) {
      const url = serverUrl + "/?resource_id=" + row_id + '&resource_type=assets' + '&token_key=' + tokenData.token_key + '&token_value=' + tokenData.token_value + '&session_key=' + tokenData.session_key;
      window.open(url, "_blank");
      if (openModal) {
        setOpen(o => !o);
      }
    }
    if (tokenError !== null) {
      showError(tokenError);
    }
  }

  const checkIfMFARequired = async (row_id) => {
    setAccessButtonLoading(true);
    const { data, error } = await getEndUserProductSettings();
    if (data != null) {
      if (data.MFAOverResourceAccess === 'false') {
        setAccessButtonLoading(false);
        openAppTab(false, row_id);
      }
      else {
        getConfiguredMFAMethod();
      }
    }
    if (error != null) {
      showError(error);
      setAccessButtonLoading(false);

    }
  }


  const columns = [
    {
      name: "Asset Id",
      selector: (row) => row.id,
      width: "120px",
      grow: 1,
      omit: !checkedStateDict["Asset Id"],
      sortable: true,
    },
    {
      name: "Asset Name",
      selector: (row) => row.asset_name,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "IP Address",
      selector: (row) => row.ip_host,
      minWidth: "150px",
      omit: !checkedStateDict["IP Address"],
      sortable: true,
    },
    {
      name: "Asset Type",
      selector: (row) => row.asset_type,
      minWidth: "150px",
      omit: !checkedStateDict["App Type"],
      sortable: true,
    },
    {
      name: "Port",
      selector: (row) => row.port,
      minWidth: "150px",
      omit: !checkedStateDict["Port"],
      sortable: true,
    },

    {
      name: "System Username",
      selector: (row) => row.system_user.system_display_username,
      minWidth: "150px",
      omit: !checkedStateDict["System Username"],
      sortable: true,
    },
    ((isCapable && isCapable.assets.access_assets) || (isCapableEnduser && isCapableEnduser.access_assets)) && {
      name: "Action",
      cell: (row) => (
        <div role="button" className="text-decoration-none text-primary d-flex align-items-center"
          onClick={() => {
            setRowID(row.id);
            checkIfMFARequired(row.id);
          }}
        >
          <div className="d-flex align-items-center"><MdWebAsset /></div>
          <div className="ps-1">Access</div>
        </div>

      ),
      middle: true,
      minWidth: "50px"
    },

  ];
  return (
    <>
      <Loader loading={accessButtonLoading} bg_papper={true} />
      <CustomModal open={open} width="48% !important" bgcolor="white" handleClose={()=>{setOpen(o=>!o);}}>
        <MFAOnResourceAccess
          userid={userid}
          setOpen={setOpen}
          configuredMFAInfo={configuredMFAInfo}
          serverUrl={serverUrl}
          rowID={rowID}
          openAppTab={openAppTab}
        />
      </CustomModal>
      {(() => {
        switch (title_heading) {
          case "Assets":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("../../../../images/all_asset_icon.svg").default}
                          alt="users round icon"
                        /> &nbsp; Assets
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100" >
                <div className="position-relative bg-white" style={{ height: `${datatable_height}` }}>
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="My Assets"
                        dataTableHeadingIcon={systemUserHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    fixedHeader
                    progressPending={pending}
                    progressComponent={
                      colCount !== null && (
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={6}
                        />
                      )
                    }
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalAssets}
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                        filterOptions={assetColumns}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        placeholder="my assets"
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
                    paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
                  />
                </div>

              </div>
            </div>
          </Fade>

        </div>
      </Slide>
    </>
  );
};
export default UserAssets;

import React from "react";
import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import images from "../../../utils/images.js";
import GetCapabilities from "../../../utils/getCapabilities.js";

function Settings(props) {
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const { title } = props;
  const dispatch = useDispatch();
  const handleTitle = async () => {
    dispatch(changeTitle("Settings"));
  };

  return (
    <Accordion.Item
      eventKey="Settings"
      className={title === "Settings" ? "sidebar-button-border " : ""}
    >
      <Accordion.Header>
        <img
          className="mx-3 filter-active"
          src={require("./icons/settings.svg").default}
          height="22px"
          width="22px"
        />
        Settings
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup variant="flush" className="sidebar-list">
          {isCapable && isCapable.miscellaneous.setting.show_product_setting && (
            <ListGroup.Item className="py-0 pe-0 ps-2">
              <NavLink
                to="/dashboard/productSettings"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                onClick={handleTitle}
              >
                <img src={images.DotIcon} alt="dot-icon" width="4px" />
                <div className="ps-4">Product Settings</div>
              </NavLink>
            </ListGroup.Item>
          )}

          {isCapable && isCapable.miscellaneous.license.view_license && (
            <ListGroup.Item className="py-0 pe-0 ps-2">
              <NavLink
                to="/dashboard/license"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                onClick={handleTitle}
              >
                <img src={images.DotIcon} alt="dot-icon" width="4px" />
                <div className="ps-4">License</div>
              </NavLink>
            </ListGroup.Item>
          )}
          {isCapable && isCapable.miscellaneous.setting.configure_external_storage && (
            <ListGroup.Item className="py-0 pe-0 ps-2">
              <NavLink
                to="/dashboard/storageConfig"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                onClick={handleTitle}
              >
                <img src={images.DotIcon} alt="dot-icon" width="4px" />
                <div className="ps-4">Storage Configuration</div>
              </NavLink>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default Settings;

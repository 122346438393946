import React from "react";
import './ListHeading.scss';
const ListHeading = ({dataTableHeadingText,dataTableHeadingIcon}) => {
    return (
        <div className="d-flex flex-row justify-content-start align-items-center py-3 rdt_heading_container">
            <img src={dataTableHeadingIcon} alt="icon" id="center-blueShadow" className="me-3 main-heading-image" />
            <h2 className="main-heading">
                {dataTableHeadingText}
            </h2>
        </div>
    )
}

export default ListHeading;

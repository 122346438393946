import { Fade, Slide } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  allocateRolesToResource,
  notAllocatedToResourceRolesList,
} from "../../../../api/apps";
import assetHeadingIcon from "../../../../images/apps_icon.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import TimeComp from "../../../common/TimeComponent/TimeComp";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../../utils/transitionEffectParams";
import GetCapabilities from "../../../../utils/getCapabilities";
import { changeTitle } from "../../../../state/slices/header";
import { showError } from "../../../../utils/showMessage";
import { showSuccess } from "../../../../utils/showMessage";

const AllocateAppToRole = ({ appName, ActionComponent }) => {
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const { app_id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_AllocateAppToRole") !== null
      ? localStorage.getItem("rowsPerPage_AllocateAppToRole")
      : 5
  );
  const [rolesList, setRolesList] = useState([]);
  const [users, setUsers] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openAllocateRole, setOpenAllocateRole] = useState(false);
  const [toBeAllocate, setToBeAllocate] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [colName, setColName] = useState(null);
  const pages_url = "dashboard/allocateAppToRole";
  const customizeColumnDict = {
    "Display Name": true,
    ID: true,
    "Total Users": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [slideChecked, setSlideChecked] = useState(false);
  const title_heading = useSelector((state) => state.header.title);

  const UserColumns = [
    { label: "Role Name", value: "role_name" },
    { label: "Display Name", value: "display_name" },
  ];

  const actionOptions = [{ label: "Allocate", value: "allocate" }];

  useEffect(() => {
    setSlideChecked(true);
    dispatch(changeTitle("Apps"));
    setSlideChecked(true);
  }, []);

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    getRolesList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, users]);

  const getRolesList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await notAllocatedToResourceRolesList({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      resource_id: app_id,
      resource_type: "app",
      allocated: 0,
    });
    if (data !== null) {
      setRolesList(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalUsers(data.count);
      setFilteredData(data.results);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getRolesList({ page: 1, rowsPerPage, search, filterBy });
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = rolesList.filter((object) => {
      if (!filterBy) {
        return (
          object.role_name.toLowerCase().match(s.toLowerCase()) ||
          object.display_name.toLowerCase().match(s.toLowerCase())
        );
      } else {
        return object[filterBy.toLowerCase()].match(s.toLowerCase());
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_AllocateAppToRole", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "allocate") {
      handleAllocate(null);
    }
  };

  const handleAllocate = async (row) => {
    let rowsToAllocate = [];
    if (row) {
      rowsToAllocate = row;
    } else {
      rowsToAllocate = selectedRows;
    }
    setToBeAllocate(rowsToAllocate);
    let temp = [];
    temp = rowsToAllocate.map((item) => ({
      id: item.role_id,
      name: item.display_name,
    }));
    setColName(temp);
    setOpenAllocateRole((o) => !o);
  };

  const handleAllocateAction = async (finalFromDate, finalToDate, policy) => {
    let ids = toBeAllocate.map((item) => item.role_id);
    let count = ids.length;
    const { data, error } = await allocateRolesToResource({
      ids: ids,
      resource_id: app_id,
      resource_type: "app",
      allocate: 1,
      policy: policy,
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
    });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalUsers % rowsPerPage === count || rowsPerPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setUsers(Object.create(null));
      setOpenAllocateRole((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "Role Name",
      selector: (row) => row.role_name,
      minWidth: "150px",
    },
    {
      name: "Display Name",
      selector: (row) => row.display_name,
      minWidth: "150px",
      omit: !checkedStateDict["Display Name"],
    },
    {
      name: "ID",
      selector: (row) => row.role_id,
      minWidth: "150px",
      omit: !checkedStateDict["ID"],
    },
    {
      name: "Total Users",
      selector: (row) => row.total_users,
      minWidth: "150px",
      omit: !checkedStateDict["Total Users"],
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <p
            className="myassetslink"
            onClick={async () => {
              handleAllocate([row]);
            }}
          >
            <AiOutlineUserAdd></AiOutlineUserAdd>Allocate
          </p>
        </>
      ),
      middle: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Apps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/apps_icon.svg").default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName && (
                  <CustomModal
                    open={openAllocateRole}
                    handleClose={() => {
                      setOpenAllocateRole((o) => !o);
                    }}
                  >
                    <TimeComp
                      setOpenAlert={setOpenAllocateRole}
                      handleAlertAction={handleAllocateAction}
                      resource_id={app_id}
                      resource_type={"apps"}
                      colName={colName}
                      resource_name={appName}
                      target_entity_name={appName}
                      target_type="Roles"
                      alertMsg="Below roles will be allocated for App"
                      headingMsg="Allocate Roles"
                      show_policy={true}
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`App : ${appName}`}
                        dataTableHeadingIcon={assetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalUsers}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        actions={ActionComponent}
                        placeholder="role"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={UserColumns}
                        actionOptions={
                          isCapable && isCapable.apps.allocate_apps
                            ? actionOptions
                            : null
                        }
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default AllocateAppToRole;

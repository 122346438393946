import axios from './axios_interceptor';
import { get_jwt_token } from "./helper_funtions";
import { loginRedirect } from './helper_funtions';


export const GetColumnPreference=async(page_url)=>{
  
    try{
    const token=get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const url = `/customer/column/?page_url=${page_url}`;
    const response = await axios.get(url, config);
    return { data: response.data.data, error: null };
    } catch (e) { 
        return { data: null, error: e.response.data.errors };
    }
}

export const PostColumnPreference=async(clmnList,page_url)=>{
    try{
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
    const args={
        'page_url':page_url,
        'options':clmnList
      }
    const url = `/customer/column/`;
    const response = await axios.post(url,args,config);
    return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const PutColumnPreference = async (clmnList,page_url) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const args = {
      'page_url': page_url,
      'options': clmnList,
    };
    const url = `/customer/column/`;
    const response = await axios.put(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};
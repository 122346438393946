import { Fade, Slide } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import {
  extendWebAppAllocationRole,
  roleWebAppAllocationList,
  webAppDeAllocateToRoles,
} from "../../../../api/web_app";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import ThreeDotIcon from "../../../../images/three_dot.svg";
import { changeTitle } from "../../../../state/slices/header";
import GetCapabilities from "../../../../utils/getCapabilities";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import images from "../../../../utils/images";
import { showError, showSuccess } from "../../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../../utils/transitionEffectParams";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import TimeCompExtend from "../../../common/TimeComponent/TimeCompExtend";
import "../../Assets/AllocateAssets/viewusers.scss";
const DeAllocateWebAppFromRole = ({ webAppName, ActionComponent }) => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const { web_app_id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_DeAllocateWebAppFromRole") !== null
      ? localStorage.getItem("rowsPerPage_DeAllocateWebAppFromRole")
      : 5
  );
  const [rolesList, setRolesList] = useState([]);
  const [changeState, setChangeState] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openViewRole, setOpenViewRole] = useState(false);
  const [toBeDeAllocate, setToBeDeAllocate] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [totalRoles, setTotalRoles] = useState(0);
  const [firstPage, setFirstPage] = useState();
  const [colName, setColName] = useState(null);
  const [colName1, setColName1] = useState(null);
  const pages_url = "dashboard/deallocateWebAppsfromRole";
  const customizeColumnDict = {
    "Display Name": true,
    ID: true,
    "Total Users": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);

  const [openExtendUserModal, setOpenExtendUserModal] = useState(false);
  const [toBeExtend, setToBeExtend] = useState([]);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    dispatch(changeTitle("WebApps"));
    setSlideChecked(true);
  }, []);
  const title_heading = useSelector((state) => state.header.title);
  const UserColumns = [
    { label: "Role Name", value: "role_name" },
    { label: "Display Name", value: "display_name" },
  ];
  const actionOptions = [{ label: "Remove", value: "remove" }];
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getRolesList();
  }, [page, rowsPerPage, dispatch, changeState]);

  const getRolesList = async () => {
    const { data, error } = await roleWebAppAllocationList({
      web_app_id: web_app_id,
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filter_by: filterBy,
      action: "get_allocated",
    });
    if (data !== null) {
      setRolesList(data.roles);
      setTotalRoles(data.totalRoles);
      setFilteredData(data.roles);
      setPending(false);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    getRolesList();
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = rolesList.filter((object) => {
      if (!filterBy) {
        return (
          object.role_name.toLowerCase().match(s.toLowerCase()) ||
          object.display_name.toLowerCase().match(s.toLowerCase())
        );
      } else {
        return object[filterBy.toLowerCase()].match(s.toLowerCase());
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_DeAllocateWebAppFromRole", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "remove") {
      handleDeAllocate(null);
    }
  };

  const handleDeAllocate = async (row) => {
    let rowsToDeAllocate = [];
    if (row) {
      rowsToDeAllocate = row;
    } else {
      rowsToDeAllocate = selectedRows;
    }
    setToBeDeAllocate(rowsToDeAllocate);
    let temp = [];
    temp = rowsToDeAllocate.map((item) => item.display_name);
    setColName1(temp);
    setOpenViewRole((o) => !o);
  };

  const handleExtend = async (row) => {
    let rowsToExtend = [];
    if (row) {
      rowsToExtend = row;
    } else {
      rowsToExtend = selectedRows;
    }
    setToBeExtend(rowsToExtend);
    var temp;
    temp = rowsToExtend.map((item) => ({
      id: item.role_id,
      name: item.display_name,
    }));
    setColName(temp);
    setOpenExtendUserModal((o) => !o);
  };

  const handleDeAllocateAction = async () => {
    let role_ids = toBeDeAllocate.map((item) => item.role_id);
    const { data, error } = await webAppDeAllocateToRoles({
      web_app_ids: [web_app_id],
      role_ids: role_ids,
    });
    if (data !== null) {
      showSuccess(data.message);
      setChangeState(Object.create(null));
      setOpenViewRole((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const handleExtendFunction = async (finalFromDate, finalToDate) => {
    let role_ids = toBeExtend.map((item) => item.role_id);
    const { data, error } = await extendWebAppAllocationRole(web_app_id, {
      entity_type: "role",
      entity_id: role_ids[0],
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
    });

    if (data !== null) {
      showSuccess(data.message);
    }
    if (error !== null) {
      showError(error);
    }

    setOpenExtendUserModal((o) => !o);
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "Role Name",
      selector: (row) => row.role_name,
      minWidth: "150px",
    },
    {
      name: "Display Name",
      selector: (row) => row.display_name,
      minWidth: "150px",
      omit: !checkedStateDict["Display Name"],
    },
    {
      name: "ID",
      selector: (row) => row.role_id,
      minWidth: "150px",
      omit: !checkedStateDict["ID"],
    },
    {
      name: "Total Users",
      selector: (row) => row.total_users,
      minWidth: "150px",
      omit: !checkedStateDict["Total Users"],
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Nav>
            <NavDropdown
              id=""
              title={<img src={ThreeDotIcon} alt="action" />}
              menuVariant="primary"
            >
              {isCapable && isCapable.web_apps.edit && (
                <NavDropdown.Item
                  id="appslink"
                  className=""
                  onClick={async () => {
                    handleExtend([row]);
                  }}
                >
                  <AiOutlineUserAdd></AiOutlineUserAdd> Extend
                </NavDropdown.Item>
              )}
              {isCapable && isCapable.web_apps.delete && (
                <NavDropdown.Item
                  id="appslink"
                  className=""
                  onClick={async () => {
                    handleDeAllocate([row]);
                  }}
                >
                  <AiOutlineUserDelete></AiOutlineUserDelete> Remove
                </NavDropdown.Item>
              )}
            </NavDropdown>
          </Nav>
        </>
      ),
      minWidth: "150px",
      grow: 1,
      middle: true,
    },
  ];

  var today = new Date();
  //receive date from backend.
  var day = today.getDate();
  if (day < 10) day = "0" + day;
  var month = today.getMonth() + 1;
  if (month < 10) month = "0" + month;
  var year = today.getFullYear();
  var hour = today.getHours();
  if (hour < 10) hour = "0" + hour;
  var minute = today.getMinutes();
  if (minute < 10) minute = "0" + minute;
  var value = hour + ":" + minute;
  var date = year + "-" + month + "-" + day;

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "WebApps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={images.WebAppHeading}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Web Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName1 && (
                  <CustomModal
                    open={openViewRole}
                    handleClose={() => {
                      setOpenViewRole((o) => !o);
                    }}
                  >
                    <ShowAlert
                      setOpenAlert={setOpenViewRole}
                      handleAlertAction={handleDeAllocateAction}
                      colName={colName1}
                      alertMsg={`Below roles will be deallocated for `}
                      entity_name={webAppName}
                      headingMsg="Deallocate Roles"
                    />
                  </CustomModal>
                )}
                {colName && (
                  <CustomModal
                    open={openExtendUserModal}
                    handleClose={() => {
                      setOpenExtendUserModal((o) => !o);
                    }}
                  >
                    <TimeCompExtend
                      setOpenAlert={setOpenExtendUserModal}
                      handleAlertAction={handleExtendFunction}
                      colName={colName}
                      toBeExtend={[{ id: toBeExtend[0].role_id }]}
                      resource_id={web_app_id}
                      alertMsg={`Below role will be extended for web app`}
                      entity_name={webAppName}
                      headingMsg="Extend Role"
                      type={"role"}
                      target_type={"Roles"}
                      resource_type={"web_apps"}
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`WebApp : ${webAppName}`}
                        dataTableHeadingIcon={images.WebAppHeading}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      colCount !== null && (
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={3}
                        />
                      )
                    }
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRoles}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="role"
                        actions={ActionComponent}
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={UserColumns}
                        actionOptions={actionOptions}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default DeAllocateWebAppFromRole;

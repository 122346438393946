import { default as React, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getToken, myAppsListUser } from "../../../../api/apps";
import { myAssetsListUser } from "../../../../api/asset";
import { UpdateTicketDetails } from "../../../../api/ticketing";
import { changeTitle } from "../../../../state/slices/header";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { RxCross1 } from "react-icons/rx";
import "../ticketing.css";
import Spinner from "react-bootstrap/Spinner";
import { myWebAppsListUser } from "../../../../api/web_app";

function UpdateTicket({
  setTicketEditModal,
  getMyTicketsList,
  getTicketListData,
  TicketData,
}) {
  const [loading, setLoading] = useState(false);
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const ws_protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const ws_endpoint = `${serverURL.replace(/http:|https:/gi, ws_protocol + ':')}/`;
  const customer_id = useSelector((state) => state.user?.user?.customer || "");
  const user_id = useSelector((state) => state.user?.user?.id || "");
  const [sessionkey, setSessionkey] = useState(null);
  const [resourcesList, setResourcesList] = useState(null);
  const options = [
    { value: "APP", label: "App" },
    { value: "ASSET", label: "Asset" },
    { value: "WEB_APP", label: "Web App" },
  ];
  const [resourceType, setResourceType] = useState(TicketData.resource_type);
  const [resourceId, setResourceId] = useState(null);
  const [reason, setReason] = useState(TicketData.reason);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [nowDate, setNowDate] = useState();
  const [nowTime, setNowTime] = useState();
  const [showFeedback, setShowFeedback] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (resourceType == "APP") {
      TicketData.resource.label = `ID : ${TicketData.resource.id} , Name : ${TicketData.resource.app_name}`;
      TicketData.resource.value = TicketData.resource.id;
      setResourceId(TicketData.resource)
    }
    else if (resourceType == "ASSET") {
      TicketData.resource.label = `ID : ${TicketData.resource.id} , Name : ${TicketData.resource.asset_name}`;
      TicketData.resource.value = TicketData.resource.id;
      setResourceId(TicketData.resource)
    }
  }, []);

  useEffect(() => {
    dispatch(changeTitle("Ticket"));
    const now = new Date();
    setNowDate(
      now.getFullYear() +
        "-" +
        (now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1) +
        "-" +
        (now.getDate() < 9 ? "0" + now.getDate() : now.getDate())
    );
    setNowTime(
      (now.getHours() <= 9 ? "0" + now.getHours() : now.getHours()) +
        ":" +
        (now.getMinutes() <= 9 ? "0" + now.getMinutes() : now.getMinutes())
    );

    handleTicketing();

    let TicketFromDate = new Date(TicketData.access_from * 1000);
    setFrom(
      TicketFromDate.getFullYear() +
        "-" +
        (TicketFromDate.getMonth() < 9
          ? "0" + (TicketFromDate.getMonth() + 1)
          : TicketFromDate.getMonth() + 1) +
        "-" +
        (TicketFromDate.getDate() <= 9
          ? "0" + TicketFromDate.getDate()
          : TicketFromDate.getDate())
    );
    setFromTime(
      (TicketFromDate.getHours() <= 9
        ? "0" + TicketFromDate.getHours()
        : TicketFromDate.getHours()) +
        ":" +
        (TicketFromDate.getMinutes() <= 9
          ? "0" + TicketFromDate.getMinutes()
          : TicketFromDate.getMinutes())
    );

    let TicketToDate = new Date(TicketData.access_until * 1000);

    setTo(
      TicketToDate.getFullYear() +
        "-" +
        (TicketToDate.getMonth() < 9
          ? "0" + (TicketToDate.getMonth() + 1)
          : TicketToDate.getMonth() + 1) +
        "-" +
        (TicketToDate.getDate() <= 9
          ? "0" + TicketToDate.getDate()
          : TicketToDate.getDate())
    );
    setToTime(
      (TicketToDate.getHours() <= 9
        ? "0" + TicketToDate.getHours()
        : TicketToDate.getHours()) +
        ":" +
        (TicketToDate.getMinutes() <= 9
          ? "0" + TicketToDate.getMinutes()
          : TicketToDate.getMinutes())
    );
  }, []);

  const handleTicketing = async () => {
    const { tokenData, tokenError } = await getToken();
    setSessionkey(tokenData.session_key);
  };

  const getAppsList = async () => {
    const { data, error } = await myAppsListUser({
      userid: TicketData.requested_by,
      page: 1 - 1,
      limit: 9999,
      search: "",
      filterBy: "",
    });
    if (data !== null) {
      setResourcesList(
        data.apps.map((app) => {
          app.label = `ID : ${app.id} , Name : ${app.app_name}`;
          app.value = app.id;
          return app;
        })
      );
    }
    if (error !== null) {
      showError(error);
    }
  };

  const getAssetsList = async () => {
    const { data, error } = await myAssetsListUser({
      userid: TicketData.requested_by,
      page: 1 - 1,
      limit: 9999,
      search: "",
      filterBy: "",
    });
    if (data !== null) {
      setResourcesList(
        data.assets.map((asset) => {
          asset.label = `ID : ${asset.id} , Name : ${asset.asset_name}`;
          asset.value = asset.id;
          return asset;
        })
      );
    }
    if (error !== null) {
      showError(error);
    }
  };

  const getWebAppsList = async () => {
    const { data, error } = await myWebAppsListUser({
      userid: TicketData.requested_by,
      page: 1 - 1,
      limit: 9999,
      search: "",
      filterBy: "",
    });
    if (data !== null) {
      setResourcesList(
        data.web_apps.map((web_app) => {
          web_app.label = `ID : ${web_app.id} , Name : ${web_app.app_name}`;
          web_app.value = web_app.id;
          return web_app;
        })
      );
    }
    if (error !== null) {
      showError(error);
    }
  };

  useEffect(() => {
    if (resourceType == "APP") {
      getAppsList();
    }
    if (resourceType == "ASSET") {
      getAssetsList();
    }
    if (resourceType == "WEB_APP") {
      getWebAppsList();
    }
  }, [resourceType]);

  const sendTicket = async (put_data) => {
    const app_connection_endpoint = `${ws_endpoint}ws/ticket/${customer_id}_true/?session_key=${sessionkey}`;
    const ticketing_ws_connection = new WebSocket(app_connection_endpoint);
    ticketing_ws_connection.onopen = function (e) {
      ticketing_ws_connection.send(
        JSON.stringify({
          type: "ticketData",
          message: put_data,
          session_key: sessionkey,
        })
      );
    };
  };

  const UpdateTicketFormHandler = async (event) => {
    if (!loading) {
      setShowFeedback(true);
      const form = event.currentTarget;
      let finalFromDate, finalToDate;
      finalFromDate = new Date(from + " " + fromTime + ":00");
      finalToDate = new Date(to + " " + toTime + ":59");

      event.preventDefault();
      if (
        form.checkValidity() === false ||
        from === null ||
        to == null ||
        resourceType === "" ||
        resourceId === null ||
        reason === null ||
        fromTime === null ||
        toTime === null
      ) {
        event.stopPropagation();
      } else {
      setLoading(true);
        let put_data = {
          ...(TicketData && { id: TicketData.id }),
          ...(resourceType !== "" && { resource_type: resourceType }),
          ...(resourceId !== "" && { resource_id: resourceId.value }),
          ...(reason !== "" && { reason: reason }),
          ...(from !== "" && { access_from: Date.parse(finalFromDate) / 1000 }),
          ...(to !== "" && { access_until: Date.parse(finalToDate) / 1000 }),
        };
        const { data, error } = await UpdateTicketDetails(put_data);
        if (data !== null) {
          sendTicket(put_data);
          showSuccess(data.message);
          getMyTicketsList(getTicketListData);
          setTicketEditModal((o) => !o);
          setLoading(false);
        }
        if (error !== null) {
          showError(error);
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      {resourcesList && (
        <Container fluid className="alert_container mx-auto">
          <Form onSubmit={UpdateTicketFormHandler}>
            <div className="mb-3">
              <span className="main_content_heading">Update Ticket</span>
              <Button
                variant="light"
                className="cursor_pointer float-end"
                onClick={() => setTicketEditModal((o) => !o)}
              >
                <RxCross1></RxCross1>
              </Button>
            </div>
            <Stack gap={1}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label column md={6} className="input_label">
                    Resource Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    onChange={(event) => {
                      setResourceId(null);
                      setResourceType(event.value);
                    }}
                    options={options}
                    defaultValue={
                      options[
                        options.findIndex((item) => item.value === resourceType)
                      ]
                    }
                    required
                  />
                  {showFeedback && !resourceType && (
                    <span className="text-danger fs-14px p-0 m-0">
                      Please select a Resource Type.
                    </span>
                  )}
                </Col>
                <Col md={6}>
                  <Form.Label column md={6} className="input_label">
                    Resource Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    required
                    onChange={(event) => {
                      setResourceId(event);
                    }}
                    options={resourcesList}
                    value={resourceId}
                    defaultValue={
                      resourcesList[
                        resourcesList.findIndex(
                          (item) => item.value === resourceId
                        )
                      ]
                    }
                    isInvalid={resourceId === null}
                    placeholder={
                      resourceType == "APP" || resourceType == "ASSET" || resourceType == "WEB_APP"
                        ? `Select an ${resourceType.toLowerCase()}`
                        : `Select..`
                    }
                    noOptionsMessage={() =>
                      resourceType == "APP" || resourceType == "ASSET" || resourceType == "WEB_APP"
                        ? `No ${resourceType.toLowerCase()}s available`
                        : `No options available`
                    }
                  />
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "#F9F9F9",
                  border: "1px solid #CBCBCB",
                }}
                className="mx-0 mb-2 pt-2 pb-3 justify-content-between"
              >
                <Col md={6}>
                  <Form.Label column md={6} className="input_label">
                    Access From <span className="text-danger">*</span>
                  </Form.Label>
                  <Row>
                    <Col md={6}>
                      <Form.Control
                        className="form_date_input_field py-2 pe-2"
                        type="date"
                        value={from}
                        onChange={(event) => setFrom(event.target.value)}
                        placeholder="MyTickets"
                        isInvalid={from === null}
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Control
                        className="form_date_input_field py-2 pe-2"
                        type="time"
                        value={fromTime}
                        onChange={(event) => setFromTime(event.target.value)}
                        isInvalid={fromTime === null}
                      />
                    </Col>
                    {showFeedback && (fromTime === null || from === null) && (
                  <span className="form_date_input_field text-danger fs-14px py-2 pe-2">
                    Please Enter a Valid Access From Date-Time
                  </span>
                )}
                  </Row>
                </Col>
                <Col style={{ borderLeft: "1px solid #CBCBCB" }} md={6}>
                  <Form.Label column md={6} className="input_label">
                    Access Until <span className="text-danger">*</span>
                  </Form.Label>
                  <Row>
                    <Col md={6}>
                      <Form.Control
                        className="form_date_input_field py-2 pe-2"
                        type="date"
                        min={from}
                        value={to}
                        onChange={(event) => setTo(event.target.value)}
                        isInvalid={to === null}
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Control
                        className="form_date_input_field py-2 pe-2"
                        type="time"
                        value={toTime}
                        min={to > from ? "00:00" : fromTime}
                        onChange={(event) => setToTime(event.target.value)}
                        placeholder="MyTickets"
                        isInvalid={toTime === null}
                      />
                    </Col>
                    {showFeedback && (toTime === null || to === null) && (
                  <span className="form_date_input_field text-danger fs-14px py-2 pe-2">
                    Please Enter a Valid Access Until Date-Time
                  </span>
                )}
                  </Row>
                </Col>
              </Row>
              {showFeedback && !from && (
                <span className="text-danger fs-14px p-0">
                  Please enter the valid date
                </span>
              )}
              <Row>
                <Col md={12}>
                  <Form.Label column md={6} className="input_label">
                    Reason <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                  required
                    className="form_input_field"
                    as="textarea"
                    rows={2}
                    value={reason}
                    onChange={(event) => setReason(event.target.value)}
                    placeholder="Enter your reason here"
                    isInvalid={reason === null}
                  />
                </Col>
              </Row>
              <Row className="pt-4 mb-3 justify-content-end">
                <Col sm="auto">
                  <Button
                    className="px-4"
                    variant="light"
                    as="input"
                    type="button"
                    value="Cancel"
                    onClick={() => setTicketEditModal((o) => !o)}
                  />
                </Col>
                <Col sm="auto" className="me-sm-2 p-0">
                  {loading ? (
                    <Button variant="primary" className="px-5" disabled>
                      <Spinner
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "white",
                        }}
                        animation="border"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  ) : (
                    <Button
                      className="px-4"
                      variant="primary"
                      as="input"
                      type="submit"
                      value="Update"
                    />
                  )}
                </Col>
              </Row>
            </Stack>
          </Form>
        </Container>
      )}
    </>
  );
}
export default UpdateTicket;

import axios from './axios_interceptor';
import {get_jwt_token, loginRedirect} from './helper_funtions'

export const myAssetsList = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/assets/list/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const myAssetsListUser = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/assets/userasset/?userid=${data.userid}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const deleteMyAsset = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: data
        };
        const url = `/assets/asset/`;
        const response = await axios.delete(url,config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const getMyAssetDetail = async ({asset_id}) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/assets/asset/?asset_id=${asset_id}`;
        const response = await axios.get(url,config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const updateMyAsset = async ({asset_id, updatedAsset}) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/assets/asset/?asset_id=${asset_id}`;
        const response = await axios.put(url,updatedAsset, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const addAsset = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/assets/asset/`;
        const response = await axios.post(url,data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const nonAllocatedGroupList = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/assets/allocate-group/?asset_id=${data.asset_id}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const allocatedGroupList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/assets/deallocate-group/?asset_id=${data.asset_id}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const allocateAssetToGroupApi = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/assets/allocate-group/`;
        const response = await axios.post(url,data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const deallocateAssetFromGroup = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/assets/deallocate-group/`;
        const response = await axios.post(url,data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const AllocatedUsersList = async (data) => {
 

    try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `/assets/allocated-users/?assetid=${data.assetid}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.get(url, config);
      return { data: response.data, error: null };
    } catch (e) {
      return { data: null, error: e.response.data.errors };
    }
  };

  export const deallocateUserFromAsset = async (data) => {
    try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          user_ids: data.user_ids,
          asset_id:data.asset_id
        },
      };
      const url = `/assets/allocated-users/?assetid=${data.assetid}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.delete(url, config,data);
      return { data: response.data, error: null };
    } catch (e) {
      return { data: null, error: e.response.data.errors };
    }
  };

export const notAllocatedUsersList = async (data) => {
 

    try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `/assets/notallocated-users/?assetid=${data.assetid}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.get(url, config);
      return { data: response.data, error: null };
    } catch (e) {
      return { data: null, error: e.response.data.errors };
    }
  };

  export const allocateUsers = async (data) => {
    try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      };
      const body = {
        user_ids: data.ids,
        asset_id:data.asset_id,
        allocation_from: data.allocation_from,
        allocation_till: data.allocation_till
      }
      const url = `/assets/notallocated-users/?asset_id=${data.assetid}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.post(url, body, config);
      return { data: response.data, error: null };
    } catch (e) {
      return { data: null, error: e.response.data.errors };
    }
  };

  export const ExtendAssetAllocation = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/assets/notallocated-users/`;
        const response = await axios.put(url, data, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

  export const getAssetAllocationInfo = async (data) => {
    try{
        const token = get_jwt_token();
        loginRedirect(token)
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            params: {
                entity_id: data.entity_id,
                asset_id: data.asset_id,
                entity_type: data.entity_type
            }
        };
        const response = await axios.get('/assets/asset-allocation-window/', config);
        return {data: response.data, error: null}
    }
    catch (e){
        return {data: null, error: e.response.data.errors};
    }
};

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import {
  postExternalIdpData,
  getExternalIdpData,
  postTestExternalIdpData,
} from "../../../api/sso";
import {
  showSuccess,
  showError,
  showErrorMsg,
} from "../../../utils/showMessage";
import { checkIpHostPattern } from "../../../utils/checkIpPattern";
import { get_jwt_token } from "../../../api/helper_funtions";
import { loginRedirect } from "../../../api/helper_funtions";
import AttributeMappingExternal from "./AttributeMappingExternal";
import { AiFillPlusCircle } from "react-icons/ai";
import Show from "../../../images/show.png";
import Hide from "../../../images/hide.png";
import { Image } from "react-bootstrap";
import { SSOapplicationName } from "../../common/regex/Regex";
import InfoIcon from "../Settings/icons/icon-info.svg";
import LightTooltip from "../../common/CustomTooltip/CustomTooltip";
import RuleBasedRoleMapping from "../../common/RuleBaseRoleMapping/RuleBasedRoleMapping.js";
import { prepareRuleBaseRoleMappingData } from "../../../utils/rule_based_mapping.js";
import images from "../../../utils/images";
import { toast } from "react-toastify";
import { PostSSOption } from "../../../api/sso";
import { DarkTooltip } from "../../common/CustomTooltip/CustomTooltip";

const ExternalIDP = ({
  selectedServer,
  setSelectedServer,
  redirectUrl,
  currentBrandingUrl,
  getConfiguredIDP,
}) => {
  const user_id = useSelector((state) => state?.user?.user?.id);
  const customer_id = useSelector((state) => state?.user?.user?.customer);
  const [rows, setRows] = useState([]);
  const [attributeList, setAttributeList] = useState([]);
  const [roleBasedMapping, setRoleBasedMapping] = useState([]);
  var oauthserverDefault,
    applicationnameDefault,
    clientidDefault,
    clientsecretDefault;
  var authorizationendpointDefault,
    tokenendpointDefault,
    enduserendpointDefault;
  var scopeDefault;

  //show or hide client secret functionality:
  const [showSecret, setShowSecret] = useState(false);
  var scopeOptions = [
    { value: "openid", label: "OpenID", isSelected: false },
    { value: "email", label: "Email", isSelected: false },
    { value: "profile", label: "Profile", isSelected: false },
  ];
  var oauthOptions = [
    { value: "okta", label: "Okta" },
    { value: "azuread", label: "AzureAD" },
    { value: "github", label: "Github" },
    { value: "miniOrangeIDP", label: "miniOrange" },
  ];
  const [oauthOptionsState, setOauthOptionsState] = useState(oauthOptions);
  const [scope, setScope] = useState(scopeOptions);
  const getScopeDict = (scopeDefault) => {
    let arr = scopeDefault.split(" ");
    const updatedScope = [...scope];
    // Update existing values and add new ones
    arr.forEach((value) => {
      const existingOptionIndex = updatedScope.findIndex(
        (option) => option.value === value
      );
      if (existingOptionIndex !== -1) {
        // If the value already exists, update isSelected to true
        updatedScope[existingOptionIndex].isSelected = true;
      } else {
        // If the value doesn't exist, create a new option with isSelected set to true
        updatedScope.push({
          value,
          label: value,
          isSelected: true,
        });
      }
    });
    // Update the state with the modified array
    setScope(updatedScope);
  };
  let oauthInoauthOptions = false;
  const getOauthDict = (OauthDefault) => {
    oauthOptions.map((scope) => {
      if (String(scope["value"]) === String(OauthDefault)) {
        oauthInoauthOptions = true;
      }
    });
    if (oauthInoauthOptions) {
    } else {
      oauthOptions.push({ value: OauthDefault, label: OauthDefault });
    }
    setOauthOptionsState(oauthOptions);
  };
  const [showTestConnectionButton, setShowTestConnectionButton] =
    useState(false);
  const getData = async () => {
    const { data, error } = await getExternalIdpData();
    if (data !== null && data.hasOwnProperty("data")) {
      if (data.data !== null) {
        oauthserverDefault = data["data"]["oauthserver"];
        getOauthDict(oauthserverDefault);
        applicationnameDefault = data["data"]["application_name"];
        clientidDefault = data["data"]["client_id"];
        clientsecretDefault = data["data"]["client_secret"];
        authorizationendpointDefault = data["data"]["authorization_endpoint"];
        tokenendpointDefault = data["data"]["token_endpoint"];
        enduserendpointDefault = data["data"]["user_info_endpoint"];
        scopeDefault = data["data"]["scope"];
        let tempValue = scopeDefault !== "" ? getScopeDict(scopeDefault) : "";
        setOauthserver(oauthserverDefault);
        setApplicationName(applicationnameDefault);
        setAuthEndpoint(authorizationendpointDefault);
        setTokenEndpoint(tokenendpointDefault);
        setEnduserinfoEndpoint(enduserendpointDefault);
        setClientid(clientidDefault);
        setClientSecret(clientsecretDefault);
        let notTruePresentRows = data["rows"];
        notTruePresentRows.map((value, idx) => {
          if (value.attributeName) {
            value["isattributeNameSelected"] = true;
          } else {
            value["isattributeNameSelected"] = false;
          }
          if (value.attributeValue) {
            value["isattributeValueSelected"] = true;
          } else {
            value["isattributeValueSelected"] = false;
          }
        });
        setRows(notTruePresentRows);
        setAttributeList(data["list"]);
        setShowTestConnectionButton(true);
        setSelectedServer(oauthserverDefault);
        setRoleBasedMapping(data["role_mappings"]);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [selectedServer]);

  const [isSearchable, setIsSearchable] = useState(true);
  const [oauthserver, setOauthserver] = useState("okta");
  const [applicationName, setApplicationName] = useState("");
  const [authEndpoint, setAuthEndpoint] = useState("");
  const [tokenEndpoint, setTokenEndpoint] = useState("");
  const [enduserinfoEndpoint, setEnduserinfoEndpoint] = useState("");
  const [clientid, setClientid] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [validated, setValidated] = useState(false);
  const [authEndpointCheck, setAuthEndpointCheck] = useState(false);
  const [tokenEndpointCheck, setTokenEndpointCheck] = useState(false);
  const [userEndpointCheck, setUserEndpointCheck] = useState(false);
  const [defaultState, setDefaultState] = useState({
    value: "okta",
    label: "Okta",
    selected: false,
  });
  var scopeSet = new Set();

  const SaveData = async (scopes) => {
    let rolesMapping = prepareRuleBaseRoleMappingData(roleBasedMapping);
    const { data, error } = await postExternalIdpData(
      oauthserver,
      applicationName,
      authEndpoint,
      tokenEndpoint,
      clientid,
      clientSecret,
      enduserinfoEndpoint,
      scopes,
      redirectUrl,
      rows,
      rolesMapping
    );
    if (data !== null) {
      showSuccess(data.message);
      getConfiguredIDP();
    } else {
      showError(error);
    }
  };
  const handleSubmitOther = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      checkIfAttributesAreCorrect();
    } else {
      event.preventDefault();
      scope.map((item) => {
        for (let k of Object.entries(item)) {
          if (item["isSelected"] === true) {
            scopeSet.add(item["value"]);
          }
        }
      });
      var scopes = [...scopeSet].join(" ");
      if (form.checkValidity() === false && !checkIpHostPattern(authEndpoint)) {
        setAuthEndpointCheck(false);
      }
      if (
        form.checkValidity() === false &&
        !checkIpHostPattern(tokenEndpoint)
      ) {
        setTokenEndpointCheck(false);
      }
      if (
        form.checkValidity() === false &&
        !checkIpHostPattern(enduserinfoEndpoint)
      ) {
        setUserEndpointCheck(false);
      }
      setValidated(true);
      if (emailExistsInAttributeRows()) {
        SaveData(scopes);
        setShowTestConnectionButton(true);
      } else {
        //email attribute is not mapped.
        toast.error("Email attribute is not mapped with any IDP parameter.");
      }
    }
  };

  const handleConnection = async (scopes) => {
    const { data, error } = await postTestExternalIdpData(
      oauthserver,
      applicationName,
      authEndpoint,
      tokenEndpoint,
      clientid,
      clientSecret,
      enduserinfoEndpoint,
      scopes,
      redirectUrl,
      rows
    );
    if (data !== null) {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      let params = `status=yes,location=yes,toolbar=no,menubar=yes,
                left=100,top=100,resizable=yes,
                width=804,
                height=710`;
      const url =
        currentBrandingUrl +
        "/sso/test-sso-connection/?application=" +
        data.application_name +
        "&token=" +
        data.token +
        "&customer_id=" +
        customer_id;
      var win = window.open(url, "_blank", params);
            win.document.title = data.application_name;
            var newLink = win.document.createElement('link');
            newLink.setAttribute('rel','icon');
            newLink.setAttribute('type','image/png');
            newLink.setAttribute('href',document.getElementById("favicon").href);
            win.document.querySelector('head').appendChild(newLink);
    } else {
      showError(error);
    }
  };
  const handleTestConnection = (event) => {
    const form = event.currentTarget.closest("form");
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      checkIfAttributesAreCorrect();
    } else {
      event.preventDefault();
      scope.map((item) => {
        for (let k of Object.entries(item)) {
          if (item["isSelected"] === true) {
            scopeSet.add(item["value"]);
          }
        }
      });
      var scopes = [...scopeSet].join(" ");
      if (form.checkValidity() === false && !checkIpHostPattern(authEndpoint)) {
        setAuthEndpointCheck(false);
      }
      if (
        form.checkValidity() === false &&
        !checkIpHostPattern(tokenEndpoint)
      ) {
        setTokenEndpointCheck(false);
      }
      if (
        form.checkValidity() === false &&
        !checkIpHostPattern(enduserinfoEndpoint)
      ) {
        setUserEndpointCheck(false);
      }
      setValidated(true);
      if (emailExistsInAttributeRows()) {
        handleConnection(scopes);
      } else {
        //email attribute is not mapped.
        toast.error("Email attribute is not mapped with any IDP parameter.");
      }
    }
  };
  const checkIfAttributesAreCorrect = () => {
    const newrow = rows.map((value, idx) => {
      if (!value.attributeName) {
        return { ...value, isattributeNameSelected: false };
      }
      if (!value.attributeValue) {
        return { ...value, isattributeValueSelected: false };
      }
      return value;
    });
    setRows(newrow);
  };
  // attribute mapping logic start:
  const handleAddAttribute = () => {
    setRows((rows) => [
      ...rows,
      {
        attributeName: "",
        attributeValue: "",
        attributeType: "",
        attributeValueLabel: "",
        isattributeNameSelected: true,
        isattributeValueSelected: true,
      },
    ]);
  };

  useEffect(() => {
    oauthOptionsState.map((item, position) => {
      if (item.value === oauthserver) {
        setDefaultState((o) => oauthOptionsState[position]);
      }
    });
  }, [oauthserver]);

  const emailExistsInAttributeRows = () => {
    //sends back true value if email exists in attribute rows.
    let attributeRowsContainEmail = false;
    try {
      if (rows.length !== 0) {
        rows.map((value, idx) => {
          if (value.attributeName === "email") {
            attributeRowsContainEmail = true;
          }
        });
      } else {
        attributeRowsContainEmail = true;
      }
      return attributeRowsContainEmail;
    } catch (e) {
      return attributeRowsContainEmail;
    }
  };
  return (
    <>
      <Form
        id="sso-form-other"
        validated={validated}
        noValidate
        className="mx-5 pb-3"
        onSubmit={handleSubmitOther}
      >
        <div className="mb-2 d-flex flex-row justify-content-between align-items-center  pt-4">
          <span className="ff-poppins fs-22px fs-normal sso-form-heading ">
            SSO via Others
          </span>
          {showTestConnectionButton && (
            <Button
              className="sso-external-test-btn"
              type="button"
              variant="outlined"
              onClick={(e) => {
                handleTestConnection(e);
              }}
            >
              <span className="sso-external-test-btn-label px-3">
                Test Connection
              </span>
            </Button>
          )}
        </div>
        <Row>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label className="my-3 sso-form-label">
                Grant Type
              </Form.Label>
              <Form.Control
                className="user_input_field"
                plaintext
                readOnly
                defaultValue={"authorization code"}
                placeholder="authorization code"
                disabled
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label className="my-3 sso-form-label">
                Sign in Redirect/OAuth Callback URL
              </Form.Label>
              <Form.Control
                className="user_input_field"
                plaintext
                readOnly
                defaultValue={redirectUrl}
                placeholder={redirectUrl}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label className="my-3 sso-form-label">
                OAuth Server
              </Form.Label>
              <CreatableSelect
                className="user_input_field select-oauth"
                isClearable
                required={true}
                value={defaultState}
                isSearchable={isSearchable}
                options={oauthOptionsState}
                onChange={(selectedOption) => {
                  setOauthserver(selectedOption.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label className="my-3 sso-form-label">
                Application Display Name
                <DarkTooltip
                  id="setting-info"
                  className="lh-lg"
                  title="This value will be displayed on login page"
                >
                  <img
                    src={images.InfoIcon}
                    alt="info"
                    width="15"
                    height="15"
                    className="mx-2 cursor_pointer"
                  />
                </DarkTooltip>
              </Form.Label>
              <Form.Control
                className="user_input_field"
                type="text"
                pattern={applicationName}
                required
                placeholder="application name"
                value={applicationName}
                onChange={(e) => {
                  setApplicationName(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid application Name.
                <LightTooltip title="Invalid application name! Name must start with a letter. Allowed characters are a-z, A-Z">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label className="my-3 sso-form-label">Client ID</Form.Label>
              <Form.Control
                className="user_input_field"
                type="text"
                required
                placeholder="client id"
                value={clientid}
                onChange={(e) => {
                  setClientid(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Client id
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label className="my-3 sso-form-label">
                Client Secret
              </Form.Label>
              <InputGroup id="clientSecret">
                <Form.Control
                  className="user_input_field border-end-0"
                  type={showSecret === false ? "password" : "text"}
                  required
                  placeholder="client secret"
                  value={clientSecret}
                  onChange={(e) => {
                    setClientSecret(e.target.value);
                  }}
                />
                <InputGroup.Text
                  id="clientSecret"
                  className="my-input-group bg-white"
                  onClick={() => setShowSecret(!showSecret)}
                >
                  {showSecret === false ? (
                    <Image src={Show} height="20px" className="px-2"></Image>
                  ) : (
                    <Image src={Hide} height="20px" className="px-2"></Image>
                  )}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label className="my-3 sso-form-label">
                Authorization Endpoint
              </Form.Label>
              <Form.Control
                className="user_input_field"
                type="text"
                required
                placeholder="http://client-name.companydomain/authorization/endpoint"
                value={authEndpoint}
                onChange={(e) => {
                  setAuthEndpoint(e.target.value);
                  setAuthEndpointCheck(checkIpHostPattern(e.target.value));
                }}
                isValid={authEndpointCheck}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Authorization endpoint.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label className="my-3 sso-form-label">
                Token endpoint
              </Form.Label>
              <Form.Control
                className="user_input_field"
                type="text"
                required
                placeholder="http://client-name.companydomain/token/endpoint"
                value={tokenEndpoint}
                onChange={(e) => {
                  setTokenEndpoint(e.target.value);
                  setTokenEndpointCheck(checkIpHostPattern(e.target.value));
                }}
                isValid={tokenEndpointCheck}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Token endpoint.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label className="my-3 sso-form-label">
                Enduser Information endpoint
              </Form.Label>
              <Form.Control
                className="user_input_field"
                type="text"
                required
                placeholder="http://client-name.companydomain/enduserinfo/endpoint"
                value={enduserinfoEndpoint}
                onChange={(e) => {
                  setEnduserinfoEndpoint(e.target.value);
                  setUserEndpointCheck(checkIpHostPattern(e.target.value));
                }}
                isValid={userEndpointCheck}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Enduser info endpoint.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6} md={12}>
            <Form.Group>
              <Form.Label className="my-3 sso-form-label">Scope</Form.Label>
              <CreatableSelect
                className="user_input_field select-oauth"
                isMulti
                closeMenuOnSelect={false}
                onChange={(value, { action }) => {
                  let newScope = [];
                  switch (action) {
                    case "remove-value":
                    case "pop-value":
                      newScope = scope.map((stateValue) => {
                        const existingIndex = value.findIndex(
                          (option) => option.value === stateValue.value
                        );
                        if (existingIndex !== -1) {
                          return stateValue;
                        } else {
                          return {
                            ...stateValue,
                            isSelected: false,
                          };
                        }
                      });
                      setScope(newScope);
                      break;
                    case "clear":
                      newScope = scope.map((scopeValue) => {
                        return {
                          ...scopeValue,
                          isSelected: false,
                        };
                      });
                      setScope(newScope);
                      break;
                    case "create-option":
                      const updatedScope = [...scope];

                      value.forEach((value) => {
                        const existingOptionIndex = updatedScope.findIndex(
                          (option) => option.value === value.value
                        );
                        if (existingOptionIndex !== -1) {
                          // If the value already exists, update isSelected to true
                          updatedScope[existingOptionIndex].isSelected = true;
                        } else {
                          // If the value doesn't exist, create a new option with isSelected set to true
                          updatedScope.push({
                            value: value.value,
                            label: value.value,
                            isSelected: true,
                          });
                        }
                      });

                      // Update the state with the modified array
                      setScope(updatedScope);

                      break;
                    case "select-option":
                      newScope = scope.map((item) => ({
                        ...item,
                        isSelected: value.some(
                          (elem) => elem.value === item.value
                        )
                          ? true
                          : item.isSelected,
                      }));
                      setScope(newScope);
                      break;
                    case "deselect-option":
                      break;
                    case "set-value":
                      break;
                    default:
                      break;
                  }
                }}
                value={scope.map((value, index) => {
                  if (value.isSelected === true) {
                    return value;
                  }
                })}
                defaultValue={scope.map((value, index) => {
                  if (value.isSelected === true) {
                    return value;
                  }
                })}
                options={scope}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="py-3 pt-4">
          <span className="sso-miniorange-attribute-mapping-heading">
            Attribute Mapping
          </span>
          <div className="sso-miniorange-attribute-mapping-subheading">
            Map PAM solution attribute from left input box to IDP attribute in
            right input box.
          </div>
          <div className="lines pt-3 attribute-mapping-line" />
        </Row>

        <Row className="mt-2">
          <Col
            lg={4}
            md={6}
            sm={12}
            onClick={handleAddAttribute}
            className="d-flex ps-3 sso-add-new-attribute"
          >
            <img
              src={images.SSOAddNewAttribute}
              className="attribute-mapping-btn mapping-add-btn"
              type="button"
              alt="Add new attribute button"
            />
            <span className=" add_new_mapping miniorange-add-new-attribute ps-3">
              Add New Attribute
              <LightTooltip
                id="setting-info"
                className="lh-lg"
                title="If Attributes are mapped then it is crucial to map 'Email' field."
              >
                <img
                  src={images.InfoIcon}
                  alt="info"
                  width="15"
                  height="15"
                  className="mx-2 cursor_pointer"
                />
              </LightTooltip>
            </span>
          </Col>
        </Row>
        <Row className="mt-3" lg={12} md={12} sm={12}>
          {rows.map((item, idx) => (
            <AttributeMappingExternal
              key={idx}
              rows={rows}
              setRows={setRows}
              item={item}
              idx={idx}
              list={attributeList}
              setAttributeList={setAttributeList}
              checkIfAttributesAreCorrect={checkIfAttributesAreCorrect}
            />
          ))}
        </Row>
        <RuleBasedRoleMapping
          rows={roleBasedMapping}
          setRows={setRoleBasedMapping}
          attributeNameList={attributeList}
          setAttributeList={setAttributeList}
        />
      </Form>
    </>
  );
};
export default ExternalIDP;

import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import CrossIcon from "../../../images/cross.svg";
import Button from "react-bootstrap/Button";
import "../TimeComponent/timeComp.scss";
import { getAppAllocationInfo, getUserAllocationInfo } from "../../../api/apps";
import { showError } from "../../../utils/showMessage";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { getAssetAllocationInfo } from "../../../api/asset";
import Select from "react-select";
import { fadedelayTime } from "../../../utils/transitionEffectParams";
import { Fade } from "@mui/material";
import {
  handleGetPolicy,
  handleSetPolicy,
  loadPolices,
} from "./helper_funtions";
import { getWebAppAllocationInfo } from "../../../api/web_app";
const TimeCompExtend = ({
  setOpenAlert,
  handleAlertAction,
  colName,
  toBeExtend,
  resource_id,
  alertMsg,
  resource_name,
  headingMsg,
  type,
  resource_type,
  entity_name,
  target_type,
  show_policy,
  target_entity_name,
}) => {
  const [from, setFrom] = useState("");
  const { width } = useWindowDimensions();
  const [fromTime, setFromTime] = useState("");
  const [to, setTo] = useState("");
  const [toTime, setToTime] = useState("");
  const [nowDate, setNowDate] = useState();
  const [nowTime, setNowTime] = useState();
  const [policy, setPolicy] = useState(null);
  const [policies, setPolicies] = useState([]);
  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const handleLoadCall = async () => {
    let entity_id =
      type === "user" || type === "role"
        ? toBeExtend[0].id
        : toBeExtend[0].group_id;
    let response;
    if (resource_type === "apps") {
      response = await getAppAllocationInfo({
        entity_id: entity_id,
        app_id: resource_id,
        entity_type: type,
      });
    } else if (resource_type === "assets") {
      response = await getAssetAllocationInfo({
        entity_id: entity_id,
        asset_id: resource_id,
        entity_type: type,
      });
    } else {
      response = await getWebAppAllocationInfo({
        entity_id: entity_id,
        web_app_id: resource_id,
        entity_type: type,
      });
    }
    const data = response.data;
    const error = response.error;
    if (data != null) {
      let storedDate = new Date(data.from * 1000);

      setFrom(
        storedDate.getFullYear() +
          "-" +
          (storedDate.getMonth() < 9
            ? "0" + (storedDate.getMonth() + 1)
            : storedDate.getMonth() + 1) +
          "-" +
          (storedDate.getDate() <= 9
            ? "0" + storedDate.getDate()
            : storedDate.getDate())
      );
      setFromTime(
        (storedDate.getHours() <= 9
          ? "0" + storedDate.getHours()
          : storedDate.getHours()) +
          ":" +
          (storedDate.getMinutes() <= 9
            ? "0" + storedDate.getMinutes()
            : storedDate.getMinutes())
      );

      let storedToDate = new Date(data.to * 1000);

      setTo(
        storedToDate.getFullYear() +
          "-" +
          (storedToDate.getMonth() < 9
            ? "0" + (storedToDate.getMonth() + 1)
            : storedToDate.getMonth() + 1) +
          "-" +
          (storedToDate.getDate() < 9
            ? "0" + storedToDate.getDate()
            : storedToDate.getDate())
      );
      setToTime(
        (storedToDate.getHours() <= 9
          ? "0" + storedToDate.getHours()
          : storedToDate.getHours()) +
          ":" +
          (storedToDate.getMinutes() <= 9
            ? "0" + storedToDate.getMinutes()
            : storedToDate.getMinutes())
      );
      setPolicy(data.policy);
    }
    if (error !== null) {
      showError(error);
    }
  };
  useEffect(() => {
    const now = new Date();
    setNowDate(
      now.getFullYear() +
        "-" +
        (now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1) +
        "-" +
        (now.getDate() <= 9 ? "0" + now.getDate() : now.getDate())
    );

    setNowTime(
      (now.getHours() <= 9 ? "0" + now.getHours() : now.getHours()) +
        ":" +
        (now.getMinutes() <= 9 ? "0" + now.getMinutes() : now.getMinutes())
    );
    handleLoadCall();
  }, []);

  let finalFromDate, finalToDate;
  const handleSubmit = (e) => {
    e.preventDefault();
    finalFromDate = new Date(from + " " + fromTime + ":00");
    finalToDate = new Date(to + " " + toTime + ":59");
    handleAlertAction(finalFromDate, finalToDate, policy);
  };
  const handleLoadPolicies = () => {
    loadPolices([resource_id], resource_type, setPolicies);
  };

  return (
    <Fade timeout={fadedelayTime} in={slideChecked}>
      <div>
        <Form onSubmit={handleSubmit} className="main_content_container">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="main_content_heading">{headingMsg}</h2>
            <img
              src={CrossIcon}
              alt="close"
              className="cursor_pointer"
              onClick={() => setOpenAlert((o) => !o)}
            />
          </div>
          <div>
            <div className="d-flex flex-row justify-content-start align-items-center">
              <p className="sub_heading">{alertMsg} </p>
              <p className="sub_heading_bold">
                <b>&nbsp;{target_entity_name} : </b>
              </p>
            </div>
            <div className="datetime-component row text-center me-0 ms-0">
              <div
                className={
                  width >= 992
                    ? "col-md-12 col-lg-6 col-sm-12 row border-end pb-2"
                    : "col-md-12 col-lg-6 col-sm-12 row border-bottom pb-2"
                }
              >
                <Form.Group className="p-1 text-truncate col-md-6 col-sm-12">
                  <Form.Label className="title-style pb-1">From</Form.Label>
                  <Form.Control
                    className="input-style"
                    type="date"
                    value={nowDate}
                    min={nowDate}
                    onChange={(e) => {
                      setFrom(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="p-1 text-truncate col-md-6 col-sm-12">
                  <Form.Label className="title-style pb-1">Time</Form.Label>
                  <Form.Control
                    className="input-style"
                    type="time"
                    min={from > nowDate ? "00:00" : nowTime}
                    value={nowTime}
                    onChange={(e) => {
                      setFromTime(e.target.value);
                    }}
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-md-12 col-lg-6  col-sm-12 row pb-2">
                <Form.Group className="p-1 col-md-6 col-sm-12 text-truncate">
                  <Form.Label className="title-style pb-1">To</Form.Label>
                  <Form.Control
                    className="input-style"
                    type="date"
                    value={to}
                    min={from}
                    onChange={(e) => {
                      setTo(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="p-1 col-md-6 col-sm-12 text-truncate">
                  <Form.Label className="title-style pb-1">Time</Form.Label>
                  <Form.Control
                    className="input-style"
                    type="time"
                    max="23:59"
                    value={toTime}
                    min={to > from ? "00:00" : fromTime}
                    onChange={(e) => {
                      setToTime(e.target.value);
                    }}
                    required
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex flex-column flex-wrap assign_policy_container mt-3 datetime-component">
              <div className="container">
                <div className="row border-bottom py-3">
                  <p className="display_names_heading mb-0 col-2">Sr No</p>
                  <p className="display_names_heading mb-0 col-3">
                    {target_type}
                  </p>
                  {show_policy && (
                    <p className="display_names_heading mb-0 col-7">Policy</p>
                  )}
                </div>
                {colName.map((col, index) => (
                  <div
                    className="row justify-content-start  align-items-center my-3"
                    key={index}
                  >
                    <p className="display_names col-2 mb-0">{index + 1}.</p>
                    <p className="display_names col-3 mb-0" key={index}>
                      {col.name}
                    </p>
                    {show_policy && policies && (
                      <div className="col-7">
                        <Select
                          className="systemuser_select col-12 col-md-10"
                          classNamePrefix="asset"
                          getOptionLabel={(option) => option.policy_name}
                          getOptionValue={(option) => option.id}
                          isClearable={true}
                          isSearchable={true}
                          name="systemuser_select"
                          menuPlacement={
                            colName.length - 1 === index && colName.length > 2
                              ? "top"
                              : "bottom"
                          }
                          required={true}
                          options={policies[resource_name]}
                          onChange={(selectedOption) => {
                            setPolicy(selectedOption);
                          }}
                          value={policy}
                          onFocus={handleLoadPolicies}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="pt-4 mb-3 d-flex flex-lg-row align-items-center justify-content-end">
            <div sm="auto">
              <Button
                variant="light"
                as="input"
                className="form_cancel_button"
                type="button"
                value="Cancel"
                onClick={() => setOpenAlert((o) => !o)}
              />
            </div>
            <div sm="auto" className="me-sm-2 p-0">
              <input
                as="input"
                className="form-submit-btn"
                type="submit"
                value="Confirm"
              />
            </div>
          </div>
        </Form>
      </div>
    </Fade>
  );
};
export default TimeCompExtend;

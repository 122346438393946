import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import {
    allWebAppsList,
    deleteWebApp,
    getWebAppDetail,
    updateWebAppAsset,
} from "../../../../api/web_app";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import exportIcon from "../../../../images/export.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import plus from "../../../../images/plus.svg";
import ThreeDotIcon from "../../../../images/three_dot.svg";
import uploadIcon from "../../../../images/upload.svg";
import { changeTitle } from "../../../../state/slices/header";
import GetCapabilities from "../../../../utils/getCapabilities";
import images from '../../../../utils/images';
import { showError, showSuccess } from "../../../../utils/showMessage";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import "../../../common/css/RDT_common.scss";
import '../../Assets/MyAssets/MyAssets.scss';
import AddWebApp from "../AddWebApp/AddWebApp";
import EditWebApp from "../EditWebApp/EditWebApp";
import { Fade, Slide } from "@mui/material";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { delayTime, fadedelayTime, slideDirection } from "../../../../utils/transitionEffectParams";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
const ActionComponent = ({ setOpenAddWebApp }) => {
    const navigate = useNavigate();
    const my_capabilities = GetCapabilities();
    const isCapable = my_capabilities[0]
    const UploadButton = styled(MuiButton)({
        boxShadow: "none",
        textTransform: "none",
        fontWeight: 600,
        fontSize: 14,
        fontStyle: "normal",
        padding: "8px 12px",
        color: "#717171",
        lineHeight: "21px",
        fontFamily: ['"DM Sans"'].join(","),
    });
    const AddButton = styled(MuiButton)({
        boxShadow: "none",
        textTransform: "none",
        fontWeight: 600,
        fontSize: 14,
        fontStyle: "normal",
        padding: "10px 20px",
        lineHeight: "21px",
        fontFamily: ['"DM Sans"'].join(","),
    });

    return (
        <Stack direction="row" spacing={2} className="py-3">
            <div className="d-none">
                <UploadButton
                    startIcon={<img src={exportIcon} alt="icon" id="center-blueShadow" />}
                >
                    Export
                </UploadButton>
                <UploadButton
                    startIcon={<img src={uploadIcon} alt="icon" id="center-blueShadow" />}
                >
                    Bulk Upload
                </UploadButton>
            </div>
            {isCapable && isCapable.web_apps.add && (<AddButton
                onClick={() => {
                    setOpenAddWebApp((o) => !o);
                }}
                variant="outlined"
                startIcon={
                    <img src={plus} alt="icon" id="center-blueShadow" className="" />
                }
            >
                Add Web App
            </AddButton>)}
            <AddButton
                onClick={() => {
                    navigate(`/dashboard/mysystemusers`);
                }}
                variant="contained"

            >
                System Users
            </AddButton>
        </Stack>
    );
};

function AllWebApps() {
    const { height } = useWindowDimensions();
    let datatable_height = (height - 280) + 'px'
    const dispatch = useDispatch();
    const my_capabilities = GetCapabilities();
    const isCapable = my_capabilities[0]
    const navigate = useNavigate();
    const [web_apps, setWebApps] = useState([]);
    const [web_app, setWebApp] = useState(null);
    const [totalWebApps, setTotalWebApps] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage_AllWebApps') !== null ? localStorage.getItem('rowsPerPage_AllWebApps') : 5);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [action, setAction] = React.useState("");
    const [filterBy, setFilterBy] = React.useState("");
    const [openAddWebApp, setOpenAddWebApp] = useState(false);
    const [openEditWebApp, setOpenEditWebApp] = useState(false);
    const [search, setSearch] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [webAppDetail, setWebAppDetail] = useState(null);
    const [rowsToDelete, setRowsToDelete] = useState([]);
    const [deleteWebAppNames, setDeleteWebAppNames] = useState([]);
    const [openDeleteWebApp, setOpenDeleteWebApp] = useState(false);
    const pages_url = "dashboard/all-web_apps";
    const [pending, setPending] = useState(true);
    const [colCount, setColCount] = useState(null);
    const [slideChecked, setSlideChecked] = useState(false);
    const customizeColumnDict = { "Web App Id": true, "Login Page URL": false };
    const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);

    const [asset, setAsset] = useState(null);
    const [assets, setAssets] = useState([]);

    const webAppColumns = [
        { label: "Web App Id", value: "id" },
        { label: "Web App Name", value: "app_name" },
        { label: "Login Page URL", value: "login_page_url" },
        { label: "Web App Type", value: "app_type" },
    ];
    const actionOptions = [{ label: "Delete", value: "delete" }];
    const title_heading = useSelector((state) => state.header.title);

    const getAllWebAppList = async ({ page, rowsPerPage, search, filterBy }) => {
        const { data } = await allWebAppsList({
            page: page - 1,
            limit: rowsPerPage,
            search: search,
            filterBy: filterBy,
        });
        if (data !== null) {
            setWebApps(data.web_apps);
            setFilteredData(data.web_apps);
            setTotalWebApps(data.totalWebApps);
            setPending(false);
        }
    };
    useEffect(() => {
        setSlideChecked(true);
        dispatch(changeTitle("WebApps"));
    }, []);
    useEffect(() => {
        GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
        getAllWebAppList({ page, rowsPerPage, search, filterBy });
    }, [page, rowsPerPage, dispatch, web_app]);
    useEffect(() => {
        let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
        const result = web_apps.filter((object) => {
            return object.app_name.toLowerCase().match(s.toLowerCase()) ||
                object.app_type.toLowerCase().match(s.toLowerCase()) ||
                object.login_page_url.toLowerCase().match(s.toLowerCase()) ||
                object.id.toString().toLowerCase().match(s.toLowerCase())
        })
        setFilteredData(result);
    }, [search])
    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPage(1);
        localStorage.setItem('rowsPerPage_AllWebApps', newPerPage);
        setRowsPerPage(newPerPage);
    };

    const handleRowSelected = useCallback((state) => {
        setSelectedRows(state.selectedRows);
    }, []);
    const handleAction = async () => {
        if (action === "delete") {
            handleDelete(null);
        }
    };

    const handleDelete = async (row) => {
        let temp = [];
        if (row) {
            temp = row;
        } else {
            temp = selectedRows;
        }
        setRowsToDelete(temp);
        let t = temp.map((object) => object.app_name);
        setDeleteWebAppNames(t);
        setOpenDeleteWebApp((o) => !o);
    };
    const handleDeleteAction = async (row) => {
        let ids = rowsToDelete.map((item) => item.id);
        const { data, error } = await deleteWebApp({ ids: ids });
        if (data !== null) {
            showSuccess(data.message);
            setWebApp(Object.create(null));
            setOpenDeleteWebApp((o) => !o);
        }
        if (error !== null) {
            showError(error);
        }
        setSelectedRows([]);
        setToggleCleared(!toggleCleared);
    };
    const handleWebAppEdit = async (web_app_id) => {
        const { data, error } = await getWebAppDetail({ web_app_id: web_app_id });
        if (data !== null) {
            setWebAppDetail(data.web_app);
            setOpenEditWebApp((o) => !o);
        }
        if (error !== null) {
            showError(error);
        }
    };
    const handleSearch = (event) => {
        event.preventDefault();
        getAllWebAppList({ page, rowsPerPage, search, filterBy });
    };
    const handleAssetWebAppChange = async (asset_id) => {
        const { data, error } = await updateWebAppAsset({ asset_id: asset_id });
        if (data !== null) {
            showSuccess(data.message);
        }
        if (error !== null) {
            showError(error);
        }
    }
    const columns = [
        {
            name: "Web App Id",
            selector: (row) => row.id,
            grow: 1,
            omit: !checkedStateDict["Web App Id"],
        },
        {
            name: "Web App Name",
            selector: (row) => row.app_name,
            grow: 1,
        },
        {
            name: "Login Page URL",
            selector: (row) => row.login_page_url,
            omit: !checkedStateDict["Login Page URL"],
        },
        {
            name: "Web App Type",
            selector: (row) => row.app_type,
            grow: 1,
        },
        isCapable && (isCapable.web_apps.edit || isCapable.web_apps.delete || isCapable.web_apps.allocate_web_apps || isCapable.web_apps.deallocate_web_apps) && {
            name: "Action",
            cell: (row) => (
                <>
                    <Nav>
                        <NavDropdown
                            id="nav-dropdown-systemuser"
                            title={<img src={ThreeDotIcon} alt="action" />}
                            menuVariant="primary"
                        >
                            {isCapable && isCapable.web_apps.edit && (<NavDropdown.Item onClick={() => handleWebAppEdit(row.id)}>
                                Edit
                            </NavDropdown.Item>)}
                            {isCapable && isCapable.web_apps.delete && (<NavDropdown.Item
                                onClick={async () => {
                                    handleDelete([row]);
                                }}
                            >
                                Delete
                            </NavDropdown.Item>)}
                            {isCapable && isCapable.web_apps.allocate_web_apps && (<NavDropdown.Item
                                onClick={() => {
                                    navigate(`/dashboard/web_apps/main-allocate/${row.id}`);
                                }}
                            >
                                Allocate
                            </NavDropdown.Item>)}
                            {isCapable && isCapable.web_apps.deallocate_web_apps && (<NavDropdown.Item
                                onClick={() => {
                                    navigate(`/dashboard/web_apps/main-deallocate/${row.id}`);
                                }}
                            >
                                View Allocated
                            </NavDropdown.Item>)}
                        </NavDropdown>
                    </Nav>
                </>
            ),
            middle: true,
            minWidth: "50px",
        },
    ];
    return (
        <>
            {(() => {
                switch (title_heading) {
                    case "WebApps":
                        return (
                            <div className="heading_datable bg-white">
                                <span
                                    style={{ color: "#50514F" }}
                                    className="ff-poppins fs-24px fw-600"
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                                            <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                                                <img
                                                    src={images.WebAppHeading}
                                                    alt="users round icon"
                                                /> &nbsp; Web Apps
                                            </span>
                                            <br />
                                        </div>
                                    </div>
                                </span>
                            </div>
                        );
                }
            })()}
            <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
                <div>
                    <Fade timeout={fadedelayTime} in={slideChecked}>
                        <div>
                            <div className="main_content_container p-3 mx-auto w-100">
                                <CustomModal open={openAddWebApp} handleClose={()=>{setOpenAddWebApp(o=>!o);}}>
                                    <AddWebApp setOpenAddWebApp={setOpenAddWebApp} setWebApp={setWebApp} />
                                </CustomModal>
                                {webAppDetail && (
                                    <CustomModal open={openEditWebApp} handleClose={()=>{setOpenEditWebApp(o=>!o);}}>
                                        <EditWebApp
                                            web_app={webAppDetail}
                                            setOpenEditWebApp={setOpenEditWebApp}
                                            setWebApp={setWebApp}
                                        />
                                    </CustomModal>
                                )}
                                {deleteWebAppNames.length > 0 && (
                                    <CustomModal open={openDeleteWebApp} handleClose={()=>{setOpenDeleteWebApp(o=>!o);}}>
                                        <ShowAlert
                                            setOpenAlert={setOpenDeleteWebApp}
                                            handleAlertAction={handleDeleteAction}
                                            colName={deleteWebAppNames}
                                            alertMsg="Below web_apps will be deleted:"
                                            headingMsg="Delete Web Apps"
                                        />
                                    </CustomModal>
                                )}
                                <div className="position-relative bg-white" style={{ height: `${datatable_height}` }}>
                                    <DataTable
                                        title={
                                            <ListHeading
                                                dataTableHeadingText="All Web Apps"
                                                dataTableHeadingIcon={images.WebAppHeading}
                                            />
                                        }
                                        columns={columns}
                                        data={filteredData}
                                        className="rdt_container"
                                        progressPending={pending}
                                        progressComponent={
                                            colCount !== null && (
                                                <CustomDelaySkeleton
                                                    rowsPerPage={rowsPerPage}
                                                    colCount={colCount}
                                                    totalColumns={4}
                                                />
                                            )
                                        }
                                        fixedHeader
                                        paginationPerPage={rowsPerPage}
                                        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalWebApps}
                                        selectableRows
                                        selectableRowsVisibleOnly
                                        selectableRowsHighlight
                                        highlightOnHover
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        onSelectedRowsChange={handleRowSelected}
                                        clearSelectedRows={toggleCleared}
                                        actions={<ActionComponent setOpenAddWebApp={setOpenAddWebApp} />}
                                        subHeader
                                        subHeaderComponent={
                                            <SubHeaderComponent
                                                filterBy={filterBy}
                                                action={action}
                                                setAction={setAction}
                                                setFilterBy={setFilterBy}
                                                handleAction={handleAction}
                                                filterOptions={webAppColumns}
                                                actionOptions={(isCapable && isCapable.web_apps.delete) ? actionOptions : null}
                                                search={search}
                                                setSearch={setSearch}
                                                handleSearch={handleSearch}
                                                placeholder="web_apps"
                                                selectedRows={selectedRows}
                                                pages_url={pages_url}
                                                checkedStateDict={checkedStateDict}
                                                setCheckedStateDict={setCheckedStateDict}
                                            />
                                        }
                                        subHeaderAlign="center"
                                        paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
                                        paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
                                        paginationIconNext={<img src={NextPageIcon} alt="next" />}
                                        paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fade>

                </div>
            </Slide>
        </>
    );
}

export default AllWebApps;

import React from 'react'
import images from '../../../../utils/images'
import '../KnowledgeBasedQuestion/KnowledgeBasedQuestion.scss';
const DisplayToken = ({ setOpenModal }) => {
    return (
        <>
            <div class="display_container">
                <div class="main_heading_and_cross_icon d-flex justify-content-between">
                    <h2 id="sms_method_heading" class="mfa_method_heading">
                        Display Token
                    </h2>
                    <img src={images.CrossIcon} alt="X" id="sms_cross_icon"
                        class="cross_icon" onClick={() => { setOpenModal(o => !o) }} />
                </div>
                <div>
                    <p id="sms_mfa_method_description pb-3" className="mfa_method_description">
                        In this method, you need to authenticate yourself using your display hardware token.
                    </p>
                </div>
                <div class="mfa_method_content py-4">
                    <ol>
                        <li className="mfa_method_description display_description"><p>Go to Xecurify Dashboard.</p></li>
                        <li className="mfa_method_description display_description"><p>Navigate to 2-Factor Authentication &#62; Assign Hardware Token to Users.</p></li>
                        <li className="mfa_method_description display_description"><p>Click on Assign Tokens.</p></li>
                        <li className="mfa_method_description display_description"><p>In "ADD DISPLAY TOKEN DETAILS" Container. Add the Token Serial Number and Token Secret key. Set the Select Counter as either 30s or 60s.</p></li>
                        <li className="mfa_method_description display_description"><p>In "ASSIGN DISPLAY TOKEN TO USERS" Container. Select the Username and their respective Token Id.</p></li>
                    </ol>
                </div>
                <div class="mt-4 d-flex justify-content-end">
                    <div class="d-flex justify-content-end">
                        <button type="button" id="sms_cancel_button"
                            class="form_cancel_button" onClick={() => { setOpenModal(o => !o) }}>Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DisplayToken

import React from "react";
import { useEffect,  useState } from "react";
import { changeTitle } from "../../../state/slices/header";
import { useDispatch } from "react-redux";
import sso from "../../../images/sso.svg";
import { Button, Form, Row, Col } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { Slide, Fade } from "@mui/material";

import "./sso.scss";
import {
  delayTime,
  slideDirection,
  fadedelayTime
} from "../../../utils/transitionEffectParams";

const Ldap = () => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 185 + "px";
  const [ldapServerProtocol, setLdapServerProtocol] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [searchBase, setSearchBase] = useState("");
  const title_heading = useSelector((state) => state.header.title);
  const dispatch = useDispatch();
  const [slideChecked, setSlideChecked] = useState(false);
  
  useEffect(() => {
    dispatch(changeTitle("SSO"));
  }, []);
  useEffect(() => {
    setSlideChecked(true);
  }, []);
    
  const [isSearchable, setIsSearchable] = useState(true);

  const handleSubmitLdap = (event) => {
    event.preventDefault();
  };
  const ldapServerUrl = [{ value: "ldap://", label: "ldap://" }];
  const [ldapServerDomain, setLdapServerDomain] = useState(ldapServerUrl);
  const searchFilterType = [{ value: "value", label: "Label" }];
  const [searchFilterTypes, setSearchFilterTypes] = useState(searchFilterType);
  const [searchFilterTypeValue, setSearchFilterTypeValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  return (
    <div className="d-none">
      {(() => {
        switch (title_heading) {
          case "SSO":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("../../../images/sso.svg").default}
                          alt="sso icon"
                        />{" "}
                        &nbsp; Authentication
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide
      timeout={delayTime}
      
      direction={slideDirection}
      in={slideChecked}
    >
      <div>
      <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
        <div
          className="position-relative bg-white overflow-auto"
          style={{ height: `${datatable_height}` }}
        >
          <div className="unscrollable">
            <div className="lines" />
            {/* heading div start */}
            <div className="d-flex flex-lg-row justify-content-between align-items-center py-2 mx-5 my-2">
              <div className="d-flex flex-lg-row justify-content-center align-items-center">
                <div className="pe-3">
                  <img src={sso} alt="SSO" />
                </div>
                <h2 className="main-heading">LDAP</h2>
              </div>
              <div>
                <Button
                  type="submit"
                  form="ldap-form"
                  className="save-btn save-btn-text my-1 mx-3 py-2 px-4"
                >
                  Save
                </Button>
              </div>
            </div>
            <div className="lines" />
          </div>

          {/* select LDAP form start */}
          <div className="overflow-auto">
            <span className="ldap-form-heading mt-4 ms-5">
              AD Configuration
            </span>
            <Form
              id="ldap-form"
              className="mx-5 pb-3 mt-3"
              onSubmit={handleSubmitLdap}
            >
              <Row>
                <Col lg={12} md={12} sm={12} className="mb-3">
                  <Form.Group>
                    <Form.Label className="ldap-server-url-label">
                      LDAP Server URL
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className="d-flex flex-row justify-content-start align-items-center ps-2"
                >
                  <CreatableSelect
                    className="ldap_server_url_input_field select-oauth"
                    isClearable
                    required={true}
                    defaultValue={ldapServerUrl[0]}
                    isSearchable={isSearchable}
                    options={ldapServerUrl}
                  />
                  <Form.Group>
                    <Form.Control
                      style={{ width: "148%", height: "3rem" }}
                      className="ldap_server_url_other_input_field"
                      type="text"
                      required
                    />
                  </Form.Group>
                  <div>
                    <Button className="ldap-server-url-test-btn">
                      <span className="ladp-server-url-test-btn-label px-3">
                        Test Connection
                      </span>
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={6} md={12} sm={12}>
                  <Form.Group>
                    <Form.Label className="ldap-server-url-label">
                      Bind Account Username
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={username}
                      className="ldap-form-input my-3"
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="ldap-server-url-label">
                      Bind Account Password
                    </Form.Label>
                    <Form.Control
                      required
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      className="ldap-form-input my-3"
                    />
                  </Form.Group>
                </Col>
                <Col lg={2} md={6} sm={12} className="position-relative">
                  <Button className="other-test-btn ps-1">
                    <span className="ladp-server-url-test-btn-label px-3">
                      Test Connection
                    </span>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <Form.Group>
                    <Form.Label className="ldap-server-url-label">
                      Search Base
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="ldap-form-input my-3"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Form.Group>
                    <Form.Label className="ldap-server-url-label">
                      Search Filter Type
                    </Form.Label>
                    <Select
                      className="my-3"
                      required={true}
                      defaultValue={searchFilterType[0]}
                      options={searchFilterType}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <Form.Group>
                    <Form.Label className="ldap-server-url-label">
                      Search Filter Value
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="ldap-form-input my-3"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <span className="my-3 att-map">Attribute Mapping</span>
              <Row className="mt-3">
                <Col lg={4} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="ldap-server-url-label">
                      First Name
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="ldap-form-input my-3"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="ldap-server-url-label">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="ldap-form-input my-3"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="ldap-server-url-label">
                      Email
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="ldap-form-input my-3"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>      
  </div>
</Fade>
      
      </div>
    </Slide>
    </div>
  );
};
export default Ldap;

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getWebAppsAccessReportData } from "../../../api/report";
import DataTable from "react-data-table-component";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import allWebAppHeadingIcon from "../../../images/apps_icon.svg";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import FirstPageIcon from "../../../images/first_page.svg";
import LastPageIcon from "../../../images/last_page.svg";
import NextPageIcon from "../../../images/arrow_right.svg";
import PreviousPageIcon from "../../../images/arrow_left.svg";
import { changeTitle } from "../../../state/slices/header";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { showError } from "../../../utils/showMessage";
import { Button } from "react-bootstrap";
import { generateToken } from "../../../api/recordings";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { Slide, Fade } from "@mui/material";
import { delayTime, slideDirection, fadedelayTime } from "../../../utils/transitionEffectParams";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { formatted_timedate } from "../../../utils/updateDateFormat";

function WebAppAccessReport() {
    const { width, height } = useWindowDimensions();
    let datatable_height = height - 280 + "px";
    const dispatch = useDispatch();
    const { auditsUrl } = useSelector((state) => state.customer);
    const navigate = useNavigate();
    const [webAppAccessData, setWebAppAccessData] = useState(null);
    const [totalWebAppAccessedData, setTotalWebAppAccessedData] = useState(0);
    const [page, setPage] = useState(1);
    const title_heading = useSelector((state) => state.header.title);
    const [colCount, setColCount] = useState(null);
    const [pending, setPending] = useState(true);
    const [slideChecked, setSlideChecked] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(
        localStorage.getItem("limit_WebAppAccessReport") !== null
            ? localStorage.getItem("limit_WebAppAccessReport")
            : 5
    );
    const [filterBy, setFilterBy] = React.useState("");
    const [search, setSearch] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const pages_url = "dashboard/webAppAccessReport";
    const customizeColumnDict = {
        "User Name": true,
        "Web App Type": true,
        "User Ip": true,
        "User Agent": true,
        "Accessed Date": true
    }
    const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict)

    const accessReportColumns = [
        { label: "User Name", value: "user_name" },
        { label: "Web App Name", value: "web_app_name" },
        { label: "Web App Type", value: "web_app_type" },
        { label: "User Ip", value: "user_ip" },
        { label: "User Agent", value: "user_agent" },
        { label: "Status", value: "status" },
    ];
    useEffect(() => {
        setSlideChecked(true);
    }, []);

    const getWebAppAccesReportList = async () => {
        const { data } = await getWebAppsAccessReportData({
            page: page - 1,
            limit: rowsPerPage,
            search: search,
            filterBy: filterBy,
        });
        if (data !== null) {
            setWebAppAccessData(data.data);
            setFilteredData(data.data);
            setTotalWebAppAccessedData(data.totalAccessedWebApps);
            setPending(false);
        }
    };

    useEffect(() => {
        GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
        getWebAppAccesReportList();
    }, [page, rowsPerPage, dispatch]);

    useEffect(() => {
        if (webAppAccessData != null || webAppAccessData != undefined) {
            let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
            const result = webAppAccessData.filter((object) => {
                return (
                    object.user_name.toLowerCase().match(s.toLowerCase()) ||
                    object.web_app_name.toLowerCase().match(s.toLowerCase()) ||
                    object.web_app_type.toLowerCase().match(s.toLowerCase()) ||
                    object.user_ip.toString().toLowerCase().match(s.toLowerCase()) ||
                    object.user_agent.toLowerCase().match(s.toLowerCase()) ||
                    object.status.toLowerCase().match(s.toLowerCase())
                );
            });
            setFilteredData(result);
        }
    }, [search]);

    useEffect(() => {
        dispatch(changeTitle("Reports"));
    }, []);

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPage(1);
        localStorage.setItem("limit_WebAppAccessReport", newPerPage);
        setRowsPerPage(newPerPage);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        getWebAppAccesReportList();
    };

    const columns = [
        {
            name: "Id",
            selector: (row) => row.id,
            grow: 0,
            minWidth: "85px",
            sortable: true,
        },
        {
            name: "Web App Name",
            selector: (row) => row.web_app_name,
            grow: 1,
            sortable: true,
        },
        {
            name: "User Name",
            selector: (row) => row.user_name,
            grow: 1,
            omit: !checkedStateDict["User Name"],
            sortable: true,
        },
        {
            name: "Web App Type",
            selector: (row) => <div className="text-uppercase">{row.web_app_type}</div>,
            grow: 1,
            omit: !checkedStateDict["Web App Type"],
            sortable: true,
        },
        {
            name: "User Ip",
            selector: (row) => row.user_ip,
            grow: 1,
            omit: !checkedStateDict["User Ip"],
            sortable: true,
        },
        {
            name: "User Agent",
            selector: (row) => row.user_agent,
            grow: 1,
            omit: !checkedStateDict["User Agent"],
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) =>
                row.status === "SUCCESS" ? (
                    <div className="text-success">{row.status}</div>
                ) : (
                    <div className="text-danger">{row.status}</div>
                ),
            grow: 1,
            sortable: true,
        },
        {
            name: "Accessed Date",
            selector: (row) => (
                formatted_timedate(row.accessed_time)
            ),
            grow: 1,
            omit: !checkedStateDict["Accessed Date"],
            sortable: true,
        },
        {
            name: "Action",
            cell: (row) => (
                row.is_recorded ?
                    <Button
                        className="border-0 bg-transparent text-primary"
                        onClick={async () => {
                            const { data, error } = await generateToken();
                            if (data !== null) {
                                const url =
                                    `${auditsUrl}/audits/session_recording/replay/?session_type=WebApp&access_id=` +
                                    row.id +
                                    "&token_key=" +
                                    data.token_key +
                                    "&token_value=" +
                                    data.token_value +
                                    "&session_key=" +
                                    data.session_key +
                                    "&video_id=" +
                                    -1 +
                                    "&audits_url=" +
                                    auditsUrl +
                                    "&customer_id=" +
                                    data.customer_id;;
                                window.open(url, "_blank");
                            }
                            if (error !== null) {
                                showError(error);
                            }
                        }}
                    >
                        <AiOutlinePlayCircle size={20} color="#32a3ea" />
                    </Button>
                    : (
                        <button
                            disabled
                            type="button"
                            className="border-0 bg-transparent"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Video not present"
                        >
                            <AiOutlinePlayCircle size={20} color="#f4646f" />
                        </button>
                    )
            ),

            center: true,
            minWidth: "50px",
        },
    ];

    return (
        <>
            {(() => {
                switch (title_heading) {
                    case "Reports":
                        return (
                            <div className="heading_datable bg-white">
                                <span
                                    style={{ color: "#50514F" }}
                                    className="ff-poppins fs-24px fw-600"
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                                            <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                                                <img
                                                    src={require("./icons/icon.svg").default}
                                                    alt="users round icon"
                                                />{" "}
                                                &nbsp; Reports
                                            </span>
                                            <br />
                                        </div>
                                    </div>
                                </span>
                            </div>
                        );
                }
            })()}
            <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
                <div>
                    <Fade timeout={fadedelayTime} in={slideChecked}>
                        <div>
                            <div className="main_content_container p-3 mx-auto w-100">
                                <div
                                    className="position-relative bg-white"
                                    style={{ height: `${datatable_height}` }}
                                >
                                    <DataTable
                                        title={
                                            <ListHeading
                                                dataTableHeadingText="WebApps Access Report"
                                                dataTableHeadingIcon={allWebAppHeadingIcon}
                                            />
                                        }
                                        columns={columns}
                                        data={filteredData}
                                        className="rdt_container"
                                        fixedHeader
                                        paginationPerPage={rowsPerPage}
                                        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        pagination
                                        paginationServer
                                        progressPending={pending}
                                        progressComponent={
                                            colCount !== null && (
                                                <CustomDelaySkeleton
                                                    rowsPerPage={rowsPerPage}
                                                    colCount={colCount}
                                                    totalColumns={3}
                                                />
                                            )
                                        }
                                        paginationTotalRows={totalWebAppAccessedData}
                                        highlightOnHover
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        subHeader
                                        subHeaderComponent={
                                            <SubHeaderComponent
                                                filterBy={filterBy}
                                                setFilterBy={setFilterBy}
                                                filterOptions={accessReportColumns}
                                                search={search}
                                                setSearch={setSearch}
                                                handleSearch={handleSearch}
                                                placeholder="reports"
                                                pages_url={pages_url}
                                                checkedStateDict={checkedStateDict}
                                                setCheckedStateDict={setCheckedStateDict}
                                            />
                                        }
                                        subHeaderAlign="center"
                                        paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
                                        paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
                                        paginationIconNext={<img src={NextPageIcon} alt="next" />}
                                        paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
                                    />
                                </div>

                            </div>
                        </div>
                    </Fade>


                </div>
            </Slide>

        </>
    );
}

export default WebAppAccessReport;

import React from "react"
import { useState, useEffect } from "react"
import CreatableSelect from "react-select/creatable"
import images from "../../../utils/images"
import { GetMinioAttributeData } from "../../../api/sso"

const AttributeMappingMiniOrange = ({rows,setRows,item,idx,list,setAttributeList,checkIfAttributesAreCorrect,oauthappname})=>{
    const [attributeName, setAttributeName] = useState(rows[idx]["attributeName"] || "")
    const [attributeValue, setAttributeValue] = useState(rows[idx]["attributeValue"] || "")
    const [attributeType, setAttributeType] = useState(rows[idx]["attributeType"] || "")
    const handleRemoveAttribute = (e, idx) => {
        const tempRows = [...rows]
        tempRows.splice(idx, 1)
        setRows(rows => tempRows)
    }

    const attributeNameOptions = [
        { value: 'email', label: 'Email', selected: false },
        { value: 'username', label: 'Username', selected: false },
        { value: 'phone_number', label: 'Phone number', selected: false },
        { value: 'first_name', label: 'First name', selected: false },
        { value: 'last_name', label: 'Last name', selected: false },
        { value: 'user_groups', label: 'User groups', selected: false }
    ]
    const element = [{ "idx": idx, "dropdown": attributeNameOptions }]
    const [attributeNameOptionsState, setAttributeNameOptionsState] = useState(element);
    
    const newAttributeNameOptions = () => {
        let attName = rows[idx]["attributeName"]
        let itemindex = attributeNameOptionsState.findIndex((item) => item["idx"] === idx);
        try {
            let temp_dropdown = []
            let array_list=[]
            for(let i in attributeNameOptions){
                item=attributeNameOptions[i]
                array_list.push(item.value)
            }
            let attNameLabel=String(attName).charAt(0).toUpperCase() + String(attName).slice(1) || "";
            if(attName === ""){
                for (let index in attributeNameOptions) {
                    let item = attributeNameOptions[index]
                    if (item.value === attName) {
                        item.selected = true;
                    }
                    temp_dropdown.push(item)
                }
            }
            else if(array_list.includes(attName)){
                for (let index in attributeNameOptions) {
                    let item = attributeNameOptions[index]
                    if (item.value === attName) {
                        item.selected = true;
                    }
                    temp_dropdown.push(item)
                }
            }
            else{
                temp_dropdown.push({value:attName,label:attNameLabel,selected:true});
                temp_dropdown.push({ value: 'email', label: 'Email', selected: false });
                temp_dropdown.push({ value: 'username', label: 'Username', selected: false });
                temp_dropdown.push({ value: 'phone_number', label: 'Phone number', selected: false });
                temp_dropdown.push({ value: 'first_name', label: 'First name', selected: false });
                temp_dropdown.push({ value: 'last_name', label: 'Last name', selected: false });
                temp_dropdown.push({ value: 'user_groups', label: 'User groups', selected: false });
            }
            attributeNameOptionsState[itemindex]["dropdown"] = temp_dropdown
            setAttributeNameOptionsState(attributeNameOptionsState => [
                ...attributeNameOptionsState])
        } catch (err) {}
    }

    useEffect(() => {
        construce_attribute_value()
        
        newAttributeNameOptions()
        newAttributeValueOptions()
    }, [rows])

    const handleCustomAttributeNameChange = (value, idx) => {
        setAttributeName(value)
        rows[idx]["attributeName"] = value
        rows[idx]["isattributeNameSelected"]=true
        setRows(rows => [...rows])
    }

    let itemindex = attributeNameOptionsState.findIndex((item) => item["idx"] === idx);
    let elementsDrop = attributeNameOptionsState[itemindex]["dropdown"]
    const handleChangeSelect = () => {
        for (let index in attributeNameOptionsState) {
            let item = attributeNameOptionsState[index];
            if (item["idx"] === idx) {
                for (let i in item["dropdown"]) {
                    let drop_element = item["dropdown"][i];
                    if (drop_element["selected"] === true) {
                        return drop_element;
                    }
                }
            }
            return "";
        }
    }

    // attributeValue logic
    const newAttributeValueOptions = ()=>{
        let attValue = rows[idx]["attributeValue"];
        let itemindex = attributeValueOptionState.findIndex((item) =>item["idx"] === idx)
        try{
            let temp_dropdown = []
            if(attValue === ""){
                for(let list_index in list){
                    temp_dropdown.push({
                        value:list[list_index],label:list[list_index],selected:false
                    })
                }
            }
            else if (list.includes(attValue)){
                for (let index in attributeValueOptions) {
                    let item = attributeValueOptions[index]
                    if (item.value === attValue) {
                        item.selected = true;
                    }
                    temp_dropdown.push(item)
                }
            }
            else{
                for(let list_index in list){
                    temp_dropdown.push({
                        value:list[list_index],label:list[list_index],selected:false
                    })
                }
                temp_dropdown.push({
                    value:attValue,label:attValue,selected:true
                })
            }
            try
            {
                attributeValueOptionState[itemindex]["dropdown"] = temp_dropdown;
                setAttributeValueOptionState(attributeValueOptionState => [
                    ...attributeValueOptionState
                ])
            } catch(e){}
        } catch (err) {}
    }
    const construce_attribute_value = () => {
        for (let list_index in list) {
            if(rows[idx]["attributeValue"]===list[list_index]){
                attributeValueOptions.push({
                    value: list[list_index], label: list[list_index], selected: true
                })
            }else{
                attributeValueOptions.push({
                    value: list[list_index], label: list[list_index], selected: false
                })
            }
        }
    }
    const attributeValueOptions=[]
    
    const attributeValueElement = [{"idx":idx,"dropdown":attributeValueOptions}];
    const [attributeValueOptionState,setAttributeValueOptionState] = useState(attributeValueElement)

    let attributeValueItemIndex = attributeValueOptionState.findIndex((item)=>item["idx"] === idx)
    let finalOptions = attributeValueOptionState[attributeValueItemIndex]["dropdown"]

    const handleAttributeValueChange = (value,idx)=>{
        setAttributeValue(value)
        rows[idx]["attributeValue"] = value;
        rows[idx]["isattributeValueSelected"]=true;
        setRows(rows => [...rows])
    }

    const AttributeValueGet = ()=>{
        for (let index in attributeValueOptionState) {
            let item = attributeValueOptionState[index];
            if (item["idx"] === idx) {
                for (let i in item["dropdown"]) {
                    let drop_element = item["dropdown"][i];
                    if (drop_element["selected"] === true) {
                        return drop_element;
                    }
                }
            }
            return "";
        }
    }

    useEffect(()=>{
        construce_attribute_value()
        const attributeValueElement = [{"idx":idx,"dropdown":attributeValueOptions}];
        setAttributeValueOptionState(attributeValueElement)
    },[list])

    // call to backend to update the attribute list.
    const refreshAttributes = async()=>{
        const {data,error} = await GetMinioAttributeData(oauthappname)
        if(data!==null){
            if(data.data !== null){
                setAttributeList(data['list']);
            }
        }
        else{
            return null;
        }
    }
    return(
        <div className="d-flex align-items-center">
        <span className="attribute-mapping-number">{idx+1}.</span>
        <div className="d-flex flex-row width-div justify-content-between align-items-center">
            <CreatableSelect
                required={true}
                className="m-2 width-div-attributeName"
                noOptionsMessage="Select Attribute Name"
                value={handleChangeSelect()}
                onChange={(selectedOption) => {handleCustomAttributeNameChange(selectedOption.value, idx)
                checkIfAttributesAreCorrect();}
                }
                options={elementsDrop}
                styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: rows[idx].isattributeNameSelected ? '#ced4da' : 'red',
                    }),
                  }}
                />
            <CreatableSelect
            onMenuOpen={()=>{
                refreshAttributes()
            }}
            required={true}
            className="m-2 width-div-attributeName"
            options={finalOptions}
            value={AttributeValueGet()}
            onChange={(selectedOption)=>{handleAttributeValueChange(selectedOption.value,idx);checkIfAttributesAreCorrect();}}
            styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: rows[idx].isattributeValueSelected ? '#ced4da' : 'red',
                }),
              }}
            />
            <img
                alt="remove-attribute-from-list"
                type="button"
                src={images.SSORemoveAttribute}
                className="mapping-remove-btn"
                onClick={(e) => { handleRemoveAttribute(e, idx) }} 
            />
        </div>
        </div>
    )
}
export default AttributeMappingMiniOrange;
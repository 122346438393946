import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import { AiFillQuestionCircle } from "react-icons/ai";
import Popover from "react-bootstrap/Popover";
import { NavLink } from "react-router-dom";
import Asset from "./Asset";
import Role from "./Groups";
import Settings from "./Settings";
import SSO from "./Sso";
import "./Sidebar.scss";
import User from "./User";
import { changeTitle } from "../../../state/slices/header";
import { useDispatch, useSelector } from "react-redux";
import Customizations from "./Customization";
import App from "./Apps";
import useWindowDimensions from "../../../utils/getHeightWidth";
import TwoFa from "./TwoFa";
import images from "../../../utils/images";
import { useState } from "react";
import contactusx from "./icons/contactus-x.svg";
import loader from "./icons/loader.svg";
import { ContactUsPost } from "../../../api/contact_us";
import { showError,showSuccess } from "../../../utils/showMessage";
import  GetCapabilities  from "../../../utils/getCapabilities";
import Policy from "./Policy";
import Groups from "./Groups";
import "../../common/css/common.scss";


import WebApp from "./WebApp";
const activeLink = "d-flex py-2 ps-3 active-accordion text-decoration-none";
const normalLink = "d-flex py-2 ps-3 inactive-text-color text-decoration-none";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return <div onClick={decoratedOnClick}>{children}</div>;
}

function Sidebar(props) {
  const dispatch = useDispatch();
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const isCapableEnduser= my_capabilities[1]
  const title = useSelector((state) => state.header.title);
  const emailValue = useSelector((state)=>state.user.user.email);
  const phonenumber =useSelector((state)=>state.user.user.phone_number)
  const { isEndUser } = props;
  const { width } = useWindowDimensions();

  const activeButton =
    " active-linkElement padding_sidebar sidebar-font text-decoration-none d-flex";

  const normalButton =
    "inactive-text-color padding_sidebar text-decoration-none text-start sidebar-font d-flex";

  const [email,setEmail] = useState(emailValue!== ""?emailValue:"")
  const [phoneNumber,setPhoneNumber] = useState(phonenumber!==""?phonenumber:"")
  const [query,setQuery] = useState("")
  const [validated,setValidated] = useState(false)
  const [submitted,setSubmitted] = useState(false)

  const postContactUs = async() => {
    const args={
      "email":email,
      "phonenumber":phoneNumber,
      "query":query
    }
    const {data,error} = await ContactUsPost(args);
    if(data!== null){
      showSuccess(data.message);
      setSubmitted(false)
    }else{
      showError(error);
      setSubmitted(false)
    }
  }
  const handleContactUsSubmit = (event)=>{
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
      setValidated(true)
    }
    else{
      event.stopPropagation();
      event.preventDefault();
      setValidated(false);
      setSubmitted(true)
      postContactUs()
    }
  }
    const popover = (
      <Popover id="popover-basic">
        <Popover.Header
          as="h3"
          className="border-0 bg-white py-3 pam-fontFamily bg-popover"
        >
          <Row>
            <Col className="contactus-heading">Need Help ? </Col>
            <Col className="text-end">
              <img src={contactusx} onClick={() => document.body.click()} />
            </Col>
          </Row>
        </Popover.Header>
        <Popover.Body className="pt-1 pam-fontFamily">
          <Form id="side-pop" noValidate validated={validated} onSubmit={handleContactUsSubmit}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label className="pop-form contactus-label">
                Email
                <span className="text-danger">*</span>
                </Form.Label>
              <Form.Control
              type="text"
              placeholder="Enter your email"
              value={email}
              pattern="^\S+@\S+\.\S+$"
              className="user_input_field contactus-input"
              required
              onChange={(e)=>setEmail(e.target.value)}/>
              <Form.Control.Feedback type="invalid">
                  Please enter a valid Email.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label className="pop-form contactus-label">
                Phone Number</Form.Label>
              <Form.Control
              placeholder="Enter your number"
              value={phoneNumber}
              pattern="^(\+\d{1,3}[\s]?)?([\s]?\d{10,12})$"
              className="user_input_field contactus-input"
              onChange={(e)=>setPhoneNumber(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                  Please enter a valid phone number. Phone number length allowed is 12. Country code length allowed is 3. Add + before country code.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicquery">
              <Form.Label className="pop-form contactus-label">
                Query Type
                <span className="text-danger">*</span>
                </Form.Label>
              <Form.Control
              as="textarea"
              type="text"
              rows={5}
              className="user_input_field contactus-input contactus-query"
              required
              placeholder="Enter your query"
              value={query}
              onChange={(e)=>setQuery(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                  Please enter a valid Query.
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              variant="secondary w-100 bg-button fs-14 py-2 mt-2"
              type="submit"
              disabled={submitted?true:false}
            >
              {!submitted && "Contact Us"}
              {submitted && <img src={loader} width="20px" height="20px" />}
            </Button>
          </Form>
        </Popover.Body>
      </Popover>
    );

  return (
    <div className="bs-white">
      <Accordion defaultActiveKey="" flush className="my-2 mr-1 p-30">
        {((isCapable) || (isCapableEnduser)) && (<Card className="border-0 bg-transparent">
          <Card.Header className="p-0 border-0 bg-transparent">
            <CustomToggle eventKey="0">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive ? activeButton : normalButton
                }
                onClick={() => {
                  dispatch(changeTitle("dashboard"));
                }}
                end
              >
                <img
                  className="mx-3 filter-active"
                  src={require("./icons/dashboard.svg").default}
                  alt="dashboard-icon"
                  height="22px"
                  width="22px"
                />
                Dashboard
              </NavLink>
            </CustomToggle>
          </Card.Header>
        </Card>)}
        {isCapable && isCapable.system_user.view && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/mysystemusers"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("Sys_user"));
                  }}
                >
                  <img
                    className="mx-3 filter-active"
                    src={require("./icons/sys_user.svg").default}
                    alt="sysUser-icon"
                    height="22px"
                    width="22px"
                  />
                  System Users
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}
        {isCapableEnduser && isCapableEnduser.view_allocated_assets && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/myassets"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("Assets"));
                  }}
                >
                  <img
                    className="mx-3 filter-active"
                    src={require("./icons/assets.svg").default}
                    alt="sysUser-icon"
                    height="22px"
                    width="22px"
                  />
                  My Assets
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}
        {isCapableEnduser && isCapableEnduser.view_allocated_apps && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/myApps"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("Apps"));
                  }}
                >
                  <img
                    className="mx-3 filter-active"
                    src={require("./icons/apps.svg").default}
                    alt="sysUser-icon"
                    height="22px"
                    width="22px"
                  />
                  My Apps
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}
        {isCapableEnduser && isCapableEnduser.view_allocated_web_apps && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/myWebApps"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("WebApps"));
                  }}
                >
                  <img
                    className="mx-3 filter-active"
                    src={images.WebAppSidebar}
                    alt="sysUser-icon"
                    height="22px"
                    width="22px"
                  />
                  My Web Apps
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}
        {isCapableEnduser && isCapableEnduser.enable_disable_mfa && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/mfa/list"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("2FA"));
                  }}
                >
                  <img
                    className="mx-3 filter-active"
                    src={images.TwoFactorSidebarIcon}
                    alt="sysUser-icon"
                    height="22px"
                    width="22px"
                  />
                  Setup 2FA
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}

        {isCapable && (isCapable.assets.view || isCapable.assets.view_allocated_assets) && <Asset title={title} />}
        {isCapable && (isCapable.apps.view || isCapable.apps.view_allocated_apps) && <App title={title} />}
        {isCapable && (isCapable.web_apps?.view || isCapable.web_apps?.view_allocated_web_apps) && <WebApp title={title} />}
        {isCapable && (isCapable.policies.manage_database_policy.view || isCapable.policies.manage_ssh_policy.view || isCapable.policies.manage_rdp_policy.view) && <Policy title={title} />}
        {isCapable && isCapable.MFA.configure_mfa && <TwoFa title={title} />}
        {isCapable && isCapable.SSO.enable_disable_sso && <SSO title={title} />}
        {isCapable && (isCapable.branding.edit_branding_setting || isCapable.branding.manage_email_provider) && <Customizations title={title} />}
        {isCapable && (isCapable.users.user.view || isCapable.users.roles.view) && <User title={title} />}
        {isCapable && isCapable.groups.view && <Groups title={title} />}
        <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/tickets"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                >
                  <img
                    className="mx-3 filter-active"
                    src={images.TicketIcon}
                    alt="ticket-icon"
                    height="22px"
                    width="22px"
                  />
                  {((isCapableEnduser && isCapableEnduser.allow_ticket_view) ||
            (isCapable && isCapable.miscellaneous.ticket.view_ticket)) ? <>Tickets</> : <>My Tickets</>}
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        {isCapable && isCapable.miscellaneous.setting.show_settings && <Settings title={title} />}
        
          
      
      </Accordion>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement={width >= 768 ? "right" : "top"}
        overlay={popover}
      >
        <div
          className={
            width >= 768
              ? "contact-div position-absolute contact-button-fixed px-0 "
              : "position-absolute contact-button-responsive px-0 "
          }
        >
          <div className="cls1">
            <Card
              border="secondary"
              className=" px-0 bg-card card-border ff-poppins card-sidebar"
            >
              <Card.Body>
                <Button className="d-flex px-2 btn btn-light button-border text-align-center border-0 bg-white ">
                  <AiFillQuestionCircle size="23" className="bg-darkGrey" />
                </Button>
                <Card.Title className=" pt-2 mb-0 text-white fs-14px">
                  Need Help?
                </Card.Title>
                <Card.Text className="text-white fs-10px">
                  Getting stuck somewhere!
                </Card.Text>
                <div className="d-flex justify-content-center">
                  <Button className="py-2 btn btn-light  text-uppercase text-button border-0 bg-white w-100">
                    contact us
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="cls2">
            <Button className="py-2 bg-button border-0 ff-poppins w-100">
              Contact Us
            </Button>
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
}

export default Sidebar;

export { activeLink, normalLink };

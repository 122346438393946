import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { recordingList, generateToken } from "../../../api/recordings";
import DataTable from "react-data-table-component";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import recordingIcon from "../../../images/recordings_icon.svg";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import FirstPageIcon from "../../../images/first_page.svg";
import LastPageIcon from "../../../images/last_page.svg";
import NextPageIcon from "../../../images/arrow_right.svg";
import PreviousPageIcon from "../../../images/arrow_left.svg";
import { changeTitle } from "../../../state/slices/header";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { showError } from "../../../utils/showMessage";
import { Button } from "react-bootstrap";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide,Fade } from "@mui/material";
import { delayTime,fadedelayTime, slideDirection } from "../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../utils/getHeightWidth";

function Recordings() {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 300 + "px";
  const dispatch = useDispatch();
  const { auditsUrl } = useSelector((state) => state.customer);
  const navigate = useNavigate();
  const [recordingData, setRecordingData] = useState(null);
  const [totalRecordingData, setTotalRecordingData] = useState(0);
  const [storageLocation, setStorageLocation] = useState(null);
  const [page, setPage] = useState(1);
  const title_heading = useSelector((state) => state.header.title);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("limit_RecordingList") !== null
      ? localStorage.getItem("limit_RecordingList")
      : 5
  );
  const [filterBy, setFilterBy] = React.useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const pages_url = "dashboard/recordings";
  const [colCount,setColCount] = useState(null);
  const [pending, setPending]= useState(true);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const customizeColumnDict = {
    "Session Type":true,
    "Resource Id":false,
    "Start Time":true,
    "User Ip":false,
    "Storage Location":false,
  };
  const [checkedStateDict,setCheckedStateDict] = useState(customizeColumnDict)

  const recordingsColumn = [
    { label: "Email", value: "email" },
    { label: "Video Id", value: "video_id" },
    { label: "Session Type", value: "session_type" },
    { label: "Resource Id ", value: "resource_id" },
    { label: "Start Time", value: "start_time" },
    { label: "User Ip", value: "user_ip" },
    { label: "Storage Location", value: "storage_location" },
  ];

  const getRecordingsList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data } = await recordingList({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
    });
    if (data !== null) {
      setRecordingData(data.results);
      setFilteredData(data.results);
      setTotalRecordingData(data.count);
      setStorageLocation(data.selected_location);
      setPending(false)
    }
  };

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getRecordingsList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch]);

  useEffect(() => {
    if (recordingData != null || recordingData != undefined) {
      let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
      const result = recordingData.filter((object) => {
        return (
          object.email.toLowerCase().match(s.toLowerCase()) ||
          object.video_id.toLowerCase().match(s.toLowerCase()) ||
          (object.resource_id &&
            object.resource_id.toLowerCase().match(s.toLowerCase())) ||
          (object.user_ip &&
            object.user_ip.toString().toLowerCase().match(s.toLowerCase())) ||
          (object.session_type &&
            object.session_type.toLowerCase().match(s.toLowerCase())) ||
          object.start_time.toLowerCase().match(s.toLowerCase()) ||
          (object.storage_location &&
            object.storage_location.toLowerCase().match(s.toLowerCase()))
        );
      });
      setFilteredData(result);
    }
  }, [search]);

  useEffect(() => {
    dispatch(changeTitle("Live"));
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("limit_RecordingList", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    getRecordingsList({ page, rowsPerPage, search, filterBy });
  };

  const columns = [
    {
      name: "Email",
      selector: (row) =>row.email,
      grow: 1,
      sortable: true,
    },
    {
      name: "Video Id",
      selector: (row) => row.video_id,
      grow: 1,
      sortable: true,
    },
    {
      name: "Session Type",
      selector: (row) => row.session_type,
      grow: 1,
      omit: !checkedStateDict["Session Type"],
      sortable: true,
    },
    {
      name: "Resource Id",
      selector: (row) => row.resource_id,
      grow: 1,
      omit: !checkedStateDict["Resource Id"],
      sortable: true,
    },
    {
      name: "Resource Name",
      selector: (row) => row.resource_name,
      grow: 1,
      sortable: true,
    },
    {
      name: "Resource Type",
      selector: (row) => row.resource_type,
      grow: 1,
      sortable: true,
    },
    {
      name: "Storage Location",
      selector: (row) => row.storage_location,
      grow: 1,
      omit: !checkedStateDict["Storage Location"],
      sortable: true,
    },
    {
      name: "Start Time",
      selector: (row) => (
        <div id="Onhover">{new Date(row.start_time).toDateString()}, {new Date(row.start_time).toLocaleTimeString().toUpperCase()}</div>
      ),
      grow: 1,
      sortable: true,
      omit: !checkedStateDict["Start Time"],
      sortable: true,
    },
    {
      name: "User Ip",
      selector: (row) => row.user_ip,
      grow: 1,
      omit: !checkedStateDict["User Ip"],
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) =>
        row.storage_location === storageLocation || row.storage_location === "local_server"? (
          <Button
            className="border-0 bg-transparent text-primary"
            onClick={async () => {
              const { data, error } = await generateToken();
              if (data !== null) {
                const url =
                  `${auditsUrl}/audits/session_recording/replay/?video_id=` +
                  row.video_id +
                  "&token_key=" +
                  data.token_key +
                  "&token_value=" +
                  data.token_value +
                  "&session_key=" +
                  data.session_key +
                  "&audits_url="+
                  auditsUrl +
                  "&customer_id="+
                  data.customer_id;
                window.open(url, "_blank");
              }
              if (error !== null) {
                showError(error);
              }
            }}
          >
            <AiOutlinePlayCircle size={20} color="#32a3ea" />
          </Button>
        ) : (
          <button
          disabled
          type="button"
          className="border-0 bg-transparent"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          title="Video not present in selected storage option"
        >
          <AiOutlinePlayCircle size={20} color="#f4646f" />
        </button>
        ),
      center: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
    {(() => {
        switch (title_heading) {
          case "Live":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("./icons/icon.svg").default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Sessions
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide  timeout={delayTime}  direction={slideDirection} in={slideChecked}>
        <div>
      <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
      <div className="position-relative bg-white"
          style={{ height: `${datatable_height}` }}>
<DataTable
          title={
            <ListHeading
              dataTableHeadingText="Recorded Sessions"
              dataTableHeadingIcon={recordingIcon}
            />
          }
          columns={columns}
          data={filteredData}
          className="rdt_container"
          progressPending={pending}
          progressComponent={
              colCount !== null && (
              <CustomDelaySkeleton
                  rowsPerPage={rowsPerPage}
                  colCount={colCount}
                  totalColumns={7}
              />
              )
          }
          fixedHeader
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          pagination
          paginationServer
          paginationTotalRows={totalRecordingData}
          highlightOnHover
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          subHeader
          subHeaderComponent={
            <SubHeaderComponent
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              filterOptions={recordingsColumn}
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              placeholder="recordings"
              pages_url={pages_url}
              checkedStateDict={checkedStateDict}
              setCheckedStateDict={setCheckedStateDict}
            />
          }
          subHeaderAlign="center"
          paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
          paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
          paginationIconNext={<img src={NextPageIcon} alt="next" />}
          paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
        />
          </div>
        
      </div>         
  </div>
</Fade>

      
      </div>
    </Slide>
    </>
  );
}

export default Recordings;

import React from "react";
import icon from "./icons/icon.svg";
import "./report.scss";
import arrow_first from "./icons/arrow_first.svg";
import arrow_last from "./icons/arrow_last.svg";
import arrow_right from "./icons/arrow_right.svg";
import arrow_left from "./icons/arrow_left.svg";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { GetAdvanceDataUser, GetReportData } from "../../../api/report";
import { changeTitle } from "../../../state/slices/header";
import { useDispatch } from "react-redux";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CustomModal from "../../common/CustomModal/CustomModal";
import AdvanceFilter from "./AdvanceFilter";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { useSelector } from "react-redux";
import {formatted_timedate} from "../../../utils/updateDateFormat";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide,Fade } from "@mui/material";
import { delayTime, slideDirection, fadedelayTime } from "../../../utils/transitionEffectParams";

const ActionComponent = ({ setOpenAddApp }) => {
  const UploadButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "8px 12px",
    color: "#717171",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const AddButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
    variant: "secondary",
  });

  return (
    <Stack direction="row" spacing={2} className="py-3">
      <AddButton
        onClick={() => {
          setOpenAddApp((o) => !o);
        }}
        variant="outlined"
      >
        Advance Filter
      </AddButton>
    </Stack>
  );
};
function Users() {
  const { width, height } = useWindowDimensions();
  let datatable_height = height-280  + "px";
  const [openAddApp, setOpenAddApp] = useState(false);
  const path = "user-login-audits";
  const title_heading = useSelector((state) => state.header.title);
  /* customize column modal functionality starts. */
  const UserColumns = [
    { label: "Email", value: "email" },
    { label: "IP/Host", value: "ip" },
    { label: "User Agent", value: "user_agent" },
    { label: "Status", value: "status" },
    { label: "Datetime", value: "datetime" },
  ];
  const pages_url = "dashboard/report_users/";
  const customizeColumnDict = { "User Agent": true, Datetime: true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "IP/Host",
        selector: (row) => row.ip,
        maxWidth: "150px",
        sortable: true,
      },
      {
        name: "User Agent",
        selector: (row) => row.user_agent,
        omit: !checkedStateDict["User Agent"],
        maxWidth: "200px",
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) =>
          row.status === "Login Success" || row.status === "Logout Success" ? (
            <span className="text-success"> {row.status}</span>
          ) : (
            <span className="text-danger"> {row.status}</span>
          ),
        maxWidth: "200px",
        sortable: true,
      },
      {
        name: "Datetime",
        selector: (row) => (
          formatted_timedate(row.datetime)
        ),
        omit: !checkedStateDict["Datetime"],
        sortable: true,
      },
    ],
    [checkedStateDict]
  );
  /* customize column logic ends. */

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(
    localStorage.getItem("rowsPerPage_UserLoginReport") !== null
      ? localStorage.getItem("rowsPerPage_UserLoginReport")
      : 5
  );
  const [asset, setAsset] = useState([]);
  const [advanceSearchToggle, setAdvanceSearchToggle] = useState(false);
  const [totalAsset, setTotalAsset] = useState(0);
  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async (page, perPage, search, filterBy) => {
    setLoading(true);
    page = page - 1;
    const { data } = await GetReportData(page, perPage, search, filterBy, path);
    if (data !== null) {
      setAsset(data.data || []);
        setTotalAsset(data.count);
        setFilteredData(data.data);
        setLoading(false);
      setPending(false);
    }
  };
  useEffect(() => {
    GetColmn(setCheckedStateDict,  pages_url,  checkedStateDict, setColCount);
    fetchData(1, perPage, search, filterBy);
  }, [perPage]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitle("Reports"));
  }, []);

  const filter_set_data = (filteredData, count) => {
    setFilteredData(filteredData);
    setTotalAsset(count);
    setAsset(filteredData);
  };

  //for advance filtering
  const today = new Date();
  let date = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  if (date < 10) date = "0" + date;
  if (month < 10) month = "0" + month;
  let date_today = year + "-" + month + "-" + date;
  const [userIdentifier, setUserIdentifier] = useState("");
  const [from, setFrom] = useState(date_today);
  const [to, setTo] = useState(date_today);

  const handlePageChange = async (page) => {
    if (advanceSearchToggle) {
      let from_date = from;
      let to_date = to;
      from_date += "T00:00:00.000000Z";
      to_date += "T23:59:59.000000Z";
      page = page - 1;
      const { data } = await GetAdvanceDataUser(
        userIdentifier,
        from_date,
        to_date,
        page,
        perPage,
        path
      );
      if (data != null) {
        filter_set_data(data.data, data.count);
      }
    } else {
      fetchData(page, perPage, search, filterBy);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (advanceSearchToggle) {
      setLoading(true);
      page = page - 1;
      let from_date = from;
      let to_date = to;
      from_date += "T00:00:00.000000Z";
      to_date += "T23:59:59.000000Z";
      const { data } = await GetAdvanceDataUser(
        userIdentifier,
        from_date,
        to_date,
        page,
        newPerPage,
        path
      );
      localStorage.setItem("rowsPerPage_UserLoginReport", newPerPage);
      setPerPage(newPerPage);
      if (data != null) {
        filter_set_data(data.data, data.count);
      }
      setLoading(false);
    } else {
      setLoading(true);
      page = page - 1;
      const { data } = await GetReportData(
        page,
        newPerPage,
        search,
        filterBy,
        path
      );
      if (data !== null) {
        setAsset(data.data || []);
        localStorage.setItem("rowsPerPage_UserLoginReport", newPerPage);
        setPerPage(newPerPage);
        setTotalAsset(data.count);
        setFilteredData(data.data);
        setLoading(false);
        setPending(false);
      }
    }
  };


  //for searching in frontend.
  useEffect(() => {
    const result = asset.filter((object) => {
      let searchs = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
      if (filterBy === "") {
        if (search == "") {
          fetchData(1, perPage, search, filterBy);
        } else {
          return (
            object.email.toLowerCase().match(searchs.toLowerCase()) ||
            object.ip.toLowerCase().match(searchs.toLowerCase()) ||
            object.user_agent.toLowerCase().match(searchs.toLowerCase()) ||
            object.datetime.toLowerCase().match(searchs.toLowerCase()) ||
            object.status.toLowerCase().match(searchs.toLowerCase())
          );
        }
      } else {
        if (search == "") {
          fetchData(1, perPage, search, filterBy);
        } else {
          return object[filterBy.toLowerCase()].match(searchs.toLowerCase());
        }
      }
    });

    setFilteredData(result);
  }, [search]);

  const handleSearch = async (event) => {
    event.preventDefault();
    fetchData(1, perPage, search, filterBy);
  };

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Reports":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("./icons/icon.svg").default}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Reports
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <CustomModal padding="0px !important" open={openAddApp} handleClose={()=>{setOpenAddApp(o=>!o);}}>
        <AdvanceFilter setOpenAddApp={setOpenAddApp} />
      </CustomModal>
      <Slide  timeout={delayTime} className="" direction={slideDirection} in={slideChecked}><div>
      <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
        <div
          className="position-relative bg-white"
          style={{ height: `${datatable_height}` }}
        >
          <DataTable
            title={
              <ListHeading
                dataTableHeadingText="User Login Report"
                dataTableHeadingIcon={icon}
              />
            }
            columns={columns}
            data={filteredData}
            className="rdt_container"
            progressPending={pending}
            progressComponent={
              <CustomDelaySkeleton
                rowsPerPage={perPage}
                colCount={0}
                totalColumns={4}
              />
            }
            fixedHeader
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
            pagination
            paginationServer
            paginationTotalRows={totalAsset}
            highlightOnHover
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={
              <SubHeaderComponent
                placeholder="user login reports"
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                filterOptions={UserColumns}
                search={search}
                setSearch={setSearch}
                handleSearch={handleSearch}
                pages_url={pages_url}
                checkedStateDict={checkedStateDict}
                setCheckedStateDict={setCheckedStateDict}
                advanceSearchToggle={advanceSearchToggle}
                showAdvance={true}
                setAdvanceSearchToggle={setAdvanceSearchToggle}
                filter_set_data={filter_set_data}
                page={1}
                perPage={perPage}
                userIdentifier={userIdentifier}
                setUserIdentifier={setUserIdentifier}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                path={path}
                for_userlogin={true}
              />
            }
            subHeaderAlign="center"
            paginationIconFirstPage={<img src={arrow_first} alt="first page" />}
            paginationIconLastPage={<img src={arrow_last} alt="last page" />}
            paginationIconNext={<img src={arrow_right} alt="next" />}
            paginationIconPrevious={<img src={arrow_left} alt="previous" />}
          />
        </div>
      </div>        
  </div>
</Fade>

      
    </div></Slide>
    </>
  );
}
export default Users;

import React, { useEffect, useState } from "react";
import { showError, showSuccess } from "../../../utils/showMessage";
import { MdWebAsset } from "react-icons/md";
import DataTable from "react-data-table-component";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import systemUserHeadingIcon from "./icons/License.svg";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import FirstPageIcon from "../../../images/first_page.svg";
import LastPageIcon from "../../../images/last_page.svg";
import NextPageIcon from "../../../images/arrow_right.svg";
import PreviousPageIcon from "../../../images/arrow_left.svg";
import { changeTitle } from "../../../state/slices/header";
import {useDispatch, useSelector} from "react-redux";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import { getLicense, updateLicense } from "../../../api/license";
import useWindowDimensions from "../../../utils/getHeightWidth";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide, Fade } from "@mui/material";
import { delayTime, slideDirection, fadedelayTime } from "../../../utils/transitionEffectParams";

import GetCapabilities from "../../../utils/getCapabilities";

const License = () => {
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 270 + "px";
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_UserApps") !== null
      ? localStorage.getItem("rowsPerPage_UserApps")
      : 5
  );
  const [apps, setApps] = useState([]);
  const [filterBy, setFilterBy] = React.useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const pages_url = "dashboard/license";
  const customizeColumnDict = {
    "Number of Users": true,
    "Number of Assets": true,
    "Number of Apps": true,
    "Current Total Users": true,
    "Current Total Assets": true,
    "Current Total Apps": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const title_heading = useSelector((state) => state.header.title);
  const appColumns = [
    { label: "Number of Users", value: "no_of_user" },
    { label: "Number of Assets", value: "no_of_assets" },
    { label: "Number of Apps", value: "no_of_apps" },
    { label: "Current Total Users", value: "total_users" },
    { label: "Current Total Assets", value: "total_assets" },
    { label: "Current Total Apps", value: "total_apps" },
  ];

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    getLicenseDetail();
  }, [page, rowsPerPage]);

  const getLicenseDetail = async () => {
    const { data, error } = await getLicense();
    if (data !== null) {
      setFilteredData(data);
      setApps(data);
    }
    if (error !== null) {
      showError(error);
    }
    setPending(false);
  };

  const updateLicenseDetails = async () => {
    const { data, error } = await updateLicense();
    if (data != null) {
      showSuccess(data.message);
      getLicenseDetail();
    }
    if (error != null) {
      showError(error);
    }
  };

  useEffect(() => {
    let s = search.replaceAll(/.*/g, "");
    const result = apps.filter((object) => {
      return (
        object.expiry_date.toLowerCase().match(s.toLowerCase()) ||
        object.no_of_user.toLowerCase().match(s.toLowerCase()) ||
        object.total_users.toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  useEffect(() => {
    dispatch(changeTitle("Settings"));
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_UserApps", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const columns = [
    {
      name: "License Expiry",
      selector: (row) => row.expiry_date,
      grow: 1,
      sortable: true,
    },
    {
      name: "Number of Users",
      selector: (row) => row.no_of_user,
      omit: !checkedStateDict["Number of Users"],
      sortable: true,
    },
    {
      name: "Number of Assets",
      selector: (row) => row.no_of_assets,
      omit: !checkedStateDict["Number of Assets"],
      sortable: true,
    },
    {
      name: "Number of Apps",
      selector: (row) => row.no_of_apps,
      omit: !checkedStateDict["Number of Apps"],
      sortable: true,
    },
    {
      name: "Current Total Users",
      selector: (row) => row.total_users,
      omit: !checkedStateDict["Current Total Users"],
      sortable: true,
    },
    {
      name: "Current Total Assets",
      selector: (row) => row.total_assets,
      omit: !checkedStateDict["Current Total Assets"],
      sortable: true,
    },
    {
      name: "Current Total Apps",
      selector: (row) => row.total_apps,
      omit: !checkedStateDict["Current Total Apps"],
      sortable: true,
    },
    isCapable && isCapable.miscellaneous.license.update_license &&{
      name: "Action",
      cell: (row) => (
        <>
          <p className="myassetslink" onClick={updateLicenseDetails}>
            <MdWebAsset></MdWebAsset>Update License
          </p>
        </>
      ),
      middle: true,
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Settings":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("./icons/settings-icon.svg").default}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Settings
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide  timeout={delayTime}  direction={slideDirection} in={slideChecked}>
        <div>
      <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
        <div
          className="position-relative bg-white"
          style={{ height: `${datatable_height}` }}
        >
          <DataTable
            title={
              <ListHeading
                dataTableHeadingText="Manage Licenses"
                dataTableHeadingIcon={systemUserHeadingIcon}
              />
            }
            columns={columns}
            data={filteredData}
            className="rdt_container"
            progressPending={pending}
          progressComponent={
            <CustomDelaySkeleton
              rowsPerPage={rowsPerPage}
              colCount={0}
              totalColumns={7}
            />
          }
            fixedHeader
            paginationPerPage="1"
            paginationRowsPerPageOptions={[5]}
            pagination
            paginationServer
            paginationTotalRows="1"
            highlightOnHover
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={
              <SubHeaderComponent
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                filterOptions={appColumns}
                search={search}
                setSearch={setSearch}
                placeholder="licenses"
                pages_url={pages_url}
                checkedStateDict={checkedStateDict}
                setCheckedStateDict={setCheckedStateDict}
                search_disabled={true}
              />
            }
            subHeaderAlign="center"
            paginationIconFirstPage={
              <img src={FirstPageIcon} alt="first page" />
            }
            paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
            paginationIconNext={<img src={NextPageIcon} alt="next" />}
            paginationIconPrevious={
              <img src={PreviousPageIcon} alt="previous" />
            }
          />
        </div>
      </div>
  </div>
</Fade>

      </div>
    </Slide>
    </>
  );
};

export default License;

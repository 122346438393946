import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import {
  deleteGroups,
  getGroupDetails,
  groupList,
} from "../../../../api/groups";
import { showError, showSuccess } from "../../../../utils/showMessage";
import "../../../common/css/RDT_common.scss";
import CustomModal from "../../../common/CustomModal/CustomModal";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import AddGroup from "../AddGroup/AddGroup";
import EditGroup from "../EditGroup/EditGroup";
import { changeTitle } from "../../../../state/slices/header";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import Images from "../../../../utils/images";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide, Fade } from "@mui/material";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import GetCapabilities from "../../../../utils/getCapabilities";

const ActionComponent = ({ setOpenAddGroup }) => {
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const UploadButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "8px 12px",
    color: "#717171",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const AddButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });

  return (
    <Stack direction="row" spacing={2} className="py-3">
      <div className="d-none">
        <UploadButton
          startIcon={
            <img src={Images.ExportIcon} alt="icon" id="center-blueShadow" />
          }
        >
          Export
        </UploadButton>
      </div>
      {isCapable && isCapable.groups.add && (
        <AddButton
          onClick={() => {
            setOpenAddGroup((o) => !o);
          }}
          variant="outlined"
          startIcon={
            <img src={Images.Plus} alt="icon" id="center-blueShadow" />
          }
        >
          Add Group
        </AddButton>
      )}
    </Stack>
  );
};

const GroupsList = () => {
  const { width, height } = useWindowDimensions();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  let datatable_height = height - 280 + "px";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [groups, setGroups] = useState([]);
  const [totalGroups, setTotalGroups] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_GroupList") !== null
      ? localStorage.getItem("rowsPerPage_GroupList")
      : 5
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [openEditGroup, setOpenEditGroup] = useState(false);
  const [openDeleteGroup, setOpenDeleteGroup] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [groupData, setGroupData] = useState(null);
  const [deleteGroupNames, setDeleteGroupNames] = useState([]);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const pages_url = "dashboard/GroupList";
  const customizeColumnDict = { "Group Id": true, "Display Name": true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const title_heading = useSelector((state) => state.header.title);
  const groupColumns = [
    { label: "Group Id", value: "group_id" },
    { label: "Name", value: "group_name" },
    { label: "Display Name", value: "group_display_name" },
  ];
  const actionOptions = [{ label: "Delete", value: "delete" }];
  const getGroups = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data } = await groupList({
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setGroups(data.groups);
      setTotalGroups(data.totalGroups);
      setFilteredData(data.groups);
      setPending(false);
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    getGroups({ page, rowsPerPage, search, filterBy });
  };
  useEffect(() => {
    dispatch(changeTitle("Groups"));
  }, []);
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getGroups({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, group]);
  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = groups.filter((object) => {
      return (
        object.group_name.toLowerCase().match(s.toLowerCase()) ||
        object.group_display_name.toLowerCase().match(s.toLowerCase()) ||
        object.group_id.toString().toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_GroupList", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "delete") {
      handleDelete(null);
    }
  };
  const handleDelete = async (row) => {
    let temp = [];
    if (row) {
      temp = row;
    } else {
      temp = selectedRows;
    }
    setRowsToDelete(temp);
    let t = temp.map((object) => object.group_display_name);
    setDeleteGroupNames(t);
    setOpenDeleteGroup((o) => !o);
  };
  const handleDeleteAction = async () => {
    let ids = rowsToDelete.map((item) => item.group_id);
    const { data, error } = await deleteGroups({ ids: ids });
    if (data !== null) {
      showSuccess(data.message);
      setGroup(Object.create(null));
      setOpenDeleteGroup((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  const handleGroupEdit = async (group_id) => {
    const { data, error } = await getGroupDetails({ group_id: group_id });
    if (data !== null) {
      setGroupData(data);
      setOpenEditGroup((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
  };
  const disabledCriteria = (row) => {
    return row.group_name === "default" || row.group_display_name === "default"
      ? true
      : false;
  };

  const columns = [
    {
      name: "Group Id",
      selector: (row) => row.group_id,
      grow: 1,
      sortable: true,
      omit: !checkedStateDict["Group Id"],
    },
    {
      name: "Name",
      selector: (row) => row.group_name,
      sortable: true,
    },
    {
      name: "Display Name",
      selector: (row) => row.group_display_name,
      omit: !checkedStateDict["Display Name"],
      sortable: true,
    },
    isCapable &&
      (isCapable.groups.delete ||
        isCapable.groups.edit ||
        isCapable.groups.assign_users_to_group ||
        isCapable.groups.deassign_users_from_group) && {
        name: "Action",
        cell: (row) => (
          <>
            <Nav>
              <NavDropdown
                id="nav-dropdown-systemuser"
                title={<img src={Images.ThreeDotIcon} alt="action" />}
                menuVariant="primary"
              >
                {!disabledCriteria(row) && (
                  <>
                    {isCapable && isCapable.groups.edit && (
                      <NavDropdown.Item
                        onClick={() => handleGroupEdit(row.group_id)}
                      >
                        Edit
                      </NavDropdown.Item>
                    )}
                    {isCapable && isCapable.groups.delete && (
                      <NavDropdown.Item
                        onClick={async () => {
                          handleDelete([row]);
                        }}
                      >
                        Delete
                      </NavDropdown.Item>
                    )}
                    {isCapable && isCapable.groups.assign_users_to_group && (
                      <NavDropdown.Item
                        onClick={async () => {
                          navigate(`assign-users/${row.group_id}`);
                        }}
                      >
                        Assign Users
                      </NavDropdown.Item>
                    )}
                  </>
                )}
                {isCapable && isCapable.groups.deassign_users_from_group && (
                  <NavDropdown.Item
                    onClick={async () => {
                      navigate(`assigned-users/${row.group_id}`);
                    }}
                  >
                    View Assigned Users
                  </NavDropdown.Item>
                )}
              </NavDropdown>
            </Nav>
          </>
        ),
        center: true,
        minWidth: "50px",
      },
  ];
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Groups":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/group_icon.svg").default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Groups
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <CustomModal
                  open={openAddGroup}
                  handleClose={() => {
                    setOpenAddGroup((o) => !o);
                  }}
                >
                  <AddGroup
                    setOpenAddGroup={setOpenAddGroup}
                    setGroup={setGroup}
                  />
                </CustomModal>
                {groupData && (
                  <CustomModal
                    open={openEditGroup}
                    handleClose={() => {
                      setOpenEditGroup((o) => !o);
                    }}
                  >
                    <EditGroup
                      group={groupData}
                      setOpenEditGroup={setOpenEditGroup}
                      setGroup={setGroup}
                    />
                  </CustomModal>
                )}
                {deleteGroupNames.length > 0 && (
                  <CustomModal
                    open={openDeleteGroup}
                    handleClose={() => {
                      setOpenDeleteGroup((o) => !o);
                    }}
                  >
                    <ShowAlert
                      setOpenAlert={setOpenDeleteGroup}
                      handleAlertAction={handleDeleteAction}
                      colName={deleteGroupNames}
                      alertMsg="Below groups will be deleted"
                      headingMsg="Delete Group"
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="Group list"
                        dataTableHeadingIcon={Images.GroupHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      colCount !== null && (
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={5}
                        />
                      )
                    }
                    fixedHeader
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalGroups}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    selectableRowDisabled={disabledCriteria}
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    actions={
                      <ActionComponent setOpenAddGroup={setOpenAddGroup} />
                    }
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="groups"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={groupColumns}
                        actionOptions={
                          isCapable && isCapable.groups.delete
                            ? actionOptions
                            : null
                        }
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={Images.FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={Images.LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={
                      <img src={Images.NextPageIcon} alt="next" />
                    }
                    paginationIconPrevious={
                      <img src={Images.PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default GroupsList;

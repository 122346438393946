import React from "react";
import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import images from "../../../utils/images.js";
import GetCapabilities from "../../../utils/getCapabilities.js";

function User(props) {
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const { title } = props;
  const dispatch = useDispatch();
  const headings= ["User","AllocateApps","AllocateAssets", "DeAllocateApps", "DeAllocateAssets", "ViewGroups" ,"AssignGroups"]
  const handleTitle = async () => {
    dispatch(changeTitle("User"));
  };
  return (
    <Accordion.Item
      eventKey="Users"
      className={(title === "User" || title === "DeAllocateApps" || title === "DeAllocateAssets" || title === "AllocateAssets" || title === 'AllocateApps') ? "sidebar-button-border " : ""}
    >
      <Accordion.Header>
        <img
          className="mx-3 filter-active"
          src={require("./icons/users.svg").default}
          height="22px"
          width="22px"
        />
        Users
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup variant="flush" className="sidebar-list">
          {isCapable && isCapable.users.user.view && (<ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/dashboard/users/usersList"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">User List</div>
            </NavLink>
          </ListGroup.Item>)}

          {isCapable && isCapable.users.roles.view && (<ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/dashboard/usersRoles"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">User Roles</div>
            </NavLink>
          </ListGroup.Item>)}
          <ListGroup.Item className="d-none py-0 pe-0 ps-2">
            <NavLink
              to="/dashboard/usersCapabilities"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Users Capabilities</div>
            </NavLink>
          </ListGroup.Item>
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default User;

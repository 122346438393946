import React from "react";
import CrossIcon from "../../../images/cross.svg"
import {
  GetColumnPreference,
  PutColumnPreference,
  PostColumnPreference,
} from "../../../api/column_pref";
import "./customize_column.scss";
import { useState } from "react";
import CutomColumn from "../../../images/customcolumn.svg";
import CustomModal from "../CustomModal/CustomModal";

export const GetColmn = async (setCheckedStateDict, page_url, checkedStateDict, setColCount) => {
  const countFalseValues= (obj) => {
    return Object.values(obj).reduce((acc, val) => acc + (val === false ? 1 : 0), 0);
}
  const response = await PostColumnPreference(checkedStateDict, page_url);
  if (response.error !== null) {
    const { data, error } = await GetColumnPreference(page_url);
    if (data != null) {
      setCheckedStateDict(data.options);
    }
  } else {
    const { data, error } = await GetColumnPreference(page_url);
    if (data != null) {
      setColCount && setColCount(countFalseValues(data.options));
      setCheckedStateDict(data.options);
    }
  }
};

export const CustomizeColumn = ({
  page_url,setCheckedStateDict,checkedStateDict
}) => {
  const [show, setShow] = useState(false);
  /* customize column modal show. */
  const handleShow = () => {
    setShow(true);
    GetColmn(setCheckedStateDict, page_url);
  };

  /* customize column modal handleclose */
  const handleClose = () => {
    setShow(false);
    const PutColmn = async () => {
      const { data, error } = await PutColumnPreference(checkedStateDict, page_url);
      if (data != null) {
        setCheckedStateDict(data.data.options);
      }
    };
    PutColmn();
  };
  const handleOnChangeDict =(item)=>{
    var tempCheckedDict=checkedStateDict
    var itemValue=tempCheckedDict[item]
    tempCheckedDict[item]=!itemValue
    setCheckedStateDict(oldDict=>({...oldDict}))
  }

  const options=[]
  for(const item in checkedStateDict){
    options.push(
    <div key={item} className="mb-2 rounded custom-pointer border p-2"
    onClick={() => handleOnChangeDict(item)}>
    <input
        type="checkbox"
        className="mx-3 custom-pointer checkbox_size"
        checked={checkedStateDict[item]}
        onChange={() => handleOnChangeDict(item)}
      />
      <label className="custom-pointer">{item}</label>
    </div>
    )
  }

  return (
    <>
      <CustomModal open={show} handleClose={()=>{setShow(false)}} width="30% !important">
        <div>
          <div className="d-flex justify-content-between align-items-center  mb-4">
            <h3 className="border-0 customize-column-name mb-0">Customize Columns</h3>
            <img
              src={CrossIcon}
              alt="close"
              className="cursor_pointer ps-3"
              onClick={() => {
                setShow(false);
                GetColmn(setCheckedStateDict, page_url);
              }}
            />
          </div>
          <div className="custom-modal-body pb-0">
            {options}
          </div>
          <div className="border-0 mt-3 d-flex justify-content-end">
            <button
              className="modals-btn-close px-4 py-2 m-1"
              onClick={() => {
                setShow(false);
                GetColmn(setCheckedStateDict, page_url);
              }}
            >
              Cancel
            </button>
            <button
              className="modals-btn-save px-4 py-2 m-1"
              onClick={handleClose}
            >
              Save
            </button>
          </div>
        </div>
      </CustomModal>
      <div className="d-flex align-items-center qu">
        <button onClick={handleShow} className="advance_filter_button">
          <img src={CutomColumn} alt="icon" id="center-blueShadow" />
        </button>
      </div>
    </>
  );
};

import axios from './axios_interceptor';
import { get_jwt_token, loginRedirect } from './helper_funtions';

export const getPolicyList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/policies/database/policy/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const getDatabaseApps = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/apps/database/`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const getDatabaseResourceListUsingAppId = async (appId, query_type, database = null, table = null) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/apps/database-info/?app_id=${appId}&query_type=${query_type}&database=${database}&table=${table}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const getDatabaseResourceCommands = async (resource_type) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/policies/database/commands/?resource_type=${resource_type}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const addPolicy = async (data) => {
    try {
        const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/policies/database/policy/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const deletePolicies = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: data
        };
        const url = `/policies/database/policy/`;
        const response = await axios.delete(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const getPolicyDetails = async (policy_id) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/policies/database/policy/?policy_id=${policy_id}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const updatePolicy = async ({ policy_id, updatedPolicy }) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/policies/database/policy/?policy_id=${policy_id}`;
        const response = await axios.put(url, updatedPolicy, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const getResourcePolicies = async (resource_ids, resource_type) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = ""
        if (resource_type === "apps") {
            url = `/apps/policies/?resource_ids=${resource_ids}`;
            const response = await axios.get(url, config);
            return { data: response.data, error: null };
        }
        return { data: null, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import GetCapabilities from "../../../utils/getCapabilities.js";
import images from "../../../utils/images.js";

const WebApp = (props) => {
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0]
  const { title } = props;
  const dispatch = useDispatch();

  const handleTitle = async () => {
    dispatch(changeTitle("WebApps"));
  };
  return (
    <Accordion.Item eventKey="WebApps" className={title === "WebApps" ? "sidebar-button-border" : ""}>
      <Accordion.Header >
        <img
          className="mx-3 filter-active"
          src={images.WebAppSidebar}
        />
        Web Apps
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup className="sidebar-list">
          {isCapable.web_apps.view && <ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/dashboard/all-web-apps"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >

              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">All Web Apps</div>
            </NavLink>
          </ListGroup.Item>}
          {isCapable.web_apps.view_allocated_web_apps && (
            <ListGroup.Item className="py-0 pe-0 ps-2">
              <NavLink
                to="/dashboard/myWebApps"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                onClick={handleTitle}
              >
                <img src={images.DotIcon} alt="dot-icon" width="4px" />
                <div className="ps-4">My Web Apps</div>
              </NavLink>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default WebApp;

import React, {useEffect, useState} from "react";
import {mySystemUserList} from "../../../../api/systemuser";
import {showError, showSuccess} from "../../../../utils/showMessage";
import {updateMyApp} from "../../../../api/apps";
import Container from "react-bootstrap/Container";
import CrossIcon from "../../../../images/cross.svg";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import {checkIpHostPattern} from '../../../../utils/checkIpPattern';
import {DarkTooltip} from "../../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "../../Settings/icons/icon-info.svg";
import Fade from '@mui/material/Fade';
import { fadedelayTime } from "../../../../utils/transitionEffectParams";

const EditMyApp = ({app, setOpenEditApp, setApp}) => {
    const [app_id, setAppid] = useState(null);
    const [name, setName] = useState("");
    const [ip_host, setIpHost] = useState("");
    const [default_database, setDefaultDatabase] = useState("");
    const [port, setPort] = useState("");
    const [appType, setAppType] = useState(null);
    const [defaultDatabaseFieldName, setDefaultDatabaseFieldName] = useState("Default database");
    const [defaultDatabaseFieldPlaceholder, setDefaultDatabaseFieldPlaceholder] = useState("Default database");
    const [systemUser, setSystemUser] = useState(null);
    const [validated, setValidated] = useState(false);
    const [systemUsers, setSystemUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const [isIpCheck, setIsIpCheck] = useState(false);
    const [connectionType, setConnectionType] = useState("sid");
    const [connectionTypeValue, setConnectionTypeValue] = useState('');

    const [slideChecked, setSlideChecked] = useState(false);
    useEffect(() => {
      setSlideChecked(true);
    }, []);
    const app_types = [
        {"value": "Psql", "label": "Psql"},
        {"value": "Mysql", "label": "Mysql"},
        {"value": "Mssql", "label": "Mssql"},
        {"value": "Oracledb", "label": "Oracledb"},
        {"value": "SAPHanadb", "label": "SAPHanadb"}
    ]

    const default_ports = {
        Psql: 5432,
        Mysql: 3306,
        Mssql: 1433,
        Oracledb: 1521,
        SAPHanadb: 30015,
    }

    const selectStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            paddingTop: "3px",
            paddingBottom: "3px",
            borderColor: "#ced4da",
            boxShadow: "none",
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#717171",
            ':hover': {
                borderColor: "#ced4da",
            }
        }),
        option: (styles) => ({
            ...styles,
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
        }),
    }

    useEffect(() => {
        const getApp = async () => {
            setAppid(app.id);
            setName(app.app_name);
            setIpHost(app.ip_host);
            let default_database = app.default_database;
            setConnectionType(default_database.split('-')[0]);
            setConnectionTypeValue(default_database.split('-')[1]);
            setDefaultDatabase(app.default_database);
            if (app.app_type === "Oracledb"){
                setDefaultDatabaseFieldName("Connection Type");
            }
            setPort(app.port);
            setAppType(app.app_type);
            setSystemUser(app.system_user);
            const {data: systemUserListData} = await mySystemUserList({"page": -1, "limit": -1});
            if (systemUserListData !== null) {
                setSystemUsers(systemUserListData.system_users);
            }
        }
        getApp();
    }, [app])

    const updateAppFormHandler = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setIsSubmited(true);
        if (form.checkValidity() === false && !checkIpHostPattern(ip_host)) {
            setIsIpCheck(true);
        } else if (!checkIpHostPattern(ip_host)) {
            setIsIpCheck(true);
            return;
        }
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            const {data, error} = await updateMyApp({
                "app_id": app_id, "updatedApp": {
                    ...(name !== "" && {"app_name": name}),
                    ...(ip_host !== "" && {"ip_host": ip_host}),
                    ...(appType === "Oracledb" ? {"default_database": connectionType + "-" + connectionTypeValue} : {"default_database": default_database}),
                    ...(port !== "" && {"port": port}),
                    ...(appType !== "" && {"app_type": appType}),
                    ...(systemUser !== null && {"system_user": systemUser.id}),
                }
            })
            if (data !== null) {
                showSuccess(data.message);
                setLoading(false);
                setApp({});
                setOpenEditApp(o => !o);
            }
            if (error !== null) {
                showError(error)
                setLoading(false);
            }
        }
    }

    return (
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <Container fluid className="main_content_container mx-auto">
            <div className='d-flex justify-content-between align-items-center  mb-4'>
                <h2 className="main_content_heading">Edit App</h2>
                <img src={CrossIcon} alt="close" className="cursor_pointer" onClick={() => setOpenEditApp(o => !o)}/>
            </div>
            <Form noValidate validated={validated} onSubmit={updateAppFormHandler}>
                <Stack gap={1}>
                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextName">
                        <Form.Label column md={3} className="input_label">
                            Name
                        </Form.Label>
                        <Col md={9}>
                            <Form.Control
                                required
                                type="text"
                                className="form_input_field"
                                value={name}
                                onChange={event => setName(event.target.value)}
                                placeholder="Name"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a app name.
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextAppType">
                        <Form.Label column md={3} className="input_label">
                            App Type
                        </Form.Label>
                        <Col md={9}>
                            {appType && <Select
                                className="app_type_select"
                                classNamePrefix="app"
                                isClearable={true}
                                isSearchable={true}
                                defaultValue={app_types.find(obj => obj.value === appType)}
                                name="app_type_edit"
                                required={true}
                                options={app_types}
                                onChange={(selectedOption) => {
                                    setAppType(selectedOption.value);
                                    setPort(default_ports[selectedOption.value]);
                                    if (selectedOption.value === "Oracledb") {
                                        setDefaultDatabaseFieldName("Connection Type");
                                        setDefaultDatabaseFieldPlaceholder("SID or Service Name");
                                    } else {
                                        setDefaultDatabaseFieldName("Default database");
                                        setDefaultDatabaseFieldPlaceholder("Default database");
                                    }
                                }}
                                styles={selectStyles}
                            />}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextPort">
                        <Form.Label column md={3} className="input_label">
                            Port
                        </Form.Label>
                        <Col md={9}>
                            <Form.Control
                                type="number"
                                required
                                className="form_input_field"
                                onChange={event => setPort(event.target.value)}
                                value={port}
                                placeholder="Port"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a port.
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextIP">
                        <Form.Label column md={3} className="input_label">
                            IP/Host
                        </Form.Label>
                        <Col md={9}>
                            <Form.Control
                                type="text"
                                required
                                className="form_input_field"
                                onChange={(event) => {
                                    setIpHost(event.target.value);
                                    if (isSubmited) {
                                        setIsIpCheck(!checkIpHostPattern(event.target.value));
                                    }
                                }}
                                value={ip_host}
                                placeholder="IP/Host"
                                isInvalid={isIpCheck}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter IP/Host.
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextIP">
                        <Form.Label column md={3} className="input_label">
                            {defaultDatabaseFieldName}
                        </Form.Label>
                        <Col md={9}>
                            {
                                appType === "Oracledb" ?
                                    <div>
                                        <div className="pb-2">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio"
                                                       name="inlineRadioOptions"
                                                       id="sid" value="sid" checked={connectionType === "sid"}
                                                       onChange={() => setConnectionType('sid')}
                                                />
                                                <label className="form-check-label" htmlFor="inlineRadio1">SID</label>
                                                <DarkTooltip id="setting-info"
                                                             title="SID is the unique name that uniquely identifies the instance/database.">
                                                    <img
                                                        src={InfoIcon}
                                                        alt="info"
                                                        width="15"
                                                        height="15"
                                                        className="mx-2 cursor_pointer"/>
                                                </DarkTooltip>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio"
                                                       name="inlineRadioOptions"
                                                       id="service_name" value="service_name"
                                                       checked={connectionType === "service_name"}
                                                       onChange={() => setConnectionType('service_name')}
                                                />
                                                <label className="form-check-label" htmlFor="inlineRadio2">Service
                                                    Name</label>
                                                <DarkTooltip id="setting-info"
                                                             title="Alias name working as a listener for a instance/database.">
                                                    <img
                                                        src={InfoIcon}
                                                        alt="info"
                                                        width="15"
                                                        height="15"
                                                        className="mx-2 cursor_pointer"/>
                                                </DarkTooltip>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio"
                                                           name="inlineRadioOptions"
                                                           id="tns" value="tns" disabled/>
                                                    <label className="form-check-label"
                                                           htmlFor="inlineRadio3">TNS</label>
                                                </div>
                                            </div>
                                        </div>

                                        <Form.Control
                                            type="text"
                                            required
                                            onChange={(event) => {
                                                setConnectionTypeValue(event.target.value)
                                            }}
                                            value={connectionTypeValue}
                                            placeholder="SID or Service Name"
                                            className="form_input_field"
                                        />

                                    </div>
                                    :


                                    <Form.Control
                                        type="text"
                                        onChange={event => setDefaultDatabase(event.target.value)}
                                        value={default_database}
                                        placeholder={defaultDatabaseFieldPlaceholder}
                                        className="form_input_field"
                                    />
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextAppType">
                        <Form.Label column md={3} className="input_label">
                            System User
                        </Form.Label>
                        <Col md={9}>
                            {systemUsers.length > 0 && <Select
                                className="basic-single"
                                classNamePrefix="app"
                                getOptionLabel={(option) => option.system_display_username}
                                getOptionValue={(option) => option.id}
                                defaultValue={systemUser}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={systemUsers}
                                onChange={(selectedOption) => setSystemUser(selectedOption)}
                                styles={selectStyles}
                            />}

                        </Col>
                    </Form.Group>
                    <Row className="mb-3 justify-content-end">
                        <Col sm="auto">
                            <Button variant='light' as="input" type="button" value="Cancel"
                                    className="form_cancel_button" onClick={() => setOpenEditApp(o => !o)}/>
                        </Col>
                        <Col sm="auto" className="me-sm-2 p-0">
                            <Button as="input" className="form_submit_button" type="submit" value="Update"
                                    disabled={loading}/>
                        </Col>
                    </Row>
                </Stack>
            </Form>
        </Container>       
  </div>
</Fade>
       
    )

}

export default EditMyApp;
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { MdDashboardCustomize } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { brandingFavicon, brandingLogo, getOrganisationName, getbrandingFavicon, getbrandingLogo, OrganisationName } from "../../api/branding";
import { changeTitle } from "../../state/slices/header";
import { showErrorMsg, showSuccess } from "../../utils/showMessage";
import "./logo.scss";
import useWindowDimensions from "../../utils/getHeightWidth";
import Slide from "@mui/material/Slide";
import { Fade } from "@mui/material";
import { delayTime,slideDirection,fadedelayTime } from "../../utils/transitionEffectParams";
import InfoIcon from "./icons/icon-info.svg";
import { DarkTooltip } from "../common/CustomTooltip/CustomTooltip";
import SaveButton from "../common/SaveButton/SaveButton";
export default function Logo() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitle("Customizations"));
  }, []);
  useEffect(() => {
    getCustomUIs();
    getCustomUIfavs();
    getOrganisation()
  }, []);
  const { width,height } = useWindowDimensions();
  let datatable_height = (height-180) + 'px'
  const [CustomUIs, setCustomUIs] = useState([]);
  const [CustomUIfavs, setCustomUIfavs] = useState([]);
  const [previewLogoImage, setPreviewLogoImage] = useState(null);
  const [previewFaviconImage, setPreviewFaviconImage] = useState(null);
  const [logofileName, setLogofileName] = useState(null);
  const [faviconfileName, setFaviconfileName] = useState(null);
  const [detailShow, setDetailShow] = useState(null);
  const [save, setSave] = useState(true);
  const [organisation, setOrganisation] = useState(null);
  const pam_domain = window.location.hostname.substring(window.location.hostname.indexOf('.') + 1)
  const navigate = useNavigate();
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const title_heading = useSelector((state) => state.header.title);
  const [slideChecked, setSlideChecked] = useState(false);
useEffect(() => {
  setSlideChecked(true);
}, []);
  const getCustomUIs = async () => {
    const response = await getbrandingLogo();
    setCustomUIs([response.data]);
  };

  const getCustomUIfavs = async () => {
    const response = await getbrandingFavicon();
    setCustomUIfavs([response.data]);
  };

  const getOrganisation = async () => {
    const response = await getOrganisationName();
    setOrganisation(response.data);
  };

  const [logo, setLogo] = useState(null);
  const [name, setName] = useState("");
  const [logo1, setLogo1] = useState(null);
  const [name1, setName1] = useState("");

  const UpdateLogoInfo = async () => {
    let formFieldLogo = new FormData();
    let formFieldFavicon = new FormData();
    let formFieldOrganisationName = new FormData();
    let result = false;
    let message = "";
    var organisation_regex = /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,}$/
    if(organisation !== null) {
      if (organisation_regex.test(String(organisation).toLowerCase()) === true) {
        formFieldOrganisationName.append("domain", `${organisation}`);
        const { data, error } = await OrganisationName(formFieldOrganisationName);
      if (data != null) {
        result = true;
        message = data.message;
      }
      if (error != null) {
        showErrorMsg(error);
      }
      } else {
        showErrorMsg("Enter a valid domain");
      }
      
    }
    if (logo !== null) {
      formFieldLogo.append("name", "logo");
      formFieldLogo.append("logo", logo);
      const { data, error } = await brandingLogo(formFieldLogo);
      if (data != null) {
        result = true;
        message = data.message;
      }
      if (error != null) {
        showErrorMsg(error);
      }
    }
    if (logo1 !== null) {
      formFieldFavicon.append("name", "favicon");
      formFieldFavicon.append("logo", logo1);
      const { data, error } = await brandingFavicon(formFieldFavicon);
      if (data != null) {
        result = true;
        message = data.message;
      }
      if (error != null) {
        showErrorMsg(error);
      }
    }
    if (result === true) {
      showSuccess(message);
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    }
    else{
      showErrorMsg("No file choosen")
    }
  };

  const LogoInputChange = (e) => {
    setPreviewLogoImage(null);
    const files = Array.from(e.target.files);
    if (
      (files[0].name.split(".").pop().toLowerCase() != "png") &
      (files[0].name.split(".").pop().toLowerCase() != "jpeg") &
      (files[0].name.split(".").pop().toLowerCase() != "ico") &
      (files[0].name.split(".").pop().toLowerCase() != "gif") &
      (files[0].name.split(".").pop().toLowerCase() != "jpg")
    ) {
      showErrorMsg("Selected file is not a png,jpeg,ico,gif,ico");
      setSave(false);
    } else {
      setSave(true);
    }
    setLogofileName(files[0].name);
    if(files[0].size < 2e+6){
      setLogo(files[0]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreviewLogoImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    });
    }
    else{
      showErrorMsg('File size must be less than 2mb');
    }
    
  };

  const FaviconInputChange = (e) => {
    setPreviewFaviconImage(null);
    const files = Array.from(e.target.files);
    if (
      (files[0].name.split(".").pop().toLowerCase() != "png") &
      (files[0].name.split(".").pop().toLowerCase() != "jpeg") &
      (files[0].name.split(".").pop().toLowerCase() != "ico") &
      (files[0].name.split(".").pop().toLowerCase() != "gif") &
      (files[0].name.split(".").pop().toLowerCase() != "jpg")
    ) {
      showErrorMsg("Selected file is not a png,jpeg,ico,gif,ico");
      setSave(false);
    } else {
      setSave(true);
    }
    setFaviconfileName(files[0].name);
    if(files[0].size < 2e+6){
      setLogo1(files[0]);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            setPreviewFaviconImage(reader.result);
          }
        };
        reader.readAsDataURL(file);
      });
    }
    else{
      showErrorMsg('File size must be less than 2mb');
    }
    
  };

  return (
    <>
    
    {(() => {
            switch (title_heading) {
              case "Customizations":
                return (
                 <div className="heading_datable bg-white">
                   <span
                    style={{ color: "#50514F" }}
                    className="ff-poppins fs-24px fw-600"
                  >
                    <div className="d-flex align-items-center">
                      <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                        <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("./icons/logo.svg").default}
                          alt="users round icon"
                        /> &nbsp; Customization
                        </span>
                        <br />
                      </div>
                    </div>
                  </span>
                  </div>
                );
            }
          })()}
          <Slide  timeout={delayTime} className="" direction={slideDirection} in={slideChecked}><div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className='main_content_container p-3 mx-auto w-100'>
     <div className="position-relative bg-white overflow-auto" style={{height:`${datatable_height}`}}>
     <div className="heading_title rdt_TableHeader p-0">
      <div className="d-flex flex-row justify-content-between align-items-center py-3">
        
            <div className="abc position-sticky top-0 d-flex align-items-center">
              <img src={require("./icons/logo.svg").default}></img>
              <h2 className='main-heading ps-3'>Login and Registration Branding</h2>
            </div>
            {save === true && (
          <SaveButton onClick={UpdateLogoInfo} id="save" variant="outlined">
            Save
          </SaveButton>
        )}
        {save === false && (
          <SaveButton
            disabled="true"
            onClick={UpdateLogoInfo}
            id="save"
            variant="outlined"
          >
            Save
          </SaveButton>
        )}
          </div>
      </div>


     <div className="px-5 pt-3">
     <div className="d-none">
     <p id="p3">Custom JS URL</p>
      <p id="p5">https://pam.com/miniorange</p>
     </div>
      <p id="p3">Organization Name <DarkTooltip title="The organization name can contain only alphabets, numbers and - character">
           <img
            src={InfoIcon}
            alt="info"
            width="15"
            height="15"
            className="cursor_pointer"/>
        </DarkTooltip>
      </p>
     
      <div className="d-flex"><input type="text" className="logo-input" name='organisation_name' value={organisation} onChange={(e) => setOrganisation(e.target.value)}id="organisationinput" className="mb-4 no-outline"></input><p className="mt-3 mx-3 text-danger ff-pam-dm-sans">( NOTE: All your custom urls will be based on this name )</p></div>
      <p id="p4">
        <i id="note">
          <b>Note : </b> For custom logos, we recommend one that:{" "}
          {detailShow === null && (
            <p
              id="logoa"
              onClick={(e) => {
                setDetailShow("True");
              }}
            >
              Details
            </p>
          )}
          {detailShow === "True" && (
            <p
              id="logoa1"
              onClick={(e) => {
                setDetailShow(null);
              }}
            >
              Details
            </p>
          )}
          {detailShow === "True" && (
            <ul id="logoul">
              <li>Is in .png format with a transparent background.</li>
              <li>Is landscape-oriented, with a maximum file size of 1MB.</li>
              <li>
                Your logo will appear on both the navigation header and login
                banner.
              </li>
            </ul>
          )}
        </i>
      </p>

      <Row>
        <Col>
          <p id="p3">Upload your Logo</p>
          <div class="file-drop-area">
            <div className="just" id="logoInput">
              {previewLogoImage === null && (
                <p id="icon3">
                  {" "}
                  <img
                    id="icon3"
                    src={require("./icons/upload.svg").default}
                  ></img>
                </p>
              )}
              <p className="choose-file-button">
                {previewLogoImage && (
                  <img
                    id="afterlogo"
                    src={require("./icons/upload.svg").default}
                  ></img>
                )}
                Drag & drop files or<span id="link1">&nbsp;Browse</span>
              </p>
              <p class="file-message">
                Supported formates: JPEG, PNG, GIF, ico
              </p>
              {previewLogoImage && <p id="p7" className="text-truncate">{logofileName}{previewLogoImage && (
            <img
              className="logoClear"
              onClick={(e) => {
                setPreviewLogoImage(null);
                setLogo(null);
                setLogofileName(null);
              }}
              src={require("./icons/Delete.svg").default}
            ></img>
          )}</p>}
              <input
                id="myInput"
                className="file-input logo-input"
                type="file"
                onChange={LogoInputChange}
              />
              <input
                type="hidden"
                name="name"
                className="logo-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          
        </Col>

        <Col>
          <div id="logocol1">
            <p id="p3">Preview / Default Logo</p>
            <div id="col2">
              {previewLogoImage === null &&
                CustomUIs.map((product, index) => (
                  <p id="p6">
                    <img
                      id="output"
                      style={{ width: "30%", height: "33%" }}
                      src={serverURL.replace('/apps','') + product.logo}
                      alt="login form"
                    />
                  </p>
                ))}
              {previewLogoImage && (
                <p id="p6">
                  <img
                    id="output"
                    style={{ width: "30%", height: "33%" }}
                    src={previewLogoImage}
                    alt="login form"
                  />
                </p>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <p id="p3" className="mt-3">
            Upload Your Favicon
          </p>
          <div class="file-drop-area1">
            <div className="just">
              {previewFaviconImage === null && (
                <p id="icon31">
                  {" "}
                  <img
                    id="icon3"
                    src={require("./icons/upload.svg").default}
                  ></img>
                </p>
              )}
              <p className="choose-file-button">
                {previewFaviconImage && (
                  <img
                    id="afterlogo1"
                    src={require("./icons/upload.svg").default}
                  ></img>
                )}
                Drag & drop files or<span id="link1">&nbsp;Browse</span>
              </p>

              <p class="file-message">
                Supported formates: JPEG, PNG, GIF, ico
              </p>
              {previewFaviconImage && <p id="p8" className="text-truncate"><div className="pe-3">{faviconfileName}</div><div>{previewFaviconImage && (
            <img
              className="logoClear"
              onClick={(e) => {
                setPreviewFaviconImage(null);
                setLogo1(null);
                setFaviconfileName(null);
              }}
              src={require("./icons/Delete.svg").default}
            ></img>
          )}</div></p>}
              <input
                className="file-input1 logo-input"
                type="file"
                onChange={FaviconInputChange}
              />
              <input
                type="hidden"
                name="name"
                className="logo-input"
                value={name1}
                onChange={(e) => setName1(e.target.value)}
              />
            </div>
          </div>
          
        </Col>

        <Col>
          <div id="logocol2">
            <p id="p3" className="mt-3">
              Preview / Default Favicon
            </p>
            <div id="col3">
              {previewFaviconImage === null &&
                CustomUIfavs.map((product, index) => (
                  <p id="p6">
                    <img
                      id="output"
                      style={{ width: "9%", height: "40%" }}
                      src={serverURL.replace('/apps','') + product.logo}
                      alt="login form"
                    />
                  </p>
                ))}
              {previewFaviconImage && (
                <p id="p6">
                  <img
                    id="output"
                    style={{ width: "9%", height: "40%" }}
                    src={previewFaviconImage}
                    alt="login form"
                  />
                </p>
              )}
            </div>
          </div>
        </Col>
      </Row>
     </div>
     </div>
    </div>       
  </div>
</Fade>
          
            </div>
            </Slide>
    
    </>
  );
}

import { GetAdvanceData } from "../../../api/report";
import { GetAdvanceDataUser } from "../../../api/report";
import { Col, Form, Row, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import AdvanceFilter from "../../dashboard/Reports/AdvanceFilter";
import {BiCalendar} from "react-icons/bi"
import Calendar from "../../../images/calendar.svg"
const AdvanceSearchFilter = ({
  filter_set_data,
  page,
  perPage,
  status,
  setStatus,
  from,
  setFrom,
  to,
  setTo,
  path,
  for_userlogin,
  userIdentifier,
  setUserIdentifier,
}) => {
  const [openAddApp, setOpenAddApp] = useState(false);
  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();
  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
  const AddButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 10px",
    lineHeight: "21px",
    background:'white',
    fontFamily: ['"DM Sans"'].join(","),
    variant: "secondary",
    borderRadius:'5px',
    border:'1px solid #cbcbcb',
  });
  const handleClear = () => {
    setFrom(currentDate);
    setTo(currentDate);
  };
  const handleSubmit = async (event) => {
    if(from !== "" && to !== ""){
      event.preventDefault();
      if (for_userlogin) {
        handleSubmitUser();
      } else {
        let from_date = from;
        let to_date = to;
        from_date += "T00:00:00.000000Z";
        to_date += "T23:59:59.000000Z";
        page = page - 1;
        const { data } = await GetAdvanceData(
          page,
          perPage,
          status,
          from_date,
          to_date,
          path
        );
        if (data != null) {
          filter_set_data(data.data, data.count);
        }
      }
    }else{
      event.preventDefault();
      toast.error("Empty Date Values");
    }
  };
  const handleSubmitUser = async () => {
    let from_date = from;
    let to_date = to;
    from_date += "T00:00:00.000000Z";
    to_date += "T23:59:59.000000Z";
    page = page - 1;
    const { data } = await GetAdvanceDataUser(
      userIdentifier,
      from_date,
      to_date,
      page,
      5,
      path
    );
    if (data != null) {
      filter_set_data(data.data, data.count);
    }
  };
  const disableFutureDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
}
  return (

    <>
    <CustomModal padding="0px !important" open={openAddApp} handleClose={()=>{setOpenAddApp(o=>!o);}}>
        <AdvanceFilter setOpenAddApp={setOpenAddApp} setFrom={setFrom} setTo={setTo} />
      </CustomModal>
    <Form
      className="mt-3 advance-search my-3 ms-0 ps-0"
      onSubmit={handleSubmit}
    >
      <Row className="mb-1 my-1 ps-3 w-100">
        <Form.Label className="d-flex flex-row justify-content-start py-2 ms-1 fw-bolder advance-search-label ">
          Advance Filter
        </Form.Label>
      </Row>
      <Row className="mb-1 ps-3 w-100">
        {for_userlogin && (
          <Form.Group
            as={Col}
            lg={6}
            md={12}
            className="mb-1 advance-filter-input"
            controlId="formAppName"
          >
            <Form.Label className="d-flex flex-row justify-content-start align-items-center">
              EndUser Email
            </Form.Label>
            <Form.Control
              type="text"
              className="advance_search_input react-tel-input p-2 w-100 bg-white"
              placeholder="admin@miniorange.com"
              onChange={(e) => setUserIdentifier(e.target.value)}
              required

            />
          </Form.Group>
        )}
        {!for_userlogin && (
          <Form.Group
            as={Col}
            lg={6}
            md={12}
            className="mb-1"
            controlId="formAppName"
          >
            <Form.Label className="d-flex flex-row justify-content-start align-items-center">
              Status
            </Form.Label>
            <Form.Control
              type="text"
              className="advance_search_input w-100"
              placeholder="Success"
              onChange={(e) => setStatus(e.target.value)}
            />
          </Form.Group>
        )}

        <Form.Group
          as={Col}
          lg={6}
          md={12}
          className="mb-1"
          controlId="formFromDate"
        >
          <Form.Label className="d-flex flex-row justify-content-start align-items-center">
            Date
          </Form.Label>
          <AddButton onClick={() => {
          setOpenAddApp((o) => !o);
        }}
        variant="primary"
      >
        <span className="letterspacing">{from}</span> <span className="px-5" style={{color:'#3D3D3D'}}>To</span> <span className="letterspacing">{to}</span><img
              src={Calendar}
              className="ps-5"
            />
      </AddButton>
        </Form.Group>
      </Row>
      <Row className="mb-3 mt-3 ps-3 w-100 text-justify d-flex">
        <span>
          <Button type="submit" className="advance-filter-submit">
            Search
          </Button>

          <Button
            onClick={() => handleClear()}
            type="reset"
            className="advance-filter-clear"
          >
            Clear
          </Button>
        </span>
      </Row>
    </Form>
    </>
  );
};
export default AdvanceSearchFilter;

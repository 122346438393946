import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import { useDispatch } from "react-redux";
import "./ConfigureSMTP.scss";
import emailImage from "../../../images/email_config.svg";
import {
  addSMTP,
  getSMTPDetail,
  testSMTPServer,
} from "../../../api/smtp_provider";
import { showError, showSuccess } from "../../../utils/showMessage";
import { changeTitle } from "../../../state/slices/header";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import { Image } from "react-bootstrap";
import InfoIcon from "./icons/info.svg";
import { DarkTooltip } from "../../common/CustomTooltip/CustomTooltip";
import { Slide,Fade } from '@mui/material';
import { delayTime,fadedelayTime, slideDirection } from '../../../utils/transitionEffectParams';

const ConfigureSMTP = () => {
  const encryption_options = [
    { label: "NONE", value: "NONE" },
    { label: "SSL", value: "SSL" },
    { label: "TLS", value: "TLS" },
  ];
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 180 + "px";
  const [loading, setLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [hostName, setHostName] = useState("");
  const [port, setPort] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [enableProvider, setEnableProvider] = useState(false);
  const [use_ssl, setUseSSL] = useState(false);
  const [use_tls, setUseTLS] = useState(false);
  const [testEmail, setTestEmail] = useState("");
  const [checkOption, setCheckOption] = useState(encryption_options[0]);
  const [slideChecked, setSlideChecked] = useState(false);

    useEffect(() => {
        setSlideChecked(true);
    }, []);
  const title_heading = useSelector((state) => state.header.title);
  const SaveButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    color: "#5177FF",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const fetchData = async () => {
    const { data, error } = await getSMTPDetail();
    if (data !== null) {
      setHostName(data.email_configurations.host);
      setPort(data.email_configurations.port);
      setUserName(data.email_configurations.username);
      setPassword(data.email_configurations.password);
      setSenderEmail(data.email_configurations.from_email);
      setEnableProvider(data.email_configurations.email_provider_enabled);
      if (data.email_configurations.use_tls === true) {
        setUseTLS(true);
        setCheckOption("TLS");
      } else if (data.email_configurations.use_ssl) {
        setUseSSL(true);
        setCheckOption("SSL");
      } else {
        setUseSSL(false);
        setUseTLS(false);
        setCheckOption("NONE");
      }
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitle("Customizations"));
  }, []);
  useEffect(() => {
    fetchData();
  }, []);
  const addSMTPFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setLoading(true);
      let post_data;
      if (enableProvider === false) {
        post_data = {
          email_provider_enabled: enableProvider,
        };
      } else {
        post_data = {
          ...(hostName !== "" ? { host: hostName } : { host: null }),
          ...(port !== "" ? { port: port } : { port: 587 }),
          ...(username !== "" ? { username: username } : { username: null }),
          ...(password !== "" ? { password: password } : { password: null }),
          ...(senderEmail !== ""
            ? { from_email: senderEmail }
            : { from_email: null }),
          ...{ email_provider_enabled: enableProvider },
          ...{ use_ssl: use_ssl },
          ...{ use_tls: use_tls },
        };
      }
      const { data, error } = await addSMTP(post_data);
      if (data !== null) {
        showSuccess(data.message);
        setLoading(false);
      }
      if (error !== null) {
        showError(error);
        setLoading(false);
      }
    }
  };
  const handleSelectChange = (event) => {
    setCheckOption(event.target.value);
    if (event.target.value === "SSL") {
      setUseSSL(true);
      setUseTLS(false);
    } else if (event.target.value === "TLS") {
      setUseSSL(false);
      setUseTLS(true);
    } else {
      setUseSSL(false);
      setUseTLS(false);
    }
  };
  const testSMTPFormHandler = async () => {
    // create a request to test the SMTP server
    setTestLoading(true);
    let post_data = {
      ...(hostName !== "" ? { host: hostName } : { host: null }),
      ...(port !== "" ? { port: port } : { port: 587 }),
      ...(username !== "" ? { username: username } : { username: null }),
      ...(password !== "" ? { password: password } : { password: null }),
      ...(senderEmail !== ""
        ? { from_email: senderEmail }
        : { from_email: null }),
      ...(testEmail !== "" ? { test_email: testEmail } : { test_email: null }),
      ...{ use_ssl: use_ssl },
      ...{ use_tls: use_tls },
    };
    const { data, error } = await testSMTPServer(post_data);
    if (data !== null) {
      showSuccess(data.message);
      setTestLoading(false);
    }
    if (error !== null) {
      showError(error);
      setTestLoading(false);
    }
  };
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Customizations":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img src={emailImage} alt="users round icon" /> &nbsp;
                        Customization
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide  timeout={delayTime}  direction={slideDirection} in={slideChecked}>
        <div>
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
        <div
          className="position-relative bg-white overflow-auto"
          style={{ height: `${datatable_height}` }}
        >
          <div className="configure_mfa_container_main bg-white">
            <div className="configure_mfa_heading p-3 d-flex flex-row justify-content-between align-items-center">
              <div>
                <img
                  src={emailImage}
                  alt="EGC"
                  className="smtp_heading_image"
                />
                <h2 className="main-heading d-inline ms-2">
                  Email Gateway Configuration
                </h2>
              </div>

              <SaveButton onClick={addSMTPFormHandler} className="me-5" type="submit" variant="outlined">
                Save
              </SaveButton>
            </div>
            <div className="configure_smtp_body">
              <div className="col-md-12 alert mt-3 alert_box" role="alert">
                <div className="warning-img">
                  <i className="fa fa-exclamation-triangle"></i>
                </div>
                <div className="col-md-11">
                  <b>
                    <u>
                      <i>NOTE:</i>
                    </u>
                  </b>{" "}
                  If your SMTP/Email servers are behind a firewall, make sure to
                  open the right ports and allow inbound connections from our IP
                  Addresses. Contact us to get the information.
                  <br />
                  <br />
                  Please use the 'Test Configuration' button to test the
                  connection before saving your settings.
                  <br />
                  <br />
                  <div className="d-flex justify-content-start">
                    <span className="h6 text-right pull-left">
                      <em>Enable your Email Provider </em>
                    </span>
                    <span className="col-md-3 mx-2">
                      <Form.Check
                        checked={enableProvider}
                        type="switch"
                        id="custom-switch"
                        onChange={() => setEnableProvider((e) => !e)}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-12 py-2 pb-3">
                <span className="ff-poppins p-2 fs-18px fw-600 text-bold">
                  Primary Email Server
                </span>
              </div>
              <Container fluid className=" mx-auto add_smtp_container">
                <Form
                  noValidate
                  validated={validated}
                  className="add_systemuser_form"
                >
                  <Stack gap={1}>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-between"
                      controlId="formPlaintextName"
                    >
                      <Form.Label column md={6} className="input_label">
                        Host Name<span className="text-danger">*</span>
                        <DarkTooltip
                          id="setting-info"
                          title="Hostname or IP address of your SMTP server"
                        >
                          <img
                            src={InfoIcon}
                            alt="info"
                            width="15"
                            height="15"
                            className="mx-2 cursor_pointer"
                          />
                        </DarkTooltip>
                      </Form.Label>
                      <Form.Label column md={6} className="input_label">
                        Port Number{" "}
                        <DarkTooltip
                          id="setting-info"
                          title="Port used by your SMTP server. Common ports include 25, 465, and 587. Please avoid using port 25 if
                you can,
                since many providers have limitations on this port."
                        >
                          <img
                            src={InfoIcon}
                            alt="info"
                            width="15"
                            height="15"
                            className="mx-2 cursor_pointer"
                          />
                        </DarkTooltip>
                      </Form.Label>
                      <Col lg={6} md={6}>
                        <Form.Control
                          required={enableProvider}
                          disabled={!enableProvider}
                          className="form_input_field"
                          type="text"
                          value={hostName}
                          onChange={(event) => setHostName(event.target.value)}
                          placeholder="Host Name"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a host name
                        </Form.Control.Feedback>
                      </Col>

                      <Col lg={6} md={6}>
                        <Form.Control
                          disabled={!enableProvider}
                          className="form_input_field"
                          type="number"
                          value={port}
                          onChange={(event) => setPort(event.target.value)}
                          placeholder="587"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-between"
                      controlId="formPlaintextName"
                    >
                      <Form.Label column md={6} className="input_label">
                        Username
                      </Form.Label>
                      <Form.Label column md={6} className="input_label">
                        Password
                      </Form.Label>
                      <Col lg={6} md={6}>
                        <Form.Control
                          disabled={!enableProvider}
                          className="form_input_field"
                          type="text"
                          value={username}
                          onChange={(event) => setUserName(event.target.value)}
                          placeholder="SMTP Username"
                        />
                      </Col>

                      <Col lg={6} md={6}>
                        <Form.Control
                          disabled={!enableProvider}
                          className="form_input_field"
                          type="password"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          placeholder="SMTP Password"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-between"
                      controlId="formPlaintextName"
                    >
                      <Form.Label column md={6} className="input_label">
                        Sender Email
                      </Form.Label>
                      <Form.Label column md={6} className="input_label">
                        Encryption
                      </Form.Label>
                      <Col lg={6} md={6}>
                        <Form.Control
                          disabled={!enableProvider}
                          className="form_input_field"
                          type="email"
                          value={senderEmail}
                          onChange={(event) =>
                            setSenderEmail(event.target.value)
                          }
                          placeholder="Sender Email"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid email
                        </Form.Control.Feedback>
                      </Col>

                      <Col lg={6} md={6}>
                        <Form.Select
                          onChange={handleSelectChange}
                          value={checkOption}
                          className="encryption_type_select"
                          disabled={!enableProvider}
                        >
                          {encryption_options.map((object, index) => (
                            <option value={object.value} key={index}>
                              {object.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-start test_email_configuration_form"
                      controlId="formPlaintextName"
                    >
                      <Form.Label column md={6} className="input_label">
                        Email Address:
                      </Form.Label>
                      <Col></Col>
                      <Col lg={6} md={6}>
                        <Form.Control
                          disabled={!enableProvider}
                          className="form_input_field test_configuration_email"
                          type="email"
                          value={testEmail}
                          onChange={(event) => setTestEmail(event.target.value)}
                          placeholder="Enter email to send test mail"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid email
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-start test_email_configuration_form"
                      controlId="formPlaintextName"
                    >
                      
                      <Col>
                        <Button
                          as="input"
                        //   type="button"
                          value="Test Configuration"
                          className=" test_email_submit_button"
                          disabled={testLoading || !enableProvider}
                          onClick={testSMTPFormHandler}
                        />
                      </Col>
                    </Form.Group>

                    {/* <Row className="mb-3 justify-content-start">
                      <Col sm="auto" className="me-sm-2 p-0">
                        <Button
                          as="input"
                          type="submit"
                          value="Save"
                          className="form_submit_button"
                          disabled={loading}
                        />
                      </Col>
                    </Row> */}
                  </Stack>
                </Form>
              </Container>
            </div>
          </div>
        </div>
      </div>        
  </div>
</Fade>
      
      </div>
      </Slide>
    </>
  );
};

export default ConfigureSMTP;

import React, { useEffect, useState } from "react";
import { GetReportData } from "../../../api/report";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import { useDispatch } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import { Row } from "react-bootstrap";
import icon from "./icons/icon.svg";
import DataTable from "react-data-table-component";
import arrow_first from "./icons/arrow_first.svg";
import arrow_last from "./icons/arrow_last.svg";
import arrow_right from "./icons/arrow_right.svg";
import arrow_left from "./icons/arrow_left.svg";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import { useSelector } from "react-redux";
import { Slide,Fade } from "@mui/material";
import { delayTime, slideDirection, fadedelayTime } from "../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../utils/getHeightWidth";

const TicketsReport = () => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height-280  + "px";
  const title_heading = useSelector((state) => state.header.title);
  const path = "ticket-audits";
  /* customize column modal functionality starts. */
  const UserColumns = [
    { label: "Email", value: "email" },
    { label: "Action", value: "action" },
    { label: "IP", value: "ip" },
  ];
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  const pages_url = "dashboard/report_tickets/";
  const customizeColumnDict = { "User ID": true, Datetime: true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        name: "Ticket No.",
        selector: (row) => row.ticket_id,
        center:true,
        width:'120px'
      },
      {
        name: "User ID",
        selector: (row) => row.user_id,
        omit: !checkedStateDict["User ID"],
        center:true,
        width:'120px'
      },
      {
        name: "Email",
        selector: (row) => row.email,
        style: {},
      },
      {
        name: "Action",
        selector: (row) => row.action,
      },
      {
        name: "IP/Host",
        selector: (row) => row.ip,
      },
      {
        name: "Datetime",
        selector: (row) => (
          <div id="Onhover">
            {new Date(row.datetime).toDateString()},{" "}
            {new Date(row.datetime).toLocaleTimeString().toUpperCase()}
          </div>
        ),
        style: {},
        omit: !checkedStateDict["Datetime"],
      },
    ],
    [checkedStateDict]
  );

  /* customize column logic ends. */
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(
    localStorage.getItem("rowsPerPage_TicketsReport") !== null
      ? localStorage.getItem("rowsPerPage_TicketsReport")
      : 5
  );
  const [app, setApp] = useState([]);
  const [totalApp, setTotalApp] = useState(0);
  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async (page, perPage, search, filterBy) => {
    setLoading(true);
    page = page - 1;
    const { data } = await GetReportData(page, perPage, search, filterBy, path);
    if (data !== null) {
      setApp(data.data || []);
      setTotalApp(data.count);
      setFilteredData(data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    fetchData(1, perPage, search, filterBy);
  }, [perPage]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitle("Reports"));
  }, []);

  const handlePageChange = (page) => {
    fetchData(page, perPage, search, filterBy);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    page = page - 1;
    const { data } = await GetReportData(
      page,
      newPerPage,
      search,
      filterBy,
      path
    );
    if (data !== null) {
      setApp(data.data || []);
      localStorage.setItem("rowsPerPage_TicketsReport", newPerPage);
      setPerPage(newPerPage);
      setTotalApp(data.count);
      setFilteredData(data.data);
      setLoading(false);
      setLoading(true);
    }
  };

  //for searching in frontend.
  useEffect(() => {
    const result = app.filter((object) => {
      let searchs = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
      if (filterBy === "") {
        if (search == "") {
          fetchData(1, perPage, search, filterBy);
        } else {
          return (
            object.ip.toLowerCase().match(searchs.toLowerCase()) ||
            object.email.toLowerCase().match(searchs.toLowerCase()) ||
            object.datetime.toLowerCase().match(searchs.toLowerCase()) ||
            object.action.toLowerCase().match(searchs.toLowerCase())
          );
        }
      } else {
        if (search == "") {
          fetchData(1, perPage, search, filterBy);
        } else {
          return object[filterBy.toLowerCase()].match(searchs.toLowerCase());
        }
      }
    });

    setFilteredData(result);
  }, [search]);

  const handleSearch = async (event) => {
    event.preventDefault();
    fetchData(1, perPage, search, filterBy);
  };

  return (
    <>
    {(() => {
        switch (title_heading) {
          case "Reports":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("./icons/icon.svg").default}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Reports
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide  timeout={delayTime} className="" direction={slideDirection} in={slideChecked}><div>
      <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
      <div className="main_content_container p-3 mx-auto w-100">
        <div
          className="position-relative bg-white"
          style={{ height: `${datatable_height}` }}
        >
          <DataTable
            title={
              <ListHeading
                dataTableHeadingText="Tickets Report"
                dataTableHeadingIcon={icon}
              />
            }
            columns={columns}
            data={filteredData}
            className="rdt_container"
            fixedHeader
            paginationPerPage={perPage}
            progressPending={loading}
            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
            pagination
            paginationServer
            paginationTotalRows={totalApp}
            highlightOnHover
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={
              <SubHeaderComponent
                placeholder="a ticket"
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                filterOptions={UserColumns}
                search={search}
                setSearch={setSearch}
                handleSearch={handleSearch}
                pages_url={pages_url}
                checkedStateDict={checkedStateDict}
                setCheckedStateDict={setCheckedStateDict}
              />
            }
            subHeaderAlign="center"
            paginationIconFirstPage={<img src={arrow_first} alt="first page" />}
            paginationIconLastPage={<img src={arrow_last} alt="last page" />}
            paginationIconNext={<img src={arrow_right} alt="next" />}
            paginationIconPrevious={<img src={arrow_left} alt="previous" />}
          />
          </div>
      </div>
      </div>
</Fade>

      
    </div></Slide>
    </>
  );
};

export default TicketsReport;

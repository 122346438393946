import Plot from "react-plotly.js";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { CommandSuccessChart } from "./Charts/CommandSuccessChart.js";
import { LoginSuccessChart } from "./Charts/LoginSuccessChart.js";
import { MostUsedResources } from "./Charts/MostUsedResources.js";
import useWindowDimensions from "../../../utils/getHeightWidth.js";
import { Slide,Fade } from "@mui/material";
import { delayTime,fadedelayTime } from "../../../utils/transitionEffectParams";
import "reactjs-popup/dist/index.css";
import "./Dashboard.scss";
import "./plot.scss";
import InfoIcon from "./icons/icon-info.svg";
import { DarkTooltip } from "../../common/CustomTooltip/CustomTooltip";

export const DashboardBarPlot = (props) => {
  const { height, width } = useWindowDimensions();
  const container_height = height -100;
  const { graph,Assetgraph } = props;
  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    setSlideChecked(true);
  }, []);

  var trace1 = {
    x: Object.keys(graph),
    y: Object.values(graph),
    name: "Apps",
    type: "bar",
    marker: {
      color: "#5177FF", 
    },
  };

  var trace2 = {
    x: Object.keys(graph),
    y: Object.values(Assetgraph),
    name: "Assets",
    type: "bar",
    marker: {
      color: "rgba(13, 109, 253, 0.285)",
    },
  };

  var data = [trace1, trace2];

  var layout = {
    autosize: true,
    barmode: "group",
    yaxis: {
      title: {
        text: "Access Count",
        font: {
          family: "poppins",
          size: 16,
          color: "#7f7f7f",
        },
      },
    },
    xaxis: {
      title: {
        text: "Date",
        font: {
          family: "poppins",
          size: 16,
          color: "#7f7f7f",
        },
      },
    },
  };

  return (
    <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div
      className="main_content_container ps-3 pe-0"
      style={{ height: container_height + "px" }}
    >
      <Row className="h-100">
        <Col xl={9} lg={8} md={12} sm={12} className="h-100">
          <Slide
            timeout={delayTime}
            className="h-100 p-0"
            direction={"right"}
            in={slideChecked}
          >
            <div className="p-0 m-0 h-100">
              <div className="pt-4 h-60 bg-white ">
                <div className={"ff-poppins fw-600 ps-5 "+ (height <= 786 ? " fs-14px":" fs-18px")}>
                  Apps-Assets Overview
                  <DarkTooltip
                id="setting-info"
                title="Number of apps and assets accessed on daily basis"
              >
                <img
                  src={InfoIcon}
                  alt="info"
                  width="15"
                  height="15"
                  className="mx-2 cursor_pointer"
                />
              </DarkTooltip>
                </div>
                <div className="h-100 w-100 px-3">
                <Plot className="h-90 w-100" data={data} layout={layout} />
                </div>
              </div>
              <div className="h-40 ">
                <div className="pt-3 h-100 ">
                  <MostUsedResources />
                </div>
              </div>
            </div>
          </Slide>
        </Col>
        <Col xl={3} lg={4} md={12} sm={12} className="ps-3 pe-0 pt-3 h-100">
          <Slide
            timeout={delayTime}
            className="h-100 p-0"
            direction={"left"}
            in={slideChecked}
          >
            <div className="p-0 m-0 h-100">
              <div className="h-50 d-flex align-items-center justify-content-center">
                <div className="w-90 h-90 bg-white border shadow-card rounded">
                  <CommandSuccessChart />
                </div>
              </div>
              <div className="h-50 d-flex  align-items-center justify-content-center">
                <div className="w-90 h-90 bg-white border shadow-card rounded">
                  <LoginSuccessChart />
                </div>
              </div>
            </div>
          </Slide>
        </Col>
      </Row>
    </div>        
  </div>
</Fade>
    
  );
};

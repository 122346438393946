import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { updateUserPassword } from "../../../../api/users";
import { showError, showSuccess } from "../../../../utils/showMessage";
import CrossIcon from "../../../../images/cross.svg";
import LightTooltip from "../../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "../icons/icon-info.svg";

const UpdatePassword = ({ setOpenUpdatePassword, setUsers, data }) => {
  const [id, setId] = useState("");
  const [username, setUserName] = useState("");
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setId(data.id);
    setUserName(data.username);
    setPassword(data.password);
  }, []);

  const updateUserPasswordFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      const { data, error } = await updateUserPassword({
        id: id,
        ...(password !== "" ? { password: password } : { password: null }),
      });
      if (data !== null) {
        showSuccess(data.message);
        setUsers(new Object());
        setLoading(false);
        setOpenUpdatePassword((o) => !o);
      }
      if (error !== null) {
        showError(error);
        setLoading(false);
      }
    }
  };

  return (
    <Container fluid className="update_password_container mx-auto">
      <div className="d-flex justify-content-between align-items-center  mb-4">
        <h2 className="main_content_heading">
          Change Password for : {username}
        </h2>
        <img
          src={CrossIcon}
          alt="close"
          className="cursor_pointer"
          onClick={() => setOpenUpdatePassword((o) => !o)}
        />
      </div>
      <Form
        noValidate
        validated={validated}
        onSubmit={updateUserPasswordFormHandler}
      >
        <Stack gap={1}>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formPlainPassword"
          >
            <Form.Label column md={2} className="input_lable">
              Password
            </Form.Label>
            <Col md={9}>
              <Form.Control
                type="password"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()<>])[A-Za-z\d@$!%*?&#^()<>]{8,20}$"
                required
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                placeholder="Enter your Password"
                className="user_input_field"
                autoComplete="new-password"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid password
                <LightTooltip
                  title="Invalid password!
                Min length:8 Special Char: 1 Lower Case: 1 Upper Case:1 Number:1.  
                Allowed characters are a-z, A-Z, 0-9, @$!%*?&#^()<>"
                >
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formconfirmPassword"
          >
            <Form.Label column md={3} className="input_lable">
              Confirm Password
            </Form.Label>
            <Col md={9}>
              <Form.Control
                type="password"
                required
                pattern={password}
                onChange={(event) => setConfirmPassword(event.target.value)}
                value={confirmPassword}
                placeholder="Confirm Password"
                className="user_input_field"
              />
              <Form.Control.Feedback type="invalid">
                Password does not match.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Row className="mb-3 justify-content-end">
            <Col sm="auto">
              <Button
                variant="light"
                as="input"
                className="user_cancel_button"
                type="button"
                value="Cancel"
                onClick={() => setOpenUpdatePassword((o) => !o)}
              />
            </Col>
            <Col sm="auto" className="me-sm-2 p-0">
              <Button
                as="input"
                className="user_submit_button"
                type="submit"
                value="Submit"
                disabled={loading}
              />
            </Col>
          </Row>
        </Stack>
      </Form>
    </Container>
  );
};

export default UpdatePassword;

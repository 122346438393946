import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { listRole } from "../../../../api/role";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import CrossIcon from "../../../../images/cross.svg";
import { showError, showSuccess } from "../../../../utils/showMessage";
import LightTooltip from "../../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "../icons/icon-info.svg";
import {Fade} from "@mui/material";
import { fadedelayTime } from "../../../../utils/transitionEffectParams";
import { RoleNameReg, DisplayNameReg } from "../../../common/regex/Regex";

const AddRole = ({ setOpenAddRole, setRoles, setOpenCapabilitiesDialog, displayName, setDisplayName, roleName, setRoleName, setCapability, capability }) => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [role, setRole]= useState(null);

  useEffect(() => {getRolesData()}, []);

  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const getRolesData= async () =>{
      const { data, error } = await listRole({
        page: 1,
        page_size: 100,
        search: "",
        filter_by: "",
      });
      if (data != null) {
        setRole(data.results);
        setCapability(data.results[0]["capabilities"]);
      }
      if (error !== null) {
        showError(error);
      }
    };

  const gotoCapabilities = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setOpenAddRole(false);
      setOpenCapabilitiesDialog(true);
    }
  };


  const getCapability = async (event) => {
    role && role.map((roleList) =>
                        roleList.display_name === event && setCapability(roleList.capabilities)
                        )
  }

  return (
      <Fade timeout={fadedelayTime} in={slideChecked}>
      <div>
        <Container fluid className=" mx-auto add_user_container main_content_container">
          <div className="d-flex justify-content-between align-items-center  mb-4 ">
            <h2 className="main_content_heading">Add Role</h2>
            <img
              src={CrossIcon}
              alt="close"
              className="cursor_pointer"
              onClick={() => {
                setOpenAddRole((o) => !o);
                setDisplayName("");
                setRoleName("");
                }
              }
            />
          </div>
          <Form
            noValidate
            validated={validated}
            onSubmit={gotoCapabilities}
            className="add_user_form"
            autoComplete="off"
          >
            <Stack gap={1}>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formRoleName"
              >
                <Form.Label column md={3} className="input_lable">
                  Role Name<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    required
                    className="user_input_field"
                    pattern={RoleNameReg}
                    type="text"
                    value={roleName}
                    onChange={(event) => setRoleName(event.target.value)}
                    placeholder="Enter Your Role Name"
                    autoComplete="new-username"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid Role Name.
                    <LightTooltip title=" Role Name can contain alphabets , underscore(_) , hyphen(-) and numbers but it should not start or end with  underscore(_) , hyphen(-). Minimum length = 4 .">
                      <img
                        src={InfoIcon}
                        alt="info"
                        width="20"
                        height="20"
                        className="mx-2 cursor_pointer"
                      />
                    </LightTooltip>
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formDisplayName"
              >
                <Form.Label column md={3} className="input_lable">
                  Display Name
                </Form.Label>

                <Col md={9}>
                  <Form.Control
                    type="text"
                    className="user_input_field"
                    value={displayName}
                    onChange={(event) => setDisplayName(event.target.value)}
                    placeholder="Enter Your Display Name"
                    pattern={DisplayNameReg}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid Display Name.
                    <LightTooltip title=" Minimum length = 4 .">
                      <img
                        src={InfoIcon}
                        alt="info"
                        width="20"
                        height="20"
                        className="mx-2 cursor_pointer"
                      />
                    </LightTooltip>
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3 justify-content-between">
                <Form.Label column md={3} className="input_lable">
                  Inherit capabilities from
                </Form.Label>

                <Col md={9}>
                  <Form.Select
                    className="ps-3 rounded-1 user_input_field"
                    onChange={(e) =>{getCapability((e.target.value).substring(0, (e.target.value).indexOf(' ')))}}
                  >
                  {role && role.map((roleList) =>
                      <option><div className="text-primary">{roleList.display_name} </div>{("is_admin_user" in roleList.capabilities)?" (additional admin)":" (enduser)"}</option>  )}
                  </Form.Select>

                </Col>
              </Form.Group>

              <Row className="mb-3 justify-content-end">
                <Col sm="auto">
                  <Button
                    variant="light"
                    as="input"
                    type="button"
                    className="user_cancel_button"
                    value="Cancel"
                    onClick={() => {
                      setOpenAddRole((o) => !o);
                      setDisplayName("");
                      setRoleName("");
                    }
                }
                  />
                </Col>
                <Col sm="auto" className="me-sm-2 p-0">
                  <Button
                    as="input"
                    type="submit"
                    value="Next"
                    className="user_submit_button"
                    disabled={loading}
                  />
                </Col>
              </Row>
            </Stack>
          </Form>
        </Container>
      </div>
      </Fade>
  );
};

export default AddRole;

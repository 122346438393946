import axios from "./axios_interceptor";
import { get_jwt_token, loginRedirect } from "./helper_funtions";

export const CreateTicketDetails = async (args) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/ticket/tickets/`;
    const response = await axios.post(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const DeleteTicketDetails = async (args) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: args,
    };
    const url = `/ticket/tickets/`;
    const response = await axios.delete(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const UpdateTicketDetails = async (args) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/ticket/tickets/`;
    const response = await axios.put(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getAdminNotifications = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let url = "";
    if (data.ticket_types) {
      url = `/ticket/raise-ticket/?${data.ticket_types}=${data.ticket_types}&page=${data.page}&limit=${data.limit}`;
    } else {
      url = `/ticket/raise-ticket/?page=${data.page}&limit=${data.limit}`;
    }
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getTicketDetails = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/ticket/tickets/?ticket_id=${data}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const updateTicketDetails = async (args) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/ticket/raise-ticket/`;
    const response = await axios.put(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getEndUserNotifications = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let url = "";
    if (data.ticket_types) {
      url = `/ticket/ticket-response/?${data.ticket_types}=${data.ticket_types}&page=${data.page}&limit=${data.limit}`;
    } else {
      url = `/ticket/ticket-response/?page=${data.page}&limit=${data.limit}`;
    }
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const updateTicketDetailsEndUser = async (args) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/ticket/ticket-response/`;
    const response = await axios.put(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const TicketsPaginatedList = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let url = `/ticket/tickets/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}&advance_filter_by=${data.advancefilterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};


export const CheckTicketDetails = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let url = `/ticket/check-ticket-details/?ticket_id=${data.id}&resource_type=${data.resource_type}&resource_id=${data.resource_id}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

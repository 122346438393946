import { Fade, Slide } from '@mui/material';
import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { assignedUsers, removeUsersFromGroup } from "../../../../api/groups";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import groupHeadingIcon from "../../../../images/group_icon.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import plus from "../../../../images/plus.svg";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { delayTime, fadedelayTime, slideDirection } from '../../../../utils/transitionEffectParams';
import CustomModal from "../../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import "../../../common/css/RDT_common.scss";
import Locked from '../../Users/icons/locked.svg';
import Unlocked from "../../Users/icons/unlocked.svg";

import { changeTitle } from "../../../../state/slices/header";
import { AiOutlineUserDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import GetCapabilities from '../../../../utils/getCapabilities';

const ActionComponent = ({ group_id }) => {
  const navigate = useNavigate();
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const AssignUser = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });

  return (
    <Stack direction="row" spacing={2} className="py-3">
      {isCapable && isCapable.groups.view && (<AssignUser
        onClick={() => {
          navigate(`/dashboard/groups/assign-users/${group_id}`);
        }}
        variant="outlined"
        startIcon={
          <img src={plus} alt="icon" id="center-blueShadow" />
        }
      >
        Assign Users
      </AssignUser>)}
    </Stack>
  );
};
const AssignedUsers = () => {
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const { group_id } = useParams();
  const [groupDisplayName, setGroupDisplayName] = useState("");
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_AssignedUser") !== null
      ? localStorage.getItem("rowsPerPage_AssignedUser")
      : 5
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [action, setAction] = useState("");
  const [changeState, setChangeState] = useState(null);
  const [assignedUserNames, setAssignedUserNames] = useState([]);
  const [openAssignedUser, setOpenAssignedUser] = useState(false);
  const [rowsToAllocate, setRowsToAllocate] = useState([]);
  const pages_url = "dashboard/AssignedUsers";
  const customizeColumnDict = {
    "User Id": true,
    "Locking": true,
    "Email": true,
    "Name": true,
    "Phone No.": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const title_heading = useSelector((state) => state.header.title);
  const userColumns = [
    { label: "User Id", value: "id" },
    { label: "Username", value: "username" },
    { label: "Email", value: "email" },
    { label: "Role", value: "role__display_name" },
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
  ];
  const actionOptions = [{ label: "Remove", value: "remove" }];
  const getUsers = async () => {
    const { data } = await assignedUsers({
      group_id: group_id,
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setGroupDisplayName(data.group_display_name);
      setUsers(data.users);
      setTotalUsers(data.totalUsers);
      setFilteredData(data.users);
      setPending(false);
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    getUsers();
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitle("Groups"));
  }, []);
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getUsers();
  }, [page, rowsPerPage, changeState]);
  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = users.filter((object) => {
      return (
        object.username.toLowerCase().match(s.toLowerCase()) ||
        object.first_name.toLowerCase().match(s.toLowerCase()) ||
        object.last_name.toLowerCase().match(s.toLowerCase()) ||
        object.email.toLowerCase().match(s.toLowerCase()) ||
        object.id.toString().toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_AssignedUser", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleUserRemoveAction = async () => {
    // extract all the ids and make a post call with an array of users ids and group id
    const user_ids = rowsToAllocate.map((row) => row.id);
    const { data, error } = await removeUsersFromGroup({
      user_ids: user_ids,
      group_id: group_id,
    });
    if (data != null) {
      showSuccess(data.message);
      setChangeState(Object.create(null));
      setOpenAssignedUser((o) => !o);
    }
    if (error != null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  const handleAction = async () => {
    if (action === "remove") {
      handleUserRemove(null);
    }
  };
  const handleUserRemove = async (row) => {
    let temp = [];
    if (row) {
      temp = row;
    } else {
      temp = selectedRows;
    }
    setRowsToAllocate(temp);
    let t = temp.map((row) => row.email);
    setAssignedUserNames(t);
    setOpenAssignedUser((o) => !o);
  };

  const columns = [
    {
      name: "",
      selector: (row) =>
        row.locked === false ? (
          <img src={Unlocked} alt="Unlocked" />
        ) : (
          <img src={Locked} alt="Locked" />
        ),
      minWidth: "100px",
      grow: 1,
      omit: !checkedStateDict["Locking"],
    },
    {
      name: "User Id",
      selector: (row) => row.id,
      minWidth: "150px",
      omit: !checkedStateDict["User Id"],
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.username,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      minWidth: "150px",
      omit: !checkedStateDict["Email"],
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role.display_name,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        row.first_name !== "" || row.last_name !== ""
          ? row.first_name + " " + row.last_name
          : "-"
      ),
      minWidth: "150px",
      omit: !checkedStateDict["Name"],
      sortable: true,
    },
    {
      name: "Phone No.",
      selector: (row) =>
        row.country_code !== "" && row.phone_number !== ""
          ? "+" + row.country_code + " " + row.phone_number
          : "-",
      minWidth: "150px",
      omit: !checkedStateDict["Phone No."],
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        isCapable && isCapable.groups.deassign_users_from_group && <>
          <p
            className="myassetslink"
            onClick={async () => {
              handleUserRemove([row]);
            }}
          >
            <AiOutlineUserDelete></AiOutlineUserDelete> remove
          </p>
        </>
      ),
      middle: true,
      omit: groupDisplayName === "default" ? true : false,
      minWidth: "50px",
    },
  ];
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Groups":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/group_icon.svg").default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Groups
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {assignedUserNames.length > 0 && (
                  <CustomModal open={openAssignedUser} handleClose={()=>{setOpenAssignedUser(o=>!o);}}>
                    <ShowAlert
                      setOpenAlert={setOpenAssignedUser}
                      handleAlertAction={handleUserRemoveAction}
                      colName={assignedUserNames}
                      alertMsg="Below users will be removed from"
                      entity_name={groupDisplayName}
                      headingMsg="Remove Users"
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`Group: ${groupDisplayName}`}
                        dataTableHeadingIcon={groupHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      colCount !== null && (
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={5}
                        />
                      )
                    }
                    fixedHeader
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalUsers}
                    selectableRows={groupDisplayName === "default" ? false : true}
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    actions={
                      groupDisplayName === "default" || groupDisplayName === "" ? (
                        false
                      ) : (
                        <ActionComponent group_id={group_id} />
                      )
                    }
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="users"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={userColumns}
                        actionOptions={(isCapable && isCapable.groups.deassign_users_from_group)?(groupDisplayName === "default" || groupDisplayName === "" ? null : actionOptions): null}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>

        </div>
      </Slide>
    </>
  );
};

export default AssignedUsers;

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { addAsset } from '../../../../api/asset';
import { mySystemUserList } from '../../../../api/systemuser';
import CrossIcon from '../../../../images/cross.svg';
import { showError, showSuccess } from '../../../../utils/showMessage';
import Fade from '@mui/material/Fade';
import { fadedelayTime } from '../../../../utils/transitionEffectParams';
import './AddAsset.scss';
import { checkIpHostPattern } from '../../../../utils/checkIpPattern';
const AddAsset = ({ open, setOpenAddAsset, setAsset }) => {
    const [name, setName] = useState("");
    const [ip_host, setIpHost] = useState("");
    const [port, setPort] = useState("");
    const [assetType, setAssetType] = useState("ssh");
    const [systemUser, setSystemUser] = useState(null);
    const [validated, setValidated] = useState(false);
    const [systemUsers, setSystemUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isIpCheck, setIsIpCheck] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const [ignoreCertificate, setIgnoreCertificate] = useState(false);
    const [slideChecked, setSlideChecked] = useState(false);
    useEffect(() => {
        setSlideChecked(true);
    }, []);
    const asset_types = [
        { "value": "ssh", "label": "SSH" },
        { "value": "rdp", "label": "RDP" },
    ]
    const selectStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            paddingTop: "3px",
            paddingBottom: "3px",
            borderColor: "#ced4da",
            boxShadow: "none",
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#717171",
            ':hover': {
                borderColor: "#ced4da",
            }
        }),
        option: (styles) => ({
            ...styles,
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
        }),
    }
    useEffect(() => {
        const getSystemUsers = async () => {
            const { data } = await mySystemUserList({ "page": -1, "limit": -1 });
            if (data !== null) {
                setSystemUsers(data.system_users);
                if (data.system_users.length > 0) {
                    setSystemUser(data.system_users[0])
                }
            }
        }
        getSystemUsers();
    }, [])
    useEffect(() => {
    }, [systemUser])
    const addAssetFormHandler = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setIsSubmited(true);
        if (form.checkValidity() === false && !checkIpHostPattern(ip_host)) {
            setIsIpCheck(true);
        } else if (!checkIpHostPattern(ip_host)) {
            setIsIpCheck(true);
            return;
        }
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            const { data, error } = await addAsset({
                ...(name !== "" && { "asset_name": name }),
                ...(ip_host !== "" && { "ip_host": ip_host }),
                ...(port !== "" && { "port": port }),
                ...(assetType !== "" && { "asset_type": assetType }),
                ...(systemUser !== null && { "system_user": systemUser.id }),
                ...({ "ignore_certificate": ignoreCertificate })

            })
            if (data !== null) {
                showSuccess(data.message);
                setLoading(false);
                setOpenAddAsset(o => !o);
                setAsset(new Object());
            }
            if (error !== null) {
                showError(error)
                setLoading(false);
            }
        }
    }
    return (
        <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
                <Container fluid className="main_content_container mx-auto">
                    <div className='d-flex justify-content-between align-items-center  mb-4'>
                        <h2 className="main_content_heading">Add Asset</h2>
                        <img src={CrossIcon} alt="close" className="cursor_pointer" onClick={() => setOpenAddAsset(o => !o)} />
                    </div>
                    <Form noValidate validated={validated} onSubmit={addAssetFormHandler} className="add_asset_form">
                        <Stack gap={1}>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextName">
                                <Form.Label column md={3} className="input_label">
                                    Asset Name<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Control
                                        required
                                        className="form_input_field"
                                        type="text"
                                        value={name}
                                        onChange={event => setName(event.target.value)}
                                        placeholder="Name"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a asset name.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextAssetType">
                                <Form.Label column md={3} className="input_label">
                                    Asset Type<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    <Select
                                        className="asset_type_select"
                                        classNamePrefix="asset"
                                        isClearable={true}
                                        isSearchable={true}
                                        defaultValue={asset_types[0]}
                                        name="asset_type"
                                        required={true}
                                        options={asset_types}
                                        onChange={(selectedOption) => setAssetType(selectedOption.value)}
                                        styles={selectStyles}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextPort">
                                <Form.Label column md={3} className="input_label">
                                    Port<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Control
                                        type="number"
                                        required
                                        onChange={event => setPort(event.target.value)}
                                        value={port}
                                        placeholder="Port"
                                        className="form_input_field"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a port.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextIP">
                                <Form.Label column md={3} className="input_label">
                                    IP/Host<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Control
                                        type="text"
                                        required
                                        onChange={(event) => {
                                            setIpHost(event.target.value);
                                            if (isSubmited) {
                                                setIsIpCheck(!checkIpHostPattern(event.target.value))
                                            }
                                        }}
                                        value={ip_host}
                                        placeholder="IP/Host"
                                        className="form_input_field"
                                        isInvalid={isIpCheck}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter valid IP/Host.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextAssetType">
                                <Form.Label column md={3} className="input_label">
                                    System User<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    <Select
                                        className="systemuser_select"
                                        classNamePrefix="asset"
                                        getOptionLabel={(option) => option.system_display_username}
                                        getOptionValue={(option) => option.id}
                                        isClearable={true}
                                        isSearchable={true}
                                        name="systemuser_select"
                                        required={true}
                                        options={systemUsers}
                                        onChange={(selectedOption) => setSystemUser(selectedOption)}
                                        styles={selectStyles}
                                        value={systemUser}
                                    />
                                </Col>
                            </Form.Group>
                            {assetType == "rdp" && <Form.Group as={Row} className="mb-3 justify-content-between" controlId="switch">
                                <Form.Label column md={3} className="input_label">
                                    Ignore-Certificate
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Check
                                        type='checkbox'
                                        id='mfa-checkbox'
                                        checked={ignoreCertificate}
                                        onChange={() => setIgnoreCertificate(!ignoreCertificate)}
                                    />
                                </Col>
                            </Form.Group>}
                            <Row className="mb-3 justify-content-end">
                                <Col sm="auto">
                                    <Button variant='light' as="input" type="button" className="form_cancel_button" value="Cancel" onClick={() => setOpenAddAsset(o => !o)} />
                                </Col>
                                <Col sm="auto" className="me-sm-2 p-0">
                                    <Button as="input" type="submit" value="Add" className="form_submit_button" disabled={loading} />
                                </Col>
                            </Row>
                        </Stack>
                    </Form>
                </Container>
            </div>
        </Fade>

    )
}

export default AddAsset

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getAssetsExecutedCommandsData } from "../../../../api/report";
import DataTable from "react-data-table-component";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import allAssetHeadingIcon from "../../../../images/apps_icon.svg";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import { changeTitle } from "../../../../state/slices/header";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { showError } from "../../../../utils/showMessage";
import { Button } from "react-bootstrap";
import { generateToken } from "../../../../api/recordings";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide,Fade } from "@mui/material";
import { delayTime, slideDirection, fadedelayTime } from "../../../../utils/transitionEffectParams";
import {formatted_timedate} from "../../../../utils/updateDateFormat";

function AssetAccessDetails() {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const dispatch = useDispatch();
  const { auditsUrl } = useSelector((state) => state.customer);
  const navigate = useNavigate();
  const { asset_access_id, asset_name, user_name, is_recorded } = useParams();
  const [assetAccessDetailsData, setAssetAccessDetailsData] = useState(null);
  const [totalAssetAccessedDetailsData, setTotalAssetAccessedDetailsData] =
    useState(0);
  const [page, setPage] = useState(1);
  const title_heading = useSelector((state) => state.header.title);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("limit_AssetAccessDetails") !== null
      ? localStorage.getItem("limit_AssetAccessDetails")
      : 5
  );
  const [filterBy, setFilterBy] = React.useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const pages_url = "dashboard/assetAccessDetails";
  const customizeColumnDict = {
    "Status": true,
    "Executed Time": true
  }
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict)
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const accessReportColumns = [
    { label: "Command", value: "command" },
    { label: "Status", value: "status" },
  ];

  const getAssetAccesDetailsReportList = async ({
    asset_access_id,
    page,
    rowsPerPage,
    search,
    filterBy,
  }) => {
    const { data } = await getAssetsExecutedCommandsData({
      id: asset_access_id,
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setAssetAccessDetailsData(data.data);
      setFilteredData(data.data);
      setTotalAssetAccessedDetailsData(data.totalCommands);
      setPending(false)
    }
  };

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getAssetAccesDetailsReportList({
      asset_access_id,
      page,
      rowsPerPage,
      search,
      filterBy,
    });
  }, [page, rowsPerPage, dispatch]);

  useEffect(() => {
    if (assetAccessDetailsData != null || assetAccessDetailsData != undefined) {
      let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
      const result = assetAccessDetailsData.filter((object) => {
        return (
          object.command.toLowerCase().match(s.toLowerCase()) ||
          object.status.toLowerCase().match(s.toLowerCase())
        );
      });
      setFilteredData(result);
    }
  }, [search]);

  useEffect(() => {
    dispatch(changeTitle("Reports"));
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("limit_AssetAccessDetails", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    getAssetAccesDetailsReportList({
      asset_access_id,
      page,
      rowsPerPage,
      search,
      filterBy,
    });
  };

  const columns = [
    {
      name: "Command",
      selector: (row) => row.command,
      maxWidth: "600px",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "SUCCESS" ? (
          <div className="text-success">{row.status}</div>
        ) : (
          <div className="text-danger">{row.status}</div>
        ),
      maxWidth: "20%",
      center: true,
      omit: !checkedStateDict["Status"],
      sortable: true,
    },
    {
      name: "Executed Time",
      selector: (row) => formatted_timedate(row.executed_time),
      maxWidth: "20%",
      center: true,
      omit: !checkedStateDict["Executed Time"],
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        is_recorded === "true"? 
        <Button
          className="border-0 bg-transparent text-primary"
          onClick={async () => {
            const { data, error } = await generateToken();
            if (data !== null) {
              const url =
                `${auditsUrl}/audits/session_recording/replay/?session_type=Asset&access_id=` +
                asset_access_id +
                "&token_key=" +
                data.token_key +
                "&token_value=" +
                data.token_value +
                "&session_key=" +
                data.session_key +
                "&video_id=" +
                -1 +
                "&time_stamp=" +
                row.executed_time +
                "&audits_url=" +
                auditsUrl+
                "&customer_id="+
                data.customer_id;
                ;
              window.open(url, "_blank");
            }
            if (error !== null) {
              showError(error);
            }
          }}
        >
          <AiOutlinePlayCircle size={20} color="#32a3ea" />
        </Button>
         :(
          <button
          disabled
          type="button"
          className="border-0 bg-transparent"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          title="Video not present"
        >
          <AiOutlinePlayCircle size={20} color="#f4646f" />
        </button>
        )
      ),

      center: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Reports":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("../icons/icon.svg").default}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Reports
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide  timeout={delayTime}  direction={slideDirection} in={slideChecked}>
        <div>
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
      <div
        className="position-relative bg-white"
        style={{ height: `${datatable_height}` }}
        >
          <DataTable
          title={
            <ListHeading
              dataTableHeadingText={"Access Id: " + asset_access_id}
              dataTableHeadingIcon={allAssetHeadingIcon}
            />
          }
          columns={columns}
          data={filteredData}
          className="rdt_container"
          progressPending={pending}
          progressComponent={
              colCount !== null && (
              <CustomDelaySkeleton
                  rowsPerPage={rowsPerPage}
                  colCount={colCount}
                  totalColumns={3}
              />
              )
          }
          fixedHeader
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          pagination
          paginationServer
          paginationTotalRows={totalAssetAccessedDetailsData}
          highlightOnHover
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          subHeader
          subHeaderComponent={
            <SubHeaderComponent
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              filterOptions={accessReportColumns}
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              placeholder="reports"
              pages_url={pages_url}
              checkedStateDict={checkedStateDict}
              setCheckedStateDict={setCheckedStateDict}
            />
          }
          subHeaderAlign="center"
          paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
          paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
          paginationIconNext={<img src={NextPageIcon} alt="next" />}
          paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
        />
        </div>
        
      </div>        
  </div>
</Fade>

      
        </div>
        </Slide>
      
    </>
  );
}

export default AssetAccessDetails;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ButtonSpinner from "../../../common/ButtonSpinner/ButtonSpinner";
import Stack from "react-bootstrap/Stack";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import "react-tabs/style/react-tabs.css";
import {
  ExtendAppAllocation,
  allocateUsersToApp,
  getAppAllocationInfo,
} from "../../../../api/apps";
import {
  ExtendAssetAllocation,
  allocateUsers,
  getAssetAllocationInfo,
} from "../../../../api/asset";
import { updateTicketDetails, CheckTicketDetails } from "../../../../api/ticketing";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { useSelector } from "react-redux";
import { getToken } from "../../../../api/apps";
import {
  handleGetPolicy,
  handleSetPolicy,
  loadPolices,
} from "../../../common/TimeComponent/helper_funtions";
import Select from "react-select";
import { extendWebAppAllocationUsers, getWebAppAllocationInfo,webAppAllocateToUsers } from "../../../../api/web_app";

function ApproveTicket({
  setApprovalModal,
  approvalTicket,
  resource,
  approvalType,
  getTicketDetail,
}) {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [ticket, setTicket] = useState();
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const ws_protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const ws_endpoint = `${serverURL.replace(/http:|https:/gi, ws_protocol + ':')}/`;
  const customer_id = useSelector((state) => state.user?.user?.customer || "");
  const [sessionkey, setSessionkey] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [policies, setPolicies] = useState([]);
  const [newAllocation, setNewAllocation] = useState(false);

  useEffect(() => {
    setTicket(approvalTicket);
    getPolicyDetails();
    ApprovalDateTime(approvalTicket);
    handleTicketing();
  }, []);

  const getPolicyDetails = async () => {
    if (approvalTicket.resource_type == "APP") {
      const { data, error } = await getAppAllocationInfo({
        entity_id: approvalTicket.requested_by.id,
        app_id: approvalTicket.resource_id,
        entity_type: "user",
      });
      if (data != null) {
        setPolicy(data.policy);
      }
      if (error !== null) {
        loadPolices([approvalTicket.resource_id],"apps",setPolicies,setPolicy,[{id: approvalTicket.requested_by.id,name: approvalTicket.requested_by.username}]);
        setNewAllocation(true);
      }
    } 
    else if (approvalTicket.resource_type == "ASSET") {
      const { data, error } = await getAssetAllocationInfo({
        entity_id: approvalTicket.requested_by.id,
        asset_id: approvalTicket.resource_id,
        entity_type: "user",
      });
      if (data != null) {
        setPolicy(data.policy);
      }
      if (error !== null) {
        loadPolices([approvalTicket.resource_id],"assets",setPolicies,setPolicy,[{id: approvalTicket.requested_by.id, name: approvalTicket.requested_by.username}]);
        setNewAllocation(true);
      }
    }
    else if (approvalTicket.resource_type == "WEB_APP") {
      const { data, error } = await getWebAppAllocationInfo({
        entity_id: approvalTicket.requested_by.id,
        web_app_id: approvalTicket.resource_id,
        entity_type: "user",
      });
      if (data != null) {
        setPolicy(data.policy);
      }
      if (error !== null) {
        loadPolices([approvalTicket.resource_id],"webapps",setPolicies,setPolicy,[{id: approvalTicket.requested_by.id, name: approvalTicket.requested_by.username}]);
        setNewAllocation(true);
      }
    }
  };

  const handleLoadPolicies = () => {
    if (approvalTicket.resource_type == "APP") {
      loadPolices([approvalTicket.resource_id], "apps", setPolicies);
    } 
    else if (approvalTicket.resource_type == "ASSET") {
      loadPolices([approvalTicket.resource_id], "assets", setPolicies);
    }
    else if (approvalTicket.resource_type == "WEB_APP") {
      loadPolices([approvalTicket.resource_id], "webapps", setPolicies);
    }
  };

  const handleTicketing = async () => {
    const { tokenData, tokenError } = await getToken();
    setSessionkey(tokenData.session_key);
  };

  const ApprovalDateTime = (ticket) => {
    let TicketFromDate = new Date(ticket.access_from * 1000);
    setFrom(
      TicketFromDate.getFullYear() +
        "-" +
        (TicketFromDate.getMonth() < 9
          ? "0" + (TicketFromDate.getMonth() + 1)
          : TicketFromDate.getMonth() + 1) +
        "-" +
        (TicketFromDate.getDate() <= 9
          ? "0" + TicketFromDate.getDate()
          : TicketFromDate.getDate())
    );
    setFromTime(
      (TicketFromDate.getHours() <= 9
        ? "0" + TicketFromDate.getHours()
        : TicketFromDate.getHours()) +
        ":" +
        (TicketFromDate.getMinutes() <= 9
          ? "0" + TicketFromDate.getMinutes()
          : TicketFromDate.getMinutes())
    );

    let TicketToDate = new Date(ticket.access_until * 1000);

    setTo(
      TicketToDate.getFullYear() +
        "-" +
        (TicketToDate.getMonth() < 9
          ? "0" + (TicketToDate.getMonth() + 1)
          : TicketToDate.getMonth() + 1) +
        "-" +
        (TicketToDate.getDate() <= 9
          ? "0" + TicketToDate.getDate()
          : TicketToDate.getDate())
    );
    setToTime(
      (TicketToDate.getHours() <= 9
        ? "0" + TicketToDate.getHours()
        : TicketToDate.getHours()) +
        ":" +
        (TicketToDate.getMinutes() <= 9
          ? "0" + TicketToDate.getMinutes()
          : TicketToDate.getMinutes())
    );
  };

  const sendTicketResponse = async (user_id) => {
    const app_connection_endpoint = `${ws_endpoint}ws/ticket/${customer_id}_${user_id}/?session_key=${sessionkey}`;
    const ticketing_ws_connection = new WebSocket(app_connection_endpoint);
    ticketing_ws_connection.onopen = function (e) {
      ticketing_ws_connection.send(
        JSON.stringify({
          type: "ticketData",
          message: { user_id: user_id },
          session_key: sessionkey,
        })
      );
      ticketing_ws_connection.close()
    };
  };

  const ticketResponse = async (e) => {
    e.preventDefault();
      const { data, error } = await CheckTicketDetails(approvalTicket);
      if (data !== null) {
        if (!loading) {
          setLoading(true);
          let approval_response;
          let finalFromDate, finalToDate;
          finalFromDate = new Date(from + " " + fromTime + ":00");
          finalToDate = new Date(to + " " + toTime + ":59");
          if (approvalTicket.resource_type === "APP") {
            if (!newAllocation) {
              approval_response = await ExtendAppAllocation({
                entity_type: "user",
                entity_id: approvalTicket.requested_by.id,
                app_id: approvalTicket.resource_id,
                allocation_from: Date.parse(finalFromDate) / 1000,
                allocation_till: Date.parse(finalToDate) / 1000,
                policy_id: policy.id,
              });
            }
            else{
              approval_response = await allocateUsersToApp({
                ids: [approvalTicket.requested_by.id],
                page: 1,
                page_size: 5,
                search: '',
                filter_by: '',
                app_id: resource.id,
                "policy": policy,
                "allocation_from": Date.parse(finalFromDate) / 1000,
                "allocation_till": Date.parse(finalToDate) / 1000,
              });
            }
          } 
          else if (approvalTicket.resource_type === "ASSET") {
            if (!newAllocation) {
                approval_response = await ExtendAssetAllocation({
                entity_type: "user",
                entity_id: approvalTicket.requested_by.id,
                asset_id: approvalTicket.resource_id,
                allocation_from: Date.parse(finalFromDate) / 1000,
                allocation_till: Date.parse(finalToDate) / 1000,
                // policy_id: response.data.policy.id, @TODO - Once Assets policy be defined, will use this
              });
            }
            else{
              approval_response = await allocateUsers({
                ids: [approvalTicket.requested_by.id],
                page: 1,
                page_size: 5,
                search: '',
                filter_by: '',
                asset_id: resource.id,
                allocation_from: Date.parse(finalFromDate) / 1000,
                allocation_till: Date.parse(finalToDate) / 1000,
            });
            }
          }
          else if (approvalTicket.resource_type === "WEB_APP") {
            if (!newAllocation) {
                approval_response = await extendWebAppAllocationUsers(approvalTicket.resource_id, {
                entity_type: "user",
                entity_id: approvalTicket.requested_by.id,
                allocation_from: Date.parse(finalFromDate) / 1000,
                allocation_till: Date.parse(finalToDate) / 1000,
                // policy_id: response.data.policy.id, @TODO - Once Web Apps policy be defined, will use this
              });
            }
            else{
              approval_response = await webAppAllocateToUsers({
                web_app_ids: [resource.id],
                allocation_from: Date.parse(finalFromDate) / 1000,
                allocation_till: Date.parse(finalToDate) / 1000,
                action: "allocate",
                user_ids: [approvalTicket.requested_by.id]
            });
            }
          }
          if (approval_response.data !== null) {
            const { data, error } = await updateTicketDetails({
              id: [approvalTicket.id],
              approval_type: approvalType,
              response: responseMessage,
              response_time: new Date(),
              access_from: Date.parse(finalFromDate) / 1000,
              access_until: Date.parse(finalToDate) / 1000,
              ...(!newAllocation ? {access_for_user_type: "Extension"} : {access_for_user_type: "Allocation"}),
            });
            if (data != null) {
              setApprovalModal((o) => !o);
              getTicketDetail();
              showSuccess(data.message);
              sendTicketResponse(approvalTicket.requested_by.id);
            }
            if (error != null) {
              showError(error);
            }
          }
          if (approval_response.error !== null) {
            showError(approval_response.error);
          }
          setLoading(false);
        }
      }
      if (error !== null) {
        showError(error);
        setApprovalModal((o) => !o);
        setLoading(false);
      }
    
  };
  return (
    <>
      <Container fluid className="alert_container mx-auto">
        <Form
          onSubmit={(e) => {
            ticketResponse(e);
          }}
          className="add_systemuser_form"
        >
          <Stack gap={1}>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="formPlaintextName"
            >
              <Col md={12}>
                <h2 className="ff-pam fw-500 profile_heading fs-24px">
                  Accepting?
                </h2>
                <h2 className="pt-2 pb-3 ff-pam fw-400 profile_heading fs-14px">
                  We notice you are approving this ticket. Please help us with a
                  response for the same. <span className="text-danger">*</span>
                </h2>
                <Row
                >
                  <Col md={6}>
                  <Form.Label column md={3} className="input_label ">
                  Created By -
                </Form.Label>
                  <Form.Label column md={9} className="mb-3">
                  {approvalTicket.requested_by.username} 
                </Form.Label>
                
                  </Col>
                  <Col md={6}>
                  <Form.Label column md={4} className="input_label">
                  {approvalTicket.resource_type == 'APP' ? <>App</> : approvalTicket.resource_type == 'ASSET' ? <>Asset</> : <>Web App</>} Name -
                </Form.Label>
                <Form.Label column md={8} className="mb-3">
                {approvalTicket.resource_type == 'APP' ? <>{resource.app_name}</> : approvalTicket.resource_type == 'ASSET' ? <>{resource.asset_name}</> : <>{resource.app_name}</>}
                </Form.Label>
                  </Col>
                </Row>
                <Row
                  style={{
                    backgroundColor: "#F9F9F9",
                    border: "1px solid #CBCBCB",
                  }}
                  className="mx-0 mb-2 pt-2 pb-3 justify-content-between"
                >
                  <Col md={6}>
                    <Form.Label column md={6} className="input_label">
                      Access From <span className="text-danger">*</span>
                    </Form.Label>
                    <Row>
                      <Col md={6}>
                        <Form.Control
                          className="form_date_input_field py-2 pe-2"
                          type="date"
                          value={from}
                          onChange={(event) => setFrom(event.target.value)}
                          placeholder="MyTickets"
                          isInvalid={from === null}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Control
                          className="form_date_input_field py-2 pe-2"
                          type="time"
                          value={fromTime}
                          onChange={(event) => setFromTime(event.target.value)}
                          isInvalid={fromTime === null}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ borderLeft: "1px solid #CBCBCB" }} md={6}>
                    <Form.Label column md={6} className="input_label">
                      Access Until <span className="text-danger">*</span>
                    </Form.Label>
                    <Row>
                      <Col md={6}>
                        <Form.Control
                          className="form_date_input_field py-2 pe-2"
                          type="date"
                          min={from}
                          value={to}
                          onChange={(event) => setTo(event.target.value)}
                          isInvalid={to === null}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Control
                          className="form_date_input_field py-2 pe-2"
                          type="time"
                          value={toTime}
                          min={to > from ? "00:00" : fromTime}
                          onChange={(event) => setToTime(event.target.value)}
                          placeholder="MyTickets"
                          isInvalid={toTime === null}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {policy && policies && <Row
                >
                  <Col md={6}>
                  <Form.Label column md={6} className="input_label mt-3">
                  Select Policy <span className="text-danger">*</span>
                </Form.Label>
                    {policy &&policies && [{id: approvalTicket.requested_by.id,name: approvalTicket.requested_by.username}].map((name, index) => (
                          <div className="mt-1">
                            <Select
                              className="systemuser_select"
                              classNamePrefix="asset"
                              getOptionLabel={(option) => option.policy_name}
                              getOptionValue={(option) => option.id}
                              isClearable={true}
                              isSearchable={true}
                              name="systemuser_select"
                              menuPlacement={
                                [{id: approvalTicket.requested_by.id,name: approvalTicket.requested_by.username}].length -  1 ===  index && [{id: approvalTicket.requested_by.id,name: approvalTicket.requested_by.username}].length > 2  ? "top"  : "bottom"
                              }
                              required={true}
                              options={
                                resource.id ? policies[ticket && approvalTicket.resource_type == "APP" ? resource.app_name : approvalTicket.resource_type == "ASSET" ? resource.asset_name : resource.app_name] : policies[name.name]
                              }
                              onChange={(selectedOption) => {
                                !newAllocation ? setPolicy(selectedOption) : handleSetPolicy(name.id, selectedOption, policy, setPolicy)
                              }}
                              value={
                                !newAllocation ? policy : handleGetPolicy(name.id, policy)
                              }
                              onFocus={handleLoadPolicies}
                            />
                          </div>
                      ))}
                  </Col>
                </Row>}
                <Row></Row>

                <Form.Label column md={6} className="input_label mt-3">
                  Message <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  className="form_input_field mt-1"
                  as="textarea"
                  rows={4}
                  value={responseMessage}
                  onChange={(event) => setResponseMessage(event.target.value)}
                  placeholder="Enter your response here."
                />
              </Col>
            </Form.Group>
            <Row className="pt-4 mb-3 justify-content-end">
              <Col sm="auto">
                <Button
                  variant="light"
                  as="input"
                  className="form_cancel_button"
                  type="button"
                  value="Cancel"
                  onClick={() => {
                    setApprovalModal((o) => !o);
                    setLoading(false);
                  }}
                />
              </Col>
              <Col sm="auto" className="me-sm-2 p-0">
                {loading ? (
                  <Button
                    style={{width: "110px"}}
                    className="form_submit_button"
                    variant="primary"
                    disabled
                  >
                      <ButtonSpinner width={'15px'} height={'15px'} color={'white'}></ButtonSpinner>
                  </Button>
                ) : (
                  <Button
                    style={{width: "110px"}}
                    as="input"
                    className="form_submit_button"
                    type="submit"
                    value="Confirm"
                  />
                )}
              </Col>
            </Row>
          </Stack>
        </Form>
      </Container>
    </>
  );
}
export default ApproveTicket;

import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { useSelector } from "react-redux";
import { getUserProfile, updateUsersProfile } from "../../../api/users";
import CrossIcon from "../../../images/cross.svg";
import { showError, showErrorMsg, showSuccess } from "../../../utils/showMessage";
import CustomModal from "../../common/CustomModal/CustomModal";
import LightTooltip from "../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "./icons/icon-info.svg";
import "./usersprofile.scss";
import PhoneInput from "react-phone-input-2";
import {Container} from "react-bootstrap";
import { signOutAllDevices } from "../../../api/users";
import { useDispatch } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import {Image} from "react-bootstrap";
import useWindowDimensions from "../../../utils/getHeightWidth";
import Slide from "@mui/material/Slide";
import { Fade } from "@mui/material";
import { delayTime, slideDirection,fadedelayTime } from "../../../utils/transitionEffectParams";
import { formatted_timedate } from "../../../utils/updateDateFormat";

import GetCapabilities from "../../../utils/getCapabilities";

function UsersProfile() {
  const SaveButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    color: "#5177FF",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const SignOutButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const { width, height } = useWindowDimensions();
    let datatable_height = height - 180 + "px";
  const dispatch = useDispatch();
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const isCapableEnduser=  my_capabilities[1]
  const id = useSelector((state) => state.user.user.id);
  const [Email, setEmail] = useState([]);
  const [Username, setusername] = useState([]);
  const [UUID, setUUID] = useState(null);
  const [FirstName, setFirstname] = useState(null);
  const [LastName, setLastname] = useState(null);
  const [Phone, setPhone] = useState(null);
  const [Role, setRole] = useState(null);
  const [RoleID, setRoleID] = useState(null);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openSignoutUser, setOpenSignoutUser] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [mfaLevel, setMfaLevel] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  const [lastLogin, setLastLogin] = useState('');
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
    dispatch(changeTitle("dashboard"));
    handleUserProfile();
  }, []);

  const handleUserProfile = async () => {
    const response = await getUserProfile(id);
    setusername(response.data.username);
    setEmail(response.data.email);
    setUUID(response.data.uuid);
    setFirstname(response.data.first_name);
    setLastname(response.data.last_name);
    setPhone(response.data.country_code + response.data.phone_number);
    setRole(response.data.role.display_name);
    setRoleID(response.data.role.role_id);
    setCountryCode(response.data.country_code);
    setMfaLevel(response.data.MFA_Level);
    setCreatedAt(response.data.created_at);
    setLastLogin(response.data.last_login);
  };
  const handleUserEdit = async () => {
    setOpenEditUser((o) => !o);
  };
  const handleUserSignout = async () => {
setOpenSignoutUser((o) => !o);
  };
  const handleUserSignoutAction = async () => {
    const { data, error } = await signOutAllDevices()
    if(data!=null){
    showSuccess(data.message)
    window.location.reload(false);
    }
    if(error!=null){
      showErrorMsg(error)
    }
    setOpenSignoutUser((o) => !o);
    
  };

  const handleCancel = () => {
    handleUserProfile()
    setOpenSignoutUser(o => !o)
  }

  const handleUsernameChange = () => {
    if (isCapable && "is_admin_user" in isCapable){
      return false;
    }
    else if(isCapableEnduser && "is_end_user" in isCapableEnduser && isCapableEnduser.allow_username_change)
    {
      return false;
    }
    else{
      return true;
    }
  }

  const handleUserEditSave = async () => {
    let len = countryCode.length;
    let phoneNumber = Phone.slice(len, Phone.length);
    const { data, error } = await updateUsersProfile({
      id: id,
      ...(Username !== "" && { username: Username }),
      ...(countryCode !== "" && phoneNumber !== ""
        ? { country_code: countryCode }
        : { country_code: "" }),
      ...(countryCode !== "" && phoneNumber !== ""
        ? { phone_number: phoneNumber }
        : { phone_number: "" }),
      ...(Email !== "" && { email: Email }),
      ...{ first_name: FirstName },
      ...{ last_name: LastName },
      ...{ role_id: RoleID },
    });
    if (data !== null) {
      showSuccess(data.message);
      
      handleUserProfile();
      setOpenEditUser((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setOpenEditUser((o) => !o);
  };
  return (
    <>
    <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 ms-2 fw-600 ">
                     Personal Profile
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
              <Slide  timeout={delayTime} direction={slideDirection} in={slideChecked}>
            <div>
            <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
      <div className="position-relative bg-white overflow-auto"
          style={{ height: `${datatable_height}` }}>
      <div className="heading_datable bg-white">
      <span
          style={{ color: "#50514F" }}
          className="ff-poppins fs-24px fw-600"
        >

              <div className="heading_title rdt_TableHeader p-0">
          <div className="d-flex flex-row justify-content-between align-items-center py-3">
            <div className="abc d-flex align-items-center">
              <h2 className=" fs-20px text-color-h1 fw-500 d-inline">
              <Image className="mb-0" src={require("./icons/User_heading_icon.svg").default}></Image>&nbsp;&nbsp;{Username}
              </h2>
            </div>
            <div>
            <SaveButton className="me-2" onClick={() => handleUserEdit()}
            variant="outlined">
              Edit
            </SaveButton>
            <SignOutButton  onClick={() => handleUserSignout()}
            variant="outlined" color="error">
              Signout of all devices
            </SignOutButton>
            </div>
          </div>
        </div>


        </span>
      <CustomModal open={openEditUser} handleClose={()=>{setOpenEditUser(o=>!o);}}>
        {" "}
        <div setOpenEditUser={setOpenEditUser}>
          <div className="d-flex justify-content-between align-items-center  mb-4">
            <h2 className="main_content_heading">Edit User</h2>
            <img
              src={CrossIcon}
              alt="close"
              className="cursor_pointer"
              onClick={() => {
                handleUserProfile();
                setOpenEditUser((o) => !o);
              }}
            />
          </div>
          <Form>
            <Stack gap={1}>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextName"
              >
                <Form.Label column md={2} className="input_lable">
                  UUID
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    className="user_input_field"
                    value={UUID}
                    disabled="true"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextName"
              >
                <Form.Label column md={2} className="input_lable">
                  Username<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    required
                    type="text"
                    pattern="[a-zA-Z]+[\w@.\d]+"
                    className="user_input_field"
                    value={Username}
                    onChange={(event) => setusername(event.target.value)}
                    placeholder="Enter your username"
                    disabled= {handleUsernameChange()}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid username
                    <LightTooltip title="Invalid username! Usernames must start with a letter. Allowed characters are a-z, A-Z, 0-9">
                      <img
                        src={InfoIcon}
                        alt="info"
                        width="20"
                        height="20"
                        className="mx-2 cursor_pointer"
                      />
                    </LightTooltip>
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formNumber"
              >
                <Form.Label column md={3} className="input_lable">
                  Phone Number
                </Form.Label>
                <Col md={9}>
                  <PhoneInput
                  className="user_input_field form-control p-0"
                    value={Phone}
                    onChange={(phone, country) => {
                      setPhone(phone);
                      setCountryCode(country.dialCode);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid phone number
                    <LightTooltip title="Phone Number must be of 10 digits.">
                      <img
                        src={InfoIcon}
                        alt="info"
                        width="20"
                        height="20"
                        className="mx-2 cursor_pointer"
                      />
                    </LightTooltip>
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formEmail"
              >
                <Form.Label column md={2} className="input_lable">
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    required
                    type="email"
                    className="user_input_field"
                    value={Email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder="Enter your email id"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email id
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextFirstName"
              >
                <Form.Label column md={2} className="input_lable">
                  First Name
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    type="text"
                    pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$"
                    className="user_input_field"
                    value={FirstName}
                    onChange={(event) => setFirstname(event.target.value)}
                    placeholder="Enter your first name"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid first name
                    <LightTooltip title="Invalid text! Name must start with a letter. Allowed characters are a-z, A-Z">
                      <img
                        src={InfoIcon}
                        alt="info"
                        width="20"
                        height="20"
                        className="mx-2 cursor_pointer"
                      />
                    </LightTooltip>
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextLastName"
              >
                <Form.Label column md={2} className="input_lable">
                  Last Name
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    type="text"
                    pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$"
                    className="user_input_field"
                    value={LastName}
                    onChange={(event) => setLastname(event.target.value)}
                    placeholder="Enter your lastname"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid last name
                    <LightTooltip title="Invalid text! Name must start with a letter. Allowed characters are a-z, A-Z">
                      <img
                        src={InfoIcon}
                        alt="info"
                        width="20"
                        height="20"
                        className="mx-2 cursor_pointer"
                      />
                    </LightTooltip>
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Row className="mb-3 justify-content-end">
                <Col sm="auto">
                  <Button
                    variant="light"
                    as="input"
                    className="user_cancel_button"
                    type="button"
                    value="Cancel"
                    onClick={() => {
                      handleUserProfile();
                      setOpenEditUser((o) => !o);
                    }}
                  />
                </Col>
                <Col sm="auto" className="me-sm-2 p-0">
                  <Button
                    as="input"
                    className="user_submit_button"
                    value="Submit"
                    type="submit"
                    onClick={() => {
                      handleUserEditSave();
                      setOpenEditUser((o) => !o);
                      
                    }}
                  />
                </Col>
              </Row>
            </Stack>
          </Form>
        </div>
      </CustomModal>
      <CustomModal open={openSignoutUser} handleClose={()=>{setOpenSignoutUser(o=>!o);}}>
        {" "}
        <Container fluid className="alert_container mx-auto">
            <div className="d-flex justify-content-between align-items-center  mb-4">
                <h2 className="main_content_heading">Confirm</h2>
                <img
                    src={CrossIcon}
                    alt="close"
                    className="cursor_pointer"
                    onClick={() => setOpenSignoutUser(o => !o)}
                />
            </div>
            <div>
                <div className="d-flex flex-row justify-content-start align-items-center">
                    <p className="sub_heading">Are you sure to sign out of all devices? </p>
                </div>
            </div>
            <Row className="pt-4 mb-3 justify-content-end">
                <Col sm="auto">
                    <Button
                        variant="light"
                        as="input"
                        className="form_cancel_button"
                        type="button"
                        value="Cancel"
                        onClick={() => handleCancel()}
                    />
                </Col>
                <Col sm="auto" className="me-sm-2 p-0">
                    <Button
                        as="input"
                        className="form_submit_button"
                        type="submit"
                        value="Confirm"
                        onClick={handleUserSignoutAction}
                    />
                </Col>
            </Row>
        </Container>
      </CustomModal>

      <div className="select-storage border-top-0 rdt_TableHeader overflow-auto p-0 pb-3">

      <div className="d-flex flex-row justify-content-between align-items-center py-3 mt-2">
            <h2 className="ff-pam fw-500 profile_heading fs-20 sub_heading d-inline ff-poppins">
              Personal Information
            </h2>
          </div>

      <Row>
        <Col lg={6}>
        <p className="ff-pam fw-500 fs-20 profile_info_heading mb-1" >First Name</p>
        {FirstName=='' ? <p className="ff-pam fw-500 fs-20 profile_info_item" >-</p> : <p className="ff-pam fw-500 fs-20 profile_info_item" >{FirstName}</p>}
        </Col>
        <Col lg={6}>
        <p className="ff-pam fw-500 fs-20 profile_info_heading mb-1 " >Last Name</p>
        {LastName=='' ? <p className="ff-pam fw-500 fs-20 profile_info_item" >-</p> : <p className="ff-pam fw-500 fs-20 profile_info_item" >{LastName}</p>}
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
        <p className="ff-pam fw-500 fs-20 profile_info_heading mb-1" >Phone Number</p>
        {(countryCode=='' || Phone=='') ? <p className="ff-pam fw-500 fs-20 profile_info_item" >-</p> : <p className="ff-pam fw-500 fs-20 profile_info_item" >+{Phone}</p>}
        </Col>
        <Col lg={6}></Col>
      </Row>
<hr></hr>

      <div className="d-flex flex-row justify-content-between align-items-center py-3 mt-2">
            <h2 className="ff-pam fw-500 profile_heading fs-20 sub_heading d-inline ff-poppins">
              Account Information
            </h2>
          </div>

      <Row>
        <Col lg={6}>
        <p className="ff-pam fw-500 fs-20 profile_info_heading mb-1" >Username</p>
        <p className="ff-pam fw-500 fs-20 profile_info_item" >{Username}</p>
        </Col>
        <Col lg={6}>
        <p className="ff-pam fw-500 fs-20 profile_info_heading mb-1 " >Role</p>
        <p className="ff-pam fw-500 fs-20 profile_info_item" >{Role}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
        <p className="ff-pam fw-500 fs-20 profile_info_heading mb-1 " >Email</p>
        <p className="ff-pam fw-500 fs-20 profile_info_item" >{Email}</p>
        </Col>
        <Col lg={6}>
        <p className="ff-pam fw-500 fs-20 profile_info_heading mb-1" >MFA Enabled</p>
        {mfaLevel==0 ? <p className="ff-pam fw-500 fs-20 profile_info_item" >Disable</p> : <p className="ff-pam fw-500 fs-20 profile_info_item" >Active</p>}
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
        <p className="ff-pam fw-500 fs-20 profile_info_heading mb-1 " >Date Joined</p>
        <p className="ff-pam fw-500 fs-20 profile_info_item" >{formatted_timedate(createdAt)}</p>
        </Col>
        <Col lg={6}>
        <p className="ff-pam fw-500 fs-20 profile_info_heading mb-1" >Date Last Login</p>
        <p className="ff-pam fw-500 fs-20 profile_info_item" >{formatted_timedate(lastLogin)}</p>
        </Col>
      </Row>
      <Row>
      </Row>

      </div>


      </div>
      </div>
      </div>
  </div>
</Fade>

      </div>
      </Slide>
    </>
  );
}
export default UsersProfile;

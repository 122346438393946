import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import CrossIcon from "../../../images/cross.svg";
import Button from "react-bootstrap/Button";
import "../TimeComponent/timeComp.scss";
import useWindowDimensions from "../../../utils/getHeightWidth";
import Select from "react-select";
import axios from "axios";
import { getResourcePolicies } from "../../../api/database_policy";
import {
  handleGetPolicy,
  handleSetPolicy,
  loadPolices,
} from "./helper_funtions";
import { fadedelayTime } from "../../../utils/transitionEffectParams";
import Fade from "@mui/material/Fade";
const TimeComp = ({
  resource_id,
  resource_type,
  setOpenAlert,
  handleAlertAction,
  colName,
  alertMsg,
  headingMsg,
  resource_name,
  target_type,
  target_entity_name,
  show_policy,
}) => {
  const { width } = useWindowDimensions();
  const [from, setFrom] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [to, setTo] = useState("");
  const [toTime, setToTime] = useState("");
  const [nowDate, setNowDate] = useState("");
  const [nowTime, setNowTime] = useState("");
  const [policy, setPolicy] = useState(null);
  const [policies, setPolicies] = useState(null);

  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  useEffect(() => {
    if (resource_type === "apps") {
      let ids = [];
      if (resource_id) {
        ids = [resource_id];
      } else {
        for (let id in colName) {
          ids.push(colName[id].id);
        }
      }
      loadPolices(ids, resource_type, setPolicies, setPolicy, colName);
    }
    const now = new Date();
    let date =
      now.getFullYear() +
      "-" +
      (now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1) +
      "-" +
      (now.getDate() < 9 ? "0" + now.getDate() : now.getDate());
    let time =
      now.getHours() <= 9
        ? "0" + now.getHours()
        : now.getHours() +
          ":" +
          (now.getMinutes() <= 9 ? "0" + now.getMinutes() : now.getMinutes());

    setNowDate(date);
    setNowTime(time);
    setFrom(date);
    setFromTime(time);
    setTo(date);
    setToTime(time);
  }, []);

  let finalFromDate, finalToDate;
  const handleSubmit = (e) => {
    e.preventDefault();
    finalFromDate = new Date(from + " " + fromTime + ":00");
    finalToDate = new Date(to + " " + toTime + ":59");
    handleAlertAction(finalFromDate, finalToDate, policy);
  };

  return (
    <Fade timeout={fadedelayTime} in={slideChecked}>
      <div>
        <Form onSubmit={handleSubmit} className="main_content_container">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="main_content_heading">{headingMsg}</h2>
            <img
              src={CrossIcon}
              alt="close"
              className="cursor_pointer"
              onClick={() => setOpenAlert((o) => !o)}
            />
          </div>
          <div>
            <div className="d-flex flex-row justify-content-start align-items-center">
              <p className="sub_heading">{alertMsg} </p>
              <p className="sub_heading_bold">
                <b>&nbsp;{target_entity_name} : </b>
              </p>
            </div>
            <div className="datetime-component row text-center me-0 ms-0">
              <div
                className={
                  width >= 992
                    ? "col-md-12 col-lg-6 col-sm-12 row border-end pb-2"
                    : "col-md-12 col-lg-6 col-sm-12 row border-bottom pb-2"
                }
              >
                <Form.Group className="p-1 text-truncate col-md-6 col-sm-12">
                  <Form.Label className="title-style pb-1">From</Form.Label>
                  <Form.Control
                    className="input-style"
                    type="date"
                    value={from}
                    min={nowDate}
                    onChange={(e) => {
                      setFrom(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="p-1 col-md-6 col-sm-12 text-truncate">
                  <Form.Label className="title-style pb-1">Time</Form.Label>
                  <Form.Control
                    className="input-style"
                    type="time"
                    min={nowTime}
                    value={fromTime}
                    onChange={(e) => {
                      setFromTime(e.target.value);
                    }}
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-md-12 col-lg-6  col-sm-12 row pb-2">
                <Form.Group className="p-1 col-md-6 col-sm-12 text-truncate">
                  <Form.Label className="title-style pb-1">To</Form.Label>
                  <Form.Control
                    className="input-style"
                    type="date"
                    value={to}
                    min={from}
                    onChange={(e) => {
                      setTo(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="p-1 col-md-6 col-sm-12 text-truncate">
                  <Form.Label className="title-style pb-1">Time</Form.Label>
                  <Form.Control
                    className="input-style"
                    type="time"
                    max="23:59"
                    value={toTime}
                    min={to > from ? "00:00" : fromTime}
                    onChange={(e) => {
                      setToTime(e.target.value);
                    }}
                    required
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex flex-column flex-wrap assign_policy_container mt-3 datetime-component">
              <div className="container">
                <div className="row border-bottom py-3">
                  <p className="display_names_heading mb-0 col-2">Sr No</p>
                  <p className="display_names_heading mb-0 col-3">
                    {target_type}
                  </p>
                  {show_policy && (
                    <p className="display_names_heading mb-0 col-7">Policy</p>
                  )}
                </div>
                {colName.map((col, index) => (
                  <div
                    className="row justify-content-start  align-items-center my-3"
                    key={index}
                  >
                    <p className="display_names col-2 mb-0">{index + 1}.</p>
                    <p className="display_names col-3 mb-0" key={index}>
                      {col.name}
                    </p>
                    {show_policy && policies && (
                      <div className="col-7">
                        <Select
                          className="systemuser_select col-12 col-md-10"
                          classNamePrefix="asset"
                          getOptionLabel={(option) => option.policy_name}
                          getOptionValue={(option) => option.id}
                          isClearable={true}
                          isSearchable={true}
                          name="systemuser_select"
                          required={true}
                          menuPlacement={
                            colName.length - 1 === index && colName.length > 2
                              ? "top"
                              : "bottom"
                          }
                          options={
                            resource_id
                              ? policies[resource_name]
                              : policies[col.name]
                          }
                          onChange={(selectedOption) => {
                            handleSetPolicy(
                              col.id,
                              selectedOption,
                              policy,
                              setPolicy
                            );
                          }}
                          value={handleGetPolicy(col.id, policy)}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="pt-4 mb-3 d-flex flex-lg-row align-items-center justify-content-end">
            <div sm="auto">
              <Button
                variant="light"
                as="input"
                className="form_cancel_button"
                type="button"
                value="Cancel"
                onClick={() => setOpenAlert((o) => !o)}
              />
            </div>
            <div sm="auto" className="me-sm-2 p-0">
              <input
                as="input"
                className="form-submit-btn"
                type="submit"
                value="Confirm"
              />
            </div>
          </div>
        </Form>
      </div>
    </Fade>
  );
};
export default TimeComp;

import React, {useEffect, useState} from "react";
import {miniorangePolling, verifyMFAForResourceAccess} from "../../../../api/two_fa";
import {showError, showSuccess} from "../../../../utils/showMessage";
import "./MFAOnResourceAccess.scss";

const MFAOnResourceAccess = ({userid, setOpen, configuredMFAInfo, openAppTab}) => {
    let timer;
    let polling_required = ["push_notification", "qr_code_authentication", "email_link", "sms_link"].includes(configuredMFAInfo.method_2fa)
    const [otp, setOtp] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");

    const startPolling = async () => {
        const {data, error} = await miniorangePolling(configuredMFAInfo.method_2fa, configuredMFAInfo.txid, 0);
        if (data != null) {
            if (data.status === 'SUCCESS') {
                clearInterval(timer);
                openAppTab();
            }
            if (data.status === 'ERROR') {
                clearInterval(timer);
                setOpen(o => !o);
            }
            if (data.status === 'DENIED') {
                // close the modal and show message
                clearInterval(timer);
                showSuccess(data.message);
                setOpen(o => !o);
            }
        }
        if (error != null) {
            clearInterval(timer);
            showError(error);
        }
    }

    useEffect(() => {
        if (polling_required) {
            timer = setInterval(() => {
                startPolling();
            }, 3000);
            return () => clearInterval(timer);
        }
    }, []);

    const handleOtpVerification = async (event) => {
        event.preventDefault();
        let request_data = {
            'otp': otp,
            'user_id': userid,
            'method': configuredMFAInfo.method_2fa,
            'txid': configuredMFAInfo.txid,
        }

        if (configuredMFAInfo.method_2fa === 'knowledge_base_question') {
            request_data['qa_list'] = JSON.stringify([
                {
                    "question": configuredMFAInfo.questions[0].question,
                    "answer": answerOne,
                },
                {
                    "question": configuredMFAInfo.questions[1].question,
                    "answer": answerTwo,
                }
            ])
        }
        const {data, error} = await verifyMFAForResourceAccess(request_data)
        if (data != null) {
            openAppTab();
        }
        if (error != null) {
            setOtp("");
            showError(error);
        }
    }

    return (
        <>
            <div className="sms_container">
                <div className="main_heading_and_cross_icon d-flex justify-content-center align-items-center">
                    <h2 id="login_heading" className="mb-5 ff-poppins fst-normal fw-600 text-center">Verify your Account</h2>
                </div>
                <div className=" py-4">
                    <p id="login_welcome_back" className="ff-poppins fst-normal fs-20px">{configuredMFAInfo.message}</p>
                    {["otp_over_sms", "otp_over_email", "otp_over_sms_email", "otp_over_call", "google_authenticator", "soft_token", "display_token"].includes(configuredMFAInfo.method_2fa) &&
                        <form method="POST" className="sms w-100" id="sms_otp_form" onSubmit={handleOtpVerification}>
                            <div className="form-group py-3 d-flex flex-row justify-content-around align-items-end">
                                <div className="d-flex flex-column align-items-start w-50"
                                     id="sms_link_phone_input_container">
                                    <label htmlFor="sms_otp" className="input_label pb-2">OTP</label>
                                    <input id="sms_otp" autoFocus={true} value={otp} onChange={(event) => {
                                        setOtp(event.target.value)
                                    }} type="text" name="otp" className="form-control form_input_field mfa_input_field"
                                           placeholder="Enter your OTP"/>
                                </div>
                                <button className="btn btn-primary form_submit_button" type="submit">Verify</button>
                            </div>
                        </form>
                        || polling_required &&
                        <div
                            className="verification-code w-100 mb-2 d-flex flex-row align-items-center justify-content-center">
                            {configuredMFAInfo.method_2fa === 'qr_code_authentication' &&
                                <img src={configuredMFAInfo.qr_code} id="ma_qr_code" alt="QR CODE"/>
                                ||
                                <span className="loader d-inline-block position-relative rounded-circle"/>
                            }
                        </div>
                        || configuredMFAInfo.method_2fa === "knowledge_base_question" &&
                        <form id="kba_validation_form" method="post"
                              className="d-flex flex-column align-items-start justify-content-around"
                              onSubmit={handleOtpVerification}>
                            <div id="kba_question"
                                 className="d-flex flex-column justify-content-between align-items-start w-100 overflow-auto my-3">
                                <div>
                                    <p className="m-1 form_input_label">{configuredMFAInfo.questions[0].question}</p>
                                </div>
                                <label className="w-100">
                                    <input className="kba_input rounded form_input_field w-100 px-3" type="password"
                                           placeholder="answer"
                                           name="kba_answer0" required onChange={(event => {
                                        setAnswerOne(event.target.value)
                                    })}/>
                                </label>
                                <input type="hidden" name="kba_question0" value={answerOne}/>
                            </div>
                            <div id="kba_question"
                                 className="d-flex flex-column justify-content-between align-items-start w-100 overflow-auto my-3">
                                <div>
                                    <p className="m-1 form_input_label">{configuredMFAInfo.questions[1].question}</p>
                                </div>
                                <label className="w-100">
                                    <input className="kba_input rounded form_input_field w-100 px-3" type="password"
                                           placeholder="answer"
                                           name="kba_answer1" required onChange={(event => {
                                        setAnswerTwo(event.target.value)
                                    })}/>
                                </label>
                                <input type="hidden" name="kba_question1" value={answerTwo}/>
                                <button className="btn btn-primary form_submit_button mt-5" type="submit">Verify
                                </button>
                            </div>
                        </form>
                    }
                </div>
                <div className="mt-4 d-flex justify-content-end">
                    <div className="d-flex justify-content-end">
                        <button type="button" id="sms_cancel_button"
                                className="form_cancel_button" onClick={() => {
                            setOpen(o => !o)
                        }}>Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default MFAOnResourceAccess;
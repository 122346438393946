import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { updateMyAsset } from '../../../../api/asset';
import { mySystemUserList } from '../../../../api/systemuser';
import CrossIcon from '../../../../images/cross.svg';
import { checkIpHostPattern } from '../../../../utils/checkIpPattern';
import { showError, showSuccess } from '../../../../utils/showMessage';
import CustomModal from '../../../common/CustomModal/CustomModal';
import { Fade } from "@mui/material";
import { fadedelayTime } from '../../../../utils/transitionEffectParams';

const EditMyAsset = ({ asset, setOpenEditAsset, setAsset }) => {
    const [asset_id, setAssetid] = useState(null);
    const [name, setName] = useState("");
    const [ip_host, setIpHost] = useState("");
    const [port, setPort] = useState("");
    const [assetType, setAssetType] = useState(null);
    const [systemUser, setSystemUser] = useState(null);
    const [validated, setValidated] = useState(false);
    const [systemUsers, setSystemUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const [isIpCheck, setIsIpCheck] = useState(false);
    const [ignoreCertificate, setIgnoreCertificate] = useState(false);
    const [slideChecked, setSlideChecked] = useState(false);
    useEffect(() => {
        setSlideChecked(true);
    }, []);
    const asset_types = [
        { "value": "ssh", "label": "SSH" },
        { "value": "rdp", "label": "RDP" },
    ]
    const selectStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            paddingTop: "3px",
            paddingBottom: "3px",
            borderColor: "#ced4da",
            boxShadow: "none",
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#717171",
            ':hover': {
                borderColor: "#ced4da",
            }
        }),
        option: (styles) => ({
            ...styles,
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
        }),
    }
    useEffect(() => {
        const getAsset = async () => {
            setAssetid(asset.id);
            setName(asset.asset_name);
            setIpHost(asset.ip_host);
            setPort(asset.port);
            setAssetType(asset.asset_type);
            setSystemUser(asset.system_user);
            setIgnoreCertificate(asset.ignore_certificate);
            const { data: systemUserListData } = await mySystemUserList({ "page": -1, "limit": -1 });
            if (systemUserListData !== null) {
                setSystemUsers(systemUserListData.system_users);
            }
        }
        getAsset();
    }, [asset])

    const updateAssetFormHandler = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setIsSubmited(true);
        if (form.checkValidity() === false && !checkIpHostPattern(ip_host)) {
            setIsIpCheck(true);
        } else if (!checkIpHostPattern(ip_host)) {
            setIsIpCheck(true);
            return;
        }
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            const { data, error } = await updateMyAsset({
                "asset_id": asset_id, "updatedAsset": {
                    ...(name !== "" && { "asset_name": name }),
                    ...(ip_host !== "" && { "ip_host": ip_host }),
                    ...(port !== "" && { "port": port }),
                    ...(assetType !== "" && { "asset_type": assetType }),
                    ...(systemUser !== null && { "system_user": systemUser.id }),
                    ...({ "ignore_certificate": ignoreCertificate })
                }
            })
            if (data !== null) {
                showSuccess(data.message);
                setLoading(false);
                setAsset(new Object());
                setOpenEditAsset(o => !o);
            }
            if (error !== null) {
                showError(error)
                setLoading(false);
            }
        }
    }

    return (
        <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
                <Container fluid className="main_content_container mx-auto">
                    <div className='d-flex justify-content-between align-items-center  mb-4'>
                        <h2 className="main_content_heading">Edit Asset</h2>
                        <img src={CrossIcon} alt="close" className="cursor_pointer" onClick={() => setOpenEditAsset(o => !o)} />
                    </div>
                    <Form noValidate validated={validated} onSubmit={updateAssetFormHandler}>
                        <Stack gap={1}>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextName">
                                <Form.Label column md={3} className="input_label">
                                    Asset Name<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Control
                                        required
                                        type="text"
                                        className="form_input_field"
                                        value={name}
                                        onChange={event => setName(event.target.value)}
                                        placeholder="Name"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a asset name.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextAssetType">
                                <Form.Label column md={3} className="input_label">
                                    Asset Type<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    {assetType && <Select
                                        className="asset_type_select"
                                        classNamePrefix="asset"
                                        isClearable={true}
                                        isSearchable={true}
                                        defaultValue={asset_types.find(obj => obj.value === assetType)}
                                        name="asset_type_edit"
                                        required={true}
                                        options={asset_types}
                                        onChange={(selectedOption) => setAssetType(selectedOption.value)}
                                        styles={selectStyles}
                                    />}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextPort">
                                <Form.Label column md={3} className="input_label">
                                    Port<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Control
                                        type="number"
                                        required
                                        className="form_input_field"
                                        onChange={event => setPort(event.target.value)}
                                        value={port}
                                        placeholder="Port"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a port.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextIP">
                                <Form.Label column md={3} className="input_label">
                                    IP/Host<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Control
                                        type="text"
                                        required
                                        className="form_input_field"
                                        onChange={(event) => {
                                            setIpHost(event.target.value);
                                            if (isSubmited) {
                                                setIsIpCheck(!checkIpHostPattern(event.target.value));
                                            }
                                        }}
                                        value={ip_host}
                                        placeholder="IP/Host"
                                        isInvalid={isIpCheck}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter valid IP/Host.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextAssetType">
                                <Form.Label column md={3} className="input_label">
                                    System User<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    {systemUsers.length > 0 && <Select
                                        className="basic-single"
                                        classNamePrefix="asset"
                                        getOptionLabel={(option) => option.system_display_username}
                                        getOptionValue={(option) => option.id}
                                        defaultValue={systemUser}
                                        isClearable={true}
                                        isSearchable={true}
                                        name="color"
                                        options={systemUsers}
                                        onChange={(selectedOption) => setSystemUser(selectedOption)}
                                        styles={selectStyles}
                                    />}

                                </Col>
                            </Form.Group>
                            {assetType == "rdp" && <Form.Group as={Row} className="mb-3 justify-content-between" controlId="switch">
                                <Form.Label column md={3} className="input_label">
                                    Ignore-Certificate
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Check
                                        type='checkbox'
                                        id='mfa-checkbox'
                                        checked={ignoreCertificate}
                                        onChange={() => setIgnoreCertificate(!ignoreCertificate)}
                                    />
                                </Col>
                            </Form.Group>}
                            <Row className="mb-3 justify-content-end">
                                <Col sm="auto">
                                    <Button variant='light' as="input" type="button" value="Cancel" className="form_cancel_button" onClick={() => setOpenEditAsset(o => !o)} />
                                </Col>
                                <Col sm="auto" className="me-sm-2 p-0">
                                    <Button as="input" className="form_submit_button" type="submit" value="Update" disabled={loading} />
                                </Col>
                            </Row>
                        </Stack>
                    </Form>
                </Container>
            </div>
        </Fade>

    )
}

export default EditMyAsset;

import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import images from "../../../utils/images.js";
import GetCapabilities from "../../../utils/getCapabilities.js";
const Asset = (props) => {
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0]
  const { title } = props;
  const dispatch = useDispatch();

  const handleTitle = async () => {
    dispatch(changeTitle("Assets"));
  };
  return (
    <Accordion.Item eventKey="Assets" className={title === "Assets" ? "sidebar-button-border" : ""}>
      <Accordion.Header>
        <img
          className="mx-3 filter-active"
          src={require("./icons/assets.svg").default}
          height="22px"
          width="22px"
        />
        Assets
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup className="sidebar-list">
          {isCapable.assets.view && <ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/dashboard/all-assets"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">All Assets</div>
            </NavLink>
          </ListGroup.Item>}
          {isCapable.assets.view_allocated_assets && (
            <ListGroup.Item className="py-0 pe-0 ps-2">
              <NavLink
                to="/dashboard/myassets"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                onClick={handleTitle}
              >
                <img src={images.DotIcon} alt="dot-icon" width="4px" />
                <div className="ps-4">My Assets</div>
              </NavLink>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default Asset;

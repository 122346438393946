import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import './KnowledgeBasedQuestion.scss';
import { miniorangeMFAConfiguration } from '../../../../api/two_fa';
import { showError, showSuccess } from '../../../../utils/showMessage';
import images from '../../../../utils/images';
const KnowledgeBasedQuestion = ({ questions, configured_questions, setConfiguredMethod, setOpenModal, setIsMethodConfigured }) => {
  const [firstAnswer, setFirstAnswer] = useState("");
  const [firstQuestion, setFirstQuestion] = useState(questions[0]);
  const [secondAnswer, setSecondAnswer] = useState(questions[1]);
  const [secondQuestion, setSecondQuestion] = useState("");
  const [customQuestion, setCustomQuestion] = useState("");
  const [thirdAnswer, setThirdAnswer] = useState("");
  const [showConfiguredQuestions, setShowConfiguredQuestions] = useState(configured_questions ? true : false);


  const convertArrayToArrayOfObject = (questions) => {
    let questionsObj = [];

    questions.map((question) => (
      questionsObj.push({ "value": question, "label": question })
    ));
    return questionsObj;
  }
  const [firstQuestionDropDownOptions, setFirstQuestionDropDownOptions] = useState(convertArrayToArrayOfObject(questions));
  const [secondQuestionDropDownOptions, setSecondQuestionDropDownOptions] = useState(convertArrayToArrayOfObject(questions));
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      paddingTop: "3px",
      paddingBottom: "3px",
      borderColor: "#ced4da",
      boxShadow: "none",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      color: "#717171",
      width: "48%",
      ':hover': {
        borderColor: "#ced4da",
      }
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
    }),
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const { data, error } = await miniorangeMFAConfiguration({
      "method": "knowledge_base_question",
      "kba_question_answer": [
        {
          "question": firstQuestion,
          "answer": firstAnswer
        },
        {
          "question": secondQuestion,
          "answer": secondAnswer
        },
        {
          "question": customQuestion,
          "answer": thirdAnswer
        }
      ]
    });
    if (data != null) {
      setConfiguredMethod("knowledge_base_question");
      setIsMethodConfigured(Object.create(null));
      setOpenModal(o => !o);
      showSuccess(data.message);

    }
    if (error != null) {
      showError(error);
    }
  }

  const handleFirstDropDown = (selectedOption) => {
    setFirstQuestion(selectedOption.value);
    let filteredArray = questions.filter(e => e !== selectedOption.value);
    setSecondQuestionDropDownOptions(convertArrayToArrayOfObject(filteredArray));
  }
  const handleSecondDropDown = (selectedOption) => {
    setSecondQuestion(selectedOption.value);
    let filteredArray = questions.filter(e => e !== selectedOption.value);
    setFirstQuestionDropDownOptions(convertArrayToArrayOfObject(filteredArray));
  }
  return (
    <>
      <div className="kba_container">
        <div className="main_heading_and_cross_icon d-flex justify-content-between">
          <h2 id="kba_method_heading" className="mfa_method_heading">
            Security Questions
          </h2>
          <img src={images.CrossIcon} alt="X"
            id="kba_cross_icon"
            className="cross_icon courser-pointer" onClick={() => { setOpenModal(o => !o) }} />
        </div>
        <div>
          <p id="kba_mfa_method_description pb-4" className="mfa_method_description">
            In this method, you answer some knowledge based security questions which are only known
            to you to authenticate yourself.
          </p>
        </div>
        {showConfiguredQuestions ? (<div>
          <h3 className='mfa_method_heading_questions'>Questions</h3>
          <ol>
            {configured_questions.map((question, index) => (
              <li key={index} className="mfa_method_description mb-2">{question.question}</li>
            ))}
          </ol>
          <div className="mt-4 d-flex justify-content-end">
            <div className="d-flex justify-content-end">
              <button type="button" id="kba_cancel_button"
                className="form_cancel_button" onClick={() => { setOpenModal(o => !o) }}>Cancel
              </button>
              <button type="button" id="kba_submit_button" onClick={() => { setShowConfiguredQuestions(o => !o) }}
                className="btn btn-primary form_submit_button">Reconfigure
              </button>
            </div>
          </div>
        </div>) :
          (<form method="POST" onSubmit={handleFormSubmit}>
            <div className="mfa_method_content">
              <div className="kba_content p-2 w-100 d-flex flex-column align-items-between justify-content-between">
                <div id="kba_first_question" className="d-flex flex-row justify-content-between">
                  <p className="kba_input m-3 mb-0 border-0 py-0 px-2 kba_heading">Select
                    Question</p>
                  <p className="kba_input m-3 ms-0 mb-0 border-0 py-0 px-2 kba_heading">
                    Answer</p>
                </div>
                <div id="kba_first_question" className="d-flex flex-row justify-content-between">
                  <Select
                    className="m-3 mb-0 kba_select"
                    classNamePrefix="mfa_select"
                    isSearchable={true}
                    name="kba_questions"
                    required={true}
                    options={firstQuestionDropDownOptions}
                    onChange={(selectedOption) => { handleFirstDropDown(selectedOption) }}
                    styles={selectStyles}
                  />
                  <input className="kba_input m-3 ms-0 mb-0 p-3 rounded form_input_field" type="text"
                    placeholder="answer" required onChange={(e) => { setFirstAnswer(e.target.value) }} />
                </div>
                <div id="kba_second_question" className="d-flex flex-row justify-content-between">
                  <Select
                    className="m-3 mb-0 kba_select"
                    classNamePrefix="mfa_select"
                    isSearchable={true}
                    name="kba_questions"
                    required={true}
                    options={secondQuestionDropDownOptions}
                    onChange={(selectedOption) => handleSecondDropDown(selectedOption)}
                    styles={selectStyles}
                  />
                  <input className="kba_input m-3 ms-0 mb-0  p-3 rounded form_input_field" type="text"
                    placeholder="answer" required onChange={(e) => { setSecondAnswer(e.target.value) }} />
                </div>
                <div id="custom_question" className="d-flex flex-row justify-content-between">
                  <input className="kba_input m-3 p-3 rounded form_input_field" type="text"
                    placeholder="custom question" required onChange={(e) => { setCustomQuestion(e.target.value) }} />
                  <input className="kba_input m-3 ms-0 p-3 rounded form_input_field" type="text"
                    placeholder="answer" required onChange={(e) => { setThirdAnswer(e.target.value) }} />
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-end">
              <div className="d-flex justify-content-end">
                <button type="button" id="kba_cancel_button"
                  className="form_cancel_button" onClick={() => { setOpenModal(o => !o) }}>Cancel
                </button>
                <button type="submit" id="kba_submit_button"
                  className="btn btn-primary form_submit_button">Confirm
                </button>
              </div>
            </div>
          </form>)}
      </div>
    </>
  )
}

export default KnowledgeBasedQuestion;

import React from "react";
import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import images from "../../../utils/images.js";
import GetCapabilities from "../../../utils/getCapabilities.js";

function Reports(props) {
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0]
  const { title } = props;
  const dispatch = useDispatch();
  const handleTitle = async () => {
    dispatch(changeTitle("Reports"));
  };
  return (
    <Accordion.Item eventKey="Reports" className={title === "Reports" ? "sidebar-button-border " : ""}>
      <Accordion.Header >
        <img
          className="mx-3 filter-active"
          src={require("./icons/reports.svg").default}
          alt="reports-icon"
          height="22px"
          width="22px"
        ></img>
        Reports
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup variant="flush" className="sidebar-list">
          {isCapable.report.apps_access_report && (<ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/audit-dashboard/report_apps"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4"> Apps Report</div>
            </NavLink>
          </ListGroup.Item>)}
          {isCapable.report.assets_report &&
            (<ListGroup.Item className="py-0 pe-0 ps-2">
              <NavLink
                to="/audit-dashboard/report_assets"
                className={({ isActive }) => (isActive ? activeLink : normalLink)}
                onClick={handleTitle}
              >
                <img src={images.DotIcon} alt="dot-icon" width="4px" />
                <div className="ps-4">Assets Report</div>
              </NavLink>
            </ListGroup.Item>)}
          {isCapable.report.web_apps_access_report && (<ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/audit-dashboard/report_web_apps"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4"> Web Apps Report</div>
            </NavLink>
          </ListGroup.Item>)}
          {isCapable.report.apps_report && (<ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/audit-dashboard/app_access_report"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">App Access Report</div>
            </NavLink>
          </ListGroup.Item>)}
          {isCapable.report.assets_access_report && (<ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/audit-dashboard/asset_access_report"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Asset Access Report</div>
            </NavLink>
          </ListGroup.Item>)}
          {isCapable.report.web_apps_access_report && (<ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/audit-dashboard/web_app_access_report"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Web App Access Report</div>
            </NavLink>
          </ListGroup.Item>)}
          {isCapable.report.user_login_report && (<ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/audit-dashboard/report_users"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">User Login Report</div>
            </NavLink>
          </ListGroup.Item>)}
         {isCapable.report.ticket_report && ( <ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/audit-dashboard/report_tickets"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Tickets Report</div>
            </NavLink>
          </ListGroup.Item>)}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default Reports;

import React, { useState } from 'react'
import { miniorangeMFAConfiguration } from '../../../../api/two_fa';
import images from '../../../../utils/images';
import { showError, showSuccess } from '../../../../utils/showMessage';

const YubikeyToken = ({ methodName, methodHeading, methodNote, setOpenModal, setConfiguredMethod, setIsMethodConfigured }) => {
    const [otp, setOtp] = useState("");
    const [verifyLoading, setVerifyLoading] = useState(false);
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setVerifyLoading(true);
        const { data, error } = await miniorangeMFAConfiguration({
          "method": methodName,
          "otp": otp
        });
        if (data != null) {
          setConfiguredMethod(methodName);
          setIsMethodConfigured(Object.create(null));
          setOpenModal(o => !o);
          showSuccess(data.message);
    
        }
        if (error != null) {
          showError(error);
        }
        setVerifyLoading(false);
      }
    return (
        <div class="yubikey_container">
            <div class="main_heading_and_cross_icon d-flex justify-content-between">
                <h2 id="yubkikey_method_heading" class="mfa_method_heading">
                    {methodHeading}
                </h2>
                <img src={images.CrossIcon} alt="X" id="sms_cross_icon"
                    class="cross_icon" onClick={() => { setOpenModal(o => !o) }} />
            </div>
            <div>
                <p id="yubikey_mfa_method_description pb-3" class="mfa_method_description">
                    {methodNote}
                </p>
            </div>
            <div class="mfa_method_content py-4">
                <form method="POST" class="sms w-100" id="sms_otp_form" onSubmit={handleFormSubmit}>
                    <div class="form-group py-3 d-flex flex-row justify-content-around align-items-end">
                        <div class="d-flex flex-column align-items-start w-50">
                            <label for="yubikey_otp" class="input_label pb-2">OTP</label>
                            <input id="yubikey_otp" autoFocus required onChange={(event) => { setOtp(event.target.value) }} type="text" name="otp" className="form-control form_input_field mfa_input_field" placeholder="Enter your OTP" />
                        </div>
                        <button class="btn btn-primary form_submit_button width-20" type="submit" disabled={verifyLoading}>
                            {verifyLoading ?
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                                "Verify OTP"}
                        </button>
                    </div>
                </form>
            </div>
            <div class="mt-4 d-flex justify-content-end">
                <div class="d-flex justify-content-end">
                    <button type="button" id="sms_cancel_button"
                        class="form_cancel_button" onClick={() => { setOpenModal(o => !o) }}>Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default YubikeyToken

import axios from './axios_interceptor';
import { get_jwt_token, loginRedirect } from './helper_funtions';


export const mySystemUserList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/assets/systemuser/list/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const systemUserListWithAssetId = async ({ asset_id }) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/assets/systemuser/list/?asset_id=${asset_id}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const addSystemUser = async (data) => {
    try {
        const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/assets/systemuser/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const deleteMySystemUser = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: data
        };
        const url = `/assets/systemuser/`;
        const response = await axios.delete(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const getMySystemUserDetail = async ({ system_user_id }) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/assets/systemuser/?system_user_id=${system_user_id}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const updateMySystemUser = async ({ system_user_id, updatedSystemUser }) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/assets/systemuser/?system_user_id=${system_user_id}`;
        const response = await axios.put(url, updatedSystemUser, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
import { Fade } from "@mui/material";
import MuiButton from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";
import { BiCheckDouble } from "react-icons/bi";
import { MdPending } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { get_jwt_token, loginRedirect } from "../../../api/helper_funtions";
import { getTicketDetails } from "../../../api/ticketing";
import { default as TicketImage, default as ticketingIcon } from "../../../images/ticket.svg";
import { changeTitle } from "../../../state/slices/header";
import GetCapabilities from "../../../utils/getCapabilities";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { showError } from "../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../utils/transitionEffectParams";
import CustomModal from "../../common/CustomModal/CustomModal";
import ApproveTicket from "./ChangeTicketStatus/ApproveTicket";
import RejectTicket from "./ChangeTicketStatus/RejectTicket";
const ActionButton = styled(MuiButton)({
  boxShadow: "none",
  textTransform: "none",
  fontWeight: 600,
  fontSize: 14,
  fontStyle: "normal",
  lineHeight: "21px",
  fontFamily: ['"DM Sans"'].join(","),
  letterSpacing:'0.5px'
});

function TicketDetails(props) {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 180 + "px";
  const [approvalModal, setApprovalModal] = useState(false);
  const [rejectionModal, setRejectionModal] = useState(false);
  const [approvalType, setApprovalType] = useState(null);
  const [approvalTicket, setApprovalTicket] = useState(null);
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const isCapableEnduser = my_capabilities[1];
  const { ticket_id } = useParams();
  const [ticket, setTicket] = useState();
  const [resource, setResourceType] = useState();
  const dispatch = useDispatch();
  const [slideChecked, setSlideChecked] = useState(false);
  const title_heading = useSelector((state) => state.header.title);
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const getTicketDetail = async () => {
    const token = get_jwt_token();
    loginRedirect(token);
    const response = await getTicketDetails(ticket_id);
    if (response.data != null) {
      setTicket(response.data.ticket);
      setResourceType(response.data.resource);
    }
    if (response.error != null) {
      showError(response.error);
    }
  };

  useEffect(() => {
    getTicketDetail();
  }, [ticket_id]);

  useEffect(() => {
    dispatch(changeTitle("Ticket"));
  }, []);


  const formatDate = (date) => {
    const today = new Date();
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: "true",
    };
    const formattedDate = new Date(date).toDateString("en-US", options);
    const formattedTime = new Date(date).toLocaleTimeString("en-US", options);
    const [weekday, month, day, year] = formattedDate.split(" ");
    const [, , , hour, period] = formattedTime.split(" ");
    const capitalizedMonth = month.toUpperCase();
    return `${day} ${capitalizedMonth} ${year}, ${weekday} - ${hour} ${period}`;
  };

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Ticket":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img src={ticketingIcon} alt="users round icon" />{" "}
                        &nbsp; Tickets
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <CustomModal open={rejectionModal} handleClose={()=>{setRejectionModal(o=>!o);}}>
        {" "}
        <RejectTicket setRejectionModal={setRejectionModal} approvalTicket={approvalTicket} resource={resource} approvalType={approvalType} getTicketDetail={getTicketDetail}></RejectTicket>
      </CustomModal>
      <CustomModal open={approvalModal} handleClose={()=>{setApprovalModal(o=>!o);}}>
        {" "}
        <ApproveTicket setApprovalModal={setApprovalModal} approvalTicket={approvalTicket} resource={resource} approvalType={approvalType} getTicketDetail={getTicketDetail}></ApproveTicket>
      </CustomModal>
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              {(
                <div className="main_content_container p-3 mx-auto w-100">
                  <div
                    className="position-relative bg-white overflow-auto"
                    style={{ height: `${datatable_height}` }}
                  >
                    {<div className="position-relative bg-white overflow-auto">
                      <div className="heading_datable bg-white">
                        <span
                          style={{ color: "#50514F" }}
                          className="ff-poppins fs-24px fw-600"
                        >
                          <div className="heading_title rdt_TableHeader p-0">
                            <div className="d-flex flex-row justify-content-between align-items-center py-3">
                              <div className="abc d-flex align-items-center">
                                <img src={TicketImage} alt="EGC" className="" />
                                <h2 className="ps-3 pt-2 fs-20px text-color-h1 fw-500 d-inline">
                                  Ticket Details
                                </h2>
                              </div>
                              {ticket && ticket.ticket_status == "OPEN" && (
                                <>
                                  {(isCapableEnduser &&
                                    isCapableEnduser.allow_ticket_view) ||
                                  (isCapable &&
                                    isCapable.miscellaneous.ticket
                                      .view_ticket) ? (
                                    <div>
                                      <ActionButton
                                        onClick={() => {
                                          setApprovalTicket(ticket);
                                          setApprovalType("DENIED");
                                          setRejectionModal((o) => !o);
                                        }}
                                        className="me-3 px-3 py-2"
                                          variant="outlined"
                                          color="error"
                                      >
                                        <RxCross2/>&nbsp; Reject
                                      </ActionButton>
                                      <ActionButton
                                        onClick={() => {
                                          setApprovalTicket(ticket);
                                          setApprovalType("APPROVED");
                                          setApprovalModal((o) => !o);
                                        }}
                                        className="me-3 px-3 py-2"
                                          variant="outlined"
                                          color="success"
                                      >
                                        <AiOutlineCheck></AiOutlineCheck>&nbsp; Accept
                                      </ActionButton>
                                    </div>
                                  ) : (
                                    <div>
                                      <span className="fs-20px text-color-h1 fw-400 ff-pam text-warning">
                                        <MdPending></MdPending> Pending
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                              {ticket && ticket.ticket_status == "CLOSED" && (
                                <div>
                                  {ticket.approval_type == "APPROVED" ? (
                                    <span className="fs-20px text-color-h1 fw-400 ff-pam text-success">
                                      <BiCheckDouble></BiCheckDouble> Approved
                                    </span>
                                  ) : (
                                    <span className="fs-20px text-color-h1 fw-400 ff-pam text-danger">
                                      <RxCross2></RxCross2> Rejected
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </span>

                        <div className="select-storage border-top-0 rdt_TableHeader overflow-auto p-0 pb-3">
                          <div className="d-flex flex-row justify-content-between align-items-center py-3 mt-2">
                            <h2 className="ff-pam fw-500 profile_heading fs-20 sub_heading d-inline ff-poppins">
                              Ticket Number : {ticket && <>{ticket.id}</>}
                            </h2>
                          </div>

                          <Row className="pt-2">
                            <Col md={6}>
                              <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1">
                                Requested By
                              </p>
                              <p className="ff-pam fs-20 profile_info_item pt-1">
                              {ticket && <>{ticket.requested_by.username}</>}
                              </p>
                            </Col>
                            <Col md={6}>
                              <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1 ">
                                Request Time
                              </p>
                              <p className="ff-pam fs-20 profile_info_item pt-1">
                              {ticket && <>{formatDate(new Date(ticket.request_time))}</>}
                              </p>
                            </Col>
                          </Row>
                          <Row className="pt-2">
                            <Col md={6}>
                              <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1">
                                Resource Type
                              </p>
                              <p className="ff-pam fs-20 profile_info_item pt-1">
                              {ticket && <>{ticket.resource_type}</>}
                              </p>
                            </Col>
                            <Col md={6}>
                              <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1 ">
                                Resource Name
                              </p>
                              <p className="ff-pam fs-20 profile_info_item pt-1">
                                {ticket && (ticket.resource_type == "APP" || ticket.resource_type == "WEB_APP") ? (
                                  <>{resource && <>{resource.app_name}</>}</>
                                ) : (
                                  <>{resource && <>{resource.asset_name}</>}</>
                                )}
                              </p>
                            </Col>
                          </Row>
                          <Row className="pt-2">
                            <Col lg={6}>
                              <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1 ">
                                Access From
                              </p>
                              <p className="ff-pam fs-20 profile_info_item pt-1">
                              {ticket && <>{formatDate(
                                  new Date(ticket.access_from * 1000)
                                )}</>}
                              </p>
                            </Col>
                            <Col lg={6}>
                              <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1">
                                Access Until
                              </p>
                              <p className="ff-pam fs-20 profile_info_item pt-1">
                              {ticket && <>{formatDate(
                                  new Date(ticket.access_until * 1000)
                                )}</>}
                              </p>
                            </Col>
                          </Row>

                          <Row className="pt-2">
                            <Col lg={12}>
                              <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1 ">
                                reason
                              </p>
                              <p className="ff-pam fs-20 profile_info_item pt-1">
                              {ticket && <>{ticket.reason}</>}
                              </p>
                            </Col>
                          </Row>
                          

                          {ticket && ticket.ticket_status == "CLOSED" && (
                            <>
                            <hr></hr>
                            <Row className="py-3 float-end">
                              <Col lg={12}>
                                <p className="ff-pam fs-20 profile_info_item  mb-1 ">
                                  <span className="profile_info_heading">
                                    Status
                                  </span>{" "}
                                  : Request{" "}
                                  <span
                                    className={
                                      ticket.approval_type == "APPROVED"
                                        ? "text-success fw-600"
                                        : "text-danger fw-600"
                                    }
                                  >
                                    {ticket.approval_type}
                                  </span>{" "}
                                  at{" "}
                                  <span className="fw-600">
                                    {formatDate(new Date(ticket.response_time))}
                                  </span>
                                </p>
                                <p className="ff-pam fs-20 profile_info_item pt-1 mb-1 ">
                                  <span className="fw-400 profile_info_heading">
                                    {ticket.approval_type} by :{" "}
                                  </span>{" "}
                                  {ticket.responded_by.username}
                                </p>
                                <p
                                  style={{
                                    maxWidth: "50ch",
                                    wordWrap: "break-word",
                                  }}
                                  className="ff-pam fs-20 profile_info_item pt-1"
                                >
                                  <span className="fw-400 profile_info_heading">
                                    Response :{" "}
                                  </span>{" "}
                                  {ticket.response}
                                </p>
                              </Col>
                            </Row>
                            </>
                          )}
                          <Row></Row>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              )}
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
}
export default TicketDetails;

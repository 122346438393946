import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdWebAsset } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getToken } from "../../../api/apps";
import { getAppsAccessReportData } from "../../../api/report";
import systemUserHeadingIcon from "../../../images/apps_icon.svg";
import PreviousPageIcon from "../../../images/arrow_left.svg";
import NextPageIcon from "../../../images/arrow_right.svg";
import FirstPageIcon from "../../../images/first_page.svg";
import LastPageIcon from "../../../images/last_page.svg";
import { changeTitle } from "../../../state/slices/header";
import { showError } from "../../../utils/showMessage";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import "./report.scss";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide,Fade } from "@mui/material";
import { delayTime, slideDirection,fadedelayTime } from "../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { getEndUserProductSettings } from "../../../api/customer";
import { challengeMFAForResourceAccess } from "../../../api/two_fa";
import Loader from "../../common/Loader/Loader";
import CustomModal from "../../common/CustomModal/CustomModal";
import MFAOnResourceAccess from "../TwoFa/MFAOnResourceAccess/MFAOnResourceAccess";

const RecentApps = () => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.user.user.id);
  const server_url = useSelector((state) => state.serverUrl)
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage_RecentAppReport') !== null ? localStorage.getItem('rowsPerPage_RecentAppReport'): 5);
  const [totalApps, setTotalApps] = useState(0);
  const [apps, setApps] = useState([]);
  const [filterBy, setFilterBy] = React.useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [pending, setPending]= useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  const title_heading = useSelector((state) => state.header.title); 
  const [open, setOpen] = useState(false);
  const [configuredMFAInfo, setConfiguredMFAInfo] = useState("");
  const [rowID, setRowID] = useState("");
  const [accessButtonLoading, setAccessButtonLoading] = useState(false);
   const { serverUrl } = useSelector((state) => state.customer);
  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const appColumns = [
    { label: "App Name", value: "app_name" },
    { label: "ID", value: "app_id" },
    { label: "App Type", value: "app_type" },
  ];

  useEffect(() => {
    getAppsList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage]);

  const getAppsList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await getAppsAccessReportData({
      userid: userid,
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setApps(data.apps);
      setFilteredData(data.apps);
      setTotalApps(data.totalApps);
    }
    if (error !== null) {
      showError(error);
    }
    setPending(false);
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = apps.filter((object) => {
      return (
        object.app_name.toLowerCase().match(s.toLowerCase()) ||
        object.app_type.toLowerCase().match(s.toLowerCase()) ||
        object.app_id.toString().toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  useEffect(() => {
    dispatch(changeTitle("Apps"));
  }, []);

  const handleSearch = (event) => {
    event.preventDefault();
    getAppsList({ page, rowsPerPage, search, filterBy });
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem('rowsPerPage_RecentAppReport', newPerPage);
    setRowsPerPage(newPerPage);
  };

  const getConfiguredMFAMethod = async () => {
    const { data, error } = await challengeMFAForResourceAccess(userid);
    if (data != null) {
      setConfiguredMFAInfo(data);
      setOpen((o) => !o);
    }
    if (error != null) {
      showError(error);
    }
    setAccessButtonLoading(false);
  };

  const openAppTab = async (openModal = true, row_id = null) => {
    const { tokenData, tokenError } = await getToken();
    if (row_id == null) {
      row_id = rowID;
    }
    if (tokenData !== null) {
      const url = serverUrl + "/?resource_id=" + row_id + '&resource_type=' + 'apps' + '&token_key=' + tokenData.token_key  + '&token_value=' + tokenData.token_value + '&session_key=' + tokenData.session_key;
      window.open(url, "_blank");
      if (openModal) {
        setOpen((o) => !o);
      }
    }
    if (tokenError !== null) {
      showError(tokenError);
    }
  };

  const checkIfMFARequired = async (row_id) => {
    setAccessButtonLoading(true);
    const { data, error } = await getEndUserProductSettings();
    if (data != null) {
      if (data.MFAOverResourceAccess === 'false') {
        setAccessButtonLoading(false);
        openAppTab(false, row_id);
      }
      else {
        getConfiguredMFAMethod();
      }
    }
    if (error != null) {
      showError(error);
      setAccessButtonLoading(false);
    }
  };

  const columns = [
    {
      name: "App Id",
      selector: (row) => row.app_id,
      width: "180px",
      grow: 1,
      sortable: true,
    },
    {
      name: "App Type",
      cell: (row) => (
        <>
          {row.app_type}
        </>
      ),
      grow: 1,
      width: "180px",
      sortable: true,
    },
    
    {
      name: "App Name",
      selector: (row) => row.app_name,
      minWidth: "180px",
      sortable: true,
    },
    {
      name: "IP Address",
      selector: (row) => row.user_ip,
      minWidth: "180px",
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => 
      row.check_allocated === "Null" ? (
        <><span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="This App is deallocated">
          <p
            className="myassetslinkdisabled"
            onClick={() => {
              setRowID(row.app_id);
              checkIfMFARequired(row.app_id);
            }}
          >
            <MdWebAsset></MdWebAsset> Access</p>
        </span>
          
        </>
      ) : (
        <>
          <p
            className="myassetslink"
            onClick={() => {
              setRowID(row.app_id);
              checkIfMFARequired(row.app_id);
            }}>
            <MdWebAsset></MdWebAsset> Access</p>
        </>
      ),
      middle: true,
      minWidth: "50px",
    },
  ];
  return (
    <>
    <Loader loading={accessButtonLoading} bg_papper={true}/>
      <CustomModal open={open} width="48% !important" bgcolor="white" handleClose={()=>{setOpen(o=>!o);}}>
        <MFAOnResourceAccess
          userid={userid}
          setOpen={setOpen}
          configuredMFAInfo={configuredMFAInfo}
          serverUrl={serverUrl}
          rowID={rowID}
          openAppTab={openAppTab}
        />
      </CustomModal>
      {(() => {
        switch (title_heading) {
          case "Apps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../images/apps_icon.svg").default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
    <Slide  timeout={delayTime}  direction={slideDirection} in={slideChecked}>
      <div>
      <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
      <div
        className="position-relative bg-white"
        style={{ height: `${datatable_height}` }}
        >
<DataTable
          title={
            <ListHeading
              dataTableHeadingText="My Recent Accessed Apps"
              dataTableHeadingIcon={systemUserHeadingIcon}/>
          }
          columns={columns}
          data={filteredData}
          className="rdt_container"
          progressPending={pending}
          progressComponent={
              <CustomDelaySkeleton
                  rowsPerPage={rowsPerPage}
                  colCount={0}
                  totalColumns={4}
              />
          }
          fixedHeader
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          pagination
          paginationServer
          paginationTotalRows={totalApps}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          subHeader
          subHeaderComponent={
            <SubHeaderComponent
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              filterOptions={appColumns}
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              placeholder="apps"/>
          }
          subHeaderAlign="center"
          paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
          paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
          paginationIconNext={<img src={NextPageIcon} alt="next" />}
          paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
        />
        </div>
        
      </div>         
  </div>
</Fade>

     
      </div>
      </Slide>
    </>
  );
};

export default RecentApps;

import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, InputGroup, Toast } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  GetMinioAttributeData,
  resetMiniorangeCloudConfiguration,
  saveMiniOrangeIDPData,
} from "../../../api/sso";
import AttributeMappingMiniOrange from "./AttributeMappingMiniOrange";
import { AiFillPlusCircle } from "react-icons/ai";
import { Button } from "react-bootstrap";
import Show from "../../../images/show.png";
import Hide from "../../../images/hide.png";
import { Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { loginRedirect } from "../../../api/helper_funtions";
import { get_jwt_token } from "../../../api/helper_funtions";
import axios from "../../../api/axios_interceptor";
import {
  showSuccess,
  showError,
  showErrorMsg,
} from "../../../utils/showMessage";
import RuleBasedRoleMapping from "../../common/RuleBaseRoleMapping/RuleBasedRoleMapping";
import { prepareRuleBaseRoleMappingData } from "../../../utils/rule_based_mapping";
import images from "../../../utils/images";
import { postTestMiniOrangeIdpData } from "../../../api/sso";
import LightTooltip, {
  DarkTooltip,
} from "../../common/CustomTooltip/CustomTooltip";
import { PostSSOption } from "../../../api/sso";

const MiniOrange = ({
  minioconfigured,
  getConfiguredIDP,
  domainurl,
  setDomainurl,
  oauthappname,
  miniOrangeApplicationExists,
  setMinioconfigured,
  redirectUrl,
  currentBrandingUrl,
}) => {
  const [usernameEmail, setUsernameEmail] = useState("");
  const [rows, setRows] = useState([]);
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [attributeList, setAttributeList] = useState([]);
  const [roleBasedMapping, setRoleBasedMapping] = useState([]);
  const [domainIsInvalid, setDomainIsInvalid] = useState(false);
  const [showTestConnectionButton, setShowTestConnectionButton] = useState(
    miniOrangeApplicationExists
  );
  const [miniOrangeSaveLoading, setMiniOrangeSaveLoading] = useState(null);
  const miniOrangeToastId = useRef(null);
  const [miniOrangeSavePostOutput, setMiniOrangeSavePostOutput] = useState("");
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);

  useEffect(() => {
    if (miniOrangeSaveLoading === "pending") {
      miniOrangeToastId.current = toast.loading(
        "miniorange oauthserver information is being saved..."
      );
    } else if (miniOrangeSaveLoading === "success") {
      setShowTestConnectionButton(true);
      toast.update(miniOrangeToastId.current, {
        render: () => {
          return miniOrangeSavePostOutput;
        },
        type: toast.TYPE.SUCCESS,
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        isLoading: false,
      });
      setMinioconfigured(true);
    } else {
      toast.update(miniOrangeToastId.current, {
        render: () => {
          return miniOrangeSavePostOutput;
        },
        type: toast.TYPE.ERROR,
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        isLoading: false,
      });
    }
  }, [miniOrangeSaveLoading, miniOrangeSavePostOutput]);

  const SaveData = async (rows) => {
    let rolesMapping = prepareRuleBaseRoleMappingData(roleBasedMapping);
    try {
      const argument = {
        redirect_uri: redirectUrl,
        email: usernameEmail,
        password: password,
        attributemapping: rows,
        oauthappname: oauthappname,
        url: domainurl,
        ruleBasedRoleMapping: rolesMapping,
      };
      setMiniOrangeSaveLoading("pending");
      const { data, error } = await saveMiniOrangeIDPData(argument);
      if (data !== null) {
        setMiniOrangeSaveLoading("success");
        setMiniOrangeSavePostOutput(data["message"]);
        getConfiguredIDP();
        getAttributeData();
      } else {
        setMiniOrangeSaveLoading("failure");
        setMiniOrangeSavePostOutput(error["data"]["errors"]["message"][0]);
      }
    } catch (e) {}
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    // event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      checkIfAttributesAreCorrect();
    } else {
      if (attributeValueIsNotEmpty()) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (emailExistsInAttributeRows()) {
          SaveData(rows);
        } else {
          //email attribute is not mapped.
          toast.error("Email attribute is not mapped with any IDP parameter.");
        }
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };
  const customer_id = useSelector((state) => state?.user?.user?.customer);
  const handleConnection = async () => {
    const { data, error } = await postTestMiniOrangeIdpData(oauthappname);
    if (data !== null) {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      let params = `status=yes,location=yes,toolbar=no,menubar=yes,
                left=100,top=100,
                resizable=yes,
                width=804,
                height=710`;
      const url =
        currentBrandingUrl +
        "/sso/test-sso-connection/?application=" +
        data.application_name +
        "&token=" +
        data.token +
        "&customer_id=" +
        customer_id;
      var win = window.open(url, "_blank", params);
            win.document.title = data.application_name;
            var newLink = win.document.createElement('link');
            newLink.setAttribute('rel','icon');
            newLink.setAttribute('type','image/png');
            newLink.setAttribute('href',document.getElementById("favicon").href);
            win.document.querySelector('head').appendChild(newLink);
    } else {
      showError(error);
    }
  };
  const handleTestConnection = (event) => {
    const form = document.getElementById("sso-form");
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      checkIfAttributesAreCorrect();
    } else {
      if (attributeValueIsNotEmpty()) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (emailExistsInAttributeRows()) {
          handleConnection();
        } else {
          //email attribute is not mapped.
          toast.error("Email attribute is not mapped with any IDP parameter.");
        }
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  const emailExistsInAttributeRows = () => {
    //sends back true value if email exists in attribute rows.
    let attributeRowsContainEmail = false;
    try {
      if (rows.length !== 0) {
        rows.map((value, idx) => {
          if (value.attributeName === "email") {
            attributeRowsContainEmail = true;
          }
        });
      } else {
        attributeRowsContainEmail = true;
      }
      return attributeRowsContainEmail;
    } catch (e) {
      return attributeRowsContainEmail;
    }
  };
  const attributeValueIsNotEmpty = () => {
    let attributeValueIsNotEmpty = true;
    try {
      if (rows.length !== 0) {
        rows.map((value, idx) => {
          if (value.isattributeValueSelected === false) {
            attributeValueIsNotEmpty = false;
          }
        });
      } else {
        attributeValueIsNotEmpty = true;
      }
      return attributeValueIsNotEmpty;
    } catch (e) {
      return attributeValueIsNotEmpty;
    }
  };

  // Getting attribute data:
  const getAttributeData = async () => {
    const { data } = await GetMinioAttributeData(oauthappname);
    if (data !== null) {
      let notTruePresentRows = data.rows;
      notTruePresentRows.map((value, idx) => {
        if (value.attributeName) {
          value["isattributeNameSelected"] = true;
        } else {
          value["isattributeNameSelected"] = false;
        }
        if (value.attributeValue) {
          value["isattributeValueSelected"] = true;
        } else {
          value["isattributeValueSelected"] = false;
        }
      });
      setRows(notTruePresentRows);
      setAttributeList(data.list);
      setRoleBasedMapping(data.role_mappings);
    } else {
      setRows([]);
      setAttributeList([]);
    }
  };
  useEffect(() => {
    getAttributeData();
  }, []);
  // attribute mapping logic start:
  const handleAddAttribute = () => {
    setRows((rows) => [
      ...rows,
      {
        attributeName: "",
        attributeValue: "",
        attributeType: "",
        attributeValueLabel: "",
        isattributeNameSelected: true,
        isattributeValueSelected: true,
      },
    ]);
  };
  const checkIfAttributesAreCorrect = () => {
    const newrow = rows.map((value, idx) => {
      if (!value.attributeName) {
        return { ...value, isattributeNameSelected: false };
      }
      if (!value.attributeValue) {
        return { ...value, isattributeValueSelected: false };
      }
      return value;
    });
    setRows(newrow);
  };

  // Regenerate miniorange configuration logic:
  const handleResetCall = async () => {
    const { data, error } = await resetMiniorangeCloudConfiguration(
      oauthappname
    );
    if (data !== null) {
      showSuccess(data.message);
      setShowTestConnectionButton(false);
      getConfiguredIDP();
      getAttributeData();
    } else {
      showError(error);
      setShowTestConnectionButton(false);
      getConfiguredIDP();
      getAttributeData();
    }
  };
  const handleReset = () => {
    handleResetCall();
  };
  return (
    <>
      <Form
        id="sso-form"
        validated={validated}
        noValidate
        onSubmit={handleSubmit}
      >
        <Row className="py-3 px-5 mx-1">
          <div className="d-flex align-items-center pb-3">
            <span className="sso-form-heading ff-poppins fs-22px fs-normal">
              SSO via miniOrange
            </span>
            <DarkTooltip
              id="setting-info"
              className="lh-lg"
              title="If form fields are disabled, then please click on Save button to complete the meta data exchange between PAM and miniOrange IDP."
            >
              <img
                src={images.InfoIcon}
                alt="info"
                width="15"
                height="15"
                className="mx-2 cursor_pointer"
              />
            </DarkTooltip>
          </div>
          <Col lg={6} md={12} sm={12}>
            <Form.Group className="p-1 sso-form-control-input">
              <Form.Label className="sso-form-label">Email</Form.Label>
              <Form.Control
                className="user_input_field "
                type="text"
                required
                placeholder="Enter your Email"
                value={minioconfigured ? "***************" : usernameEmail}
                disabled={minioconfigured ? minioconfigured : false}
                onChange={(e) => {
                  setUsernameEmail(e.target.value);
                  console.log("herr");
                  const regex = new RegExp(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  );
                  if (regex.test(e.target.value)) {
                    console.log("here when it passes");
                    setEmailIsInvalid(false);
                  } else {
                    console.log("here when it does not pass");
                    setEmailIsInvalid(true);
                  }
                }}
                isInvalid={emailIsInvalid}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Email.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Form.Group className="p-1 sso-form-control-input">
              <Form.Label className="sso-form-label">Password</Form.Label>
              <InputGroup controlId="miniorangePassword">
                <Form.Control
                  className="user_input_field border-end-0"
                  type={
                    minioconfigured
                      ? "text"
                      : showPassword === false
                      ? "password"
                      : "text"
                  }
                  required
                  value={minioconfigured ? "***************" : password}
                  disabled={minioconfigured ? minioconfigured : false}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password."
                />
                <InputGroup.Text
                  id="miniorangePassword"
                  className={
                    minioconfigured
                      ? "bg-inherit my-input-group input-group-text"
                      : "bg-white my-input-group input-group-text"
                  }
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword === false ? (
                    <Image src={Show} height="20px" className="px-2"></Image>
                  ) : (
                    <Image src={Hide} height="20px" className="px-2"></Image>
                  )}
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  Please enter Password.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row className="py-3 px-5 mx-1">
          <Col lg={6} md={12} sm={12}>
            <Form.Group className="p-1 sso-form-control-input">
              <Form.Label className="sso-form-label">Domain URL</Form.Label>
              <DarkTooltip
                id="setting-info"
                className="lh-lg"
                title="Please enter valid branding url.In case of on-premise solution enter domain url, example: http://localhost:8080. In case of cloud solution, enter branding url, example: https://branding.xecurify.com/moas"
              >
                <img
                  src={images.InfoIcon}
                  alt="info"
                  width="15"
                  height="15"
                  className="mx-2 cursor_pointer"
                />
              </DarkTooltip>
              <Form.Control
                className="user_input_field"
                type="text"
                required
                placeholder="example: https://branding.xecurify.com"
                value={domainurl}
                disabled={minioconfigured ? minioconfigured : false}
                onChange={(e) => {
                  setDomainurl(e.target.value);
                  const regex = new RegExp(
                    /^https?:\/\/(?:localhost(?::([1-9]\d{0,3}))?|(?:[^\/]+\.[^\/]+(?:\/[^\/]*)?))(?:(?!\/$|;\d{1,5}\/?$)[^\/])*$/
                  );
                  if (regex.test(e.target.value)) {
                    setDomainIsInvalid(false);
                  } else {
                    setDomainIsInvalid(true);
                  }
                }}
                isInvalid={domainIsInvalid}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid branding url.In case of on-premise solution
                enter domain url, example: http://localhost:8080. In case of
                cloud solution, enter branding url, example:
                https://branding.xecurify.com/moas
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={3} md={6} sm={12} className="d-flex align-items-end ">
            <Button
              type="button"
              variant="primary-outlined"
              onClick={() => {
                handleReset();
              }}
              className="mt-4 reset-btn save-btn-text mx-2 py-2 px-4"
            >
              Reset
            </Button>
          </Col>
          <Col lg={3} md={6} sm={12} className="d-flex align-items-end">
            {showTestConnectionButton && (
              <Button
                className="sso-external-test-btn mt-4 mx-2 py-2 px-4"
                type="button"
                variant="outlined"
                onClick={(e) => handleTestConnection(e)}
              >
                <span className="sso-external-test-btn-label px-3">
                  Test Connection
                </span>
              </Button>
            )}
          </Col>
        </Row>
        <Row className="py-3 px-5 mx-1">
          <span className="sso-miniorange-attribute-mapping-heading">
            Attribute Mapping
          </span>
          <div className="sso-miniorange-attribute-mapping-subheading">
            Map PAM solution attribute from left input box to IDP attribute in
            right input box.
          </div>
          <div className="lines pt-3 attribute-mapping-line" />
        </Row>

        <Row className="mt-2 ms-5">
          <Col
            lg={4}
            md={6}
            sm={12}
            onClick={handleAddAttribute}
            className="d-flex ps-3 sso-add-new-attribute"
          >
            <img
              src={images.SSOAddNewAttribute}
              className="attribute-mapping-btn mapping-add-btn"
              type="button"
              alt="Add new attribute button"
            />
            <span className="add_new_mapping miniorange-add-new-attribute ps-3">
              Add New Attribute
              <LightTooltip
                id="setting-info"
                className="lh-lg"
                title="If Attributes are mapped then it is crucial to map 'Email' field."
              >
                <img
                  src={images.InfoIcon}
                  alt="info"
                  width="15"
                  height="15"
                  className="mx-2 cursor_pointer"
                />
              </LightTooltip>
            </span>
          </Col>
        </Row>
        <Row className="my-3 ms-5" lg={12} md={12} sm={12}>
          {rows.map((item, idx) => (
            <AttributeMappingMiniOrange
              key={idx}
              rows={rows}
              setRows={setRows}
              item={item}
              idx={idx}
              list={attributeList}
              setAttributeList={setAttributeList}
              checkIfAttributesAreCorrect={checkIfAttributesAreCorrect}
              oauthappname={oauthappname}
            />
          ))}
        </Row>
        <Row className="my-3 ms-5">
          <RuleBasedRoleMapping
            rows={roleBasedMapping}
            setRows={setRoleBasedMapping}
            attributeNameList={attributeList}
            setAttributeList={setAttributeList}
            oauthappname={oauthappname}
          />
        </Row>
      </Form>
    </>
  );
};
export default MiniOrange;

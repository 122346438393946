import axios from './axios_interceptor';
import { get_jwt_token, loginRedirect } from './helper_funtions'


export const addRole = async (args) => {
    try {
        let token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let url = `/roles/role/`;
        const response = await axios.post(url, args, config);
        return { data: response.data, error: null }

    } catch (e) {
        return { data: null, error: e.response.data.errors }
    }
}

export const updateRole = async (args) => {
    try {
        let token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let url = `/roles/role/`;
        const response = await axios.put(url, args, config);
        return { data: response.data, error: null }

    } catch (e) {
        return { data: null, error: e.response.data.errors }
    }
}

export const getRole = async (args) => {
    try {
        let token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let url = `/roles/role/?id=${args.role_id}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null }

    } catch (e) {
        return { data: null, error: e.response.data.errors }
    }
}

export const deleteRole = async (data) => {
    try {
        let token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            data: data
        };
        let url = `/roles/role/`;
        const response = await axios.delete(url, config);
        return { data: response.data, error: null }

    } catch (e) {
        return { data: null, error: e.response.data.errors }
    }
}

export const listRole = async ({ page, page_size, search, filter_by }) => {
    try {
        let token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let url = `/roles/role/?page=${page}&page_size=${page_size}&search=${search}&filter_by=${filter_by}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null }

    } catch (e) {
        return { data: null, error: e.response.data.errors }
    }
}



export const roleResourceList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/roles/role-resource-allocate/?role_id=${data.role_id}&resource_type=${data.resource_type}&allocated=${data.allocated}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const allocateResourceToRole = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        };
        const body = {
            resource_ids: data.resource_ids,
            role_id: data.role_id,
            allocation_from: data.allocation_from,
            allocation_till: data.allocation_till,
            resource_type: data.resource_type,
            allocate: data.allocate,
            policy: data.policy
        }
        const url = `/roles/role-resource-allocate/`;
        const response = await axios.post(url, body, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
};


export const DeallocateResourceFromRole = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        };
        const body = {
            resource_ids: data.resource_ids,
            role_id: data.role_id,
            resource_type: data.resource_type,
            allocate: data.allocate,
        }
        const url = `/roles/role-resource-allocate/`;
        const response = await axios.post(url, body, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
};


export const ExtendResourceAllocation = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/roles/role-resource-allocate/`;
        const response = await axios.put(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
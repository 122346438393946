import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import CrossIcon from "../../../images/cross.svg";
import './ShowAlert.scss';
const ShowAlert = ({ setOpenAlert, handleAlertAction, colName, alertMsg, headingMsg, entity_name}) => {
    return (

        <Container fluid className="main_content_container alert_container mx-auto">
            <div className="d-flex justify-content-between align-items-center  mb-4">
                <h2 className="main_content_heading">{headingMsg}</h2>
                <img
                    src={CrossIcon}
                    alt="close"
                    className="cursor_pointer"
                    onClick={() => setOpenAlert(o => !o)}
                />
            </div>
            <div>
                <div className="d-flex flex-row justify-content-start align-items-center">
                    <p className="sub_heading">{alertMsg} </p>
                    {entity_name && <p className="sub_heading_bold"><b>&nbsp;{ entity_name} : </b></p>}
                </div>
                <div className="d-flex flex-column flex-wrap alert_main_container">
                    {colName.map((name, index) =>
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <p className="display_names">{index + 1}.</p>
                            <p className="display_names mx-2" key={index}>{name}</p>
                        </div>
                    )}
                </div>
            </div>
            <Row className="pt-4 mb-3 justify-content-end">
                <Col sm="auto">
                    <Button
                        variant="light"
                        as="input"
                        className="form_cancel_button"
                        type="button"
                        value="Cancel"
                        onClick={() => setOpenAlert(o => !o)}
                    />
                </Col>
                <Col sm="auto" className="me-sm-2 p-0">
                    <Button
                        as="input"
                        className="form_submit_button"
                        type="submit"
                        value="Confirm"
                        onClick={handleAlertAction}
                    />
                </Col>
            </Row>
        </Container>
    );
};
export default ShowAlert;
import { Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArcElement, Chart, Legend, Tooltip as ToolTipBar } from "chart.js";
import { React, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import Plot from "react-plotly.js";
import { useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { getMostActiveUsersList, getReportPlotData } from "../../../api/plot";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { DarkTooltip } from "../../common/CustomTooltip/CustomTooltip";
import "./Dashboard.scss";
import active from "./icons/active.svg";
import InfoIcon from "./icons/icon-info.svg";
import inactive from "./icons/inactive.svg";
import offline from "./icons/offline.svg";
import "./plot.scss";
import Slide from "@mui/material/Slide";
import { delayTime,fadedelayTime } from '../../../utils/transitionEffectParams';
import {Fade} from "@mui/material";

Chart.register(ArcElement, ToolTipBar, Legend,ToolTipBar, Legend);

export const DashboardPlot = () => {
  const navigate = useNavigate();
  const [activeUsers, setActiveUsers] = useState(0);
  const [inActiveUsers, setInActivesUsers] = useState(0);
  const [offlineUsers, setOfflineUsers] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [groups, setGroups] = useState([]);
  const [dateCount, setDateCount] = useState([]);
  const [dateName, setDateName] = useState([]);
  const [grpRow, setGrpRow] = useState([]);
  const [usrRow, setUsrRow] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const grpsRow = [];
  const ursRow = [];
  const { width } = useWindowDimensions();
  const x = width < 768 ? (width / 100) * 80 : (width / 100) * 55;
  let datename = [];
  let countnum = [];

  var today = new Date();
  var last = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
  var month = last.getMonth() + 1; //months from 1-12
  if (month < 10) month = "0" + month;
  var day = last.getDate();
  if (day < 10) day = "0" + day;
  var year = last.getFullYear();
  var fd = year + "-" + month + "-" + day;
  var monthh = today.getMonth() + 1; //months from 1-12
  if (monthh < 10) monthh = "0" + monthh;
  var dayy = today.getDate();
  if (dayy < 10) dayy = "0" + dayy;
  var yearr = today.getFullYear();
  var ld = yearr + "-" + monthh + "-" + dayy;
    const [slideChecked, setSlideChecked] = useState(false);
  
    useEffect(() => {
      setSlideChecked(true);
    }, []);

  const getData = async () => {
    const data = await getReportPlotData();
    if (data != null) {
      setActiveUsers(data.data.users.Active);
      setInActivesUsers(data.data.users.Inactive);
      setOfflineUsers(data.data.users.Offline);
      setTotalCount(data.data.users.totalCount);
      setGroups(data.data.groups);
      // setFilteredData(data.data.userslist);
      const urs = data.data.users;
      for (const [a, b] of Object.entries(data.data.session)) {
        for (const [k, v] of Object.entries(b)) {
          if (k == "date_only") {
            datename.push(v);
          } else {
            countnum.push(v);
          }
        }
      }
      setDateCount(countnum);
      setDateName(datename);
      const grp = data.data.groups;
      let i = 0;
      for (const [k, v] of Object.entries(grp)) {
        i++;
        grpsRow.push({
          name: k,
          count: v,
        });
        if (i === 3) {
          break;
        }
      }
      setGrpRow(grpsRow);
      for (const [k, v] of Object.entries(urs)) {
        ursRow.push({
          name: k,
          count: v,
        });
      }
      setUsrRow(ursRow);
    }
  };
  const getUsers = async () => {
    const users_data = await getMostActiveUsersList();
    if (users_data !== null) {
      setFilteredData(users_data.data.userslist);
    }
  };
  useEffect(() => {
    getUsers();
    getData();
  }, []);
  //session plot
  var dataPlot = [
    {
      x: dateName,
      y: dateCount,
      fill: "tozeroy",
      fillcolor: "rgba(13, 109, 253, 0.285)",
      type: "scatter",
      text: dateCount,
      hoverinfo: "text",
      name: "Session Count",
      hovertemplate: "<i>Count</i>: %{y}, <i>Date</i>: %{x}",
      line: { shape: "spline", smoothing: 0.8 },
    },
  ];
  var layout = {
    autosize: true,
    height: 350,
    width: x,
    yaxis: {
      title: {
        text: "Count",
        font: {
          family: "poppins",
          size: 16,
          color: "#7f7f7f",
        },
      },
      range: [0, dateCount],
      showgrid: true,
      rangemode: "tozero",
      type: "linear",
      fixedrange: true,
    },
    margin: {
      l: 70,
      r: 20,
      t: 20,
      b: 65,
    },
    xaxis: {
      autosize: false,
      showgrid: false,
      title: {
        text: "Date",
        font: {
          family: "poppins",
          size: 16,
          color: "#7f7f7f",
        },
      },
      range: [fd, ld],
      type: "date",
    },
    showlegend: false,
  };
  var config = { displaylogo: false, displayModeBar: true, scrollZoom: true };

  //doughnut
  const dataDoughnut = {
    labels: ["Active", "Inactive", "Offline"],
    datasets: [
      {
        label: " Number of users",
        data: [activeUsers, inActiveUsers, offlineUsers],
        backgroundColor: ["#5177FF", "#F7F7FB", "#B1C2FE"],
        display: true,
        borderRadius: 10,
      },
    ],
  };
  const columns = [
    {
      name: "username",
      selector: (row) => row.username,
      width: "220px",
      sortable: true,
    },
    {
      name: "App accessed",
      selector: (row) => row.apps_accessed,
      center: true,
      sortable: true,
    },
    {
      name: "Asset accessed",
      selector: (row) => row.assets_accessed,
      center: true,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <p
          className="myassetslink"
          onClick={() => {
            navigate(`/dashboard/most-active-user-details/${row.username}`);
          }}
        >
          Details
        </p>
      ),
      center: true,
    },
  ];
  return (
    <div>
      <Row className="px-2">
      <Slide direction="right"  timeout={delayTime} in={slideChecked}>
        <Col lg={9} md={7} sm={12} className="pt-0  pe-4 p-3">
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="bg-white main_content_container">
            <span className="ps-5 pt-4 session-plot-name">
              Session Overview
              <DarkTooltip
                id="setting-info"
                title="Successful login attempts made on a daily basis"
              >
                <img
                  src={InfoIcon}
                  alt="info"
                  width="15"
                  height="15"
                  className="mx-2 cursor_pointer"
                />
              </DarkTooltip>
            </span>
            <div>
              {dateCount && (
                <Plot
                  data={dataPlot}
                  layout={layout}
                  config={config}
                />
              )}
            </div>
          </div>         
  </div>
</Fade>
          
        </Col>
        </Slide>
        <Slide direction="left"  timeout={delayTime} in={slideChecked}>
        <Col lg={3} md={5} sm={6} className="pt-1 pe-0">
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="front-user-rectangle main_content_container mt-3">
            <span className="usr-plot-name ps-3 d-flex justify-content-center  py-3 border-bottom">
              Total Performance
              <DarkTooltip
                id="setting-info"
                title="Total users counts and sub counts of Active(online users), Inactive(locked users), Offline(offline users)"
              >
                <img
                  src={InfoIcon}
                  alt="info"
                  width="15"
                  height="15"
                  className="mx-2 cursor_pointer"
                />
              </DarkTooltip>
            </span>
            <div className="position-relative">
              <Doughnut
                data={dataDoughnut}
                className="px-3 p-0 canvas_size"
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: true,
                    },
                  },
                  rotation: -90,
                  circumference: 180,
                  cutout: "75%",
                  maintainAspectRatio: true,
                  responsive: true,
                }}
              />
              <p className="position-absolute under-chart">
                <span className="user-name text-center pb-3">Total Count</span>
                <div className="tcount text-center">{totalCount}</div>
              </p>
            </div>

            <div className="text-center p-2">
              <Tooltip title="Active gives count of current logged in users.">
                <div className="d-inline-flex">
                  <img src={active} />
                  <div className="user-name ps-1">Active</div>
                  <div className="ps-1 pe-3 count">{activeUsers}</div>
                </div>
              </Tooltip>
              <Tooltip title="Inactive gives locked users count.">
                <div className="d-inline-flex">
                  <img src={inactive} />
                  <div className="user-name ps-1">Inactive</div>
                  <div className="ps-1 pe-3 count">{inActiveUsers}</div>
                </div>
              </Tooltip>
              <Tooltip title="Offline gives count of offline users.">
                <div className="d-inline-flex">
                  <img src={offline} />
                  <div className="user-name ps-1">Offline</div>
                  <div className="ps-1 pe-3 count">{offlineUsers}</div>
                </div>
              </Tooltip>
            </div>
        </div>        
  </div>
</Fade>
          
      </Col>
      </Slide>
    </Row>
    <Row className="px-2">
      <Slide direction="right" timeout={delayTime} in={slideChecked}>
      <Col lg={9} className="pt-0 pe-4 p-3">
      <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="bg-white main_content_container">
      <span className="ps-5 pt-4 pb-2 session-plot-name">Most Active Users</span>
        <div className="px-4">
          <hr className="my-2 mb-0" style={{color:'#D8DDE3'}}></hr>
        <DataTable
          columns={columns}
          data={filteredData}
          selectableRowsHighlight
          highlightOnHover
        />
        </div>
      </div>          
  </div>
</Fade>
      
      </Col>
      
      </Slide>
    <Slide direction="left" timeout={delayTime} in={slideChecked}>
      <Col
      lg={3}
      md={6}
      sm={12}
      className="pt-0 ps-0 pe-4 p-3"
      >
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="bg-white main_content_container mx-auto">
      <span className="session-plot-name ps-5 pt-4">Groups</span>
            <div className="px-0">
            <BasicTable rows={grpRow} />
            </div>
      </div>         
  </div>
</Fade>
    
      </Col>
      </Slide>
    </Row>
    </div>)
}

function BasicTable({ rows }) {
  return (
    <TableContainer
      className="my-3 px-4"
      sx={{ maxWidth: 400, gap: 2 }}
      component={Paper}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={1}>
              <b>Group Name</b>
            </TableCell>
            <TableCell align="center">
              <b>User count</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

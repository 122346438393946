import React from 'react';
import images from '../../../utils/images';
import { Tooltip } from '@mui/material';

const CustomTooltip = ({ message, placement }) => {
    return (
        <>
            <Tooltip title={message} placement={placement} className='mx-2'>
                <img
                    src={images.InfoIcon}
                    alt="info"
                    width="15"
                    height="15"
                    className=" cursor_pointer" />
            </Tooltip>
        </>
    )
}

export default CustomTooltip;

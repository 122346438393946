import React from "react";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "./ProductSettings.scss";
import Image from 'react-bootstrap/Image';
import Button from "react-bootstrap/Button";
import SettingsIcon from "./icons/settings-icon.svg";
import InfoIcon from "./icons/icon-info.svg";
import { updateProductSettings, getProductSettings } from "../../../api/customer";
import { useSelector, useDispatch } from "react-redux";
import { showError, showSuccess } from '../../../utils/showMessage';
import { setRecordSession } from "../../../state/slices/customer";
import { changeTitle } from "../../../state/slices/header";
import { delayTime, slideDirection, fadedelayTime } from "../../../utils/transitionEffectParams";
import { Slide, Fade } from "@mui/material";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import { DarkTooltip } from "../../common/CustomTooltip/CustomTooltip";
import { URLReg, URLReg2 } from "../../common/regex/Regex";
import GetCapabilities from "../../../utils/getCapabilities";

const ProductSetting = () => {
    const SaveButton = styled(MuiButton)({
        boxShadow: "none",
        textTransform: "none",
        fontWeight: 500,
        fontSize: 16,
        fontStyle: "normal",
        padding: "10px 28px",
        lineHeight: "21px",
        color: "#5177FF",
        fontFamily: ['"DM Sans"'].join(","),
    });
    const { width, height } = useWindowDimensions();
    let datatable_height = height - 180 + "px";
    const dispatch = useDispatch();
    const tabs = {
        "customer": true,
        "app": false,
        "user": false
    }
    const [tabList, setTabList] = useState(tabs)
    const my_capabilities = GetCapabilities();
    const isCapable = my_capabilities[0];
    const [validated, setValidated] = useState(false);
    const title_heading = useSelector((state) => state.header.title);
    // customers
    const serverProtocol = useRef("");
    const [serverDefault, setServerDefault] = useState(false);
    const [protocol, setProtocol] = useState('');
    const [domain, setDomain] = useState('');
    const [version, setVersion] = useState('');
    const [api_key, setApi_key] = useState('');
    const [record_session, setRecord_session] = useState('');
    const { recordSession } = useSelector(state => state.customer);
    const customer_id = "1" // useSelector(state => state.user.user);
    // app/asset/web_app
    const [appMaxIdleTime, setAppMaxIdleTime] = useState('');
    const [assetMaxIdleTime, setAssetMaxIdleTime] = useState('');
    const [webAppMaxIdleTime, setWebAppMaxIdleTime] = useState('');
    const [onExpiryAction, setOnExpiryAction] = useState('');
    const [showToAdminOnly, setShowToAdminOnly] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [mfaOnResource, setMfaOnResource] = useState(false);
    // user
    const [maximumInvalidAttempts, setMaximumInvalidAttempts] = useState('');
    const [userLockingInterval, setUserLockingInterval] = useState('');
    const [userLockedTime, setUserLockedTime] = useState('');
    const [slideChecked, setSlideChecked] = useState(false);

    useEffect(() => {
        setSlideChecked(true);
    }, []);

    useEffect(() => {
        dispatch(changeTitle("Settings"));
    }, [])

    useEffect(() => {
        setRecord_session(recordSession)
    }, [recordSession])

    useEffect(() => {
        serverProtocol.current = protocol

        if (protocol === "http") setServerDefault(false)
        else setServerDefault(true)

        if (onExpiryAction === 'show_to_admin_only') setShowToAdminOnly(true);
        else if (onExpiryAction === 'delete_item') setDeleteItem(true);

    }, [protocol, onExpiryAction])

    const handleRecordSession = () => {
        record_session === 'false' ? setRecord_session('true') : setRecord_session('false');
    }
    const handleMfaOnResource = () => {
        setMfaOnResource(!mfaOnResource)
    }
    const updateSettings = async () => {
        const { data, error } = await updateProductSettings({
            'user_lock_attempts': maximumInvalidAttempts,
            'user_lock_interval': userLockingInterval,
            'user_locked_time': userLockedTime,
            'app_max_idle_time': appMaxIdleTime,
            'asset_max_idle_time': assetMaxIdleTime,
            'web_apps_max_idle_time': webAppMaxIdleTime,
            'record_session': record_session,
            'on_expiry_action': onExpiryAction,
            'protocol': serverProtocol.current,
            'domain': domain,
            'MFAOverResourceAccess': (mfaOnResource ? 'true' : 'false')
        })

        if (data !== null) {
            dispatch(setRecordSession({ 'record_session': record_session }));
            showSuccess(data.message)
        }
        else showError(error)
    }

    const addSettingsFormHandler = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            updateSettings();
            setValidated('');
        }
    }

    useEffect(() => {
        const getSettings = async () => {
            const { data, error } = await getProductSettings();
            if (data !== null) {
                setVersion(data.version);
                setApi_key(data.api_key);
                setDomain(data.domain);
                setProtocol(data.protocol);
                setMaximumInvalidAttempts(data.user_lock_attempts)
                setUserLockingInterval(data.user_lock_interval)
                setUserLockedTime(data.user_locked_time)
                setAppMaxIdleTime(data.app_max_idle_time)
                setWebAppMaxIdleTime(data.web_apps_max_idle_time);
                setAssetMaxIdleTime(data.asset_max_idle_time)
                setOnExpiryAction(data.on_expiry_action)
                setMfaOnResource(data.MFAOverResourceAccess === 'false' ? false : true)
            }
            else showError(error)
        }
        getSettings();
    }, [])

    return (<>
        {(() => {
            switch (title_heading) {
                case "Settings":
                    return (
                        <div className="heading_datable bg-white">
                            <span
                                style={{ color: "#50514F" }}
                                className="ff-poppins fs-24px fw-600"
                            >
                                <div className="d-flex align-items-center">
                                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                                        <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                                            <img src={require("../../../images/settings-icon.svg").default} alt="users round icon" /> &nbsp;
                                            Settings
                                        </span>
                                        <br />
                                    </div>
                                </div>
                            </span>
                        </div>
                    );
            }
        })()}
        <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}><div>
            <Fade timeout={fadedelayTime} in={slideChecked}>
                <div>
                    <div className="main_content_container p-3 mx-auto w-100">
                        <div className="position-relative bg-white overflow-auto"
                            style={{ height: `${datatable_height}` }}>
                            <div id="setting-container" >
                                <div id="setting-heading" className="fixed-top">
                                    <hr id="setting-line-break" className="m-0" />
                                    <div className="configure_mfa_heading p-3 d-flex flex-row justify-content-between align-items-center">
                                        <div>
                                            <img
                                                src={SettingsIcon}
                                                alt="EGC"
                                                className="smtp_heading_image"
                                            />
                                            <h2 className="main-heading d-inline ms-2">
                                                &nbsp; Product Settings
                                            </h2>
                                        </div>

                                        <SaveButton className="me-5" id="setting-button1" form="setting-form" type="submit" variant="outlined">
                                            Save
                                        </SaveButton>
                                    </div>

                                    {/* settings categorized. */}
                                    <div className="mx-5 mt-3 mb-3 p-0">
                                        <div className="four-tabs">
                                            <div className="tab-div d-flex
                                            flex-row justify-content-start
                                            align-items-end
                                            ">
                                                <div id="customer"
                                                    className={tabList["customer"] ? "tab-heading tab-active-heading active-below-border me-3"
                                                        : "tab-heading tab-inactive-heading me-3"}
                                                    onClick={() => setTabList((tabs) => ({
                                                        "customer": true,
                                                        "app": false,
                                                        "user": false
                                                    }))}>Customer</div>

                                                {isCapable && (isCapable.miscellaneous.setting.app_timeout || isCapable.miscellaneous.setting.asset_timeout || isCapable.miscellaneous.setting.enable_mfa || isCapable.miscellaneous.setting.edit_asset_expiry || isCapable.miscellaneous.setting.edit_app_expiry) && <div id="app"
                                                    className={tabList["app"] ? "tab-heading tab-active-heading active-below-border me-3"
                                                        : "tab-heading tab-inactive-heading me-3"}
                                                    onClick={() => setTabList((tabs) => ({
                                                        "customer": false,
                                                        "app": true,
                                                        "user": false
                                                    }))}
                                                >App/Asset/WebApp</div>}

                                                {isCapable && (isCapable.miscellaneous.setting.edit_user_locking) && <div id="user"
                                                    className={tabList["user"] ? "tab-heading tab-active-heading active-below-border me-3"
                                                        : "tab-heading tab-inactive-heading me-3"}
                                                    onClick={() => setTabList((tabs) => ({
                                                        "customer": false,
                                                        "app": false,
                                                        "user": true
                                                    }))}
                                                >User</div>}
                                            </div>
                                        </div>
                                        {/* Tab elements */}
                                        <Form noValidate validated={validated}
                                            onSubmit={addSettingsFormHandler}
                                            id="setting-form"
                                            autocomplete="off">
                                            <Form.Group id='' className="w-100" controlId="formServerUrl">

                                                {tabList["customer"] &&
                                                    <Slide timeout={delayTime} className="h-100" direction={slideDirection} in={slideChecked}><div>
                                                        <Fade timeout={fadedelayTime} in={slideChecked}>
                                                            <div>
                                                                <div className="mt-4 main_content_container">
                                                                    <div className="setting-element-heading">Server URL</div>
                                                                    <Form.Group id='setting-form-elements' className="mb-3 w-50" controlId="formServerUrl">
                                                                        <Form.Label className="setting-element-sub-heading">Current Server Url</Form.Label>
                                                                        <DarkTooltip id="setting-info" className="lh-lg" title="Server Url can contain numbers, alphabets and . Ex: login.xecurify.com">
                                                                            <img
                                                                                src={InfoIcon}
                                                                                alt="info"
                                                                                width="15"
                                                                                height="15"
                                                                                className="mx-2 cursor_pointer" />
                                                                        </DarkTooltip>
                                                                        <span className="d-flex border setting-enclosed-customer-span">
                                                                            <Form.Select className="setting-protocol-select" onChange={event => { serverProtocol.current = event.target.value }} as="select" custom>
                                                                                <option className="setting-protocol-option" value="http">http</option>
                                                                                <option className="setting-protocol-option" selected={serverDefault} value="https">https</option>
                                                                            </Form.Select>
                                                                            <Form.Control
                                                                                className="setting-main-input"
                                                                                type="text"
                                                                                required
                                                                                placeholder="Enter Server Url"
                                                                                pattern={URLReg}
                                                                                value={domain}
                                                                                onChange={event => setDomain(event.target.value)} />
                                                                        </span>
                                                                    </Form.Group>
                                                                    <hr id="setting-line-break"></hr>
                                                                    <div className="setting-element-heading">Customer Details </div>
                                                                    <div id="setting-customer-info">You will need the following information to update License</div>
                                                                    <div id='setting-form-nelements' class="customer-detail row">
                                                                        <Form.Group className="col-sm p-0" controlId="formSsoUrl">
                                                                            <Form.Label className="setting-element-sub-heading">Version</Form.Label>
                                                                            <Form.Control value={version} className="setting-non-input customer-version" type="text" disabled placeholder="Version" />
                                                                        </Form.Group>

                                                                        <Form.Group className="col-sm p-0" controlId="formSsoUrl">
                                                                            <Form.Label className="setting-element-sub-heading">Customer Key</Form.Label>
                                                                            <Form.Control value={customer_id} className="setting-non-input customer-key" type="text" disabled placeholder="Customer Key" />
                                                                        </Form.Group>

                                                                        <Form.Group className="col-sm p-0" controlId="formSsoUrl">
                                                                            <Form.Label className="setting-element-sub-heading">License Key</Form.Label>
                                                                            <Form.Control value={api_key} className="setting-non-input customer-api" type="text" disabled placeholder="API Key" />
                                                                        </Form.Group>
                                                                    </div>
                                                                    {isCapable && isCapable.miscellaneous.setting.enable_disable_recording && <>
                                                                        <hr id="setting-line-break"></hr>

                                                                        <div className="setting-element-heading">Session Recording
                                                                            <DarkTooltip id="setting-info" className="lh-lg" title="Enable the functionality to record session data for every user.">
                                                                                <img
                                                                                    src={InfoIcon}
                                                                                    alt="info"
                                                                                    width="15"
                                                                                    height="15"
                                                                                    className="mx-2 cursor_pointer" />
                                                                            </DarkTooltip>
                                                                        </div>
                                                                        <div className="d-flex flex-row justify-content-start align-items-end pb-3">
                                                                            <Form.Group id='setting-form-elements' className="mb-3" controlId="formEnableSso">
                                                                                <Form.Check checked={record_session === 'false' ? false : true} onChange={handleRecordSession} type="checkbox" label="Enable User Session Recording" />
                                                                            </Form.Group>
                                                                        </div></>}
                                                                </div>
                                                            </div>
                                                        </Fade>

                                                    </div></Slide>}

                                                {tabList["app"] &&
                                                    <Slide timeout={delayTime} className="h-100" direction={slideDirection} in={slideChecked}><div>
                                                        <Fade timeout={fadedelayTime} in={slideChecked}>
                                                            <div>
                                                                <div className="mt-4 main_content_container">
                                                                    {isCapable && isCapable.miscellaneous.setting.app_timeout &&
                                                                        <>
                                                                            <div className="setting-element-heading">App Timeout</div>
                                                                            <div id="setting-customer-info">App timeout configuration</div>
                                                                            <Form.Group id='setting-form-elements' className="mb-3 w-50" controlId="formSsoUrl">
                                                                                <Form.Label className="setting-element-sub-heading">App max idle time(in minutes)</Form.Label>
                                                                                <DarkTooltip className="lh-lg" title="The below time(in minutes) will specify the time after which the app will timeout on inactivity.">
                                                                                    <img
                                                                                        src={InfoIcon}
                                                                                        alt="info"
                                                                                        width="15"
                                                                                        height="15"
                                                                                        className="mx-2 cursor_pointer" />
                                                                                </DarkTooltip>
                                                                                <span className="d-flex setting-enclosed-span">
                                                                                    <Form.Control
                                                                                        className="setting-main-input"
                                                                                        type="text"
                                                                                        required
                                                                                        placeholder="Timeout time in minutes"
                                                                                        pattern="^([1-9]|[1-9][0-9]{1,13})$"
                                                                                        value={appMaxIdleTime}
                                                                                        onChange={event => setAppMaxIdleTime(event.target.value)} />
                                                                                </span>
                                                                            </Form.Group>
                                                                        </>
                                                                    }
                                                                    {isCapable && isCapable.miscellaneous.setting.asset_timeout && <>
                                                                        <div className="setting-element-heading">Asset Timeout</div>
                                                                        <div id="setting-customer-info">Asset timeout configuration</div>
                                                                        <Form.Group id='setting-form-elements' className="mb-3 w-50" controlId="formSsoUrl">
                                                                            <Form.Label className="setting-element-sub-heading">Asset max idle time(in minutes)</Form.Label>
                                                                            <DarkTooltip className="lh-lg" title="The below time(in minutes) will specify the time after which the asset will timeout on inactivity.">
                                                                                <img
                                                                                    src={InfoIcon}
                                                                                    alt="info"
                                                                                    width="15"
                                                                                    height="15"
                                                                                    className="mx-2 cursor_pointer" />
                                                                            </DarkTooltip>
                                                                            <span className="d-flex setting-enclosed-span">
                                                                                <Form.Control
                                                                                    className="setting-main-input"
                                                                                    type="text"
                                                                                    required
                                                                                    placeholder="Timeout time in minutes"
                                                                                    pattern="^([1-9]|[1-9][0-9]{1,13})$"
                                                                                    value={assetMaxIdleTime}
                                                                                    onChange={event => setAssetMaxIdleTime(event.target.value)} />
                                                                            </span>
                                                                        </Form.Group></>
                                                                    }
                                                                    {isCapable && isCapable.miscellaneous.setting.web_app_timeout && <>
                                                                        <div className="setting-element-heading">Web App Timeout</div>
                                                                        <div id="setting-customer-info">Web app timeout configuration</div>
                                                                        <Form.Group id='setting-form-elements' className="mb-3 w-50" controlId="formSsoUrl">
                                                                            <Form.Label className="setting-element-sub-heading">Web app max idle time(in minutes)</Form.Label>
                                                                            <DarkTooltip className="lh-lg" title="The below time(in minutes) will specify the time after which the web app will timeout on inactivity.">
                                                                                <img
                                                                                    src={InfoIcon}
                                                                                    alt="info"
                                                                                    width="15"
                                                                                    height="15"
                                                                                    className="mx-2 cursor_pointer" />
                                                                            </DarkTooltip>
                                                                            <span className="d-flex setting-enclosed-span">
                                                                                <Form.Control
                                                                                    className="setting-main-input"
                                                                                    type="text"
                                                                                    required
                                                                                    placeholder="Timeout time in minutes"
                                                                                    pattern="^([1-9]|[1-9][0-9]{1,13})$"
                                                                                    value={webAppMaxIdleTime}
                                                                                    onChange={event => setWebAppMaxIdleTime(event.target.value)} />
                                                                            </span>
                                                                        </Form.Group></>
                                                                    }

                                                                    {isCapable && (isCapable.miscellaneous.setting.edit_asset_expiry || isCapable.miscellaneous.setting.edit_app_expiry) && <>
                                                                        <div className="setting-element-heading">App/Asset Expiry Action</div>
                                                                        <Form.Group id='setting-form-elements' className="mb-3 w-50" controlId="formServerUrl">
                                                                            <Form.Label className="setting-element-sub-heading">Action on app or asset gets expired</Form.Label>
                                                                            <DarkTooltip id="setting-info" className="lh-lg" title="The action to be taken on expiry of an allocated app or asset. The options are 1. Show to end user flagged as expired. This will not allow the app access
                         2.Show expiry details to admin only 3.Delete the item automatically ">
                                                                                <img
                                                                                    src={InfoIcon}
                                                                                    alt="info"
                                                                                    width="15"
                                                                                    height="15"
                                                                                    className="mx-2 cursor_pointer" />
                                                                            </DarkTooltip>
                                                                            <span className="d-flex border rounded setting-enclosed-span-drop_down">
                                                                                <Form.Select className="setting-protocol-select" onChange={event => setOnExpiryAction(event.target.value)} as="select" custom>
                                                                                    <option className="setting-protocol-option" value="show_to_end_user">Show to End User</option>
                                                                                    <option className="setting-protocol-option" selected={showToAdminOnly} value="show_to_admin_only">Show to Admin Only</option>
                                                                                    <option className="setting-protocol-option" selected={deleteItem} value="delete_item">Delete Item</option>
                                                                                </Form.Select>
                                                                            </span>
                                                                        </Form.Group>
                                                                        <hr id="setting-line-break"></hr>
                                                                    </>}

                                                                    {isCapable && isCapable.miscellaneous.setting.enable_mfa && <>
                                                                        <div className="setting-element-heading">Enforce MFA on Resource access
                                                                            <DarkTooltip id="setting-info" className="lh-lg" title="To enhance the security of your application or asset, you can enable multi-factor authentication (MFA) for resource access.">
                                                                                <img
                                                                                    src={InfoIcon}
                                                                                    alt="info"
                                                                                    width="15"
                                                                                    height="15"
                                                                                    className="mx-2 cursor_pointer" />
                                                                            </DarkTooltip>
                                                                        </div>
                                                                        <div className="d-flex flex-row justify-content-start align-items-end pb-3">
                                                                            <Form.Group id='setting-form-elements' className="mb-3" controlId="formEnableSso">
                                                                                <Form.Check checked={mfaOnResource === false ? false : true} onChange={handleMfaOnResource} type="checkbox" label="Enable enforcing MFA over Resource access" />
                                                                            </Form.Group>
                                                                        </div>
                                                                    </>}
                                                                </div>
                                                            </div>
                                                        </Fade>

                                                    </div></Slide>}

                                                {tabList["user"] &&
                                                    <Slide timeout={delayTime} className="h-100" direction={slideDirection} in={slideChecked}><div>
                                                        <Fade timeout={fadedelayTime} in={slideChecked}>
                                                            <div>
                                                                {isCapable && isCapable.miscellaneous.setting.edit_user_locking && <div className="mt-4 main_content_container">
                                                                    <div className="setting-element-heading">User Locking</div>
                                                                    <div id="setting-customer-info">You can configure user locking settings here</div>
                                                                    <div id='user-settings' class="">
                                                                        <Form.Group className="mb-3 p-0" controlId="formSsoUrl">
                                                                            <Form.Label className="user-settings-label">Maximum Invalid Attempts</Form.Label>
                                                                            <DarkTooltip className="lh-lg" title="Maximum Invalid Attemps must be in range 1 - 10" >
                                                                                <img
                                                                                    src={InfoIcon}
                                                                                    alt="info"
                                                                                    width="15"
                                                                                    height="15"
                                                                                    className="mx-2 cursor_pointer" />
                                                                            </DarkTooltip>
                                                                            <Form.Control
                                                                                value={maximumInvalidAttempts}
                                                                                className="setting-non-input user-settings-input"
                                                                                pattern="^([1-9]|10)$"
                                                                                type="text"
                                                                                required
                                                                                onChange={event => setMaximumInvalidAttempts(event.target.value)}
                                                                                placeholder="Maximum Invalid Attempts" />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3 p-0" controlId="formSsoUrl">
                                                                            <Form.Label className="user-settings-label">User Locking Interval (min)</Form.Label>
                                                                            <DarkTooltip className="lh-lg" title="User Locking Interval must be in range 1 min - 24 hrs (1440 min)">
                                                                                <img
                                                                                    src={InfoIcon}
                                                                                    alt="info"
                                                                                    width="15"
                                                                                    height="15"
                                                                                    className="mx-2 cursor_pointer" />
                                                                            </DarkTooltip>
                                                                            <Form.Control
                                                                                value={userLockingInterval}
                                                                                className="setting-non-input user-settings-input"
                                                                                type="text"
                                                                                required
                                                                                pattern="^([1-9]|[1-9][0-9]{1,2}|1[0-3][0-9]{2}|14[0-3][0-9]|1440)$"
                                                                                onChange={event => setUserLockingInterval(event.target.value)}
                                                                                placeholder="User Locking Interval" />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3 p-0" controlId="formSsoUrl">
                                                                            <Form.Label className="user-settings-label">User Locked Time (min)</Form.Label>
                                                                            <DarkTooltip className="lh-lg" title="User Locked Time must have minimum value of 1 min">
                                                                                <img
                                                                                    src={InfoIcon}
                                                                                    alt="info"
                                                                                    width="15"
                                                                                    height="15"
                                                                                    className="mx-2 cursor_pointer" />
                                                                            </DarkTooltip>
                                                                            <Form.Control
                                                                                value={userLockedTime}
                                                                                className="setting-non-input user-settings-input"
                                                                                type="text"
                                                                                required
                                                                                pattern="^0*[1-9]\d*$"
                                                                                onChange={event => setUserLockedTime(event.target.value)}
                                                                                placeholder="User Locked Time" />
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        </Fade>

                                                    </div></Slide>}
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Fade>

        </div>
        </Slide>
    </>)
}
export default ProductSetting
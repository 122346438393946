import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { listRole } from "../../../../api/role";
import { addUsersData } from "../../../../api/users";
import Select from "react-select";
import CrossIcon from "../../../../images/cross.svg";
import { showError, showSuccess } from "../../../../utils/showMessage";
import LightTooltip from "../../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "../icons/icon-info.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  PasswordReg,
  UserNameReg,
  FirstLastNameReg,
} from "../../../common/regex/Regex";
import {Fade} from "@mui/material";
import { fadedelayTime } from "../../../../utils/transitionEffectParams";

const AddUsers = ({ setOpenAddUser, setUsers }) => {
  const [username, setUserName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [role_id, setRoleId] = useState("");
  const [validated, setValidated] = useState(false);
  const [password, setUserPassword] = useState("");
  const [userRole, setuserRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [role, setRole] = useState(null);
  const [boxChecked, setBoxChecked] = useState(false);

  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "#ced4da",
      boxShadow: "none",
      borderRadius: "0.375rem",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "21px",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      fontSize: "14px",
    }),
  };

  useEffect(() => {
    let userRoles = [];
    let defaultRole = [];
    const getUserroles = async () => {
      const { data, error } = await listRole({
        page: 1,
        page_size: 100,
        search: "",
        filter_by: "",
      });
      if (data != null) {
        for (let i = 0; i < data.results.length; i++) {
          if (data.results[i].role_name !== "Customeradmin") {
            userRoles.push(data.results[i]);
          }
          if (data.results[i].role_name === "Enduser") {
            defaultRole.push(data.results[i]);
          }
        }
        setRole(defaultRole[0]);
        setuserRole(userRoles);
      }
      if (error !== null) {
        showError(error);
      }
    };
    getUserroles();
  }, []);

  const addUserFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setLoading(true);
      let len = countryCode.length;
      let phoneNumber = phone_number.slice(len, phone_number.length);
      setPhoneNumber(phoneNumber);
      const { data, error } = await addUsersData({
        ...(username !== "" && { username: username }),
        ...(countryCode !== "" && phoneNumber !== ""
          ? { country_code: countryCode }
          : { country_code: "" }),
        ...(countryCode !== "" && phoneNumber !== ""
          ? { phone_number: phoneNumber }
          : { phone_number: "" }),
        ...(email !== "" && { email: email }),
        ...{ first_name: first_name },
        ...{ last_name: last_name },
        ...(role && { role_id: role.role_id }),
        ...(password !== "" && { password: password }),
      });
      if (data !== null) {
        showSuccess(data.message);
        setUsers(new Object());
        setLoading(false);
        setOpenAddUser((o) => !o);
      }
      if (error !== null) {
        showError(error);
        setLoading(false);
      }
    }
  };
  const isRequired = () => {
    return password === "";
  };

  return (
    <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <Container fluid className=" mx-auto add_user_container main_content_container">
      <div className="d-flex justify-content-between align-items-center  mb-4">
        <h2 className="main_content_heading">Add User</h2>
        <img
          src={CrossIcon}
          alt="close"
          className="cursor_pointer"
          onClick={() => setOpenAddUser((o) => !o)}
        />
      </div>
      <Form
        noValidate
        validated={validated}
        onSubmit={addUserFormHandler}
        className="add_user_form"
        autoComplete="off"
      >
        <Stack gap={1}>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formPlaintextName"
          >
            <Form.Label column md={3} className="input_lable">
              Username<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Form.Control
                required
                className="user_input_field"
                pattern={UserNameReg}
                type="text"
                value={username}
                onChange={(event) => setUserName(event.target.value)}
                placeholder="Enter your username"
                autoComplete="new-username"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid username
                <LightTooltip title="Invalid username! Username must contain at least 4 characters and start with letter. Allowed characters are a-z, A-Z, 0-9">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formEmail"
          >
            <Form.Label column md={2} className="input_lable">
              Email<span className="text-danger">*</span>
            </Form.Label>

            <Col md={9}>
              <Form.Control
                type="text"
                className="user_input_field"
                pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                required
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                placeholder="Enter your email"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid Email.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formphoneNumber"
          >
            <Form.Label column md={3} className="input_lable ">
              Phone Number
            </Form.Label>
            <Col md={9}>
              <PhoneInput
                className="user_input_field form-control p-0"
                country={"in"}
                value={phone_number}
                onChange={(phone, country) => {
                  setPhoneNumber(phone);
                  setCountryCode(country.dialCode);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid phone number
                <LightTooltip title="Phone Number must be of 10 digits.">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formUserRole"
          >
            <Form.Label column md={2} className="input_label">
              Select Role <span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              {userRole.length > 0 && (
                <Select
                  className="role_select"
                  classNamePrefix="role"
                  getOptionLabel={(option) => option.display_name}
                  getOptionValue={(option) => option.role_id}
                  defaultValue={role}
                  isClearable={true}
                  isSearchable={true}
                  name="role_id"
                  required={true}
                  options={userRole}
                  onChange={(selectedOption) => setRole(selectedOption)}
                  styles={selectStyles}
                />
              )}
            </Col>
            <Form.Control.Feedback type="invalid">
              Please select a role
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formPlainFirstName"
          >
            <Form.Label column md={2} className="input_lable">
              First Name
            </Form.Label>
            <Col md={9}>
              <Form.Control
                pattern={FirstLastNameReg}
                className="user_input_field"
                type="text"
                value={first_name}
                onChange={(event) => setFirstName(event.target.value)}
                placeholder="Enter your first name"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid first name
                <LightTooltip title="Invalid text! Name must start with a letter. Allowed characters are a-z, A-Z">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formPlainLastName"
          >
            <Form.Label column md={2} className="input_lable">
              Last name
            </Form.Label>
            <Col md={9}>
              <Form.Control
                pattern={FirstLastNameReg}
                className="user_input_field"
                type="text"
                value={last_name}
                onChange={(event) => setLastName(event.target.value)}
                placeholder="Enter your last name"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid last name
                <LightTooltip title="Invalid text! Name must start with a letter. Allowed characters are a-z, A-Z">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formPlainPassword"
          >
            <Form.Label column md={2} className="input_lable">
              Password<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Form.Control
                type={boxChecked === true ? "text" : "password"}
                pattern={PasswordReg}
                required={isRequired()}
                onChange={(event) => setUserPassword(event.target.value)}
                value={password}
                placeholder="Enter your Password"
                className="user_input_field"
                autoComplete="new-password"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid password
                <LightTooltip
                  title="Invalid password!
                Min length:8 Special Char: 1 Lower Case: 1 Upper Case:1 Number:1.  
                Allowed characters are a-z, A-Z, 0-9, @$!%*?&#^().<>."
                >
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
              <Form.Check
                type="checkbox"
                label="Show Password"
                className="checkbox_show_password ps-2 my-2 d-flex align-items-center"
                onChange={() => setBoxChecked(!boxChecked)}
              />
            </Col>
          </Form.Group>

          <Row className="mb-3 justify-content-end">
            <Col sm="auto">
              <Button
                variant="light"
                as="input"
                type="button"
                className="user_cancel_button"
                value="Cancel"
                onClick={() => setOpenAddUser((o) => !o)}
              />
            </Col>
            <Col sm="auto" className="me-sm-2 p-0">
              <Button
                as="input"
                type="submit"
                value="Add"
                className="user_submit_button"
                disabled={loading}
              />
            </Col>
          </Row>
        </Stack>
      </Form>
    </Container>         
  </div>
</Fade>
    
  );
};

export default AddUsers;

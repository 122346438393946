import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import images from "../../../utils/images.js";

//File that contains dropdown of SSO options.
const SSO = (props) => {
  const { isEndUser, title } = props;
  const dispatch = useDispatch();

  const handleTitle = async () => {
    dispatch(changeTitle("SSO"));
  };
  return (
    <Accordion.Item
      eventKey="sso"
      className={title === "SSO" ? "sidebar-button-border " : ""}
    >
      <Accordion.Header>
        <img
          className="mx-3 filter-active"

          alt="sso-logo"
          src={require("./icons/sso.svg").default}
          height="22px"
          width="22px"
        />
        Authentication
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup className="sidebar-list">
          {!isEndUser && (
            <ListGroup.Item className="py-0 pe-0 ps-2">
              <NavLink
                to="/dashboard/sso"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                onClick={handleTitle}
              >
                <img src={images.DotIcon} alt="dot-icon" width="4px" />
                <div className="ps-4">Single Sign On</div>
              </NavLink>
            </ListGroup.Item>
          )}
          {
            <ListGroup.Item className="d-none py-0 pe-0 ps-2">
              <NavLink
                to="/dashboard/ldap"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                onClick={handleTitle}
              >
                <img src={images.DotIcon} alt="dot-icon" width="4px" />
                <div className="ps-4">LDAP</div>
              </NavLink>
            </ListGroup.Item>
          }
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};
export default SSO;

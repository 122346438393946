import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { showError, showSuccess } from "../../../../utils/showMessage";
import Unlocked from "../../Users/icons/unlocked.svg";
import Locked from "../../Users/icons/locked.svg";
import { AiOutlineUserAdd } from "react-icons/ai";
import CustomModal from "../../../common/CustomModal/CustomModal";
import DataTable from "react-data-table-component";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import assetHeadingIcon from "../../../../images/apps_icon.svg";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import {
  allocateUsersToApp,
  notAllocatedToAppUsersList,
} from "../../../../api/apps";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import { changeTitle } from "../../../../state/slices/header";
import TimeComp from "../../../common/TimeComponent/TimeComp";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../../utils/transitionEffectParams";
import { Slide } from "@mui/material";
import { Fade } from "@mui/material";
import GetCapabilities from "../../../../utils/getCapabilities";

const AllocateAppToUser = ({ appName, ActionComponent }) => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const { app_id } = useParams();
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_AllocateAppToUser") !== null
      ? localStorage.getItem("rowsPerPage_AllocateAppToUser")
      : 5
  );
  const [usersList, setUsersList] = useState([]);
  const [users, setUsers] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openAllocateUser, setOpenAllocateUser] = useState(false);
  const [toBeAllocate, setToBeAllocate] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [colName, setColName] = useState(null);
  const pages_url = "dashboard/allocateAppToUser";
  const customizeColumnDict = {
    "User Id": true,
    Locking: true,
    Email: true,
    Name: true,
    "Phone No.": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  const title_heading = useSelector((state) => state.header.title);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const UserColumns = [
    { label: "User Id", value: "id" },
    { label: "Username", value: "username" },
    { label: "Email", value: "email" },
    { label: "Role", value: "role__display_name" },
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
  ];

  const actionOptions = [{ label: "Allocate", value: "allocate" }];

  useEffect(() => {
    dispatch(changeTitle("Apps"));
  }, []);

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getUsersList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, users]);

  const getUsersList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await notAllocatedToAppUsersList({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      app_id: app_id,
    });
    if (data !== null) {
      setUsersList(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalUsers(data.count);
      setFilteredData(data.results);
    } else {
      showError(error);
    }
    setPending(false);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getUsersList({ page: 1, rowsPerPage, search, filterBy });
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = usersList.filter((object) => {
      if (!filterBy) {
        return (
          object.username.toLowerCase().match(s.toLowerCase()) ||
          object.email.toLowerCase().match(s.toLowerCase()) ||
          object.role.display_name.toLowerCase().match(s.toLowerCase())
        );
      } else {
        if (filterBy === "role__display_name")
          return object.role.display_name.toLowerCase().match(s.toLowerCase());
        else return object[filterBy.toLowerCase()].match(s.toLowerCase());
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_AllocateAppToUser", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "allocate") {
      handleAllocate(null);
    }
  };

  const handleAllocate = async (row) => {
    let rowsToAllocate = [];
    if (row) {
      rowsToAllocate = row;
    } else {
      rowsToAllocate = selectedRows;
    }
    setToBeAllocate(rowsToAllocate);
    let temp = [];
    temp = rowsToAllocate.map((item) => ({ id: item.id, name: item.username }));
    setColName(temp);
    setOpenAllocateUser((o) => !o);
  };

  const handleAllocateAction = async (finalFromDate, finalToDate, policy) => {
    let ids = toBeAllocate.map((item) => item.id);
    let count = ids.length;
    const { data, error } = await allocateUsersToApp({
      ids: ids,
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      app_id: app_id,
      policy: policy,
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
    });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalUsers % rowsPerPage === count || rowsPerPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setUsers(Object.create(null));
      setOpenAllocateUser((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "",
      selector: (row) =>
        row.locked === false ? (
          <img src={Unlocked} alt="Unlocked" />
        ) : (
          <img src={Locked} alt="Locked" />
        ),
      minWidth: "100px",
      grow: 1,
      omit: !checkedStateDict["Locking"],
    },
    {
      name: "User Id",
      selector: (row) => row.id,
      minWidth: "150px",
      omit: !checkedStateDict["User Id"],
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.username,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      minWidth: "150px",
      omit: !checkedStateDict["Email"],
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role.display_name,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) =>
        row.first_name !== "" || row.last_name !== ""
          ? row.first_name + " " + row.last_name
          : "-",
      minWidth: "150px",
      omit: !checkedStateDict["Name"],
      sortable: true,
    },
    {
      name: "Phone No.",
      selector: (row) =>
        row.country_code !== "" && row.phone_number !== ""
          ? "+" + row.country_code + " " + row.phone_number
          : "-",
      minWidth: "150px",
      omit: !checkedStateDict["Phone No."],
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) =>
        isCapable &&
        isCapable.apps.allocate_apps && (
          <>
            <p
              className="myassetslink"
              onClick={async () => {
                handleAllocate([row]);
              }}
            >
              <AiOutlineUserAdd></AiOutlineUserAdd>Allocate
            </p>
          </>
        ),
      middle: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Apps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/apps_icon.svg").default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName && (
                  <CustomModal
                    open={openAllocateUser}
                    handleClose={() => {
                      setOpenAllocateUser((o) => !o);
                    }}
                  >
                    <TimeComp
                      setOpenAlert={setOpenAllocateUser}
                      handleAlertAction={handleAllocateAction}
                      resource_id={app_id}
                      resource_type={"apps"}
                      colName={colName}
                      resource_name={appName}
                      target_entity_name={appName}
                      target_type="Users"
                      alertMsg="Below users will be allocated for App"
                      headingMsg="Allocate Users"
                      show_policy={true}
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`App : ${appName}`}
                        dataTableHeadingIcon={assetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      colCount !== null && (
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={4}
                        />
                      )
                    }
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalUsers}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        actions={ActionComponent}
                        placeholder="users"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={UserColumns}
                        actionOptions={
                          isCapable && isCapable.apps.allocate_apps
                            ? actionOptions
                            : null
                        }
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default AllocateAppToUser;

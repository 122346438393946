import {
    Accordion,
    Button,
    Card,
    Col,
    Form,
    OverlayTrigger,
    Row,
    useAccordionButton,
  } from "react-bootstrap";
  import { AiFillQuestionCircle } from "react-icons/ai";
  import Popover from "react-bootstrap/Popover";
  import { MdClose } from "react-icons/md";
  import { NavLink } from "react-router-dom";
  import Reports from "./Reports";
  import LiveSession from "./LiveSession";
  import "./Sidebar.scss";
  import { changeTitle } from "../../../state/slices/header";
  import { useDispatch, useSelector } from "react-redux";
  import useWindowDimensions from "../../../utils/getHeightWidth";
import GetCapabilities from "../../../utils/getCapabilities";

  const activeLink = "d-flex py-2 ps-2 active-accordion text-decoration-none";
  const normalLink = "d-flex py-2 ps-2 inactive-text-color text-decoration-none";
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header
        as="h3"
        className="border-0 bg-white py-3 ff-poppins bg-popover"
      >
        <Row>
          <Col>Need Help ? </Col>
          <Col className="text-end">
            <MdClose id="closecontactus" onClick={() => document.body.click()} />
          </Col>
        </Row>
      </Popover.Header>
      <Popover.Body className="pt-1 ff-poppins">
        <Form id="side-pop">
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label className="pop-form">Name</Form.Label>
            <Form.Control type="text" placeholder="Enter your name" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPhone">
            <Form.Label className="pop-form">Phone Nmuber</Form.Label>
            <Form.Control type="number" placeholder="Enter your number" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicquery">
            <Form.Label className="pop-form">Query Type</Form.Label>
            <Form.Control type="text" placeholder="Enter your query" />
          </Form.Group>
          <Button
            variant="secondary w-100 bg-button fs-14px py-2 mt-2"
            type="submit"
          >
            Contact Us
          </Button>
        </Form>
      </Popover.Body>
    </Popover>
  );
  
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return <div onClick={decoratedOnClick}>{children}</div>;
  }
  
  function AuditSidebar() {
    const my_capabilities=GetCapabilities();
    const isCapable =my_capabilities[0]
    const dispatch = useDispatch();
    const title = useSelector((state) => state.header.title);
    const { width } = useWindowDimensions();
  
    const activeButton =
      " active-linkElement padding_sidebar sidebar-font text-decoration-none d-flex";
  
    const normalButton =
      "inactive-text-color padding_sidebar text-decoration-none text-start sidebar-font d-flex";
  
    return (
      <div className="bs-white">
        <Accordion defaultActiveKey="" flush className="my-2 mr-1 p-30">
        {isCapable &&  (<Card className="border-0 bg-transparent">
          <Card.Header className="p-0 border-0 bg-transparent">
            <CustomToggle eventKey="0">
              <NavLink
                to="/audit-dashboard"
                className={({ isActive }) =>
                  isActive ? activeButton : normalButton
                }
                onClick={() => {
                  dispatch(changeTitle("dashboard"));
                }}
                end
              >
                <img
                  className="mx-3 filter-active"
                  src={require("./icons/dashboard.svg").default}
                  alt="dashboard-icon"
                  height="22px"
                  width="22px"
                />
                Dashboard
              </NavLink>
            </CustomToggle>
          </Card.Header>
        </Card>)}
          {isCapable && isCapable.report.show_reports && <Reports title={title} />}
          {isCapable && isCapable.report.live_session && <LiveSession title={title} />}
        </Accordion>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement={width >= 768 ? "right" : "top"}
          overlay={popover}
        >
          <div
            className={
              width >= 768
                ? "contact-div position-absolute contact-button-fixed px-0 "
                : "position-absolute contact-button-responsive px-0 "
            }
          >
            <div className="cls1">
              <Card
                border="secondary"
                className=" px-0 bg-card card-border ff-poppins card-sidebar"
              >
                <Card.Body>
                  <Button className="d-flex px-2 btn btn-light button-border text-align-center border-0 bg-white ">
                    <AiFillQuestionCircle size="23" className="bg-darkGrey" />
                  </Button>
                  <Card.Title className=" pt-2 mb-0 text-white fs-14px">
                    Need Help?
                  </Card.Title>
                  <Card.Text className="text-white fs-10px">
                    Getting stuck somewhere!
                  </Card.Text>
                  <div className="d-flex justify-content-center">
                    <Button className="py-2 btn btn-light  text-uppercase text-button border-0 bg-white w-100">
                      contact us
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="cls2">
              <Button className="py-2 bg-button border-0 ff-poppins w-100">
                Contact Us
              </Button>
            </div>
          </div>
        </OverlayTrigger>
      </div>
    );
  }
  
  export default AuditSidebar;
  
  export { activeLink, normalLink };
  
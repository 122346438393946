import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import images from "../../../utils/images.js";
import GetCapabilities from "../../../utils/getCapabilities.js";

const Policy = (props) => {
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const { title } = props;
  const dispatch = useDispatch();

  const handleTitle = async () => {
    dispatch(changeTitle("DatabasePolicy"));
  };
  return (
    <Accordion.Item eventKey="Policy"
      className={
        title === "DatabasePolicy" ? "sidebar-button-border " : ""
      }>
      <Accordion.Header>
        <img
          className="mx-3 filter-active"
          src={images.PolicySidebarIcon}
          height="22px"
          width="22px"
        />
        Policy
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup variant="flush" className="sidebar-list">
          {isCapable && isCapable.policies.manage_database_policy.view && (<ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/dashboard/policies/database"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Database</div>
            </NavLink>
          </ListGroup.Item>)}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default Policy;

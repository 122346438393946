import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import { deleteMyAsset, getMyAssetDetail, myAssetsList, } from "../../../../api/asset";
import allAssetHeadingIcon from "../../../../images/all_asset_icon.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import exportIcon from "../../../../images/export.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import plus from "../../../../images/plus.svg";
import uploadIcon from "../../../../images/upload.svg";
import { showError, showSuccess } from "../../../../utils/showMessage";
import "../../../common/css/RDT_common.scss";
import CustomModal from "../../../common/CustomModal/CustomModal";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import AddAsset from "../AddAsset/AddAsset";
import NavDropdown from "react-bootstrap/NavDropdown";
import ThreeDotIcon from "../../../../images/three_dot.svg";
import EditMyAsset from "../EditMyAsset/EditMyAsset";
import { changeTitle } from "../../../../state/slices/header";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import "./MyAssets.scss";
import { Navigate, useNavigate } from "react-router-dom";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import { Fade, Slide } from "@mui/material";
import { delayTime, fadedelayTime, slideDirection } from "../../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import GetCapabilities from "../../../../utils/getCapabilities";

const ActionComponent = ({ setOpenAddAsset }) => {
  const navigate = useNavigate();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0]
  const UploadButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "8px 12px",
    color: "#717171",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const AddButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });

  return (
    <Stack direction="row" spacing={2} className="py-3">
      <div className="d-none">
        <UploadButton
          startIcon={<img src={exportIcon} alt="icon" id="center-blueShadow" />}
        >
          Export
        </UploadButton>
        <UploadButton
          startIcon={<img src={uploadIcon} alt="icon" id="center-blueShadow" />}
        >
          Bulk Upload
        </UploadButton>
      </div>
      {isCapable && isCapable.assets.add && (<AddButton
        onClick={() => {
          setOpenAddAsset((o) => !o);
        }}
        variant="outlined"
        startIcon={
          <img src={plus} alt="icon" id="center-blueShadow" />
        }
      >
        Add Asset
      </AddButton>)} {/* TODO: add capability to system user */}
      <AddButton
        onClick={() => {
          navigate(`/dashboard/mysystemusers`);
        }}
        variant="contained"

      >
        System Users
      </AddButton>
    </Stack>
  );
};

function MyAssets() {
  const { width, height } = useWindowDimensions();
  let datatable_height = (height - 280) + 'px'
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0]
  const navigate = useNavigate();
  const [assets, setAssets] = useState([]);
  const [systemUsers, setSystemUsers] = useState(null);
  const [asset, setAsset] = useState(null);
  const [totalAssets, setTotalAssets] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage_MyAssets') !== null ? localStorage.getItem('rowsPerPage_MyAssets') : 5);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [openAddAsset, setOpenAddAsset] = useState(false);
  const [openEditAsset, setOpenEditAsset] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [assetDetail, setAssetDetail] = useState(null);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [deleteAssetNames, setDeleteAssetNames] = useState([]);
  const [openDeleteAsset, setOpenDeleteAsset] = useState(false);
  const pages_url = "dashboard/all-assets";
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const customizeColumnDict = { "Asset Id": true, "Asset Type": true, "Port": true, "Ignore-Certificate": true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict)
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const assetColumns = [
    { label: "Asset Id", value: "id" },
    { label: "Asset Name", value: "asset_name" },
    { label: "IP Address", value: "ip_host" },
    { label: "Asset Type", value: "asset_type" },
    { label: "Port", value: "port" },
  ];
  const actionOptions = [{ label: "Delete", value: "delete" }];
  const title_heading = useSelector((state) => state.header.title);
  const getMyAssetList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data } = await myAssetsList({
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setAssets(data.assets);
      setFilteredData(data.assets);
      setTotalAssets(data.totalAssets);
      setPending(false);
    }
  };
  useEffect(() => {
    dispatch(changeTitle("Assets"));
  }, [])
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getMyAssetList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, asset]);
  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = assets.filter((object) => {
      return object.asset_name.toLowerCase().match(s.toLowerCase()) ||
        object.ip_host.toLowerCase().match(s.toLowerCase()) ||
        object.asset_type.toLowerCase().match(s.toLowerCase()) ||
        object.id.toString().toLowerCase().match(s.toLowerCase()) ||
        object.port.toString().toLowerCase().match(s.toLowerCase())
    })
    setFilteredData(result);
  }, [search])
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem('rowsPerPage_MyAssets', newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "delete") {
      handleDelete(null);
    }
  };

  const handleDelete = async (row) => {
    let temp = [];
    if (row) {
      temp = row;
    } else {
      temp = selectedRows;
    }
    setRowsToDelete(temp);
    let t = temp.map((object) => object.asset_name);
    setDeleteAssetNames(t);
    setOpenDeleteAsset((o) => !o);
  };
  const handleDeleteAction = async (row) => {
    let ids = rowsToDelete.map((item) => item.id);
    const { data, error } = await deleteMyAsset({ ids: ids });
    if (data !== null) {
      showSuccess(data.message);
      setAsset(Object.create(null));
      setOpenDeleteAsset((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  const handleAssetEdit = async (asset_id) => {
    const { data, error } = await getMyAssetDetail({ asset_id: asset_id });
    if (data !== null) {
      setAssetDetail(data.asset);
      setOpenEditAsset((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    getMyAssetList({ page, rowsPerPage, search, filterBy });
  };
  const columns = [
    {
      name: "Asset Id",
      selector: (row) => row.id,
      grow: 1,
      omit: !checkedStateDict["Asset Id"],
      sortable: true,
    },
    {
      name: "Asset Name",
      selector: (row) => row.asset_name,
      grow: 1,
      sortable: true,
    },
    {
      name: "IP Address",
      selector: (row) => row.ip_host,
      grow: 1,
      sortable: true,
    },
    {
      name: "Asset Type",
      selector: (row) => row.asset_type,
      grow: 1,
      omit: !checkedStateDict["Asset Type"],
      sortable: true,
    },
    {
      name: "Port",
      selector: (row) => row.port,
      grow: 1,
      omit: !checkedStateDict["Port"],
      sortable: true,
    },
    {
      name: "Ignore-Certificate",
      selector: (row) => row.asset_type === "rdp" ? row.ignore_certificate ? "Yes" : "No" : "-",
      grow: 1,
      omit: !checkedStateDict["Ignore-Certificate"],
    },
    isCapable && (isCapable.assets.edit || isCapable.assets.delete || isCapable.assets.allocate_assets || isCapable.assets.deallocate_assets) && {
      name: "Action",
      cell: (row) => (
        <>
          <Nav>
            <NavDropdown
              id="nav-dropdown-systemuser"
              title={<img src={ThreeDotIcon} alt="action" />}
              menuVariant="primary"
            >
              {isCapable && isCapable.assets.edit && (<NavDropdown.Item onClick={() => handleAssetEdit(row.id)}>
                Edit
              </NavDropdown.Item>)}
              {isCapable && isCapable.assets.delete && (<NavDropdown.Item
                onClick={async () => {
                  handleDelete([row]);
                }}
              >
                Delete
              </NavDropdown.Item>)}
              {isCapable && isCapable.assets.allocate_assets && (<NavDropdown.Item
                onClick={() => {
                  navigate(`/dashboard/assets/main-allocate/${row.id}`);
                }}
              >
                Allocate
              </NavDropdown.Item>)}
              {isCapable && isCapable.assets.deallocate_assets && (<NavDropdown.Item
                onClick={() => {
                  navigate(`/dashboard/assets/main-deallocate/${row.id}`);
                }}
              >
                View Allocated
              </NavDropdown.Item>)}
            </NavDropdown>
          </Nav>
        </>
      ),
      middle: true,
      minWidth: "50px",
    },
  ];
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Assets":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("../../../../images/all_asset_icon.svg").default}
                          alt="users round icon"
                        /> &nbsp; Assets
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <CustomModal open={openAddAsset} handleClose={()=>{setOpenAddAsset(o=>!o);}}>
                  <AddAsset setOpenAddAsset={setOpenAddAsset} setAsset={setAsset} />
                </CustomModal>
                {assetDetail && (
                  <CustomModal open={openEditAsset} handleClose={()=>{setOpenEditAsset(o=>!o);}}>
                    <EditMyAsset
                      asset={assetDetail}
                      setOpenEditAsset={setOpenEditAsset}
                      setAsset={setAsset}
                    />
                  </CustomModal>
                )}
                {deleteAssetNames.length > 0 && (
                  <CustomModal open={openDeleteAsset} handleClose={()=>{setOpenDeleteAsset(o=>!o);}}>
                    <ShowAlert
                      setOpenAlert={setOpenDeleteAsset}
                      handleAlertAction={handleDeleteAction}
                      colName={deleteAssetNames}
                      alertMsg="Below assets will be deleted:"
                      headingMsg="Delete Assets"
                    />
                  </CustomModal>
                )}
                <div className="position-relative bg-white" style={{ height: `${datatable_height}` }}>
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="All Assets"
                        dataTableHeadingIcon={allAssetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      colCount !== null && (
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={4}
                        />
                      )
                    }
                    fixedHeader
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalAssets}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    actions={<ActionComponent setOpenAddAsset={setOpenAddAsset} />}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={assetColumns}
                        actionOptions={(isCapable && isCapable.assets.delete) ? actionOptions : null}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        placeholder="all assets"
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
                    paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
                  />
                </div>
              </div>
            </div>
          </Fade>

        </div>
      </Slide>
    </>
  );
}

export default MyAssets;

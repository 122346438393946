import React, { useRef } from "react";
import { useEffect } from "react";
import { Replayer } from "rrweb";
import "rrweb-player/dist/style.css";
import { useState } from "react";
import "./livereplayer.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Bars } from "react-loader-spinner";
import Navbar from "react-bootstrap/Navbar";
import { Button, Modal } from "react-bootstrap";
import useWindowDimensions from "../../../utils/getHeightWidth";
import {Fade} from "@mui/material";
import { fadedelayTime } from "../../../utils/transitionEffectParams";
import GetCapabilities from "../../../utils/getCapabilities";

const LiveReplayer = ({ setOpenUpdatePassword, window_name }) => {
  sessionStorage.setItem("livestreaming", "true");
  const {width,height} = useWindowDimensions()
  const { tab_id } = useParams();
  const { username } = useParams();
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const customer_id = useSelector((state) => state.user?.user?.customer);
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0];
  const ws_protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const ws_endpoint = `${serverURL.replace(/http:|https:/gi, ws_protocol + ':')}/ws/livesession/${customer_id}/`;
  const [spinner, setSpinner] = useState(true);
  const ref = useRef();
  const [showModal, setShowModal] = useState(false);

const [slideChecked, setSlideChecked] = useState(false);
useEffect(() => {
  setSlideChecked(true);
}, []);
  useEffect(() => {
    const replayer = new Replayer([], {
      root: ref.current,
      liveMode: true,
      mouseTail: false,
      UNSAFE_replayCanvas: true,
    });
    replayer.startLive();
    const recording_ws_connection = new WebSocket(ws_endpoint);
    recording_ws_connection.onopen = function (e) {
      const data_message = {'tab_id':tab_id}
      recording_ws_connection.send(
        JSON.stringify({
          type: "start_recording",
          message: data_message,
        })
      );
    };
    recording_ws_connection.onmessage = function (e) {
      let data = JSON.parse(e.data);
      if (data.type === "recorded_events") {
        if (data.message.tab == tab_id && data.message.event.type != "custom") {
          setSpinner(false);
          replayer.addEvent(data.message.event);
        }
      }
    };
  }, []);
  const style = {
    height: "auto",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
  };
  return (
    <>
    {spinner == false && (
          <Navbar className="px-5 livereplayernav">
            <Navbar.Brand className="fs-22px ff-pam">
              <span className="fw-600">Live Streaming</span> - {username}
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              {isCapable && isCapable.miscellaneous.setting.kill_session &&(<Button
                variant="danger"
                onClick={() => {
                  const recording_ws_connection = new WebSocket(ws_endpoint);
                  recording_ws_connection.onopen = function (e) {
                    recording_ws_connection.send(
                      JSON.stringify({
                        type: "kill_session",
                        message: tab_id,
                      })
                    );
                    setShowModal(true);
                  };
                }}
              >
                Terminate Session
              </Button>)}
            </Navbar.Collapse>
          </Navbar>
        )}
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">

        {spinner == true && (
          <div style={style}>
            <Bars
              height="80"
              width="80"
              color="#5177FF"
              ariaLabel="bars-loading"
              visible={true}
            />
            <span id="spinnerloading">Loading</span>
          </div>
        )}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Session Terminated Successfully</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button style={{width:"90px"}} variant="primary" onClick={() => window.close()}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        <div style={{ '--width': `${width-80}px` ,'--height':`${height}px`}} ref={ref}></div>
      </div>
  </div>
</Fade>

    </>
  );
};

export default LiveReplayer;

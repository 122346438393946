import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { updateMySystemUser } from '../../../../api/systemuser';
import { showError, showSuccess } from '../../../../utils/showMessage';
import CrossIcon from '../../../../images/cross.svg';
import LightTooltip from "../../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "../../Roles/icons/icon-info.svg";
import { Fade } from "@mui/material";
import { fadedelayTime } from '../../../../utils/transitionEffectParams';
import { Image } from "react-bootstrap";
import images from "../../../../utils/images";

const EditSystemUser = ({ systemUser, setOpenEditSystemUser, setSystemUser }) => {
    const [system_user_id, setSystemUserId] = useState(null);
    const [systemDispalyUserName, setSystemDisplayUserName] = useState("");
    const [systemUserName, setSystemUserName] = useState("");
    const [password, setPassword] = useState("");
    const [certificate, setCertificate] = useState("");
    const [mfa, setMfa] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showHidden1, setShowHidden1] = useState(false);
    const [showHidden2, setShowHidden2] = useState(false);
    const [slideChecked, setSlideChecked] = useState(false);
    useEffect(() => {
        setSlideChecked(true);
    }, []);
    useEffect(() => {
        setSystemUserId(systemUser.id);
        setSystemDisplayUserName(systemUser.system_display_username);
        setSystemUserName(systemUser.system_username);
        setPassword(systemUser.password === null ? "" : systemUser.password);
        setCertificate(systemUser.certificate === null ? "" : systemUser.certificate);
        setMfa(systemUser.mfa_configured);
    }, [systemUser])
    useEffect(() => {
    }, [password, certificate])

    const updateSystemUserFormHandler = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            const { data, error } = await updateMySystemUser({
                "system_user_id": system_user_id, "updatedSystemUser": {
                    ...(systemDispalyUserName !== "" && { "system_display_username": systemDispalyUserName }),
                    ...(systemUserName !== "" && { "system_username": systemUserName }),
                    ...(password !== "" ? { "password": password } : { "password": null }),
                    ...(certificate !== "" ? { "certificate": certificate } : { "certificate": null }),
                    ...(mfa !== "" && { "mfa_configured": mfa }),
                }
            })
            if (data !== null) {
                showSuccess(data.message);
                setSystemUser(new Object());
                setLoading(false);
                setOpenEditSystemUser(o => !o)
            }
            if (error !== null) {
                showError(error)
                setLoading(false);
            }
        }
    }
    const isRequired = () => {
        return password === "" && certificate === "";
    }

    return (
        <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
                <Container fluid className="edit_systemuser_container mx-auto main_content_container">
                    <div className='d-flex justify-content-between align-items-center  mb-4'>
                        <h2 className="main_content_heading">Edit System User</h2>
                        <img src={CrossIcon} alt="close" className="cursor_pointer" onClick={() => setOpenEditSystemUser(o => !o)} />
                    </div>
                    <Form noValidate validated={validated} onSubmit={updateSystemUserFormHandler} className="edit_systemuser_form">
                        <Stack gap={1}>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextNameDisplay">
                                <Form.Label column md={3} className="input_label">
                                    Display Username<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Control
                                        required
                                        className="form_input_field"
                                        type="text"
                                        value={systemDispalyUserName}
                                        onChange={event => setSystemDisplayUserName(event.target.value)}
                                        placeholder="Enter your system display username"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid display username
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextName">
                                <Form.Label column md={2} className="input_label">
                                    Username<span className="text-danger">*</span>
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Control
                                        required
                                        type="text"
                                        className="form_input_field"
                                        value={systemUserName}
                                        onChange={event => setSystemUserName(event.target.value)}
                                        placeholder="Enter your system username"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a username
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formeditPlainPassword">
                                <Form.Label column md={2} className="input_label">
                                    Password{isRequired() && <span className="text-danger">*</span>}
                                </Form.Label>
                                <Col md={9}>
                                    <InputGroup
                                        className="pb-2 form_label"
                                    >
                                        <Form.Control
                                            type={showHidden2 ? "text" : "password"}
                                            required={isRequired()}
                                            onChange={event => setPassword(event.target.value)}
                                            value={password}
                                            placeholder="Enter your Password"
                                            className="form_input_field border-end-0 border_bottom_right_radius_0 border_top_right_radius_0"
                                        />
                                        <InputGroup.Text
                                            className="bg-white form_input_group_text"
                                            onClick={() => setShowHidden2(!showHidden2)}
                                        >
                                            {showHidden2 === false ? (
                                                <Image
                                                    src={images.Show}
                                                    height="20px"
                                                    className="px-2"
                                                ></Image>
                                            ) : (
                                                <Image
                                                    src={images.Hide}
                                                    height="20px"
                                                    className="px-2"
                                                ></Image>
                                            )}
                                        </InputGroup.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a password or certificate
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formeditPlainTextAreaCertificate">
                                <Form.Label column md={2} className="input_label">
                                    Certificate{isRequired() && <span className="text-danger">*</span>}
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        required={isRequired()}
                                        value={certificate}
                                        onChange={event => setCertificate(event.target.value)}
                                        placeholder="Certificate"
                                        className="form_input_field"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a certificate or password
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {false && <Form.Group as={Row} className="mb-3 justify-content-between" controlId="switch">
                                <Form.Label column md={2} className="input_label">
                                    MFA
                                </Form.Label>
                                <Col md={9}>
                                    <Form.Check
                                        type='checkbox'
                                        id='mfa-checkbox'
                                        checked={mfa}
                                        onChange={() => setMfa(!mfa)}
                                    />
                                </Col>
                            </Form.Group>}
                            <Row className="mb-3 justify-content-end">
                                <Col sm="auto">
                                    <Button variant='light' as="input" className="form_cancel_button" type="button" value="Cancel" onClick={() => setOpenEditSystemUser(o => !o)} />
                                </Col>
                                <Col sm="auto" className="me-sm-2 p-0">
                                    <Button as="input" className="form_submit_button" type="submit" value="Update" disabled={loading} />
                                </Col>
                            </Row>
                        </Stack>
                    </Form>
                </Container>
            </div>
        </Fade>

    )
}

export default EditSystemUser;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import "react-tabs/style/react-tabs.css";
import { updateTicketDetails, CheckTicketDetails } from "../../../../api/ticketing";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { useSelector } from "react-redux";
import { getToken } from "../../../../api/apps";
import ButtonSpinner from "../../../common/ButtonSpinner/ButtonSpinner";

function RejectTicket({
  setRejectionModal,
  approvalTicket,
  resource,
  approvalType,
  getTicketDetail,
}) {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [ticket, setTicket] = useState();
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const ws_protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const ws_endpoint = `${serverURL.replace(/http:|https:/gi, ws_protocol + ':')}/`;
  const customer_id = useSelector((state) => state.user?.user?.customer || "");
  const [sessionkey, setSessionkey] = useState(null);

  useEffect(() => {
    setTicket(approvalTicket);
    ApprovalDateTime(approvalTicket);
    handleTicketing();
  }, []);

  const handleTicketing = async () => {
    const { tokenData, tokenError } = await getToken();
    setSessionkey(tokenData.session_key);
  };

  const sendTicketResponse = async (user_id) => {
    const app_connection_endpoint = `${ws_endpoint}ws/ticket/${customer_id}_${user_id}/?session_key=${sessionkey}`;
    const ticketing_ws_connection = new WebSocket(app_connection_endpoint);
    ticketing_ws_connection.onopen = function (e) {
      ticketing_ws_connection.send(
        JSON.stringify({
          type: "ticketData",
          message: { user_id: user_id },
          session_key: sessionkey,
        })
      );
      ticketing_ws_connection.close()
    };
  };

  const ApprovalDateTime = (ticket) => {
    let TicketFromDate = new Date(ticket.access_from * 1000);
    setFrom(
      TicketFromDate.getFullYear() +
        "-" +
        (TicketFromDate.getMonth() < 9
          ? "0" + (TicketFromDate.getMonth() + 1)
          : TicketFromDate.getMonth() + 1) +
        "-" +
        (TicketFromDate.getDate() <= 9
          ? "0" + TicketFromDate.getDate()
          : TicketFromDate.getDate())
    );
    setFromTime(
      (TicketFromDate.getHours() <= 9
        ? "0" + TicketFromDate.getHours()
        : TicketFromDate.getHours()) +
        ":" +
        (TicketFromDate.getMinutes() <= 9
          ? "0" + TicketFromDate.getMinutes()
          : TicketFromDate.getMinutes())
    );

    let TicketToDate = new Date(ticket.access_until * 1000);

    setTo(
      TicketToDate.getFullYear() +
        "-" +
        (TicketToDate.getMonth() < 9
          ? "0" + (TicketToDate.getMonth() + 1)
          : TicketToDate.getMonth() + 1) +
        "-" +
        (TicketToDate.getDate() <= 9
          ? "0" + TicketToDate.getDate()
          : TicketToDate.getDate())
    );
    setToTime(
      (TicketToDate.getHours() <= 9
        ? "0" + TicketToDate.getHours()
        : TicketToDate.getHours()) +
        ":" +
        (TicketToDate.getMinutes() <= 9
          ? "0" + TicketToDate.getMinutes()
          : TicketToDate.getMinutes())
    );
  };

  const ticketResponse = async (e) => {
    e.preventDefault();
      const { data, error } = await CheckTicketDetails(ticket);
      if (data !== null) {
        if (!loading) {
          setLoading(true);
          let finalFromDate, finalToDate;
          finalFromDate = new Date(from + " " + fromTime + ":00");
          finalToDate = new Date(to + " " + toTime + ":59");
          const { data, error } = await updateTicketDetails({
            id: [approvalTicket.id],
            approval_type: approvalType,
            response: responseMessage,
            response_time: new Date(),
            access_from: Date.parse(finalFromDate) / 1000,
            access_until: Date.parse(finalToDate) / 1000,
            access_for_user_type: null,
          });
          if (data != null) {
            setRejectionModal((o) => !o);
            getTicketDetail();
            showSuccess(data.message);
            sendTicketResponse(approvalTicket.requested_by.id);
          }
          if (error != null) {
            showError(error);
          }
          setLoading(false);
        }
      }
      if (error !== null) {
        showError(error);
        setRejectionModal((o) => !o);
        setLoading(false);
      }
    
  };

  return (
    <>
      <Container fluid className="alert_container mx-auto">
        <Form
          onSubmit={(e) => {
            ticketResponse(e);
          }}
          className="add_systemuser_form"
        >
          <Stack gap={1}>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="formPlaintextName"
            >
              <Col md={12}>
                <h2 className="ff-pam fw-500 profile_heading fs-24px">
                  <>Declining!</>
                </h2>
                <h2 className="pt-2 pb-3 ff-pam fw-400 profile_heading fs-14px">
                  We notice you are rejecting this ticket. Please help us with a
                  reason for the same. <span className="text-danger">*</span>
                </h2>
                <Row
                >
                  <Col md={6}>
                  <Form.Label column md={3} className="input_label ">
                  Created By -
                </Form.Label>
                  <Form.Label column md={9}>
                  {approvalTicket.requested_by.username} 
                </Form.Label>
                
                  </Col>
                  <Col md={6}>
                  <Form.Label column md={4} className="input_label">
                  {approvalTicket.resource_type == 'APP' ? <>App</> : approvalTicket.resource_type == 'ASSET' ? <>Asset</> : <>Web App</>} Name -
                </Form.Label>
                <Form.Label column md={8}>
                {approvalTicket.resource_type == 'APP' ? <>{resource.app_name}</> : approvalTicket.resource_type == 'ASSET' ? <>{resource.asset_name}</> : <>{resource.app_name}</>}
                </Form.Label>
                  </Col>
                </Row>
                <Form.Label column md={6} className="input_label mt-3">
                  Message <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  className="form_input_field"
                  as="textarea"
                  rows={4}
                  value={responseMessage}
                  onChange={(event) => setResponseMessage(event.target.value)}
                  placeholder="Enter your response here."
                />
              </Col>
            </Form.Group>
            <Row className="pt-4 mb-3 justify-content-end">
              <Col sm="auto">
                <Button
                  variant="light"
                  as="input"
                  className="form_cancel_button"
                  type="button"
                  value="Cancel"
                  onClick={() => {
                    setRejectionModal((o) => !o);
                    setLoading(false);
                  }}
                />
              </Col>
              <Col sm="auto" className="me-sm-2 p-0">
                {loading ? (
                  <Button
                    style={{width: "110px"}}
                    className="form_submit_button"
                    variant="primary"
                    disabled
                  >
                      <ButtonSpinner width={'15px'} height={'15px'} color={'white'}></ButtonSpinner>
                  </Button>
                ) : (
                  <Button
                    style={{width: "110px"}}
                    as="input"
                    className="form_submit_button"
                    type="submit"
                    value="Confirm"
                  />
                )}
              </Col>
            </Row>
          </Stack>
        </Form>
      </Container>
    </>
  );
}
export default RejectTicket;

import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ExtendAppAllocation } from "../../../../api/apps";
import {
  AllocatedUserAppsList,
  DeallocateAppsFromUser,
} from "../../../../api/users";
import assetHeadingIcon from "../../../../images/User_heading_icon.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import ThreeDotIcon from "../../../../images/three_dot.svg";
import { changeTitle } from "../../../../state/slices/header";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import TimeCompExtend from "../../../common/TimeComponent/TimeCompExtend";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../../utils/transitionEffectParams";
import { Slide, Fade } from "@mui/material";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import GetCapabilities from "../../../../utils/getCapabilities";

const DeAllocateUserFromApp = ({ userName, ActionComponent }) => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_DeAllocateUserFromApp") !== null
      ? localStorage.getItem("rowsPerPage_DeAllocateUserFromApp")
      : 5
  );
  const [appsList, setAppsList] = useState([]);
  const [apps, setApps] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openViewUser, setOpenViewUser] = useState(false);
  const [toBeDeAllocate, setToBeDeAllocate] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [colName, setColName] = useState(null);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const pages_url = "dashboard/users/resource-deallocate/";
  const title_heading = useSelector((state) => state.header.title);
  const customizeColumnDict = {
    "App Id": true,
    "IP Address": true,
    "App Type": true,
    Port: true,
    "Default Database": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const appColumns = [
    { label: "App Id", value: "id" },
    { label: "App Name", value: "app_name" },
    { label: "IP Address", value: "ip_host" },
    { label: "App Type", value: "app_type" },
    { label: "Port", value: "port" },
    { label: "Default Database", value: "default_database" },
  ];
  const [openExtendUserModal, setOpenExtendUserModal] = useState(false);
  const [toBeExtend, setToBeExtend] = useState([]);
  const actionOptions = [{ label: "Remove", value: "remove" }];
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    getUsersList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, apps]);

  const getUsersList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await AllocatedUserAppsList({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      user_id: user_id,
      type: "apps",
    });
    if (data !== null) {
      setAppsList(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalUsers(data.count);
      setFilteredData(data.results);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getUsersList({ page: 1, rowsPerPage, search, filterBy });
  };

  useEffect(() => {
    dispatch(changeTitle("DeAllocateApps"));
  }, []);

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = appsList.filter((object) => {
      if (!filterBy) {
        return (
          object.app_name.toLowerCase().match(s.toLowerCase()) ||
          object.ip_host.toLowerCase().match(s.toLowerCase()) ||
          object.app_type.toLowerCase().match(s.toLowerCase()) ||
          object.id.toString().toLowerCase().match(s.toLowerCase()) ||
          object.port.toString().toLowerCase().match(s.toLowerCase()) ||
          object.default_database
            .toString()
            .toLowerCase()
            .match(s.toLowerCase())
        );
      } else {
        if (filterBy === "role__display_name")
          return object.role.display_name.toLowerCase().match(s.toLowerCase());
        else return object[filterBy.toLowerCase()].match(s.toLowerCase());
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_DeAllocateUserFromApp", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "remove") {
      handleDeAllocate(null);
    }
  };

  const handleDeAllocate = async (row) => {
    let rowsToDeAllocate = [];
    if (row) {
      rowsToDeAllocate = row;
    } else {
      rowsToDeAllocate = selectedRows;
    }
    setToBeDeAllocate(rowsToDeAllocate);
    let temp = [];
    temp = rowsToDeAllocate.map((item) => item.app_name);
    setColName(temp);
    setOpenViewUser((o) => !o);
  };

  const handleExtend = async (row) => {
    let rowsToExtend = [];
    if (row) {
      rowsToExtend = row;
    } else {
      rowsToExtend = selectedRows;
    }
    setToBeExtend(rowsToExtend);
    var temp;
    temp = rowsToExtend.map((item) => ({
      id: item.app_id,
      name: item.app_name,
    }));
    setColName(temp);
    setOpenExtendUserModal((o) => !o);
  };

  const handleDeAllocateAction = async () => {
    let ids = toBeDeAllocate.map((item) => item.id);
    let count = ids.length;
    const { data, error } = await DeallocateAppsFromUser({
      ids: ids,
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      user_id: user_id,
      type: "apps",
    });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalUsers % rowsPerPage === count || rowsPerPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setApps(Object.create(null));
      setOpenViewUser((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const handleExtendFunction = async (finalFromDate, finalToDate, policy) => {
    let ids = toBeExtend.map((item) => item.id);
    //extend api
    const { data, error } = await ExtendAppAllocation({
      entity_type: "user",
      entity_id: user_id,
      app_id: ids[0],
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
      policy_id: policy.id,
    });

    if (data !== null) {
      showSuccess(data.message);
    }
    if (error !== null) {
      showError(error);
    }

    setOpenExtendUserModal((o) => !o);
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "App Id",
      selector: (row) => row.id,
      grow: 1,
      omit: !checkedStateDict["App Id"],
      sortable: true,
    },
    {
      name: "App Name",
      selector: (row) => row.app_name,
      grow: 1,
      sortable: true,
    },
    {
      name: "IP Address/Domain",
      selector: (row) => row.ip_host,
      grow: 1,
      omit: !checkedStateDict["IP Address"],
      width: "200px",
      sortable: true,
    },
    {
      name: "App Type",
      selector: (row) => row.app_type,
      grow: 1,
      omit: !checkedStateDict["App Type"],
      sortable: true,
    },
    {
      name: "Port",
      selector: (row) => row.port,
      grow: 1,
      omit: !checkedStateDict["Port"],
      sortable: true,
    },
    {
      name: "Default Database",
      selector: (row) => row.default_database,
      grow: 1,
      omit: !checkedStateDict["Default Database"],
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Nav>
            <NavDropdown
              id=""
              title={<img src={ThreeDotIcon} alt="action" />}
              menuVariant="primary"
            >
              {isCapable && isCapable.apps.allocate_apps && (
                <NavDropdown.Item
                  id="appslink"
                  onClick={async () => {
                    handleExtend([row]);
                  }}
                >
                  <AiOutlineUserAdd></AiOutlineUserAdd> Extend
                </NavDropdown.Item>
              )}
              {isCapable && isCapable.apps.deallocate_apps && (
                <NavDropdown.Item
                  id="appslink"
                  onClick={async () => {
                    handleDeAllocate([row]);
                  }}
                >
                  <AiOutlineUserDelete></AiOutlineUserDelete> Remove
                </NavDropdown.Item>
              )}
            </NavDropdown>
          </Nav>
        </>
      ),
      minWidth: "150px",
      grow: 1,
      middle: true,
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "DeAllocateApps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/User_heading_icon.svg")
                              .default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Remove Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName && (
                  <CustomModal
                    open={openViewUser}
                    handleClose={() => {
                      setOpenViewUser((o) => !o);
                    }}
                  >
                    <ShowAlert
                      setOpenAlert={setOpenViewUser}
                      colName={colName}
                      handleAlertAction={handleDeAllocateAction}
                      alertMsg={`Below Apps will be Deallocated for User:`}
                      entity_name={userName}
                      headingMsg="Remove Apps"
                    />
                  </CustomModal>
                )}
                {colName && toBeExtend.length != 0 && (
                  <CustomModal
                    open={openExtendUserModal}
                    handleClose={() => {
                      setOpenExtendUserModal((o) => !o);
                    }}
                  >
                    <TimeCompExtend
                      setOpenAlert={setOpenExtendUserModal}
                      handleAlertAction={handleExtendFunction}
                      colName={colName}
                      toBeExtend={[{ id: user_id }]}
                      resource_id={toBeExtend[0].id}
                      alertMsg={`Below App will be extended for User`}
                      resource_name={toBeExtend[0].app_name}
                      target_entity_name={userName}
                      headingMsg="Extend App"
                      type={"user"}
                      target_type={"Apps"}
                      resource_type={"apps"}
                      show_policy={true}
                    />
                  </CustomModal>
                )}

                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`User : ${userName}`}
                        dataTableHeadingIcon={assetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalUsers}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="app"
                        actions={ActionComponent}
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={appColumns}
                        actionOptions={
                          isCapable && isCapable.apps.deallocate_apps
                            ? actionOptions
                            : null
                        }
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default DeAllocateUserFromApp;

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import { useSelector } from 'react-redux';
import { updateMySystemUser } from '../../../../api/systemuser';
import { showError, showSuccess } from '../../../../utils/showMessage';
import CrossIcon from '../../../../images/cross.svg';
import { getGroupDetails, updateGroup } from '../../../../api/groups';
import {Fade} from "@mui/material";
import { fadedelayTime } from '../../../../utils/transitionEffectParams';

const EditGroup = ({group, setOpenEditGroup, setGroup }) => {
    const [group_id, setGroupId] = useState(null);
    const [groupName, setGroupName] = useState("");
    const [groupDisplayName, setDisplayName] = useState("");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [slideChecked, setSlideChecked] = useState(false);
useEffect(() => {
  setSlideChecked(true);
}, []);
    useEffect(() => {
        setGroupId(group.group_id);
        setDisplayName(group.group_display_name);
        setGroupName(group.group_name);
    }, [group]);

    const updateGroupFormHandler = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            const { data, error } = await updateGroup({
                "group_id": group_id, "updatedGroup": {
                    ...(groupName !== "" && { "group_name": groupName }),
                    ...(groupDisplayName !== "" && { "group_display_name": groupDisplayName })
                }
            })
            if (data !== null) {
                showSuccess(data.message);
                setGroup(Object.create(null));
                setLoading(false);
                setOpenEditGroup(o => !o)
            }
            if (error !== null) {
                showError(error)
                setLoading(false);
            }
        }
    }

    return (
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <Container fluid className="edit_group_container mx-auto main_content_container ">
            <div className='d-flex justify-content-between align-items-center  mb-4'>
                <h2 className="main_content_heading">Edit Group</h2>
                <img src={CrossIcon} alt="close" className="cursor_pointer" onClick={() => setOpenEditGroup(o => !o)} />
            </div>
            <Form noValidate validated={validated} onSubmit={updateGroupFormHandler} className="edit_systemuser_form">
                <Stack gap={1}>
                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextName">
                        <Form.Label column md={3} className="input_label">
                            Display Name
                        </Form.Label>
                        <Col md={9}>
                            <Form.Control
                                required
                                type="text"
                                className="form_input_field"
                                value={groupDisplayName}
                                onChange={event => setDisplayName(event.target.value)}
                                placeholder="Enter your group display name"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a display name
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formeditPlainPassword">
                        <Form.Label column md={2} className="input_label">
                            Name
                        </Form.Label>
                        <Col md={9}>
                            <Form.Control
                                required
                                className="form_input_field"
                                type="text"
                                value={groupName}
                                onChange={event => setGroupName(event.target.value)}
                                placeholder="Enter your group name"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a group name
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Row className="mb-3 justify-content-end">
                        <Col sm="auto">
                            <Button variant='light' as="input" className="form_cancel_button" type="button" value="Cancel" onClick={() => setOpenEditGroup(o => !o)} />
                        </Col>
                        <Col sm="auto" className="me-sm-2 p-0">
                            <Button as="input" className="form_submit_button" type="submit" value="Update" disabled={loading} />
                        </Col>
                    </Row>
                </Stack>
            </Form>
        </Container>         
  </div>
</Fade>
       
    )
}

export default EditGroup;

import axios from "../../../api/axios_interceptor";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FcLock } from "react-icons/fc";
import { useSelector } from "react-redux";
import { get_jwt_token, loginRedirect } from "../../../api/helper_funtions";
import {
  showEmptyError,
  showError,
  showSuccess,
} from "../../../utils/showMessage";
import "./ChangePassword.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import { useEffect } from "react";
import { Slide } from "@mui/material";
import { delayTime,fadedelayTime, slideDirection } from "../../../utils/transitionEffectParams";
import {Fade} from "@mui/material";
import useWindowDimensions from "../../../utils/getHeightWidth";
import SaveButton from "../../common/SaveButton/SaveButton";
import { ResetButton } from "../../common/SaveButton/SaveButton";
export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 190 + "px";
  const [currentpassword, setCurrentpassword] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const id = useSelector((state) => state.user.user.id);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);
    
  const RegisterAdmin = async (event) => {
    event.preventDefault();
    let formField = new FormData();
    formField.append("currentpassword", currentpassword);
    formField.append("id", id);
    formField.append("password", password);
    formField.append("confirmpassword", confirmpassword);
    const token = get_jwt_token();
    try {
      loginRedirect(token);
      const response = await axios({
        method: "PUT",
        url: "/users/adminpassword/",
        data: formField,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data !== null) {
        showSuccess(response.data.message);
        navigate("/dashboard");
      }
    } catch (e) {
      if (e.response.data.message !== null) {
        showEmptyError(e.response.data.message);
      }
      if (e.response.data.errors !== null) {
        showError(e.response.data.errors);
      }
    }
  };
  const handleClick = () => {
    setConfirmpassword("");
    setCurrentpassword("");
    setpassword("");
  };
  useEffect(() => {
    dispatch(changeTitle("dashboard"));
  }, []);
  return (
    <>
      <div className="heading_datable bg-white">
        <span
          style={{ color: "#50514F" }}
          className="ff-poppins fs-24px fw-600"
        >
          <div className="d-flex align-items-center">
            <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
              <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                <FcLock id="passwordicon2"></FcLock> &nbsp; Change Password
              </span>
              <br />
            </div>
          </div>
        </span>
      </div>
      <Slide  timeout={delayTime}  direction={slideDirection} in={slideChecked}>
        <div>
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
        <div
          className="position-relative bg-white p-4 overflow-auto"
          style={{ height: `${datatable_height}` }}
        >
          <div className="p-3 pb-2 w-100" id="passwordp4">
            <i id="passwordnote">
              <b>Note &nbsp;: &nbsp;</b> For Setting Password, we recommend one
              that :
              <ul id="passwordul">
                <li className="mt-1">
                  Password must be at least 8 characters and not more than 20
                  characters
                </li>
                <li className="mt-1">
                  Must include at least one upper case letter and one lower case
                  letter
                </li>
                <li className="mt-1">
                  Must include at least one special character and one numeric
                  character
                </li>
              </ul>
            </i>
          </div>
          <Form className="pt-3" onSubmit={RegisterAdmin}>
            <Form.Group className="mb-3">
              <Row className="mb-3">
                <Col md="6">
                  <Form.Label id="passwordlabel1">
                    Current Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    id="passwordinput1"
                    type="password"
                    required
                    placeholder="Current password"
                    value={currentpassword}
                    onChange={(e) => setCurrentpassword(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md="6">
                  <Form.Label id="passwordlabel1">
                    New Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    id="passwordinput1"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()<>])[A-Za-z\d@$!%*?&#^()<>]{8,20}$"
                    type="password"
                    required
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </Col>
                <Col md="6">
                  <Form.Label id="passwordlabel1">
                    Confirm Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    id="passwordinput1"
                    type="password"
                    required
                    placeholder="Confirm Password"
                    value={confirmpassword}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>

          <SaveButton type="submit" className="me-4" variant="outlined">
            Save
          </SaveButton>
          <ResetButton onClick={handleClick} variant="outlined">
            Reset
          </ResetButton>
        </Form>
      </div>
      </div>      
  </div>
</Fade>
      
      </div>
      </Slide>
    </>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { ExtendResourceAllocation } from "../../../../api/role";
import { DeallocateResourceFromRole } from "../../../../api/role";
import assetHeadingIcon from "../../../../images/User_heading_icon.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import ThreeDotIcon from "../../../../images/three_dot.svg";
import { changeTitle } from "../../../../state/slices/header";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import TimeCompExtend from "../../../common/TimeComponent/TimeCompExtend";
import { roleResourceList } from "../../../../api/role";
import { Slide, Fade } from "@mui/material";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { useSelector } from "react-redux";

const DeAllocateRoleFromAssets = ({ roleName, ActionComponent }) => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const { role_id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_DeAllocateRoleFromAssets") !== null
      ? localStorage.getItem("rowsPerPage_DeAllocateRoleFromAssets")
      : 5
  );
  const [assetsList, setAssetsList] = useState([]);
  const [assets, setAssets] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openViewRole, setOpenViewRole] = useState(false);
  const [toBeDeAllocate, setToBeDeAllocate] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [colName, setColName] = useState(null);
  const pages_url = "dashboard/roleAssets";
  const customizeColumnDict = { "Asset Type": true, ID: true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const AssetsColumns = [
    { label: "Asset Name", value: "asset_name" },
    { label: "ID", value: "id" },
    { label: "IP Address", value: "ip_host" },
    { label: "Asset Type", value: "asset_type" },
    { label: "Port", value: "port" },
  ];
  const [openExtendUserModal, setOpenExtendUserModal] = useState(false);
  const [toBeExtend, setToBeExtend] = useState([]);
  const actionOptions = [{ label: "Remove", value: "deallocate" }];
  const title_heading = useSelector((state) => state.header.title);
  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    dispatch(changeTitle("DeAllocateAssets"));
    setSlideChecked(true);
  }, []);

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    getAssetsList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, assets]);

  const getAssetsList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await roleResourceList({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      role_id: role_id,
      resource_type: "asset",
      allocated: 1,
    });
    if (data !== null) {
      setAssetsList(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalUsers(data.count);
      setFilteredData(data.results);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getAssetsList({ page: 1, rowsPerPage, search, filterBy });
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = assetsList.filter((object) => {
      if (!filterBy) {
        return (
          object.asset_name.toLowerCase().match(s.toLowerCase()) ||
          object.ip_host.toLowerCase().match(s.toLowerCase()) ||
          object.asset_type.toLowerCase().match(s.toLowerCase()) ||
          object.id.toString().toLowerCase().match(s.toLowerCase()) ||
          object.port.toString().toLowerCase().match(s.toLowerCase())
        );
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_DeAllocateUserFromAssets", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "deallocate") {
      handleDeAllocate(null);
    }
  };

  const handleDeAllocate = async (row) => {
    let rowsToDeAllocate = [];
    if (row) {
      rowsToDeAllocate = row;
    } else {
      rowsToDeAllocate = selectedRows;
    }
    setToBeDeAllocate(rowsToDeAllocate);
    let temp = [];
    temp = rowsToDeAllocate.map((item) => item.asset_name);
    setColName(temp);
    setOpenViewRole((o) => !o);
  };

  const handleExtend = async (row) => {
    let rowsToExtend = [];
    if (row) {
      rowsToExtend = row;
    } else {
      rowsToExtend = selectedRows;
    }
    setToBeExtend(rowsToExtend);
    var temp;
    temp = rowsToExtend.map((item) => ({ id: item.id, name: item.asset_name }));
    setColName(temp);
    setOpenExtendUserModal((o) => !o);
  };

  const handleDeAllocateAction = async () => {
    let ids = toBeDeAllocate.map((item) => item.id);
    let count = ids.length;
    const { data, error } = await DeallocateResourceFromRole({
      resource_ids: ids,
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      role_id: role_id,
      resource_type: "asset",
      allocate: 0,
    });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalUsers % rowsPerPage === count || rowsPerPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setAssets(Object.create(null));
      setOpenViewRole((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const handleExtendFunction = async (finalFromDate, finalToDate, policy) => {
    let ids = toBeExtend.map((item) => item.id);
    //extend api
    const { data, error } = await ExtendResourceAllocation({
      entity_type: "asset",
      entity_id: role_id,
      resource_id: ids[0],
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
    });

    if (data !== null) {
      showSuccess(data.message);
    }
    if (error !== null) {
      showError(error);
    }

    setOpenExtendUserModal((o) => !o);
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "Asset Name",
      selector: (row) => row.asset_name,
      grow: 1,
    },
    {
      name: "IP Address",
      selector: (row) => row.ip_host,
      grow: 1,
    },
    {
      name: "Asset Type",
      selector: (row) => row.asset_type,
      grow: 1,
      omit: !checkedStateDict["Asset Type"],
    },
    {
      name: "ID",
      selector: (row) => row.id,
      grow: 1,
      omit: !checkedStateDict["ID"],
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Nav>
            <NavDropdown
              id=""
              title={<img src={ThreeDotIcon} alt="action" />}
              menuVariant="primary"
            >
              <NavDropdown.Item
                id="appslink"
                className=""
                onClick={async () => {
                  handleExtend([row]);
                }}
              >
                <AiOutlineUserAdd></AiOutlineUserAdd> Extend
              </NavDropdown.Item>
              <NavDropdown.Item
                id="appslink"
                className=""
                onClick={async () => {
                  handleDeAllocate([row]);
                }}
              >
                <AiOutlineUserDelete></AiOutlineUserDelete> Remove
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </>
      ),
      minWidth: "150px",
      grow: 1,
      middle: true,
    },
  ];

  var today = new Date();
  //receive date from backend.
  var day = today.getDate();
  if (day < 10) day = "0" + day;
  var month = today.getMonth() + 1;
  if (month < 10) month = "0" + month;
  var year = today.getFullYear();
  var hour = today.getHours();
  if (hour < 10) hour = "0" + hour;
  var minute = today.getMinutes();
  if (minute < 10) minute = "0" + minute;

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "DeAllocateAssets":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/User_heading_icon.svg")
                              .default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Remove Assets
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName && (
                  <CustomModal
                    open={openViewRole}
                    handleClose={() => {
                      setOpenViewRole((o) => !o);
                    }}
                  >
                    <ShowAlert
                      setOpenAlert={setOpenViewRole}
                      handleAlertAction={handleDeAllocateAction}
                      colName={colName}
                      alertMsg={`Below assets will be deallocated for `}
                      entity_name={roleName}
                      headingMsg="Deallocate Assets"
                    />
                  </CustomModal>
                )}
                {colName && toBeExtend.length != 0 && (
                  <CustomModal
                    open={openExtendUserModal}
                    handleClose={() => {
                      setOpenExtendUserModal((o) => !o);
                    }}
                  >
                    <TimeCompExtend
                      setOpenAlert={setOpenExtendUserModal}
                      handleAlertAction={handleExtendFunction}
                      colName={colName}
                      toBeExtend={[{ id: role_id }]}
                      resource_id={toBeExtend[0].id}
                      alertMsg={`Below asset will be extended for role`}
                      entity_name={roleName}
                      headingMsg="Extend Asset"
                      type={"role"}
                      target_type={"Assets"}
                      resource_type={"assets"}
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`Role : ${roleName}`}
                        dataTableHeadingIcon={assetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalUsers}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="asset"
                        actions={ActionComponent}
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={AssetsColumns}
                        actionOptions={actionOptions}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default DeAllocateRoleFromAssets;

import { Col, Row } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { EntityCounts } from "../../../api/users";
import { DashboardPlot } from "./Plot";
import { useSelector, useDispatch } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import EnduserDashboard from "./EnduserDashboard";
import { DashboardBarPlot } from "./AuditHome";
import Slide from "@mui/material/Slide";
import CircularProgress from '@mui/material/CircularProgress';
import { delayTime,fadedelayTime } from "../../../utils/transitionEffectParams";
import { Fade } from "@mui/material";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import useWindowDimensions from "../../../utils/getHeightWidth";
import GetCapabilities from "../../../utils/getCapabilities";

export default function Home(props) {
  const { isAuditDashboard } = props;
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const isCapableEnduser=  my_capabilities[1]
  const dispatch = useDispatch();
  const [usersCount, setUsersCount] = useState([]);
  const [assetsCount, setAssetsCount] = useState([]);
  const [systemUsersCount, setSystemUsersCount] = useState([]);
  const [appsCount, setAppsCount] = useState([]);
  const [myAssetsCount, setMyAssetsCount] = useState([]);
  const [myAppsCount, setMyAppsCount] = useState([]);
  const [recordingsMade, setRecordingsMade] = useState([]);
  const [usersMonitored, setUsersMonitored] = useState([]);
  const [graph, setGraph] = useState();
  const [Assetgraph, setAssetGraph] = useState();
  const [lastLoginTime, setLastLoginTime] = useState(
    "1970-01-30T08:59:00.000Z"
  );
  const [slideChecked, setSlideChecked] = useState(false);
  const title_heading = useSelector((state) => state.header.title);
  const id = useSelector((state) => state.user.user.id);
  const first_name = useSelector((state) => state.user.user.first_name);
  const last_name = useSelector((state) => state.user.user.last_name);
  const username = useSelector((state) => state.user.user.username);
  const customer_id = useSelector((state) => state.user?.user?.customer);
  const name = first_name + " " + last_name;
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const ws_protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const ws_endpoint = `${serverURL.replace(/http:|https:/gi, ws_protocol + ':')}/ws/livesession/${customer_id}/`;
  const [activeSessions, setActiveSessions] = useState([]);
  let listusers = [];
  useEffect(() => {
    dispatch(changeTitle("dashboard"));
  }, []);
  useEffect(() => {
    setSlideChecked(true);
    if(isCapable){
      getCounts();
    }
    if(isCapableEnduser && isCapableEnduser.view_dashboard){
      getCountsEndUser();
    }
  }, []);

  const getCounts = async () => {
    const response = await EntityCounts(id);
    setUsersCount(response.data.users_count);
    setAssetsCount(response.data.assets_count);
    setSystemUsersCount(response.data.systemusers_count);
    setAppsCount(response.data.apps_count);
    setRecordingsMade(response.data.recordings_made);
    setUsersMonitored(response.data.users_monitored);
    setGraph(response.data.apps_assets_graph);
    setAssetGraph(response.data.assets_apps_graph);
  };
  const getCountsEndUser = async () => {
    const response = await EntityCounts(id);
    setMyAssetsCount(response.data.my_assets_count);
    setMyAppsCount(response.data.my_apps_count);
    setLastLoginTime(response.data.last_login_time);
  };
  const { width } = useWindowDimensions();
  useEffect(() => {
    const recording_ws_connection = new WebSocket(ws_endpoint);
    recording_ws_connection.onopen = function (e) {
      recording_ws_connection.send(
        JSON.stringify({
          type: "active_tabs_list",
          message: "get_tab_id",
        })
      );
      recording_ws_connection.onmessage = function (e) {
        const data = JSON.parse(e.data);
        if (data.type === "active_users") {
          if (window.name != data.message.tab_id) {
            const found = listusers.some(el => el.tab_id === data.message.tab_id);
            if (!found) {
                listusers.push(data.message);
            }
          }
          const distinctlist = [...new Set(listusers)];
          listusers = distinctlist;
          setActiveSessions(distinctlist+1)

        }
      };
    };
  }, []);
  return (
    <div>
      {(() => {
        switch (title_heading) {
          case "dashboard":
            return (
              <div className="bg-white position-sticky top-0" style={{zIndex:'500'}}>
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-22px text-color-h1 ps-4 fw-semibold ">
                        Welcome to your Dashboard {width >= 925 ? "," : ""}
                      </span>{" "}
                      {width >= 925 ? (
                        <span className="fs-18px text-color-h3 ">
                          {name === " " ? username : name}
                        </span>
                      ) : (
                        <></>
                      )}
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="ff-pam main_content_container mx-auto bg-home">
        {isCapable && isCapable.miscellaneous.setting.admin_dashboard && isAuditDashboard == false &&(
          <div className="overflow-auto">
          {!slideChecked &&(<div className="h-100 d-flex justify-content-center align-items-center">
           <div className=""><CircularProgress color="inherit" /></div>
          </div>)}
          <Slide direction="down" timeout={delayTime} in={slideChecked}>
            <div>
            <div className="px-4 pt-1">
              <Row
                style={{ backgroundColor: "white" }}
                className="py-3 mt-2 border-light d-flex align-items-center"
              >
                <Col
                  lg={3}
                  md={6}
                  sm={12}
                  className="px-0 border-end border-2 border-light h-100 py-2"
                >
                  <Row>
                    <Col>
                      <div className="ps-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          <img
                            src={require("./icons/users.svg").default}
                            alt="users round icon"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="pe-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          Total Users
                        </p>
                        <p className=" my-0 ff-pam fs-24px fw-600">
                          {usersCount}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={3}
                  md={6}
                  sm={12}
                  className="px-0 border-end border-2 border-light h-100 py-2"
                >
                  <Row>
                    <Col>
                      <div className="ps-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          <img
                            src={require("./icons/assets.svg").default}
                            alt="assets round icon"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="pe-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          Total Assets
                        </p>
                        <p className=" my-0 ff-pam fs-24px fw-600">
                          {assetsCount}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={3}
                  md={6}
                  sm={12}
                  className="px-0 border-end border-2 border-light h-100 py-2"
                >
                  <Row>
                    <Col>
                      <div className="ps-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          <img
                            src={require("./icons/sys_users.svg").default}
                            alt="assets round icon"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="pe-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          System Users
                        </p>
                        <p className=" my-0 ff-pam fs-24px fw-600">
                          {systemUsersCount}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} md={6} sm={12} className="px-0 h-100 py-2">
                  <Row>
                    <Col>
                      <div className="ps-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          <img
                            src={require("./icons/apps.svg").default}
                            alt="assets round icon"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="pe-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          Total Apps
                        </p>
                        <p className=" my-0 ff-pam fs-24px fw-600">
                          {appsCount}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              </div>
              </div>
          </Slide>
              <Row className="mt-2 w-100">
                <div className="pt-1">
                  <DashboardPlot />
                </div>
              </Row>
          </div>
        )}

        {isCapable && isCapable.miscellaneous.setting.audit_dashboard&& isAuditDashboard == true && (
          <>
            <Slide direction="down" timeout={delayTime} in={slideChecked}>
            <div>
            <div className="px-4 pt-1">
              <Row
                style={{ backgroundColor: "white" }}
                className="py-3 mt-2 border-light d-flex align-items-center"
              >
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className="px-0 border-end border-2 border-light h-100 py-2"
                >
                  <Row>
                    <Col>
                      <div className="ps-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          <img
                            src={require("./icons/users.svg").default}
                            alt="users round icon"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="pe-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                        Active Users
                        </p>
                        <p className=" my-0 ff-pam fs-24px fw-600">
                        {usersMonitored}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className="px-0 border-end border-2 border-light h-100 py-2"
                >
                  <Row>
                    <Col>
                      <div className="ps-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          <img
                            src={require("./icons/video.svg").default}
                            alt="assets round icon"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="pe-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                        Active Sessions
                        </p>
                        <p className=" my-0 ff-pam fs-24px fw-600">
                        {activeSessions.length}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className="px-0 border-end border-2 border-light h-100 py-2"
                >
                  <Row>
                    <Col>
                      <div className="ps-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          <img
                            src={require("./icons/disk.svg").default}
                            alt="assets round icon"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="pe-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                        Recordings Made
                        </p>
                        <p className=" my-0 ff-pam fs-24px fw-600">
                          {recordingsMade}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              </div>
              </div>
              </Slide>
                       <Slide direction="right" timeout={delayTime} in={slideChecked}>
            <div>
              <Row className="mt-2 w-100">
                <div className="pt-1 pe-0">
                {graph && Assetgraph && <DashboardBarPlot graph={graph} Assetgraph={Assetgraph}></DashboardBarPlot>}
                </div>
              </Row>
            </div>
          </Slide>
          </>
        )}

        {isCapableEnduser && isCapableEnduser.view_dashboard && (
          <>
          <Slide direction="down" timeout={delayTime} in={slideChecked}>

            <div className="px-4 pt-1">
              <Row
                style={{ backgroundColor: "white" }}
                className="py-3 mt-2 border-light d-flex align-items-center"
              >
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className="px-0 border-end border-2 border-light h-100 py-2"
                >
                  <Row>
                    <Col>
                      <div className="ps-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          <img
                            src={require("./icons/assets.svg").default}
                            alt="users round icon"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="pe-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                        My Assets
                        </p>
                        <p className=" my-0 ff-pam fs-24px fw-600">
                        {myAssetsCount}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className="px-0 border-end border-2 border-light h-100 py-2"
                >
                  <Row>
                    <Col>
                      <div className="ps-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          <img
                            src={require("./icons/apps.svg").default}
                            alt="assets round icon"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="pe-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                        My Apps
                        </p>
                        <p className=" my-0 ff-pam fs-24px fw-600">
                        {myAppsCount}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className="px-0 border-end border-2 border-light h-100 py-2"
                >
                  <Row>
                    <Col>
                      <div className="ps-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                          <img
                            src={require("./icons/sys_users.svg").default}
                            alt="assets round icon"
                          />
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="pe-5">
                        <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                        Last log in
                        </p>
                        <p className=" my-0 ff-pam fs-14px fw-600">
                        {lastLoginTime}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              </div>
          </Slide>
          <Slide direction="right" timeout={delayTime} in={slideChecked}>
            <div>
              <Row className="mt-2 w-100">
                <div className="pt-1">
                <EnduserDashboard></EnduserDashboard>
                </div>
              </Row>
              </div>
          </Slide>
          </>
        )}


      </div>
  </div>
</Fade>

    </div>
  );
}

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdWebAsset } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import { changeTitle } from "../../../../state/slices/header";
import GetCapabilities from "../../../../utils/getCapabilities";
import images from '../../../../utils/images';
import { showError } from "../../../../utils/showMessage";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import Loader from "../../../common/Loader/Loader";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import '../../Assets/UsersAssets/UsersAssets.scss';

import { Fade, Slide } from "@mui/material";
import { getEndUserProductSettings } from "../../../../api/customer";
import { challengeMFAForResourceAccess } from "../../../../api/two_fa";
import { myWebAppsListUser } from "../../../../api/web_app";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { delayTime, fadedelayTime, slideDirection } from "../../../../utils/transitionEffectParams";
import CustomModal from "../../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import MFAOnResourceAccess from "../../TwoFa/MFAOnResourceAccess/MFAOnResourceAccess";
import { getToken } from "../../../../api/apps";
const UserWebApps = () => {
    const navigate = useNavigate();
    const { width, height } = useWindowDimensions();
    let datatable_height = (height - 280) + 'px'
    const my_capabilities = GetCapabilities();
    const isCapable = my_capabilities[0]
    const isCapableEnduser = my_capabilities[1]
    const { serverUrl } = useSelector((state) => state.customer);
    const userid = useSelector((state) => state.user.user.id);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rowsPerPage_UsersWebApps') !== null ? localStorage.getItem('rowsPerPage_UsersWebApps') : 5);
    const [totalWebApps, setTotalWebApps] = useState(0);
    const [webApps, setWebApps] = useState([]);
    const [filterBy, setFilterBy] = React.useState("");
    const [search, setSearch] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const pages_url = "dashboard/usersWebApps";
    const customizeColumnDict = { "Web App Id": true, "Login Page URL": false };
    const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
    const [open, setOpen] = useState(false);
    const [configuredMFAInfo, setConfiguredMFAInfo] = useState("");
    const [rowID, setRowID] = useState("");
    const [colCount, setColCount] = useState(null);
    const [pending, setPending] = useState(true);
    const [slideChecked, setSlideChecked] = useState(false);
    const [accessButtonLoading, setAccessButtonLoading] = useState(false);
    useEffect(() => {
        setSlideChecked(true);
    }, []);
    const title_heading = useSelector((state) => state.header.title);
    const webAppColumns = [
        { label: "Web App Id", value: "Web App Id" },
        { label: "Web App Name", value: "app_name" },
        { label: "Login Page URL", value: "login_page_url" },
        { label: "Web App Type", value: "app_type" },
    ];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeTitle("WebApps"));
    }, [])
    useEffect(() => {
        GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
        getWebAppsList({ page, rowsPerPage, search, filterBy });
    }, [page, rowsPerPage]);

    const getWebAppsList = async ({ page, rowsPerPage, search, filterBy }) => {
        setPending(true);
        const { data, error } = await myWebAppsListUser({
            userid: userid,
            page: page - 1,
            limit: rowsPerPage,
            search: search,
            filterBy: filterBy,
        });
        if (data !== null) {
            setWebApps(data.web_apps);
            setFilteredData(data.web_apps);
            setTotalWebApps(data.totalWebApps);
            setPending(false)
        }
        if (error !== null) {
            showError(error);
        }
    };

    useEffect(() => {
        let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
        const result = webApps.filter((object) => {
            return object.app_name.toLowerCase().match(s.toLowerCase()) ||
                object.app_type.toLowerCase().match(s.toLowerCase()) ||
                object.login_page_url.toLowerCase().match(s.toLowerCase()) ||
                object.id.toString().toLowerCase().match(s.toLowerCase())
        })
        setFilteredData(result);
    }, [search])

    const handleSearch = (event) => {
        event.preventDefault();
        getWebAppsList({ page, rowsPerPage, search, filterBy });
    }

    const handlePageChange = (page) => {
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setPage(1);
        localStorage.setItem('rowsPerPage_UsersWebApps', newPerPage);
        setRowsPerPage(newPerPage);
    };
    const getConfiguredMFAMethod = async () => {
        const { data, error } = await challengeMFAForResourceAccess(userid);
        if (data != null) {
            setConfiguredMFAInfo(data);
            setOpen(o => !o);
        }
        if (error != null) {
            showError(error);
        }
        setAccessButtonLoading(false);
    }

    const openAppTab = async (openModal = true, row_id = null) => {
        const { tokenData, tokenError } = await getToken();
        if (row_id == null) {
            row_id = rowID;
        }
        if (tokenData !== null) {
            const url = serverUrl + "/?resource_id=" + row_id + '&resource_type=web_apps' + '&token_key=' + tokenData.token_key + '&token_value=' + tokenData.token_value + '&session_key=' + tokenData.session_key;
            window.open(url, "_blank");
            if (openModal) {
                setOpen(o => !o);
            }
        }
        if (tokenError !== null) {
            showError(tokenError);
        }
    }

    const checkIfMFARequired = async (row_id) => {
        setAccessButtonLoading(true);
        const { data, error } = await getEndUserProductSettings();
        if (data != null) {
            if (data.MFAOverResourceAccess === 'false') {
                setAccessButtonLoading(false);
                openAppTab(false, row_id);
            }
            else {
                getConfiguredMFAMethod();
            }
        }
        if (error != null) {
            showError(error);
            setAccessButtonLoading(false);

        }
    }

    const columns = [
        {
            name: "Web App Id",
            selector: (row) => row.id,
            grow: 1,
            omit: !checkedStateDict["Web App Id"],
        },
        {
            name: "Web App Name",
            selector: (row) => row.app_name,
            grow: 1,
        },
        {
            name: "Login Page URL",
            selector: (row) => row.login_page_url,
            omit: !checkedStateDict["Login Page URL"],
        },
        {
            name: "Web App Type",
            selector: (row) => row.app_type,
            grow: 1,
        },
        ((isCapable && isCapable.web_apps.access_web_apps) || (isCapableEnduser && isCapableEnduser.access_web_apps)) && {
            name: "Action",
            cell: (row) => (
                ((isCapable && isCapable.web_apps.access_web_apps) || (isCapableEnduser && isCapableEnduser.access_web_apps)) &&
                <div role="button" className="text-decoration-none text-primary d-flex align-items-center"
                    onClick={() => {
                        setRowID(row.id);
                        checkIfMFARequired(row.id);
                    }}>
                    <div className="d-flex align-items-center"><MdWebAsset /></div>
                    <div className="ps-1">Access</div>
                </div>

            ),
            middle: true,
            minWidth: "50px"
        },

    ];
    return (
        <>
            <Loader loading={accessButtonLoading} bg_papper={true} />
            <CustomModal open={open} width="48% !important" bgcolor="white" handleClose={()=>{setOpen(o=>!o);}}>
                <MFAOnResourceAccess
                    userid={userid}
                    setOpen={setOpen}
                    configuredMFAInfo={configuredMFAInfo}
                    serverUrl={serverUrl}
                    rowID={rowID}
                    openAppTab={openAppTab}
                />
            </CustomModal>
            {(() => {
                switch (title_heading) {
                    case "WebApps":
                        return (
                            <div className="heading_datable bg-white">
                                <span
                                    style={{ color: "#50514F" }}
                                    className="ff-poppins fs-24px fw-600"
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                                            <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                                                <img
                                                    src={images.WebAppHeading}
                                                    alt="users round icon"
                                                /> &nbsp; Web Apps
                                            </span>
                                            <br />
                                        </div>
                                    </div>
                                </span>
                            </div>
                        );
                }
            })()}
            <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
                <div>
                    <Fade timeout={fadedelayTime} in={slideChecked}>
                        <div>
                            <div className="main_content_container p-3 mx-auto w-100" >
                                <div className="position-relative bg-white" style={{ height: `${datatable_height}` }}>
                                    <DataTable
                                        title={
                                            <ListHeading
                                                dataTableHeadingText="My Web Apps"
                                                dataTableHeadingIcon={images.WebAppHeading}
                                            />
                                        }
                                        columns={columns}
                                        data={filteredData}
                                        className="rdt_container"
                                        fixedHeader
                                        progressPending={pending}
                                        progressComponent={
                                            colCount !== null && (
                                                <CustomDelaySkeleton
                                                    rowsPerPage={rowsPerPage}
                                                    colCount={colCount}
                                                    totalColumns={6}
                                                />
                                            )
                                        }
                                        paginationPerPage={rowsPerPage}
                                        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalWebApps}
                                        highlightOnHover
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        subHeader
                                        subHeaderComponent={
                                            <SubHeaderComponent
                                                filterBy={filterBy}
                                                setFilterBy={setFilterBy}
                                                filterOptions={webAppColumns}
                                                search={search}
                                                setSearch={setSearch}
                                                handleSearch={handleSearch}
                                                placeholder="web_apps"
                                                pages_url={pages_url}
                                                checkedStateDict={checkedStateDict}
                                                setCheckedStateDict={setCheckedStateDict}
                                            />
                                        }
                                        subHeaderAlign="center"
                                        paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
                                        paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
                                        paginationIconNext={<img src={NextPageIcon} alt="next" />}
                                        paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fade>

                </div>
            </Slide>
        </>
    );
};
export default UserWebApps;

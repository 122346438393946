import axios from './axios_interceptor';
import { get_jwt_token, loginRedirect } from './helper_funtions';

export const listUsers = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/users/user/?page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getUserDetails = async (id) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/users/user/?id=${id}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const addUsersData = async (args) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/users/user/`;
    const response = await axios.post(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const updateUserDetails = async (args) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/users/user/`;
    const response = await axios.put(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const deleteUser = async ({ ids }) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        ids: ids,
      },
    };
    const url = `/users/user/`;
    const response = await axios.delete(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const updateUserPassword = async (args) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/users/password/`;
    const response = await axios.put(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const bulkUploadUser = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      }
    };
    const url = `/users/uploadData/`;
      const response = await axios.post(url, data,config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getUsersCsv = async () => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/users/exportcsv/`;
    const response = await axios.get(url, config);
    return { data: response, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const EntityCounts = async (id) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/users/count/?userid=${id}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};
export const forgotPassword = async (data) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const url = `/users/forgot-password/`;
    const response = await axios.post(url, data, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const resetPassword = async (data) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const url = `/users/reset-password/?reset_token=${data.reset_token}`;
    const args = {
      password: data.password,
      confirm_password: data.confirmPassword
    }
    const response = await axios.post(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};


export const getUserProfile = async (id) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/users/usersprofile/?id=${id}`;
    const response = await axios.get(url,config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const updateUsersProfile = async (args) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/users/usersprofile/`;
    const response = await axios.put(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};


export const getAuthenticatedUserProfile = async () => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let url = `/users/profile/`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const signOutAllDevices = async () => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let url = `/users/signout-all-devices/`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const notAllocatedUserAppsList = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
      };
      const url = `/users/allocate-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.get(url, config);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const allocateAppsToUser = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          }
      };
      const body = {
          app_ids: data.ids,
          user_id: data.user_id,
          allocation_from: data.allocation_from,
          allocation_till: data.allocation_till,
          policy: data.policy
      }
      const url = `/users/allocate-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.post(url, body, config);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const notAllocatedUserAssetsList = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
      };
      const url = `/users/allocate-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.get(url, config);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const allocateAssetsToUser = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          }
      };
      const body = {
          asset_ids: data.ids,
          user_id: data.user_id,
          allocation_from: data.allocation_from,
          allocation_till: data.allocation_till
      }
      const url = `/users/allocate-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.post(url, body, config);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const AllocatedUserAppsList = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
      };
      const url = `/users/allocated-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.get(url, config);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const DeallocateAppsFromUser = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
          data : {
            app_ids: data.ids,
            user_id: data.user_id
        }
      };
      const url = `/users/allocated-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.delete(url, config, data);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const AllocatedUserAssetsList = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
      };
      const url = `/users/allocated-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.get(url, config);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const DeallocateAssetsFromUser = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
          data : {
            asset_ids: data.ids,
            user_id: data.user_id
        }
      };
      const url = `/users/allocated-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.delete(url, config, data);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const AllocatedUserGroupsList = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
      };
      const url = `/users/allocated-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.get(url, config);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const DeallocateGroupsFromUser = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
          data : {
            group_ids: data.ids,
            user_id: data.user_id
        }
      };
      const url = `/users/allocated-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.delete(url, config, data);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const notAllocatedUserGroupsList = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
      };
      const url = `/users/allocate-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.get(url, config);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};

export const allocateGroupsToUser = async (data) => {
  try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          }
      };
      const body = {
          group_ids: data.ids,
          user_id: data.user_id,
      }
      const url = `/users/allocate-resource/?user_id=${data.user_id}&type=${data.type}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
      const response = await axios.post(url, body, config);
      return {data: response.data, error: null};
  } catch (e) {
      return {data: null, error: e.response.data.errors};
  }
};
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  allocateAppsToUser,
  notAllocatedUserAppsList,
} from "../../../../api/users";
import assetHeadingIcon from "../../../../images/User_heading_icon.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import { changeTitle } from "../../../../state/slices/header";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import TimeComp from "../../../common/TimeComponent/TimeComp";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { Slide, Fade } from "@mui/material";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../../utils/transitionEffectParams";
import GetCapabilities from "../../../../utils/getCapabilities";

const AllocateUserToApp = ({ userName, ActionComponent }) => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_AllocateUserToApp") !== null
      ? localStorage.getItem("rowsPerPage_AllocateUserToApp")
      : 5
  );
  const [appsList, setAppsList] = useState([]);
  const [apps, setApps] = useState(null);
  const [totalApps, setTotalApps] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openAllocateUser, setOpenAllocateUser] = useState(false);
  const [toBeAllocate, setToBeAllocate] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [colName, setColName] = useState(null);
  const [checkedState, setCheckedState] = useState([]);
  const pages_url = "dashboard/users/resource-allocate";
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const title_heading = useSelector((state) => state.header.title);
  const customizeColumnDict = {
    "App Id": true,
    "IP Address": true,
    "App Type": true,
    Port: true,
    "Default Database": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const appColumns = [
    { label: "App Id", value: "id" },
    { label: "App Name", value: "app_name" },
    { label: "IP Address", value: "ip_host" },
    { label: "App Type", value: "app_type" },
    { label: "Port", value: "port" },
    { label: "Default Database", value: "default_database" },
  ];

  const actionOptions = [{ label: "Allocate", value: "allocate" }];

  useEffect(() => {
    dispatch(changeTitle("AllocateApps"));
  }, []);

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    getUsersList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, apps]);

  const getUsersList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await notAllocatedUserAppsList({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      user_id: user_id,
      type: "apps",
    });
    if (data !== null) {
      setAppsList(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalApps(data.count);
      setFilteredData(data.results);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getUsersList({ page: 1, rowsPerPage, search, filterBy });
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = appsList.filter((object) => {
      if (!filterBy) {
        return (
          object.app_name.toLowerCase().match(s.toLowerCase()) ||
          object.ip_host.toLowerCase().match(s.toLowerCase()) ||
          object.app_type.toLowerCase().match(s.toLowerCase()) ||
          object.id.toString().toLowerCase().match(s.toLowerCase()) ||
          object.port.toString().toLowerCase().match(s.toLowerCase()) ||
          object.default_database
            .toString()
            .toLowerCase()
            .match(s.toLowerCase())
        );
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_AllocateUserToApp", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "allocate") {
      handleAllocate(null);
    }
  };

  const handleAllocate = async (row) => {
    let rowsToAllocate = [];
    if (row) {
      rowsToAllocate = row;
    } else {
      rowsToAllocate = selectedRows;
    }
    setToBeAllocate(rowsToAllocate);
    let temp = [];
    temp = rowsToAllocate.map((item) => ({ id: item.id, name: item.app_name }));
    setColName(temp);
    setOpenAllocateUser((o) => !o);
  };

  const handleAllocateAction = async (finalFromDate, finalToDate, policy) => {
    let ids = toBeAllocate.map((item) => item.id);
    let count = ids.length;
    const { data, error } = await allocateAppsToUser({
      ids: ids,
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      user_id: user_id,
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
      type: "apps",
      policy: policy,
    });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalApps % rowsPerPage === count || rowsPerPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setApps(Object.create(null));
      setOpenAllocateUser((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "App Id",
      selector: (row) => row.id,
      grow: 1,
      omit: !checkedStateDict["App Id"],
      sortable: true,
    },
    {
      name: "App Name",
      selector: (row) => row.app_name,
      grow: 1,
      sortable: true,
    },
    {
      name: "IP Address/Domain",
      selector: (row) => row.ip_host,
      grow: 1,
      omit: !checkedStateDict["IP Address"],
      width: "200px",
      sortable: true,
    },
    {
      name: "App Type",
      selector: (row) => row.app_type,
      grow: 1,
      omit: !checkedStateDict["App Type"],
      sortable: true,
    },
    {
      name: "Port",
      selector: (row) => row.port,
      grow: 1,
      omit: !checkedStateDict["Port"],
      sortable: true,
    },
    {
      name: "Default Database",
      selector: (row) => row.default_database,
      grow: 1,
      omit: !checkedStateDict["Default Database"],
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) =>
        isCapable &&
        isCapable.apps.allocate_apps && (
          <>
            <p
              className="myassetslink"
              onClick={async () => {
                handleAllocate([row]);
              }}
            >
              <AiOutlineUserAdd></AiOutlineUserAdd>Allocate
            </p>
          </>
        ),
      middle: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "AllocateApps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/User_heading_icon.svg")
                              .default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Allocate Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName && (
                  <CustomModal
                    open={openAllocateUser}
                    handleClose={() => {
                      setOpenAllocateUser((o) => !o);
                    }}
                  >
                    <TimeComp
                      setOpenAlert={setOpenAllocateUser}
                      handleAlertAction={handleAllocateAction}
                      colName={colName}
                      entity_name={userName}
                      target_type="Apps"
                      resource_type={"apps"}
                      alertMsg="Below Apps will be allocated for User"
                      headingMsg="Allocate Apps"
                      show_policy={true}
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`User : ${userName}`}
                        dataTableHeadingIcon={assetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalApps}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        actions={ActionComponent}
                        placeholder="apps"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={appColumns}
                        actionOptions={
                          isCapable && isCapable.apps.allocate_apps
                            ? actionOptions
                            : null
                        }
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        setCheckedStateDict={setCheckedStateDict}
                        checkedStateDict={checkedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default AllocateUserToApp;

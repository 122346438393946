import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Select from 'react-select';
import { addGroup } from '../../../../api/groups';
import { listUsers } from '../../../../api/users';
import CrossIcon from '../../../../images/cross.svg';
import { showError, showSuccess } from '../../../../utils/showMessage';
import {Fade} from "@mui/material";
import { fadedelayTime } from '../../../../utils/transitionEffectParams';

const AddGroup = ({ open, setOpenAddGroup, setGroup }) => {
    const [groupName, setGroupName] = useState("");
    const [groupDisplayName, setGroupDisplayName] = useState("");
    const [certificate, setCertificate] = useState("");
    const [asset, setAsset] = useState("");
    const [user, setUser] = useState("");
    const [groupUser, setGroupsUsers] = useState("");
    const [assets, setAssets] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const [slideChecked, setSlideChecked] = useState(false);
    useEffect(() => {
      setSlideChecked(true);
    }, []);
    const getUsers = async () => {
        const { data } = await listUsers({ "page": -1, "page_size": -1 });
        if (data !== null) {
            setUsers(data.users);
        }
    }
    const selectStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            paddingTop: "3px",
            paddingBottom: "3px",
            borderColor: "#ced4da",
            boxShadow: "none",
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#717171",
            ':hover': {
                borderColor: "#ced4da",
            }
        }),
        option: (styles) => ({
            ...styles,
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
        }),
    }
    useEffect(() => {
        getUsers();
    }, [])
    const addGroupFormHandler = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            const { data, error } = await addGroup({
                ...(groupName !== "" && { "group_name": groupName }),
                ...(groupDisplayName !== "" && { "group_display_name": groupDisplayName }),
                ...(groupUser !== null && { "users": groupUser })
            })
            if (data !== null) {
                showSuccess(data.message);
                setGroup(Object.create(null));
                setLoading(false);
                setOpenAddGroup(o => !o)
            }
            if (error !== null) {
                showError(error);
                setLoading(false);
            }
        }
    }

    return (
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <Container fluid className=" mx-auto add_group_container main_content_container">
            <div className='d-flex justify-content-between align-items-center  mb-4'>
                <h2 className="main_content_heading">Add Group</h2>
                <img src={CrossIcon} alt="close" className="cursor_pointer" onClick={() => setOpenAddGroup(o => !o)} />
            </div>
            <Form noValidate validated={validated} onSubmit={addGroupFormHandler} className="add_systemuser_form">
                <Stack gap={1}>
                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextName">
                        <Form.Label column md={3} className="input_label">
                            Display Name<span className="text-danger">*</span>
                        </Form.Label>
                        <Col md={9}>
                            <Form.Control
                                required
                                className="form_input_field"
                                type="text"
                                value={groupDisplayName}
                                onChange={event => setGroupDisplayName(event.target.value)}
                                placeholder="Enter your group display name"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a display name
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextName">
                        <Form.Label column md={2} className="input_label">
                            Name<span className="text-danger">*</span>
                        </Form.Label>
                        <Col md={9}>
                            <Form.Control
                                required
                                className="form_input_field"
                                type="text"
                                value={groupName}
                                onChange={event => setGroupName(event.target.value)}
                                placeholder="Enter your group name"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a group name
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextAssetType">
                        <Form.Label column md={2} className="input_label">
                            Users
                        </Form.Label>
                        <Col md={9}>
                            <Select
                                isMulti
                                className="systemuser_select"
                                classNamePrefix="asset"
                                getOptionLabel={(option) => option.email}
                                getOptionValue={(option) => option.id}
                                isClearable={true}
                                isSearchable={true}
                                name="systemuser_select"
                                options={users}
                                onChange={(selectedOption) => { setGroupsUsers(selectedOption.map(user => user.id)) }}
                                styles={selectStyles}
                            />
                        </Col>
                    </Form.Group>

                    <Row className="mb-3 justify-content-end">
                        <Col sm="auto">
                            <Button variant='light' as="input" type="button" className="form_cancel_button" value="Cancel" onClick={() => setOpenAddGroup(o => !o)} />
                        </Col>
                        <Col sm="auto" className="me-sm-2 p-0">
                            <Button as="input" type="submit" value="Add" className="form_submit_button" disabled={loading} />
                        </Col>
                    </Row>
                </Stack>
            </Form>
        </Container>       
  </div>
</Fade>
        
    )
}

export default AddGroup;
